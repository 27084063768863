import {
  Avatar,
  Badge,
  Card,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Box,
  Grid,
  Button
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, withStyles } from "@material-ui/styles";
import { captureException } from "@sentry/react";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearToastAction, toastAction } from "../../../../actions";
import api from "../../../../utils/api";
import getAvatarStyle from "../../../../utils/getAvatarStyle";
import rolename from "../../../../utils/rolename";
import getInitials from "../../../../utils/getInitials";
import Loader from "../../../../utils/loader";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end"
  },
  capitalize: {
    textTransform: "capitalize"
  },
  chipButton: {
    paddingLeft: 12,
    paddingRight: 12
  },
  tableCell: {
    paddingLeft: 8,
    paddingRight: 8
  },
  bold: {
    fontWeight: "bold"
  },
  checkedIn: {
    backgroundColor: "green"
  },
  checkedOut: {
    backgroundColor: "red"
  },

  filterTextField: {
    "&::placeholder": {
      color: "#000"
    },
    "&::-ms-input-placeholder ": {
      color: "#000"
    }
  }
}));

const columns = [
  {
    label: "User",
    dataType: "profile",
    field: "user"
  },
  {
    label: "In Time",
    dataType: "datetime",
    field: "inTime"
  },
  {
    label: "Out Time",
    dataType: "datetime",
    field: "outTime"
  },
  {
    label: "Status",
    dataType: "status",
    field: "status"
  }
];

const StyledBadge = withStyles(theme => ({
  badge: {
    right: -48,
    top: 10,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px"
  }
}))(Badge);

const Results = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const orgSettings = useSelector(state => state.orgSettings);
  const [loading, setLoading] = useState(true);
  const [suggestionLoading, setSuggestionLoading] = useState(false);
  const [openSuggestion, setOpenSuggestion] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [filterEmail, setFilterEmail] = useState("");
  const [meetings, setMeetings] = useState([]);
  const [meetingCount, setMeetingCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState({});
  const [currentSort, setCurrentSort] = useState({
    label: "Date & time",
    direction: "desc"
  });
  const [query, setQuery] = useState({
    search: [],
    sort: { key: "checkInActivity.createdAt", order: "DESC" },
    skip: 0,
    take: 10
  });

  let navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      orgSettings.tempMeasure &&
      !columns.map(colItem => colItem.label).includes("Temperature")
    )
      columns.splice(3, 0, {
        label: "Temperature",
        dataType: "text",
        field: "temperature"
      });
  }, [page, rowsPerPage]);

  useEffect(() => {
    handleQuery();
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (!openSuggestion) {
      setSuggestionList([]);
    } else {
      setSuggestionLoading(true);
      (async () => {
        try {
          var data = await api(
            "POST",
            "meeting/searchUsersWithKeyword",
            { keyword: filterEmail, organizationId: user.organizationId },
            true
          );
          var suggestions = [...data.employee, ...data.visitor];

          setSuggestionList(suggestions);
          setSuggestionLoading(false);
        } catch (error) {
          captureException(error);
        }
      })();
    }
  }, [openSuggestion, filterEmail]);

  const renderFilterColumns = (column, i) => {
    switch (column.dataType) {
      case "profile":
        return (
          <TableCell key={i} style={{ paddingLeft: 8, paddingRight: 8 }}>
            <Autocomplete
              fullWidth
              open={openSuggestion}
              blurOnSelect
              clearOnBlur
              noOptionsText={"No user found"}
              options={suggestionList}
              loading={suggestionLoading}
              onOpen={() => {
                setOpenSuggestion(true);
              }}
              onClose={() => {
                setOpenSuggestion(false);
              }}
              onChange={(event, newValue) => {
                if (newValue) {
                  setQuery({
                    ...query,
                    search: [
                      ...query.search.filter(
                        item => item.key !== "checkInActivity.createdBy"
                      ),
                      {
                        key: "checkInActivity.createdBy",
                        value: newValue.id,
                        comparison: "="
                      }
                    ]
                  });
                  setFilters({ ...filters, User: newValue.name });
                }
              }}
              filterOptions={(options, state) => options}
              getOptionLabel={option => {
                return option.name;
              }}
              renderOption={item => (
                <ListItem dense disableGutters style={{ overflowX: "hidden" }}>
                  <ListItemAvatar>
                    <Avatar style={getAvatarStyle(item.email)}>
                      {getInitials(item.name)}
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      <StyledBadge
                        badgeContent={
                          item.role ? rolename(item.role) : "Visitor"
                        }
                        color={item.role ? "primary" : "secondary"}
                      >
                        <Typography variant="h5">{item.name}</Typography>
                      </StyledBadge>
                    }
                    secondary={
                      <Typography variant="body2" color="textSecondary">
                        {item.role &&
                          item[orgSettings.employeePrimaryIdentifier]}
                        {!item.role &&
                          item[orgSettings.visitorPrimaryIdentifier]}
                      </Typography>
                    }
                  />
                </ListItem>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  className={classes.filterTextField}
                  placeholder="Search user by email or phone number"
                  variant="outlined"
                  onChange={e => {
                    setFilterEmail(e.target.value);
                  }}
                  value={filterEmail}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {suggestionLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
          </TableCell>
        );
      case "datetime":
        return (
          <TableCell key={i} style={{ paddingLeft: 8, paddingRight: 8 }}>
            <TextField
              type="date"
              size="small"
              margin="none"
              variant="outlined"
              placeholder="Filter by date"
              onChange={e => {
                setQuery({
                  ...query,
                  search: [
                    ...query.search.filter(
                      item => item.key !== "checkInActivity.createdAt"
                    ),
                    {
                      key: "checkInActivity.createdAt",
                      value: moment(e.target.value)
                        .endOf("day")
                        .utc()
                        .format("MM-DD-YYYY HH:mm"),
                      comparison: "<="
                    },
                    {
                      key: "checkInActivity.createdAt",
                      value: moment(e.target.value)
                        .startOf("day")
                        .utc()
                        .format("MM-DD-YYYY HH:mm"),
                      comparison: ">"
                    }
                  ]
                });
                setFilters({
                  ...filters,
                  "Requested on": e.target.value
                });
              }}
              required
              fullWidth
            />
          </TableCell>
        );
      default:
        return (
          <TableCell key={i} style={{ paddingLeft: 8, paddingRight: 8 }}>
            <TextField
              type="text"
              className={classes.filterTextField}
              size="small"
              margin="none"
              variant="outlined"
              placeholder={"Filter by " + column.label.toLowerCase()}
              required
              fullWidth
              onChange={e => {
                setQuery({
                  ...query,
                  search: [
                    ...query.search.filter(
                      item =>
                        item.key !==
                        "checkInActivity." + column.label.toLowerCase()
                    ),
                    {
                      key: "checkInActivity." + column.label.toLowerCase(),
                      value: e.target.value,
                      comparison: "LIKE"
                    }
                  ]
                });
                setFilters({
                  ...filters,
                  [column.label.toLowerCase()]: e.target.value
                });
              }}
            />
          </TableCell>
        );
    }
  };

  const renderDataColumns = (data, columns) => {
    return columns.map((item, i) => {
      var dataField;
      if (item.field === "user") {
        dataField = data.checkInRequest
          ? data.checkInRequest.userAccess.employee ||
            data.checkInRequest.userAccess.visitor ||
            data.checkInRequest.userAccess.staff
          : data.checkInUserMap
          ? data.checkInUserMap.userAccess.employee ||
            data.checkInUserMap.userAccess.visitor ||
            data.checkInUserMap.userAccess.staff
          : {};
      } else if (item.field.includes("|")) {
        item.field.split("|").forEach(item => {
          dataField =
            dataField || item.split(".").reduce((ox, ix) => ox[ix], data);
        });
      } else if (item.field.includes("&")) {
        try {
          item.field.split("&").forEach((item, index) => {
            if (index === 0)
              dataField =
                item.split(".").reduce((ox, ix) => ox[ix], data) || "";
            else
              dataField =
                dataField +
                  ", " +
                  item.split(".").reduce((ox, ix) => ox[ix], data) || "";
          });
        } catch (error) {
          dataField = "";
        }
      } else {
        dataField = item.field.split(".").reduce((ox, ix) => ox[ix], data);
      }
      switch (item.dataType) {
        case "profile":
          return (
            <TableCell size="small" className={classes.tableCell} key={i}>
              <ListItem disableGutters dense>
                <ListItemAvatar>
                  <Avatar style={getAvatarStyle(dataField.email)}>
                    {getInitials(dataField.name)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="h5" className={classes.capitalize}>
                      {dataField.name}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="textSecondary">
                      {dataField.role &&
                        dataField[orgSettings.employeePrimaryIdentifier]}
                      {!dataField.role &&
                        dataField[orgSettings.visitorPrimaryIdentifier]}
                    </Typography>
                  }
                />
              </ListItem>
            </TableCell>
          );
        case "datetimerange":
          const times = dataField.split(", ");
          const date = moment
            .utc(new Date(parseInt(times[0])).toISOString())
            .local()
            .format("Do MMM YYYY");
          const start = moment
            .utc(new Date(parseInt(times[0])).toISOString())
            .local()
            .format("LT");
          const end = moment
            .utc(new Date(parseInt(times[1])).toISOString())
            .local()
            .format("LT");
          return (
            <TableCell size="small" className={classes.tableCell} key={i}>
              {date} <br /> <b>{start + " - " + end}</b>
            </TableCell>
          );
        case "datetime":
          return dataField ? (
            <TableCell size="small" className={classes.tableCell} key={i}>
              <b>
                {moment
                  .utc(new Date(parseInt(dataField)).toISOString())
                  .local()
                  .format("LT")}
              </b>{" "}
              {moment
                .utc(new Date(parseInt(dataField)).toISOString())
                .local()
                .format("Do MMM YYYY")}
            </TableCell>
          ) : (
            <TableCell size="small" className={classes.tableCell} key={i}>
              Not Available
            </TableCell>
          );
        case "status":
          return (
            <TableCell
              size="small"
              key={i}
              className={clsx(classes.capitalize, classes.tableCell)}
            >
              <Chip
                label={dataField}
                color="primary"
                size="small"
                className={classes[dataField]}
              />
            </TableCell>
          );
        default:
          return (
            <TableCell size="small" className={classes.tableCell} key={i}>
              {dataField}
            </TableCell>
          );
      }
    });
  };
  const handleChangePage = (event, page) => {
    setQuery({ ...query, skip: page * rowsPerPage });
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setQuery({ ...query, skip: 0, take: event.target.value });
    setRowsPerPage(event.target.value);
  };

  const handleSort = label => {
    var direction = "asc";
    var key;
    if (currentSort.label === label && currentSort.direction === "asc")
      direction = "desc";
    const order = direction.toUpperCase();

    switch (label.toLowerCase()) {
      case "in time":
        key = "checkInActivity.inTime";
        break;
      case "out time":
        key = "checkInActivity.outTime";
        break;
      default:
        break;
    }
    handleQuery({ ...query, sort: { key, order }, skip: 0 });
    setQuery({ ...query, sort: { key, order }, skip: 0 });
    setCurrentSort({ label, direction });
  };

  const handleDeleteChip = async key => {
    switch (key) {
      case "User":
        setQuery({
          ...query,
          search: [
            ...query.search.filter(
              item => item.key !== "checkInActivity.createdBy"
            )
          ]
        });
        setFilterEmail("");
        break;
      case "Date":
        setQuery({
          ...query,
          search: [
            ...query.search.filter(
              item => item.key !== "checkInActivity.startTime"
            )
          ]
        });
        break;
      default:
        break;
    }

    let allFilters = filters;
    delete allFilters[key];
    Object.keys(allFilters).length === 0 &&
      handleQuery({
        ...query,
        search: ["superadmin", "admin"].includes(user.role)
          ? []
          : user.role === "visitor"
          ? [
              {
                key: "participant.visitorId",
                value: user.userId,
                comparison: "="
              }
            ]
          : [
              {
                key: "participant.employeeId",
                value: user.userId,
                comparison: "="
              }
            ]
      });
    setFilters({ ...allFilters });
  };

  const handleRowClick = id => {};

  const handleQuery = async customQuery => {
    dispatch(
      toastAction({
        status: true,
        message: "Loading...",
        type: "info",
        noIcon: true
      })
    );
    try {
      const meetingCountData = customQuery
        ? await api("POST", "checkInActivity/findCount", customQuery, true)
        : await api("POST", "checkInActivity/findCount", query, true);
      const meetingData = customQuery
        ? await api("POST", "checkInActivity/find", customQuery, true)
        : await api("POST", "checkInActivity/find", query, true);
      setMeetingCount(meetingCountData);
      setMeetings(meetingData);
      setLoading(false);
      dispatch(clearToastAction({}));
    } catch (error) {
      dispatch(clearToastAction({}));
      captureException(error);
    }
  };

  const handleReport = async () => {
    const checkInActivityReportUrl = await api(
      "POST",
      "checkInActivity/findAllReport",
      query,
      true
    );
    window.open(checkInActivityReportUrl, "_blank");
  };

  return loading ? (
    <Loader />
  ) : (
    <div {...rest} className={clsx(classes.root, className)}>
      <Box px={1.5} py={1}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography color="textSecondary" variant="h6">
              {query.search.length === 0 ? "All Activity " : "Filtered results"}
            </Typography>
            <Typography color="textSecondary" gutterBottom variant="caption">
              {meetingCount} Results found. Page {page + 1} of{" "}
              {Math.ceil(meetingCount / rowsPerPage)}
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={handleReport} variant="outlined" color="primary">
              Download Report
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Card>
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((col, i) => (
                      <TableCell className={classes.capitalize} key={i}>
                        {(col.label.toLowerCase() === "in time" ||
                          col.label.toLowerCase() === "out time") && (
                          <TableSortLabel
                            active={currentSort.label === col.label}
                            direction={currentSort.direction}
                            onClick={() => handleSort(col.label)}
                          >
                            {col.label}
                          </TableSortLabel>
                        )}
                        {col.label.toLowerCase() !== "in time" &&
                          col.label.toLowerCase() !== "out time" &&
                          col.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>{columns.map(renderFilterColumns)}</TableRow>
                  {Object.keys(filters).length > 0 && (
                    <TableRow selected>
                      <TableCell colSpan={columns.length} align="center">
                        <Typography variant="h6" component="p">
                          Selected filters: &nbsp;
                        </Typography>
                        {Object.keys(filters).map((filter, i) => {
                          return (
                            <React.Fragment key={i}>
                              <Chip
                                variant="outlined"
                                color="primary"
                                onDelete={() => {
                                  handleDeleteChip(filter);
                                }}
                                label={
                                  <>
                                    <b className={classes.capitalize}>
                                      {filter}
                                    </b>
                                    : {filters[filter]}
                                  </>
                                }
                              />
                              &nbsp; &nbsp;
                            </React.Fragment>
                          );
                        })}
                        <Chip
                          color="primary"
                          icon={<SearchIcon />}
                          className={classes.chipButton}
                          clickable
                          onClick={() => handleQuery()}
                          label={<b>Search</b>}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  {meetings.map(meeting => (
                    <TableRow
                      hover
                      key={meeting.id}
                      onClick={() => handleRowClick(meeting.id)}
                    >
                      {renderDataColumns(meeting, columns)}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={meetingCount}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  meetings: PropTypes.array.isRequired
};

Results.defaultProps = {
  meetings: []
};

export default Results;
