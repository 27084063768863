import {
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import CsvViewer from "react-csv-viewer";
import {
  PlusSquare,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../../utils/api";
import AddItemModal from "../AddItemModal";
import AddModal from "../AddModal";

const useStyles = makeStyles(() => ({
  root: {},
  breadcrumbIcon: {
    marginLeft: 6,
    marginRight: 6
  }
}));

const Header = props => {
  const { className, setReload, ...rest } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenItem, setModalOpenItem] = useState(false);
  const [addType, setAddType] = useState("");
  //const [uploadModalError, setUploadModalError] = useState("");
  //const [uploadOrgId, setUploadOrgId] = useState("");
  const [companyList, setCompanyList] = useState([]);
  //const [bulkInviteOpen, setBulkInviteModalOpen] = useState(false);
  const [inviteOrgId, setInviteOrgId] = useState("");
  //const [inviteEmails, setInviteEmail] = useState("");
  //const [inviteError, setInviteError] = useState("");
  const user = useSelector(state => state.user);
  //const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (user.role === "businessAdmin") {
      (async () => {
        try {
          const orgList = await api(
            "GET",
            "organization/getOrganization/" + user.businessId,
            null,
            false
          );
          setCompanyList(orgList);
        } catch (error) {
          alert("Something went wrong!");
        }
      })();
    } else {
      setInviteOrgId(user.organizationId);
    }
  }, [user]);

  const openModal = type => {
    setAddType(type);
    setModalOpen(true);
  };
  const closeModal = () => {
    setReload(false);
    setReload(true);
    setModalOpen(false);
  };

  const openModalItem = type => {
    setAddType(type);
    setModalOpenItem(true);
  };
  const closeModalItem = () => {
    setReload(false);
    setReload(true);
    setModalOpenItem(false);
  };

  
  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}
        direction="row"
      >
        <Grid item>
          <Typography component="h1" variant="h3" color="primary">
            All Pantry
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <Button
                color="primary"
                startIcon={<PlusSquare />}
                variant="outlined"
                onClick={() => {
                  openModal("pantry");
                }}
              >
                Add Pantry
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                startIcon={<PlusSquare />}
                variant="outlined"
                onClick={() => {
                  openModalItem("pantry");
                }}
              >
                Add Item
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AddModal open={modalOpen} close={closeModal} type={addType} />
      <AddItemModal open={modalOpenItem} close={closeModalItem} type={addType} />
      {/* <Dialog
        open={uploadModalOpen}
        onClose={e => setUploadModalOpen(false)}
        maxWidth="lg"
      >
        <DialogTitle disableTypography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={5}
          >
            <Grid item>
              <Grid container direction="row" spacing={1}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Download />}
                    onClick={downloadSample}
                  >
                    Download Sample
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<FileText />}
                    color="primary"
                    onClick={simulateClick}
                  >
                    Select File
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="body2">
              Click on the 'Select file' icon and select a file from your
              device. Once a valid file is selected click on the 'Upload' button
              bellow to upload.
            </Typography>
            {user.role === "businessAdmin" && (
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                margin="normal"
              >
                <InputLabel id="company"> Select company</InputLabel>
                <Select
                  labelId="company"
                  id="company"
                  label="Select company"
                  displayEmpty
                  onChange={e => {
                    setUploadOrgId(e.target.value);
                  }}
                  autoWidth
                >
                  {companyList.map(item => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <CsvViewer />
            {uploadModalError !== "" && (
              <Alert severity="error">{uploadModalError}</Alert>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={e => setUploadModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={uploadCsv} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={bulkInviteOpen}
        onClose={e => {
          setInviteEmail("");
          setBulkInviteModalOpen(false);
        }}
        maxWidth="lg"
      >
        <DialogTitle disableTypography>
          <Typography variant="h4" color="primary">
            Invite employees to sign up
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="body2">
              Enter the email addresses of the employees you want to invite.
              Seperate individual email addresses by a comma.
            </Typography>
            {inviteError !== "" && (
              <Alert severity="error">{inviteError}</Alert>
            )}
            {user.role === "businessAdmin" && (
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                margin="normal"
              >
                <InputLabel id="company"> Select company</InputLabel>
                <Select
                  labelId="company"
                  id="company"
                  label="Select company"
                  displayEmpty
                  onChange={e => {
                    setInviteOrgId(e.target.value);
                  }}
                  autoWidth
                >
                  {companyList.map(item => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <TextField
              label="Email addresses seperated by comma"
              variant="outlined"
              type="text"
              margin="normal"
              fullWidth
              value={inviteEmails}
              onChange={e => {
                setInviteEmail(e.target.value);
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={e => {
              setInviteEmail("");
              setBulkInviteModalOpen(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={sendInvite} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog> */}
      <br />
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
