import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { captureException } from "@sentry/browser";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, Clipboard, Clock, Info, MapPin } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Page from "src/components/Page";
import * as actions from "../../actions";
import { toastAction, organizationSettings } from "../../actions";
import api from "../../utils/api";
import getAvatarStyle from "../../utils/getAvatarStyle";
import getInitials from "../../utils/getInitials";
import Loader from "../../utils/loader";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));

const TrackingReportDetails = props => {
  const { customToken, visitId } = props;
  const navigate = useNavigate();
  const params = useParams();
  const [meeting, setMeeting] = useState({});
  const [reportMeeting, setReportMeeting] = useState({});
  const [loading, setLoading] = useState(true);
  const [responseSent, setResponseSent] = useState(false);
  const orgSettings = useSelector(state => state.orgSettings);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    if (customToken) {
      setTimeout(() => {
        (async () => {
          try {
            // const data = await api(
            //   "GET",
            //   `checkIn/${visitId}`,
            //   null,
            //   false,
            //   false,
            //   false,
            //   false,
            //   customToken
            // );

            // const newOrgSettings = await api(
            //   "GET",
            //   "organization/getOrgSettings/" + data.organization.id,
            //   null,
            //   false,
            //   false,
            //   false,
            //   false,
            //   customToken
            // );
            // dispatch(
            //   organizationSettings({ ...orgSettings, ...newOrgSettings })
            // );

            setMeeting({
              id: "40599x2q2kkny0odj",
              inTime: "1612266789223",
              outTime: null,
              status: "checkedIn",
              meetingPurpose: "Test meeting",
              createdAt: "1612266789223",
              updatedAt: "1612266789223",
              contactBreaches: [
                {
                  title: "Pradip Roy",
                  zone_name: "Small_area",
                  contact_start_time: "2021-02-03T06:57:23.559Z",
                  contact_duration_sec: 155
                },
                {
                  title: "Pradip Roy",
                  zone_name: "Small_area",
                  contact_start_time: "2021-02-03T07:00:21.636Z",
                  contact_duration_sec: 100
                },
                {
                  title: "Pradip Roy",
                  zone_name: "Small_area",
                  contact_start_time: "2021-02-03T07:02:03.699Z",
                  contact_duration_sec: 130
                },
                {
                  title: "Pradip Roy",
                  zone_name: "Small_area",
                  contact_start_time: "2021-02-03T07:04:40.310Z",
                  contact_duration_sec: 58
                }
              ],
              visitor: {
                id: "40599x2mskknsnb2w",
                organizationId: "cvsdvd654641",
                name: "Check email visitor",
                email: "vetefew335@alicdh.com",
                password:
                  "$2b$10$8EHXW891QJpuPV5ToLqBe.voz5ehtR9m65glAPdnENjQzTGB/aDCq",
                mobile: "7584554455",
                photo:
                  "https://s3.ap-south-1.amazonaws.com/img.thesentinel.io/undefined/profilePhoto/1612266308040.jpg",
                countryCode: "+91",
                isActive: true,
                emailVerified: false,
                source: "Dashboard",
                createdAt: "1612257767469",
                updatedAt: "1612257767469",
                lastLogin: null,
                rememberToken: null,
                idPhoto:
                  "https://s3.ap-south-1.amazonaws.com/img.thesentinel.io/undefined/idPhoto/1612266308119.jpg",
                frPhoto: null,
                businessId: "cvsdvd654641"
              }
            });
            setLoading(false);
          } catch (error) {
            captureException(error);
          }
        })();
      }, 1000);
    } else {
      (async () => {
        try {
          const data = await api("POST", "meeting/findNavigineReport", {
            search:[
              {key:"participant.id",value:params.id,comparison:"="},
              ],
          }, true);
          setMeeting(data[0]);
          setReportMeeting(data[0].meeting);
          setLoading(false);
        } catch (error) {
          captureException(error);
        }
      })();
    }
  }, [responseSent]);

  return loading ? (
    <Loader />
  ) : (
    <Page className={classes.root} title="Meeting Details">
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}
        direction="row"
      >
        <Grid item>
          <Typography component="h1" variant="h3" color="primary">
            View Tracking Report
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.container} container spacing={1}>
        <Grid item md={4} sm={12}>
          <Card>
            <CardContent>
              <Typography component="h2" gutterBottom variant="h4">
                Meeting Details
              </Typography>
              {(() => {
                const item = meeting.visitor;
                return (
                  <React.Fragment>
                    <Divider />
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justify="space-between"
                    >
                      <Grid item>
                        <Box flexBasis={1}>
                          <ListItem disableGutters>
                            <ListItemAvatar>
                              <Avatar style={getAvatarStyle(item.email || "")}>
                                {getInitials(item.name)}
                              </Avatar>
                            </ListItemAvatar>

                            <ListItemText
                              primary={
                                <Typography variant="h5">
                                  {item.name}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {item.role &&
                                    item[orgSettings.employeePrimaryIdentifier]}
                                  {!item.role &&
                                    item[orgSettings.visitorPrimaryIdentifier]}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box></Box>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                );
              })()}
              <br />
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <Box width={30}>
                        <Clipboard />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box width={80}>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              <Typography variant="h5">Purpose</Typography>
                            }
                          />
                        </ListItem>
                      </Box>
                    </Grid>
                    <Grid item>
                      <ListItem disableGutters>
                        <ListItemText
                          primary={
                            <Typography variant="body1">
                              {reportMeeting.purpose}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Grid>
                {reportMeeting.room && (
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <Grid item>
                        <Box width={30}>
                          <MapPin />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box width={80}>
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                <Typography variant="h5">Venue</Typography>
                              }
                            />
                          </ListItem>
                        </Box>
                      </Grid>
                      <Grid item>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              <Typography variant="body1">
                                {reportMeeting.room.name}
                                {", "}
                                {reportMeeting.building.name}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <Box width={30}>
                        <Calendar />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box width={80}>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={<Typography variant="h5">Date</Typography>}
                          />
                        </ListItem>
                      </Box>
                    </Grid>
                    <Grid item>
                      <ListItem disableGutters>
                        <ListItemText
                          primary={
                            <Typography variant="body1">
                              {moment(parseInt(reportMeeting.startTime))
                                .local()
                                .format("Do MMM YYYY")}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <Box width={30}>
                        <Clock />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box width={80}>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={<Typography variant="h5">Time</Typography>}
                          />
                        </ListItem>
                      </Box>
                    </Grid>
                    <Grid item>
                      <ListItem disableGutters>
                        <ListItemText
                          primary={
                            <Typography variant="body1">
                              {moment(parseInt(reportMeeting.startTime))
                                .local()
                                .format("LT")}
                              {" to "}
                              {moment(parseInt(reportMeeting.endTime))
                                .local()
                                .format("LT")}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <Box width={30}>
                        <Info />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box width={80}>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              <Typography variant="h5">Status</Typography>
                            }
                          />
                        </ListItem>
                      </Box>
                    </Grid>
                    <Grid item>
                      <ListItem disableGutters>
                        <ListItemText
                          primary={
                            <Typography variant="button">
                              {reportMeeting.status}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={8} sm={12}>
          <Card>
            <CardContent>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
              >
                <Grid item xs={12}>
                  <Typography component="h2" gutterBottom variant="h4">
                    Contact Breaches
                  </Typography>
                  {(meeting.metadata.find(item => item.key === "navigineReport") ? meeting.metadata.find(item => item.key === "navigineReport").value.data.filter(ditem=>ditem.contact_duration_sec > 120).length : 0) === 0 && (
                    <Table style={{ width: "100%" }}>
                      <TableBody>
                        <TableRow>
                          <TableCell>No breaches</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                  {(meeting.metadata.find(item => item.key === "navigineReport") ? meeting.metadata.find(item => item.key === "navigineReport").value.data.filter(ditem=>ditem.contact_duration_sec > 120).length : 0) > 0 &&
                    meeting.metadata.find(item => item.key === "navigineReport").value.data.filter(ditem=>ditem.contact_duration_sec > 120).map(eachActivity => (
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        key={eachActivity.id}
                        justify="space-between"
                      >
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <br />

                        <Grid item>
                          <Box minWidth={120} flexBasis={1}>
                            <Typography variant="h5" color="primary">
                              <b>Contact With</b>
                            </Typography>
                            <Typography>
                              <b>{eachActivity.title}</b>
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box minWidth={120} flexBasis={1}>
                            <Typography variant="h5" color="primary">
                              <b>Contact Zone</b>
                            </Typography>
                            <Typography>{eachActivity.zone_name}</Typography>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box minWidth={120} flexBasis={1}>
                            <Typography variant="h5" color="primary">
                              <b>Contact Start Time</b>
                            </Typography>
                            <Typography>
                              <b>
                                {moment
                                  .utc(
                                    new Date(
                                      eachActivity.contact_start_time
                                    ).toISOString()
                                  )
                                  .local()
                                  .format("LT")}{" "}
                              </b>
                              {moment
                                .utc(
                                  new Date(
                                    eachActivity.contact_start_time
                                  ).toISOString()
                                )
                                .local()
                                .format("Do MMM YYYY")}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box minWidth={120} flexBasis={1}>
                            <Typography variant="h5" color="primary">
                              <b>Contact Duration</b>
                            </Typography>
                            <Typography>
                              {eachActivity.contact_duration_sec}
                              {" seconds"}
                            </Typography>
                          </Box>
                        </Grid>

                        <br />
                        <br />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

export default TrackingReportDetails;
