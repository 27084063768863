import {
  Avatar,
  Box,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import {
  BarChart as BarChartIcon,
  Calendar as CalendarIcon,
  Map as MapIcon,
  Settings as SettingsIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  Activity as ActivityIcon,
  Clock as ClockIcon,
  Thermometer as ThermometerIcon
} from "react-feather";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import getAvatarStyle from "../../../utils/getAvatarStyle";
import rolename from "../../../utils/rolename";
import getInitials from "../../../utils/getInitials";
import NavItem from "./NavItem";

const items = {
  businessAdmin: [
    {
      href: "/dashboard",
      icon: BarChartIcon,
      title: "Dashboard"
    },
    {
      href: "/visit",
      icon: AssignmentIndOutlinedIcon,
      title: "Visit"
    },
    {
      href: "/meeting",
      icon: CalendarIcon,
      title: "Meetings"
    },
    {
      href: "/participants",
      icon: AssignmentOutlinedIcon,
      title: "Participant Report"
    },
    {
      href: "/activities",
      icon: ActivityIcon,
      title: "Activities"
    },
    {
      href: "/temperaturelog",
      icon: ThermometerIcon,
      title: "Temperature Log"
    },
    {
      href: "/checkinformdata",
      icon: AssignmentOutlinedIcon,
      title: "CheckIn Form Data"
    },
    {
      href: "/coviddeclaration",
      icon: AssignmentOutlinedIcon,
      title: "Covid Declaration"
    },
    {
      href: "/livetracking",
      icon: MapIcon,
      title: "Live Tracking"
    },
    {
      href: "/trackingreports",
      icon: FindInPageOutlinedIcon,
      title: "Tracking Reports"
    },
    {
      href: "/users",
      icon: UsersIcon,
      title: "Manage Users"
    },
    {
      href: "/roomavailability",
      icon: CalendarIcon,
      title: "Room Availability"
    },
    {
      href: "/pantry",
      icon: UsersIcon,
      title: "Manage Pantry"
    },
    {
      href: "/rosters",
      icon: ClockIcon,
      title: "Roster Management"
    },
    {
      href: "/settings",
      icon: SettingsIcon,
      title: "Settings"
    }
  ],
  superadmin: [
    {
      href: "/dashboard",
      icon: BarChartIcon,
      title: "Dashboard"
    },
    {
      href: "/createmeeting",
      icon: UserPlusIcon,
      title: "Create Meeting"
    },
    {
      href: "/createvisit",
      icon: PostAddOutlinedIcon,
      title: "Create Visit"
    },
    {
      href: "/visit",
      icon: AssignmentIndOutlinedIcon,
      title: "Visit"
    },
    {
      href: "/meeting",
      icon: CalendarIcon,
      title: "Meetings"
    },
    {
      href: "/participants",
      icon: AssignmentOutlinedIcon,
      title: "Participant Report"
    },
    {
      href: "/checkInRules",
      icon: ClockIcon,
      title: "Check In Rule"
    },
    {
      href: "/rosters",
      icon: ClockIcon,
      title: "Roster Management"
    },
    {
      href: "/activities",
      icon: ActivityIcon,
      title: "Activities"
    },
    {
      href: "/temperaturelog",
      icon: ThermometerIcon,
      title: "Temperature Log"
    },
    {
      href: "/livetracking",
      icon: MapIcon,
      title: "Live Tracking"
    },
    {
      href: "/trackingreports",
      icon: FindInPageOutlinedIcon,
      title: "Tracking Reports"
    },
    {
      href: "/users",
      icon: UsersIcon,
      title: "Manage Users"
    },
    {
      href: "/roomavailability",
      icon: CalendarIcon,
      title: "Room Availability"
    },
    {
      href: "/attendance",
      icon: UsersIcon,
      title: "Attendance"
    },
    {
      href: "/settings",
      icon: SettingsIcon,
      title: "Settings"
    }
  ],
  admin: [
    {
      href: "/dashboard",
      icon: BarChartIcon,
      title: "Dashboard"
    },
    {
      href: "/createmeeting",
      icon: UserPlusIcon,
      title: "Create Meeting"
    },
    {
      href: "/createvisit",
      icon: PostAddOutlinedIcon,
      title: "Create Visit"
    },
    {
      href: "/visit",
      icon: AssignmentIndOutlinedIcon,
      title: "Visit"
    },
    {
      href: "/meeting",
      icon: CalendarIcon,
      title: "Meetings"
    },
    {
      href: "/participants",
      icon: AssignmentOutlinedIcon,
      title: "Participant Report"
    },
    {
      href: "/checkInRules",
      icon: ClockIcon,
      title: "Check In Rule"
    },
    {
      href: "/activities",
      icon: ActivityIcon,
      title: "Activities"
    },
    {
      href: "/temperaturelog",
      icon: ThermometerIcon,
      title: "Temperature Log"
    },
    {
      href: "/users",
      icon: UsersIcon,
      title: "Manage Users"
    },
    {
      href: "/roomavailability",
      icon: CalendarIcon,
      title: "Room Availability"
    },
    {
      href: "/attendance",
      icon: UsersIcon,
      title: "Attendance"
    }
  ],
  employee: [
    {
      href: "/dashboard",
      icon: BarChartIcon,
      title: "Dashboard"
    },
    {
      href: "/createmeeting",
      icon: UserPlusIcon,
      title: "Create Meeting"
    },
    {
      href: "/meeting",
      icon: CalendarIcon,
      title: "Meetings"
    },
    {
      href: "/rosters",
      icon: ClockIcon,
      title: "Roster Management"
    }
  ],
  visitor: [
    {
      href: "/dashboard",
      icon: BarChartIcon,
      title: "Dashboard"
    },
    {
      href: "/createmeeting",
      icon: UserPlusIcon,
      title: "Create Meeting"
    },
    {
      href: "/createvisit",
      icon: PostAddOutlinedIcon,
      title: "Create Visit"
    },
    {
      href: "/visit",
      icon: AssignmentIndOutlinedIcon,
      title: "Visit"
    },
    {
      href: "/meeting",
      icon: CalendarIcon,
      title: "Meetings"
    }
  ]
};

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 65,
    height: "calc(100% - 64px)"
  },
  role: {
    textTransform: "uppercase"
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const user = useSelector(state => state.user);
  const orgSettings = useSelector(state => state.orgSettings);
  const business = useSelector(state => state.business);
  const navigate = useNavigate();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box height={60}>
        <ListItem
          button
          onClick={() => {
            navigate("/account");
          }}
        >
          <ListItemAvatar>
            {user.photo && (
              <Avatar
                style={getAvatarStyle(user.email)}
                src={user.photo}
                className={classes.avatar}
              >
                {getInitials(user.name)}
              </Avatar>
            )}
            {!user.photo && (
              <Avatar
                style={getAvatarStyle(user.email)}
                className={classes.avatar}
              >
                {getInitials(user.name)}
              </Avatar>
            )}
          </ListItemAvatar>
          <ListItemText
            primary={<Typography variant="h5">{user.name}</Typography>}
            secondary={
              <Typography
                variant="caption"
                color="primary"
                className={classes.role}
              >
                {rolename(user.role)}
              </Typography>
            }
          />
        </ListItem>
      </Box>
      <Box px={2}>
        <List>
          {user.role &&
            items[user.role]
              .filter(item => {
                if (item.href === "/attendance") {
                  return orgSettings.telpoAttendanceSynce;
                } else if (
                  business.domain === "indigo" &&
                  (item.href === "/createmeeting" ||
                    item.href === "/createvisit" ||
                    item.href === "/visit" ||
                    item.href === "/meeting" ||
                    item.href === "/participants" ||
                    item.href === "/activities" ||
                    item.href === "/coviddeclaration" ||
                    item.href === "/roomavailability" ||
                    item.href === "/pantry" ||
                    item.href === "/checkInRules")
                ) {
                  return false;
                } else if (
                  business.domain === "freeman" &&
                  (item.href === "/coviddeclaration" ||
                    item.href === "/pantry" ||
                    item.href === "/checkInRules")
                ) {
                  return false;
                } else if (
                  business.domain === "vmly" &&
                  (item.href === "/createmeeting" ||
                    item.href === "/createvisit" ||
                    item.href === "/visit" ||
                    item.href === "/meeting" ||
                    item.href === "/participants" ||
                    item.href === "/activities" ||
                    item.href === "/coviddeclaration" ||
                    item.href === "/roomavailability" ||
                    item.href === "/pantry" ||
                    item.href === "/checkInRules")
                ) {
                  return false;
                } else if (
                  business.domain === "sattva" &&
                  (item.href === "/createmeeting" ||
                    item.href === "/createvisit" ||
                    item.href === "/activities" ||
                    item.href === "/roomavailability" ||
                    item.href === "/pantry" ||
                    item.href === "/checkInRules")
                ) {
                  return false;
                } else if (
                  business.domain === "alliancedata" &&
                  (item.href === "/createmeeting" ||
                    item.href === "/createvisit" ||
                    item.href === "/meeting" ||
                    item.href === "/participants" ||
                    item.href === "/activities" ||
                    item.href === "/temperaturelog" ||
                    item.href === "/roomavailability" ||
                    item.href === "/pantry" ||
                    item.href === "/checkInRules")
                ) {
                  return false;
                } else if (
                  business.domain === "groupm" &&
                  (item.href === "/dashboard" ||
                    item.href === "/createmeeting" ||
                    item.href === "/createvisit" ||
                    item.href === "/visit" ||
                    item.href === "/meeting" ||
                    item.href === "/participants" ||
                    item.href === "/activities" ||
                    item.href === "/coviddeclaration" ||
                    item.href === "/roomavailability" ||
                    item.href === "/pantry" ||
                    item.href === "/checkInRules")
                ) {
                  return false;
                } else if (item.href === "/coviddclaration") {
                  return business.domain === "alliancedata";
                } else if (item.href === "/checkinformdata") {
                  return business.domain === "hiplamulti";
                } else if (
                  item.href === "/livetracking" ||
                  item.href === "/trackingreports"
                ) {
                  return business.domain === "nus";
                } else if (
                  item.href === "/visit" ||
                  item.href === "/createvisit"
                ) {
                  return orgSettings.checkInSystemEnable;
                } else if (
                  item.href === "/checkInRules" ||
                  item.href === "/activities"
                ) {
                  return business.domain !== "nus";
                } else if (item.href === "/temperaturelog") {
                  return orgSettings.tempLog;
                } else if (item.href === "/rosters") {
                  if (
                    user.role === "employee" &&
                    user.isHod &&
                    (orgSettings.rosterManagementAdminEnabled ||
                      orgSettings.individualSupervisorRosteringEnabled) &&
                    orgSettings.rosterManagementEnabled &&
                    orgSettings.rosterManagementEnabledOrg
                  ) {
                    return true;
                  } else if (user.role === "businessAdmin") {
                    return orgSettings.rosterManagementEnabled;
                  } else if (user.role === "superadmin") {
                    return (
                      orgSettings.rosterManagementEnabled &&
                      orgSettings.rosterManagementEnabledOrg
                    );
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              })
              .map(item => (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              ))}
          {!user.role &&
            items["visitor"].map(item => (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
