import {
  Box,
  Container,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  Divider,
  Button,
  FormControl,
  InputLabel,
  Select,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  MenuItem,
  TextField,
  ListItemAvatar,
  Avatar
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { captureException } from "@sentry/browser";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Page from "../../components/Page";
import api from "../../utils/api";
import Loader from "../../utils/loader";
import { X, Plus } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { toastAction, organizationSettings, business } from "../../actions";
import getAvatarStyle from "../../utils/getAvatarStyle";
import getInitials from "../../utils/getInitials";
import { Calendar, Clipboard, Clock, Info, MapPin } from "react-feather";
import moment from "moment";

const Response = props => {
  var query = {};
  const location = useLocation();
  location.search
    .slice(1)
    .split("&")
    .forEach(item => {
      query[item.split("=")[0]] = item.split("=")[1];
    });
  const businessOnState = useSelector(state => state.business);
  const orgSettings = useSelector(state => state.orgSettings);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [error, setError] = useState(false);
  const [visit, setvisit] = useState(null);
  const [baggage, setbaggage] = useState([]);
  const [baggageModal, setbaggageModal] = useState(false);
  const [newBaggageError, setnewBaggageError] = useState("");
  const [newBaggageName, setnewBaggageName] = useState("");
  const [newBaggageDescription, setnewBaggageDescription] = useState("");
  const [newBaggageModel, setnewBaggageModel] = useState("");
  const [newBaggageSerial, setnewBaggageSerial] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setLoading(true);
      let customToken = query.token;

      setTimeout(() => {
        (async () => {
          try {
            const data = await api(
              "GET",
              `checkIn/${query.checkInRequestId}`,
              null,
              false,
              false,
              false,
              false,
              customToken
            );

            if (data.baggages.length > 0) setStatus("success");
            setvisit(data);
            let businessFromToken = await api(
              "GET",
              "business/" + data.business.id,
              null,
              false,
              false,
              false,
              false,
              customToken
            );
            dispatch(business(businessFromToken));

            const newOrgSettings = await api(
              "GET",
              "organization/getOrgSettings/" + data.organization.id,
              null,
              false,
              false,
              false,
              false,
              customToken
            );
            const newBizSettings = await api(
              "GET",
              "business/getBusinessSettings/" + data.business.id,
              null,
              false,
              false,
              false,
              false,
              customToken
            );
            dispatch(
              organizationSettings({
                ...orgSettings,
                ...newOrgSettings,
                ...newBizSettings
              })
            );

            // setMeeting(data);
            setLoading(false);
          } catch (error) {
            captureException(error);
          }
        })();
      }, 1000);
      setLoading(false);
    })();
  }, []);

  const handleSubmit = async () => {
    try {
      await api(
        "PATCH",
        "checkIn/addBaggage/" + visit.id,
        {
          baggage: baggage
        },
        false,
        false,
        false,
        false,
        query.token
      );
      setStatus("success");
    } catch (error) {
      setError(true);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <Page title="Add Baggage to Visit">
      <Container maxWidth="sm">
        {businessOnState && orgSettings && visit && (
          <Box p={3} m={3} style={{ backgroundColor: "#fff" }}>
            <Box mb={3} alignItems="center">
              <Box
                mb={3}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  src={businessOnState.logo}
                  style={{ maxWidth: 180 }}
                  alt="logo"
                />
              </Box>
              <Typography
                color="primary"
                variant="h1"
                component="h2"
                align="center"
                style={{ fontWeight: 900 }}
              >
                Welcome to {businessOnState.name}
              </Typography>
            </Box>
            <Box mt={3} mb={2}>
              <Typography
                variant="h4"
                component="h2"
                align="center"
                color="primary"
              >
                Add Baggage for Visit
              </Typography>
            </Box>
            {status === "" && (
              <Box my={3}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Typography component="h2" gutterBottom variant="h4">
                    Visit Details
                  </Typography>
                  <Divider />
                  {(() => {
                    const item =
                      visit.userAccess.employee ||
                      visit.userAccess.visitor ||
                      visit.userAccess.staff;
                    return (
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                      >
                        <Grid item>
                          <Box flexBasis={1}>
                            <ListItem disableGutters>
                              <ListItemAvatar>
                                <Avatar
                                  style={getAvatarStyle(item.email || "")}
                                >
                                  {getInitials(item.name)}
                                </Avatar>
                              </ListItemAvatar>

                              <ListItemText
                                primary={
                                  <Typography variant="h5">
                                    {item.name}
                                  </Typography>
                                }
                                secondary={
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {item.role &&
                                      item[
                                        orgSettings.employeePrimaryIdentifier
                                      ]}
                                    {!item.role &&
                                      item[
                                        orgSettings.visitorPrimaryIdentifier
                                      ]}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          </Box>
                        </Grid>
                      </Grid>
                    );
                  })()}
                  <br />
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Grid item>
                          <Box width={30}>
                            <Clipboard />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box width={80}>
                            <ListItem disableGutters>
                              <ListItemText
                                primary={
                                  <Typography variant="h5">Purpose</Typography>
                                }
                              />
                            </ListItem>
                          </Box>
                        </Grid>
                        <Grid item>
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                <Typography variant="body1">
                                  {visit.purpose}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Grid item>
                          <Box width={30}>
                            <Calendar />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box width={80}>
                            <ListItem disableGutters>
                              <ListItemText
                                primary={
                                  <Typography variant="h5">Date</Typography>
                                }
                              />
                            </ListItem>
                          </Box>
                        </Grid>
                        <Grid item>
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                <Typography variant="body1">
                                  {moment(parseInt(visit.startTime))
                                    .local()
                                    .format("Do MMM YYYY")}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Grid item>
                          <Box width={30}>
                            <Clock />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box width={80}>
                            <ListItem disableGutters>
                              <ListItemText
                                primary={
                                  <Typography variant="h5">Time</Typography>
                                }
                              />
                            </ListItem>
                          </Box>
                        </Grid>
                        <Grid item>
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                <Typography variant="body1">
                                  {moment(parseInt(visit.startTime))
                                    .local()
                                    .format("LT")}
                                  {" to "}
                                  {moment(parseInt(visit.endTime))
                                    .local()
                                    .format("LT")}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Grid item>
                          <Box width={30}>
                            <Clock />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box width={80}>
                            <ListItem disableGutters>
                              <ListItemText
                                primary={
                                  <Typography variant="h5">Status</Typography>
                                }
                              />
                            </ListItem>
                          </Box>
                        </Grid>
                        <Grid item>
                          <ListItem disableGutters>
                            {/* <ListItemText primary={visit.status} /> */}
                            {visit.activity.length === 0 && (
                              <ListItemText primary={visit.status} />
                            )}
                            {visit.activity.length > 0 && (
                              <ListItemText
                                primary={
                                  visit.activity[visit.activity.length - 1]
                                    .status
                                }
                              />
                            )}
                          </ListItem>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Box width="100%" height={40}></Box>
                  <Grid item>
                    <Typography component="h2" gutterBottom variant="h4">
                      Baggage Details
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="outlined"
                      id="submit"
                      size="large"
                      onClick={e => {
                        setbaggageModal(true);
                      }}
                      startIcon={<Plus />}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>

                <Dialog
                  open={baggageModal}
                  onClose={e => setbaggageModal(false)}
                  maxWidth="xs"
                  fullWidth
                  aria-labelledby="form-dialog-title"
                >
                  <DialogContent>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography component="h2" gutterBottom variant="h4">
                          Add Baggage
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton
                          edge="end"
                          color="primary"
                          onClick={() => setbaggageModal(false)}
                        >
                          <X />
                        </IconButton>
                      </Grid>
                    </Grid>
                    {newBaggageError !== "" && (
                      <Alert
                        severity="error"
                        action={
                          <Button
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setnewBaggageError("");
                            }}
                          >
                            <X />
                          </Button>
                        }
                      >
                        {newBaggageError}
                      </Alert>
                    )}
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%" }}
                      id="baggageSelect"
                    >
                      <InputLabel id="building"> Type</InputLabel>
                      <Select
                        labelId="type"
                        id="type"
                        label="Type"
                        value={newBaggageName}
                        onChange={e => {
                          setnewBaggageError("");
                          setnewBaggageName(e.target.value);
                        }}
                        autoWidth
                      >
                        {JSON.parse(orgSettings.baggageItems).map(bitem => (
                          <MenuItem value={bitem}>
                            {bitem.charAt(0).toUpperCase() + bitem.slice(1)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <br />
                    <br />
                    <TextField
                      label="Description"
                      id="description"
                      variant="outlined"
                      type="text"
                      margin="none"
                      value={newBaggageDescription}
                      onChange={e => {
                        setnewBaggageError("");
                        setnewBaggageDescription(e.target.value);
                      }}
                      fullWidth
                    />
                    <br />
                    <br />
                    {JSON.parse(orgSettings.baggageFields).includes(
                      "model"
                    ) && (
                      <>
                        <TextField
                          label="Make and model"
                          id="model"
                          variant="outlined"
                          type="text"
                          margin="none"
                          value={newBaggageModel}
                          onChange={e => {
                            setnewBaggageError("");
                            setnewBaggageModel(e.target.value);
                          }}
                          fullWidth
                        />
                        <br />
                        <br />
                      </>
                    )}
                    {JSON.parse(orgSettings.baggageFields).includes(
                      "model"
                    ) && (
                      <>
                        <TextField
                          label="Serial no."
                          id="model"
                          variant="outlined"
                          type="text"
                          margin="none"
                          value={newBaggageSerial}
                          onChange={e => {
                            setnewBaggageError("");
                            setnewBaggageSerial(e.target.value);
                          }}
                          fullWidth
                        />
                        <br />
                        <br />
                      </>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      id="submit"
                      size="large"
                      fullWidth
                      onClick={e => {
                        if (newBaggageName !== "") {
                          setbaggage([
                            ...baggage,
                            {
                              name: newBaggageName,
                              description: newBaggageDescription,
                              model: newBaggageModel,
                              serial: newBaggageSerial
                            }
                          ]);
                          setnewBaggageName("");
                          setnewBaggageDescription("");
                          setnewBaggageModel("");
                          setnewBaggageSerial("");
                          setbaggageModal(false);
                        } else {
                          setnewBaggageError("Please fill all the fields");
                        }
                      }}
                    >
                      Add Baggage to List
                    </Button>
                    <br />
                    <br />{" "}
                    {newBaggageError !== "" && (
                      <Alert
                        severity="error"
                        action={
                          <Button
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setnewBaggageError("");
                            }}
                          >
                            <X />
                          </Button>
                        }
                      >
                        {newBaggageError}
                      </Alert>
                    )}
                    <FormControl
                      variant="outlined"
                      style={{ width: "100%" }}
                      id="baggageSelect"
                    >
                      <InputLabel id="building"> Type</InputLabel>
                      <Select
                        labelId="type"
                        id="type"
                        label="Type"
                        value={newBaggageName}
                        onChange={e => {
                          setnewBaggageError("");
                          setnewBaggageName(e.target.value);
                        }}
                        autoWidth
                      >
                        {JSON.parse(orgSettings.baggageItems).map(bitem => (
                          <MenuItem value={bitem}>
                            {bitem.charAt(0).toUpperCase() + bitem.slice(1)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <br />
                    <br />
                    <TextField
                      label="Description"
                      id="description"
                      variant="outlined"
                      type="text"
                      margin="none"
                      value={newBaggageDescription}
                      onChange={e => {
                        setnewBaggageError("");
                        setnewBaggageDescription(e.target.value);
                      }}
                      fullWidth
                    />
                    <br />
                    <br />
                    <Button
                      color="primary"
                      variant="contained"
                      id="submit"
                      size="large"
                      fullWidth
                      onClick={e => {
                        if (newBaggageName !== "") {
                          setbaggage([
                            ...baggage,
                            {
                              name: newBaggageName,
                              description: newBaggageDescription,
                              model: newBaggageModel,
                              serial: newBaggageSerial
                            }
                          ]);
                          setnewBaggageName("");
                          setnewBaggageDescription("");
                          setnewBaggageModel("");
                          setnewBaggageSerial("");
                          setbaggageModal(false);
                        } else {
                          setnewBaggageError("Please fill all the fields");
                        }
                      }}
                    >
                      Add Baggage to List
                    </Button>
                    <br />
                    <br /> const [newBaggageError, setnewBaggageError] =
                    useState("");
                  </DialogContent>
                </Dialog>
                <List dense>
                  <Divider />
                  {baggage.map((bag, index) => (
                    <ListItem divider key={index}>
                      <ListItemText
                        primary={
                          bag.name.charAt(0).toUpperCase() + bag.name.slice(1)
                        }
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              {bag.description}
                            </Typography>
                            {bag.model !== "" &&
                              bag.model !== null &&
                              " - " + bag.model}
                            {bag.serial !== "" &&
                              bag.serial !== null &&
                              " - " + bag.serial}
                          </React.Fragment>
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          onClick={e => {
                            setbaggage(baggage.filter((b, i) => i !== index));
                          }}
                        >
                          <X />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
                {error && (
                  <Alert severity="error">
                    <AlertTitle>Error!</AlertTitle>
                    Sorry! Something went wrong.
                  </Alert>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  id="submitBaggageList"
                  size="large"
                  fullWidth
                  onClick={e => {
                    // setStatus("success");
                    handleSubmit();
                  }}
                >
                  Submit
                </Button>
              </Box>
            )}{" "}
            {status === "success" && (
              <>
                <br />
                <Alert severity="success">
                  <AlertTitle>Success!</AlertTitle>
                  Your baggage details is submitted.
                </Alert>
                <br />
              </>
            )}
            <Grid
              container
              direction="row"
              spacing={1}
              alignItems="center"
              justify="center"
            >
              <Grid item>
                <Typography variant="h5" color="primary">
                  Powered by
                </Typography>
              </Grid>
              <Grid item>
                <img
                  src="https://hipla.io/vectors/hipla-logo.svg"
                  style={{ width: 100 }}
                  alt="logo"
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
    </Page>
  );
};

export default Response;
