import { Box, Container, Grid, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { captureException } from "@sentry/browser";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Page from "../../components/Page";
import api from "../../utils/api";
import Loader from "../../utils/loader";

const Response = props => {
  var query = {};
  const location = useLocation();
  location.search
    .slice(1)
    .split("&")
    .forEach(item => {
      query[item.split("=")[0]] = item.split("=")[1];
    });
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let exists = localStorage.getItem("token");
      localStorage.setItem("token", query.token);
      try {
        let data = await api(
          "PATCH",
          "meeting/updateParticipantStatus/" + query.participantId,
          {
            status: query.response
          },
          true
        );
        setStatus(data.status);
      } catch (error) {
        captureException(error);
        setError(true);
      }
      if (exists) {
        localStorage.setItem("token", exists);
      } else {
        localStorage.removeItem("token");
      }
      setLoading(false);
    })();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <Page title="Meeting Request Response">
      <Container maxWidth="sm">
        <Box p={3} m={3} style={{ backgroundColor: "#fff" }}>
          <Box mt={3} mb={2}>
            <Typography variant="h5" component="h2" align="center">
              Meeting Request Response
            </Typography>
          </Box>
          <Box my={3}>
            {!error && (
              <Alert severity="success">
                <AlertTitle>Success!</AlertTitle>
                Your meeting request is successfully {status}
              </Alert>
            )}
            {error && (
              <Alert severity="error">
                <AlertTitle>Error!</AlertTitle>
                Sorry! Something went wrong.
              </Alert>
            )}
          </Box>
          <Grid
            container
            direction="row"
            spacing={1}
            alignItems="center"
            justify="center"
          >
            <Grid item>
              <Typography variant="h5" color="primary">
                Powered by
              </Typography>
            </Grid>
            <Grid item>
              <img
                src="https://hipla.io/vectors/hipla-logo.svg"
                style={{ width: 100 }}
                alt="logo"
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default Response;
