import {
  Button,
  Container,
  Grid,
  makeStyles,
  Card,
  Divider,
  CardHeader,
  CardContent,
  Typography
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { clearToastAction, toastAction, login } from "../../../actions";
import Page from "src/components/Page";
import api from "../../../utils/api";
import Loader from "../../../utils/loader";
import ChangePassword from "./ChangePassword";
import Profile from "./Profile";
import ProfileDetails from "./ProfileDetails";
import QRCode from "qrcode.react";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  mb4: {
    marginBottom: theme.spacing(3)
  }
}));

const Account = () => {
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const loggedInUser = useSelector(state => state.user);
  const business = useSelector(state => state.business);
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      const data = await api(
        "GET",
        `${loggedInUser.loginType}/${loggedInUser.userId}`,
        null,
        true
      );
      setUser(data);
      setLoading(false);
    })();
  }, []);

  const sendCredentials = async () => {
    try {
      dispatch(
        toastAction({
          status: true,
          message: "Sending credentials to email/sms",
          type: "info",
          noIcon: true
        })
      );
      const data = await api(
        "GET",
        `${loggedInUser.loginType}/sendCredentials/${loggedInUser.userId}`,
        null,
        true
      );
      dispatch(
        toastAction({
          status: true,
          message: "Credentials sent successfully",
          type: "success",
          noIcon: true
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async data => {
    setError("");
    if (
      loggedInUser.loginType === "employee" ||
      loggedInUser.loginType === "businessAdmin"
    )
      delete data.organizationId;
    try {
      dispatch(
        toastAction({
          status: true,
          message: "Updating profile",
          type: "info",
          noIcon: true
        })
      );
      const updatedData = await api(
        "PATCH",
        `${loggedInUser.loginType}/${loggedInUser.userId}`,
        data,
        true
      );
      setUser(updatedData);
      dispatch(
        login({
          data: {
            ...loggedInUser,
            name: updatedData.name,
            photo: updatedData.photo
          }
        })
      );
      dispatch(
        toastAction({
          status: true,
          message: "Profile updated",
          type: "success",
          noIcon: true
        })
      );
    } catch (error) {
      error.response && error.response.data && setError(error.response.data);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <Page className={classes.root} title="Account">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <Profile userProfile={user} className={classes.mb4} />
            {user.role && (
              <>
                <Card>
                  <CardHeader title="Visiting Card" />
                  <Divider />
                  <CardContent>
                    <center>
                      <QRCode
                        value={`https://${business.domain}.thesentinel.io/visitingCard/${user.id}`}
                        size={180}
                        includeMargin
                      />
                      <br />
                      <br />
                      <br />
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={e => {
                          window.open(
                            `https://${business.domain}.thesentinel.io/visitingCard/${user.id}`,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                      >
                        View
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={e => {
                          navigator.clipboard.writeText(
                            `https://${business.domain}.thesentinel.io/visitingCard/${user.id}`
                          );
                          dispatch(
                            toastAction({
                              status: true,
                              message: "URL copied to clipboard",
                              type: "success"
                            })
                          );
                        }}
                      >
                        Copy URL
                      </Button>
                    </center>
                  </CardContent>
                </Card>
              </>
            )}
            <br />
            <br />
            {user.role && user.accessDetails && (
              <>
                <Card>
                  <CardHeader title="Access Credentials" />
                  <Divider />
                  <CardContent>
                    <center>
                      <QRCode
                        value={user.accessDetails.qrPin}
                        size={180}
                        includeMargin
                      />
                      <br />
                      <Typography
                        className={classes.dateText}
                        color="textPrimary"
                        variant="h4"
                      >
                        PIN: {user.accessDetails.qrPin}
                      </Typography>
                      <br />
                      <br />
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={sendCredentials}
                      >
                        Send to email/sms
                      </Button>
                    </center>
                  </CardContent>
                </Card>
              </>
            )}
          </Grid>
          <Grid item lg={8} md={6} xs={12}>
            {error !== "" && (
              <Alert
                severity="error"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setError("");
                    }}
                  >
                    <X />
                  </Button>
                }
              >
                {error}
              </Alert>
            )}
            <ProfileDetails userProfile={user} handleUpdate={handleUpdate} />
            <br />
            <ChangePassword className={classes.mb4} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
