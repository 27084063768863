export default role => {
  switch (role) {
    case "businessAdmin":
      return "Global Admin";
    case "superadmin":
      return "Company Admin";
    case "admin":
      return "Support Admin";
    case "reception":
      return "Reception";
    default:
			return role.charAt(0).toUpperCase() + role.slice(1);
  }
};
