import {
  Grid,
  makeStyles,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState, useEffect } from "react";
import { X } from "react-feather";
import "react-phone-input-2/lib/material.css";
import api from "src/utils/api";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    minWidth: 300,
    justifyContent: "space-between"
  },
  formControl: {
    marginTop: "16px"
  },
  formSelect: {
    marginBottom: "5px"
  }
}));

const initialState = {
  floorIds: []
};

const AddDeviceModal = props => {
  const classes = useStyles();
  const { open, close, floorList, roomList, deviceCount, fetchData } = props;
  const [formData, setFormData] = useState(initialState);
  const [isLoading, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [buildingsSelected, setBuildingsSelected] = useState([]);
  const [roomSelected, setRoomSelected] = useState();
  const [pantrySelected, setPantrySelected] = useState();
  const [employeeSelected, setEmployeeSelected] = useState();
  // before running that line of code, emp id set nahi hua
  const [pantryList, setPantryList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const handleClose = () => {
    close();
  };

  const handleAdd = async () => {
    if (formData.floorIds.length !== 0) {
      setLoader(true);
      let body = {
        floorIds: formData.floorIds,
        name: name,
        category: category,
        subCategory: subCategory,
        deviceType: deviceType
      };
      if (["outdoorMeetingRoom", "insideMeetingRoom"].includes(category)) {
        body.roomId = roomSelected.id;
      }
      if (["insideVIPRoom", "outsideVIPRoom"].includes(category)) {
        body.roomId = roomSelected.id;
        body.employeeId = employeeSelected.id;
      }
      if (["pantry"].includes(category)) {
        body.pantryId = pantrySelected.id;
      }
      try {
        const res = await api("POST", "device", body, true);
        if (res.apiKey) {
          deviceCount();
          fetchData();
          handleClose();
          setLoader(false);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          setError(error.response.data);
        }
        setLoader(false);
      }
    } else {
      setError("Please select buildings and floors");
    }
  };

  useEffect(() => {
    (async () => {
      const employee = await api(
        "POST",
        "employee/find",
        {
          search: [
            {
              key: "employee.name",
              value: searchInput,
              comparison: "LIKE"
            }
          ],
          take: 10
        },
        true
      );
      setEmployeeList(employee);
    })();
  }, [searchInput]);
  useEffect(() => {
    (async () => {
      const pantry = await api("POST", "pantry/find", { take: 10 }, true);
      setPantryList(pantry);
    })();
  }, []);

  const onBuildingChange = (event, values) => {
    setBuildingsSelected(values);
  };

  const onRoomChange = (event, values) => {
    setRoomSelected(values);
  };

  const onEmployeeChange = (event, value) => {
    setEmployeeSelected(value);
  };
  const onPantryChange = (event, value) => {
    setPantrySelected(value);
  };

  const onFloorChange = (event, values) => {
    setFormData(formData => ({
      ...formData,
      floorIds: values.map(item => item.id)
    }));
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle disableTypography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography component="h1" variant="h3" color="primary">
              Add New Device
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>

        <DialogContent>
          {error !== "" && (
            <>
              <Alert
                severity="error"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setError("");
                    }}
                  >
                    <X />
                  </Button>
                }
              >
                {error}
              </Alert>
              <br />
            </>
          )}

          <TextField
            id="outlined-basic"
            fullWidth
            label="Enter Device Name"
            variant="outlined"
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
          />
          <br />
          <br />

          <Autocomplete
            id="building"
            options={floorList
              .map(item => item.building)
              .filter((value, index, self) => {
                return (
                  self.indexOf(self.find(item => item.id === value.id)) ===
                  index
                );
              })}
            getOptionLabel={option => option.name}
            onChange={onBuildingChange}
            value={buildingsSelected}
            multiple
            disableClearable
            renderInput={params => (
              <TextField
                {...params}
                label="Buildings"
                fullWidth
                variant="outlined"
              />
            )}
          />
          <br />
          <Autocomplete
            id="floor"
            options={floorList.filter(floor =>
              buildingsSelected.map(item => item.id).includes(floor.building.id)
            )}
            getOptionLabel={option => option.name}
            onChange={onFloorChange}
            value={formData.floorIds.map(id =>
              floorList.find(item => item.id === id)
            )}
            multiple
            disableClearable
            renderInput={params => (
              <TextField
                {...params}
                label="Floors"
                fullWidth
                variant="outlined"
              />
            )}
          />
          <br />
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel> Device Type</InputLabel>
            <Select
              labelId="deviceType"
              id="deviceType"
              label="Device Type"
              value={deviceType}
              onChange={e => {
                setDeviceType(e.target.value);
              }}
              autoWidth
            >
              <MenuItem value="Android">Android</MenuItem>
              <MenuItem value="TelpoAndroid">Telpo - Android</MenuItem>
            </Select>
            <br />
          </FormControl>
          <br />
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel id="category"> Category</InputLabel>
            <Select
              labelId="category"
              id="category"
              label="Category"
              value={category}
              onChange={e => {
                setCategory(e.target.value);
                if (
                  !["insideVIPRoom", "outsideVIPRoom"].includes(e.target.value)
                ) {
                  setEmployeeSelected(null);
                }
                if (
                  ![
                    "authenticator",
                    "entrance",
                    "parkingAuthenticator"
                  ].includes(e.target.value)
                ) {
                  setSubCategory([]);
                }
                if (!["pantry"].includes(e.target.value)) {
                  setPantrySelected(null);
                }

                if (
                  ![
                    "insideVIPRoom",
                    "outsideVIPRoom",
                    "outdoorMeetingRoom",
                    "insideMeetingRoom"
                  ].includes(e.target.value)
                ) {
                  setRoomSelected(null);
                }
              }}
              autoWidth
            >
              <MenuItem value="entrance">Entrance</MenuItem>
              <MenuItem value="authenticator">Authenticator</MenuItem>
              <MenuItem value="parkingAuthenticator">
                Parking Authenticator
              </MenuItem>
              <MenuItem value="tempScanner">Temperature Scanner</MenuItem>
              <MenuItem value="uvcKiosk">UVC Kiosk</MenuItem>
              <MenuItem value="outdoorMeetingRoom">
                Meeting Room Entrance
              </MenuItem>
              <MenuItem value="insideMeetingRoom">Inside Meeting Room</MenuItem>
              <MenuItem value="insideVIPRoom">Inside VIP Room</MenuItem>
              <MenuItem value="outsideVIPRoom">Outside VIP Room</MenuItem>
              <MenuItem value="pantry">Pantry Tab</MenuItem>
            </Select>
          </FormControl>
          <br />
          <br />
          {[
            "outdoorMeetingRoom",
            "insideMeetingRoom",
            "insideVIPRoom",
            "outsideVIPRoom"
          ].includes(category) && (
            <>
              <Autocomplete
                id="room"
                options={roomList}
                getOptionLabel={option => option.name}
                onChange={onRoomChange}
                value={roomSelected}
                disableClearable
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Room"
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
              <br />
            </>
          )}
          {["insideVIPRoom", "outsideVIPRoom"].includes(category) && (
            <>
              <Autocomplete
                id="employee"
                options={employeeList}
                getOptionLabel={option => option.name}
                onChange={onEmployeeChange}
                value={employeeSelected}
                disableClearable
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Employee"
                    onChange={e => setSearchInput(e.target.value)}
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
              <br />
            </>
          )}
          {["pantry"].includes(category) && (
            <>
              <Autocomplete
                id="pantry"
                options={pantryList}
                getOptionLabel={option => option.name}
                onChange={onPantryChange}
                value={pantrySelected}
                disableClearable
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Pantry"
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
              <br />
            </>
          )}
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel id="subCategory">Sub category</InputLabel>
            <Select
              labelId="subCategory"
              disabled={
                !["authenticator", "entrance", "parkingAuthenticator"].includes(
                  category
                )
              }
              id="subCategory"
              label="Sub category"
              value={subCategory}
              onChange={e => setSubCategory(e.target.value)}
              multiple
              autoWidth
            >
              <MenuItem value="checkIn">Check In</MenuItem>
              <MenuItem value="checkOut">Check Out</MenuItem>
            </Select>
          </FormControl>
          <br />

          <Grid
            container
            direction="row-reverse"
            alignItems="center"
            spacing={3}
            className={classes.marginNormal}
          >
            <Grid item>
              <Button
                onClick={handleAdd}
                disabled={isLoading || !(formData && formData.floorIds !== [])}
                variant="contained"
                color="primary"
              >
                Create
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleClose} variant="contained">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddDeviceModal;
