import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import ChipInput from "material-ui-chip-input";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import api from "../../../utils/api";
import Loader from "../../../utils/loader";
import { DefaultEditor } from "react-simple-wysiwyg";

const useStyles = makeStyles({
  root: {},
  item: {
    display: "flex",
    flexDirection: "column"
  }
});

const DeleteDialog = props => {
  const { deleteOpen, handleDeleteClose, handleDelete } = props;
  return (
    <Dialog
      open={deleteOpen}
      onClose={handleDeleteClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure that you want to remove this question? <br />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeleteClose} color="primary" autoFocus>
          No
        </Button>
        <Button onClick={() => handleDelete()} color="secondary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Question = ({ className, close, ...rest }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteingIndex, setDeletingIndex] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const data = await api("POST", "question/mobile/find", null, true);
        setQuestions(data.data);
        setLoading(false);
      } catch (error) {
        setQuestions([]);
        setLoading(false);
      }
    })();
  }, []);

  const handleChange = (key, value, index) => {
    let newItem = { ...questions[index], [key]: value };
    let newQuestions = [...questions];
    newQuestions[index] = newItem;
    setQuestions(newQuestions);
  };

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    const data = await api(
      "PATCH",
      "question/update",
      { questions: questions },
      true
    );
    setQuestions(data.data);
    setLoading(false);
  };

  return loading ? (
    <Loader />
  ) : (
    <Box width="100%" className={clsx(classes.root, className)} {...rest}>
      <Grid container spacing={6} wrap="wrap">
        <Grid className={classes.item} item md={12} sm={12}>
          <Typography color="textPrimary" gutterBottom variant="h4">
            Checkin Questionnaire
          </Typography>
          {questions.length > 0 &&
            questions.map((item, index) => (
              <Card key={index} style={{ marginBottom: 16 }}>
                <CardContent>
                  <Grid container direction="row" justify="space-between">
                    <Grid item>
                      <Grid container direction="row" spacing={3}>
                        <Grid item>
                          <Box
                            height={48}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography
                              color="textPrimary"
                              gutterBottom
                              variant="h5"
                            >
                              Question {index + 1}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={item.isRequired}
                                onChange={e => {
                                  handleChange(
                                    "isRequired",
                                    e.target.checked,
                                    index
                                  );
                                }}
                                name="required"
                              />
                            }
                            label="Required"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Box
                        height={48}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <IconButton
                          onClick={e => {
                            setDeletingIndex(index);
                            setDeleteOpen(true);
                          }}
                        >
                          <X />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container direction="row" spacing={3}>
                    <Grid item xs={12} sm={6} md={8}>
                      <TextField
                        fullWidth
                        label="Title of the question"
                        multiline
                        rowsMax={4}
                        margin="normal"
                        name="title"
                        type="text"
                        value={item.title}
                        onChange={e => {
                          handleChange("title", e.target.value, index);
                        }}
                        variant="outlined"
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        margin="normal"
                      >
                        <InputLabel id="default-room">Question Type</InputLabel>
                        <Select
                          id="questionType"
                          label="Question Type"
                          value={item.type}
                          onChange={e => {
                            handleChange("type", e.target.value, index);
                          }}
                          autoWidth
                        >
                          <MenuItem value="radiogroup">Radio Group</MenuItem>
                          <MenuItem value="textinput">Text Input</MenuItem>
                          {(item.type === "photo" ||
                            !questions
                              .map(qitem => qitem.type)
                              .includes("photo")) && (
                            <MenuItem value="photo">Photo</MenuItem>
                          )}
                          {(item.type === "fullPageWysiwyg" ||
                            !questions
                              .map(qitem => qitem.type)
                              .includes("fullPageWysiwyg")) && (
                            <MenuItem value="fullPageWysiwyg">
                              Full Page NDA
                            </MenuItem>
                          )}
                          {(item.type === "signature" ||
                            !questions
                              .map(qitem => qitem.type)
                              .includes("signature")) && (
                            <MenuItem value="signature">Signature</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    {item.type === "radiogroup" && (
                      <>
                        <Grid item xs={12} sm={6} md={8}>
                          <ChipInput
                            defaultValue={item.choices}
                            fullWidth
                            label="Answers"
                            margin="normal"
                            name="title"
                            multiline
                            rowsMax={4}
                            type="text"
                            onChange={chips => {
                              handleChange("choices", chips, index);
                            }}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormControl
                            variant="outlined"
                            style={{ width: "100%" }}
                            margin="normal"
                          >
                            <InputLabel id="default-room">
                              Expected answer
                            </InputLabel>
                            <Select
                              id="expectedAnswer"
                              label="Expected Answer"
                              multiple
                              value={
                                item.expected ? [...item.expected] : ["Any"]
                              }
                              renderValue={selected => selected.join(", ")}
                              onChange={e => {
                                if (
                                  e.target.value[e.target.value.length - 1] ===
                                  "Any"
                                ) {
                                  handleChange("expected", ["Any"], index);
                                } else if (e.target.value.length > 0) {
                                  handleChange(
                                    "expected",
                                    e.target.value.filter(
                                      item => item !== "Any"
                                    ),
                                    index
                                  );
                                }
                              }}
                              autoWidth
                            >
                              <MenuItem value="Any">
                                <em>Any</em>
                              </MenuItem>
                              {item.choices.map((innerItem, innerIndex) => (
                                <MenuItem value={innerItem} key={innerIndex}>
                                  <Checkbox
                                    checked={
                                      item.expected &&
                                      item.expected.includes(innerItem)
                                    }
                                  />
                                  {innerItem}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    {item.type === "fullPageWysiwyg" && (
                      <Grid item xs={12}>
                        <DefaultEditor
                          value={item.nda ?? ""}
                          onChange={e => {
                            handleChange("nda", e.target.value, index);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            ))}
        </Grid>
      </Grid>
      <Divider />
      <br />
      <Grid container direction="row-reverse" py={2} spacing={3}>
        <Grid item>
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              setQuestions([
                ...questions,
                {
                  type: "radiogroup",
                  choices: [],
                  isRequired: true,
                  title: "",
                  ans: ""
                }
              ]);
            }}
          >
            Add More
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="outlined" onClick={close}>
            Close
          </Button>
        </Grid>
      </Grid>
      <DeleteDialog
        deleteOpen={deleteOpen}
        handleDeleteClose={e => setDeleteOpen(false)}
        handleDelete={e => {
          setDeleteOpen(false);
          setQuestions(
            questions.filter(
              innerItem => questions.indexOf(innerItem) !== deleteingIndex
            )
          );
        }}
      />
    </Box>
  );
};

Question.propTypes = {
  className: PropTypes.string
};

export default Question;
