import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import { Business } from "@material-ui/icons";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { LogIn } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Page from "src/components/Page";
import api from "../../utils/api";
import Loader from "../../utils/loader";
import { organization, business } from "../../actions";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%"
  },
  container: {
    padding: theme.spacing(3),
    backgroundColor: "#fff"
  },
  img: {
    padding: theme.spacing(3)
  },
  link: {
    cursor: "pointer"
  },
  linkIcon: {
    "& svg": {
      verticalAlign: "middle",
      marginRight: "5px"
    },
    "& span": {
      verticalAlign: "middle"
    }
  }
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const org = useSelector(state => state.org);
  const businessOnState = useSelector(state => state.business);
  const orgSettings = useSelector(state => state.orgSettings);
  const [accountType, setAccountType] = useState("");
  const [accountAdmin, setAccountAdmin] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [success, setSuccess] = useState(false);
  const [isOtpEnter, setIsOtpEnter] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [domain, setDomain] = useState(
    businessOnState && businessOnState.id ? businessOnState.domain : ""
  );
  const [error, setError] = useState("");
  const [isloading, setIsloading] = useState(false);
  const navigate = useNavigate();

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    (async () => {
      try {
        if (businessOnState) {
          setDomain(businessOnState.domain);
          const orgList = await api(
            "GET",
            "organization/getOrganization/" + businessOnState.id,
            null,
            false
          );
          setCompanyList(orgList);
          if(orgList.length === 1) {
            dispatch(organization(orgList[0]));
          }
        }
      } catch (error) {
        setError("Could not fetch organization list in this for this account");
      }
    })();
  }, [businessOnState]);

  const findDomainFromInput = async () => {
    try {
      const validateDomain = await api(
        "GET",
        "business/searchByDomain/" + domain,
        null,
        false
      );
      if (validateDomain.id) {
        dispatch(business(validateDomain));
        setError("");
      } else {
        setError("Could not find account for this domain");
      }
    } catch (error) {
      setError("Could not find account for this domain");
    }
  };

  const handleSelectOrganization = async orgId => {
    dispatch(organization(companyList.find(item => (item.id = orgId))));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsloading(true);
    setError("");
    if (accountType === "") {
      setError("Please select a user type");
      setIsloading(false);
      return;
    }
    if (!accountAdmin && !org) {
      setError("Please select an organization");
      setIsloading(false);
      return;
    }
    if (email.trim() === "" || domain.trim() === "") {
      setError("Please fill up all the fields");
      setIsloading(false);
      return;
    }

    try {
      await api(
        "POST",
        "auth/forgotPassword",
        accountAdmin
          ? {
              contact: email,
              domain: domain.toLowerCase(),
              loginType: "businessAdmin"
            }
          : {
              contact: email,
              domain: domain.toLowerCase(),
              organizationId: org.id
            }
      );
      setIsOtpEnter(true);
      setIsloading(false);
    } catch (error) {
      if (error.response && error.response.data) setError(error.response.data);
      setIsloading(false);
    }
  };

  const handleSubmitForValidateOtp = async e => {
    e.preventDefault();
    setIsloading(true);
    setError("");
    if (otp.trim() === "") {
      setError("Please fill the OTP field below");
      setIsloading(false);
      return;
    }
    try {
      await api(
        "POST",
        "auth/validateForgotToken",
        accountAdmin
          ? {
              otp,
              contact: email,
              domain: domain.toLowerCase(),
              loginType: "businessAdmin"
            }
          : {
              otp,
              contact: email,
              domain: domain.toLowerCase(),
              organizationId: org.id
            }
      );
      setSuccess(true);
      setIsloading(false);
    } catch (error) {
      if (error.response && error.response.data) setError(error.response.data);
      setIsloading(false);
    }
  };
  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="flexStart"
        className={classes.container}
      >
        <Container maxWidth="lg">
          <Grid container direction="row" spacing={3} alignItems="center">
            <Grid item md={8} xs={12}>
              <Container maxWidth="md">
                <img
                  src="/static/banner.png"
                  style={{ maxWidth: "100%", padding: "60px 0" }}
                  className={classes.img}
                  alt="logo"
                />
              </Container>
            </Grid>
            <Grid item md={4} xs={12}>
              {org && org.id && (
                <Box mb={3}>
                  <Box>
                    <img
                      src={org.logo}
                      style={{ maxWidth: "100%" }}
                      className={classes.img}
                      alt="img"
                    />
                  </Box>
                  <Typography
                    color="primary"
                    variant="h1"
                    component="span"
                    style={{ fontWeight: 900 }}
                  >
                    Welcome to {org.name}
                  </Typography>
                </Box>
              )}

              {isloading ? (
                <Loader />
              ) : (
                <React.Fragment>
                  {success ? (
                    <React.Fragment>
                      <Box mb={1}>
                        <Typography color="textPrimary" variant="h2">
                          Password has been updated successfully!
                        </Typography>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="body2"
                        >
                          Please check your inbox and for updated password!
                        </Typography>
                      </Box>
                      <Box my={2}>
                        <Button
                          color="primary"
                          fullWidth
                          size="large"
                          id="login"
                          variant="contained"
                          endIcon={<LogIn />}
                          onClick={() => navigate("/")}
                        >
                          Login
                        </Button>
                      </Box>
                    </React.Fragment>
                  ) : isOtpEnter ? (
                    <form onSubmit={handleSubmitForValidateOtp}>
                      <Box mb={1}>
                        <Typography color="textPrimary" variant="h2">
                          Enter OTP
                        </Typography>
                        <Typography
                          color="textSecondary"
                          gutterBottom
                          variant="body2"
                        >
                          Please check your inbox and submit the OTP!
                        </Typography>
                      </Box>

                      {error !== "" && (
                        <Alert
                          onClose={() => {
                            setError("");
                          }}
                          severity="error"
                        >
                          {error}
                        </Alert>
                      )}

                      <TextField
                        fullWidth
                        label="Enter OTP"
                        margin="normal"
                        name="otp"
                        id="otp"
                        onChange={e => setOtp(e.target.value)}
                        type="text"
                        value={otp}
                        variant="outlined"
                      />

                      <Box my={2}>
                        <Button
                          color="primary"
                          fullWidth
                          size="large"
                          id="submit"
                          type="submit"
                          variant="contained"
                          endIcon={<LogIn />}
                        >
                          Submit OTP
                        </Button>
                      </Box>
                      <Box>
                        <Typography
                          color="primary"
                          variant="h5"
                          component="span"
                          style={{ fontWeight: 900 }}
                          className={classes.link}
                          onClick={() => setIsOtpEnter(false)}
                        >
                          <Link className={classes.linkIcon}>
                            <KeyboardBackspaceIcon />
                            <span>Back </span>
                          </Link>
                        </Typography>
                      </Box>
                    </form>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <Box mb={1}>
                        <Typography color="textPrimary" variant="h2">
                          Forgot Password
                        </Typography>
                      </Box>

                      {error !== "" && (
                        <Alert
                          onClose={() => {
                            setError("");
                          }}
                          severity="error"
                        >
                          {error}
                        </Alert>
                      )}

                      {(!businessOnState || !businessOnState.id) && (
                        <TextField
                          fullWidth
                          label="Domain"
                          margin="normal"
                          name="domain"
                          id="domain"
                          onChange={e => setDomain(e.target.value)}
                          onBlur={e => findDomainFromInput()}
                          type="text"
                          value={domain}
                          variant="outlined"
                        />
                      )}

                      <FormControl component="fieldset">
                        <FormLabel component="legend">User type</FormLabel>
                        <RadioGroup
                          row
                          onChange={e => {
                            setAccountType(e.target.value);
                            if (e.target.value === "accountAdmin")
                              setAccountAdmin(true);
                            else setAccountAdmin(false);
                          }}
                        >
                          <FormControlLabel
                            value="visitor"
                            control={<Radio />}
                            label="Visitor"
                          />
                          <FormControlLabel
                            value="employee"
                            control={<Radio />}
                            label="Employee"
                          />
                          <FormControlLabel
                            value="accountAdmin"
                            control={<Radio />}
                            label="Account Admin"
                          />
                        </RadioGroup>
                      </FormControl>

                      {!accountAdmin && companyList.length > 1 && (
                        <FormControl
                          variant="outlined"
                          style={{ width: "100%" }}
                          margin="normal"
                        >
                          <InputLabel id="company"> Select company</InputLabel>
                          <Select
                            labelId="company"
                            id="company"
                            label="Select company"
                            displayEmpty
                            onChange={e => {
                              handleSelectOrganization(e.target.value);
                            }}
                            autoWidth
                          >
                            {companyList.map(item => (
                              <MenuItem value={item.id} key={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}

                      <TextField
                        fullWidth
                        label="Email or Phone number"
                        margin="normal"
                        name="email"
                        id="email"
                        onChange={e => setEmail(e.target.value)}
                        type="text"
                        value={email}
                        variant="outlined"
                      />

                      <Box my={2}>
                        <Button
                          color="primary"
                          fullWidth
                          size="large"
                          id="submit"
                          type="submit"
                          variant="contained"
                          endIcon={<LogIn />}
                        >
                          Get otp
                        </Button>
                      </Box>
                      <Box>
                        <Typography
                          color="primary"
                          variant="h5"
                          component="span"
                          style={{ fontWeight: 900 }}
                          className={classes.link}
                          onClick={() => navigate("/login")}
                        >
                          <Link className={classes.linkIcon}>
                            <KeyboardBackspaceIcon />
                            <span>Back to Login </span>
                          </Link>
                        </Typography>
                      </Box>
                    </form>
                  )}
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Page>
  );
};

export default ForgotPassword;
