import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import { captureException } from "@sentry/browser";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch } from "react-redux";
import { clearToastAction, toastAction } from "../../../../actions";
import api from "../../../../utils/api";
import AddModal from "../AddModal";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end"
  },
  capitalize: {
    textTransform: "capitalize"
  },
  chipButton: {
    paddingLeft: 12,
    paddingRight: 12
  },
  redButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.dark
    },
    "&:disabled": {
      backgroundColor: theme.palette.error.light
    }
  }
}));

const columns = [
  {
    label: "name",
    dataType: "text",
    field: "name"
  },
  {
    label: "email",
    dataType: "text",
    field: "email"
  },
  {
    label: "phone number",
    dataType: "text",
    field: "mobile"
  },
  {
    label: "designation",
    dataType: "text",
    field: "designation"
  },
  {
    label: "department",
    dataType: "text",
    field: "department"
  }
];

const EmployeesArchive = props => {
  const { className, reload, ...rest } = props;
  const classes = useStyles();
  const [employees, setEmployees] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filters, setFilters] = useState({});
  const [currentSort, setCurrentSort] = useState({
    label: "",
    direction: "asc"
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [query, setQuery] = useState({
    search: [],
    sort: { key: "employee.createdAt", order: "DESC" },
    skip: page * rowsPerPage,
    take: rowsPerPage
  });

  const dispatch = useDispatch();

  useEffect(() => {
    console.log();
    (async () => {
      handleQuery({ ...query, skip: page * rowsPerPage, take: rowsPerPage });
      setQuery({ ...query, skip: page * rowsPerPage, take: rowsPerPage });
    })();
  }, [page, rowsPerPage, modalOpen, reload]);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const handleChangeFilter = (label, field, value) => {
    let allFilters = filters;

    if (value.trim() !== "") {
      allFilters[label] = value;
      setQuery({
        ...query,
        search: [
          ...query.search.filter(item => item.key !== "employee." + field),
          { key: "employee." + field, value: value, comparison: "LIKE" }
        ],
        skip: 0
      });
    } else if (allFilters.hasOwnProperty(label)) {
      setQuery({
        ...query,
        search: [
          ...query.search.filter(item => item.key !== "employee." + field)
        ],
        skip: 0
      });
      delete allFilters[label];
    }

    setFilters({ ...allFilters });
  };

  const handleDeleteChip = key => {
    let allFilters = filters;
    delete allFilters[key];
    var field = key === "phone number" ? "mobile" : key;
    setQuery({
      ...query,
      search: [
        ...query.search.filter(item => item.key !== "employee." + field)
      ],
      skip: 0
    });
    if (Object.keys(allFilters).length === 0) {
      handleQuery({
        ...query,
        search: []
      });
    }
    setFilters({ ...allFilters });
  };

  const handleEditRow = data => {
    setEditData(data);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSort = label => {
    var direction = "asc";
    var key = "employee." + (label === "phone number" ? "mobile" : label);
    if (currentSort.label === label && currentSort.direction === "asc")
      direction = "desc";
    const order = direction.toUpperCase();
    handleQuery({ ...query, sort: { key, order }, skip: 0 });
    setQuery({ ...query, sort: { key, order }, skip: 0 });
    setCurrentSort({ label, direction });
  };

  const handleQuery = async customQuery => {
    dispatch(
      toastAction({
        status: true,
        message: "Loading...",
        type: "info",
        noIcon: true
      })
    );
    try {
      const data = customQuery
        ? await api("POST", "employee/find/archive", customQuery, true)
        : await api("POST", "employee/find/archive", query, true);
      const countData = customQuery
        ? await api("POST", "employee/findCount/archive", customQuery, true)
        : await api("POST", "employee/findCount/archive", query, true);
      setCount(countData);
      setEmployees(data);
      dispatch(clearToastAction({}));
    } catch (error) {
      captureException(error);
      dispatch(clearToastAction({}));
    }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <PerfectScrollbar>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((col, i) => (
                  <TableCell className={classes.capitalize} key={i}>
                    <TableSortLabel
                      active={currentSort.label === col.label}
                      direction={currentSort.direction}
                      onClick={() => handleSort(col.label)}
                    >
                      {col.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {columns.map((col, i) => (
                  <TableCell key={i} size="small" style={{ padding: 6 }}>
                    <TextField
                      onChange={e => {
                        handleChangeFilter(
                          col.label,
                          col.field,
                          e.target.value
                        );
                      }}
                      size="small"
                      name={col.label}
                      placeholder={`Search by ${col.label}`}
                      variant="outlined"
                      value={filters[col.label] || ""}
                    />
                  </TableCell>
                ))}
              </TableRow>
              {Object.keys(filters).length > 0 && (
                <>
                  <TableRow selected>
                    <TableCell colSpan={columns.length} align="center">
                      <Typography variant="h6" component="p">
                        Selected filters: &nbsp;
                      </Typography>
                      {Object.keys(filters).map((filter, i) => {
                        return (
                          <React.Fragment key={i}>
                            <Chip
                              variant="outlined"
                              color="primary"
                              onDelete={() => {
                                handleDeleteChip(filter);
                              }}
                              label={
                                <>
                                  <b className={classes.capitalize}>{filter}</b>
                                  : {filters[filter]}
                                </>
                              }
                            />
                            &nbsp; &nbsp;
                          </React.Fragment>
                        );
                      })}
                      <Chip
                        color="primary"
                        icon={<SearchIcon />}
                        className={classes.chipButton}
                        clickable
                        onClick={() => {
                          handleQuery();
                        }}
                        label={<b>Search</b>}
                      />
                    </TableCell>
                  </TableRow>
                </>
              )}
              {employees.map(employee => (
                <TableRow
                  hover
                  key={employee.id}
                  onClick={() => handleEditRow(employee)}
                >
                  {columns.map((col, i) => (
                    <TableCell key={i}>{employee[col.field]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={count}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <AddModal
        open={modalOpen}
        close={closeModal}
        type="employee"
        userData={editData}
      />
    </div>
  );
};

EmployeesArchive.propTypes = {
  className: PropTypes.string,
  employees: PropTypes.array.isRequired,
  reload: PropTypes.bool
};

EmployeesArchive.defaultProps = {
  employees: []
};

export default EmployeesArchive;
