import { Grid, makeStyles, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
import { X } from "react-feather";
import "react-phone-input-2/lib/material.css";
import api from "src/utils/api";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  formControl: {
    marginTop: "16px"
  },
  formSelect: {
    marginBottom: "5px"
  }
}));

const initialState = {
  name: "",
  number: "",
  occupancy: "",
  minOccupancy: 0,
  maxOccupancy: "",
  buildingId: "",
  type: ""
};

const RoomModal = props => {
  const classes = useStyles();
  const {
    open,
    close,
    buildingList,
    floorList,
    activeData,
    roomCount,
    fetchData,
    editRoomToArray
  } = props;
  const [formData, setFormData] = useState(initialState);
  const [isLoading, setLoader] = useState(false);
  const [error, setError] = useState("");

  const handleClose = () => {
    close();
  };

  React.useEffect(() => {
    if (activeData.id) {
      activeData.buildingId = activeData.building.id;
      activeData.buildingName = activeData.building.name;
      setFormData(activeData);
    }
  }, []);

  const handleAction = () => {
    if (activeData.id) {
      handleUpdate();
    } else {
      handleAdd();
    }
  };

  const handleAdd = async () => {
    if (
      (formData.name &&
        formData.number &&
        formData.occupancy &&
        formData.minOccupancy &&
        formData.maxOccupancy &&
        formData.buildingId) ||
      formData.type
    ) {
      setLoader(true);
      try {
        const res = await api(
          "POST",
          "room",
          {
            name: formData.name,
            number: formData.number,
            buildingId: formData.buildingId,
            floorId: formData.floorId,
            occupancy: formData.occupancy,
            meetingRoomEmailDisplayName: formData.meetingRoomEmailDisplayName,
            meetingRoomEmail: formData.meetingRoomEmail,
            minOccupancy: formData.minOccupancy,
            maxOccupancy: formData.maxOccupancy,
            type: formData.type
          },
          true
        );
        if (res.id) {
          roomCount();
          handleClose();
          fetchData();
          setLoader(false);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          setError(error.response.data);
        }
        setLoader(false);
      }
    } else {
      setError("Please fill all the fields");
    }
  };

  const handleUpdate = async () => {
    if (
      (formData.name &&
        formData.number &&
        formData.occupancy &&
        formData.buildingId) ||
      formData.type
    ) {
      setLoader(true);
      try {
        const data = await api(
          "PATCH",
          `room/${activeData.id}`,
          {
            name: formData.name,
            number: formData.number,
            buildingId: formData.buildingId,
            floorId: formData.floorId,
            occupancy: formData.occupancy,
            meetingRoomEmailDisplayName: formData.meetingRoomEmailDisplayName,
            meetingRoomEmail: formData.meetingRoomEmail,
            minOccupancy: formData.minOccupancy,
            maxOccupancy: formData.maxOccupancy,
            type: formData.type
          },
          true
        );
        if (data.id) {
          editRoomToArray(data);
          handleClose();
          setLoader(false);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          setError(error.response.data);
        }
        setLoader(false);
      }
    } else {
      setError("Please fill all the fields");
    }
  };

  const onTagsChange = (event, values) => {
    setFormData(formData => ({
      ...formData,
      buildingId: values.id
    }));
  };

  const onFloorChange = (event, values) => {
    setFormData(formData => ({
      ...formData,
      floorId: values.id
    }));
  };

  const handleInputChange = event => {
    event.persist();
    const { name, value } = event.target;
    if (
      name === "number" ||
      name === "occupancy" ||
      name === "minOccupancy" ||
      name === "maxOccupany"
    ) {
      const re = /^[0-9\b]+$/;
      if (value === "" || re.test(value)) {
        setFormData(formData => ({
          ...formData,
          [name]: value
        }));
      }
    } else {
      setFormData(formData => ({
        ...formData,
        [name]: value
      }));
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle disableTypography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography component="h1" variant="h3" color="primary">
              {activeData.id ? "Edit" : "Add"} Room
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {error !== "" && (
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setError("");
                  }}
                >
                  <X />
                </Button>
              }
            >
              {error}
            </Alert>
          )}
          <Autocomplete
            id="building"
            options={buildingList}
            getOptionLabel={option => option.name}
            onChange={onTagsChange}
            defaultValue={{
              name: formData.buildingName,
              buildingId: formData.buildingId
            }}
            disableClearable
            className={classes.formSelect}
            renderInput={params => (
              <TextField
                {...params}
                label="Building"
                fullWidth
                variant="outlined"
              />
            )}
          />
          <br />
          <Autocomplete
            id="floor"
            options={floorList.filter(
              item => item.building.id === formData.buildingId
            )}
            getOptionLabel={option => option.name}
            onChange={onFloorChange}
            defaultValue={{
              name: formData.floorName,
              buildingId: formData.floorId
            }}
            disableClearable
            className={classes.formSelect}
            renderInput={params => (
              <TextField
                {...params}
                label="Floor"
                fullWidth
                variant="outlined"
              />
            )}
          />
          <TextField
            id="name"
            label="Name"
            variant="outlined"
            type="text"
            margin="normal"
            required
            fullWidth
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
          <TextField
            id="number"
            label="Number"
            variant="outlined"
            type="text"
            margin="normal"
            required
            fullWidth
            name="number"
            value={formData.number}
            onChange={handleInputChange}
          />
          <TextField
            id="occupancy"
            label="Occupancy"
            variant="outlined"
            type="text"
            margin="normal"
            required
            fullWidth
            name="occupancy"
            value={formData.occupancy}
            onChange={handleInputChange}
          />
          <TextField
            id="occupancy"
            label="Maximum occupancy"
            variant="outlined"
            type="text"
            margin="normal"
            required
            fullWidth
            name="maxOccupancy"
            value={formData.maxOccupancy}
            onChange={handleInputChange}
          />
          <TextField
            id="occupancy"
            label="Minimum occupancy"
            variant="outlined"
            type="text"
            margin="normal"
            required
            fullWidth
            name="minOccupancy"
            value={formData.minOccupancy}
            onChange={handleInputChange}
          />
          <FormControl
            variant="outlined"
            fullWidth
            className={classes.formControl}
          >
            <InputLabel>Type</InputLabel>
            <Select
              label="Type"
              name="type"
              value={formData.type}
              onChange={handleInputChange}
            >
              <MenuItem value="" disabled>
                <em>Select</em>
              </MenuItem>
              {["square", "rectangle", "circle", "oval", "other"].map(
                (val, ind) => (
                  <MenuItem key={ind} value={val}>
                    {(val.charAt(0) && val.charAt(0).toUpperCase()) +
                      val.slice(1)}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <TextField
            id="meetingRoomEmail"
            label="Room mailbox (for exchange integration only)"
            variant="outlined"
            type="text"
            margin="normal"
            fullWidth
            name="meetingRoomEmail"
            value={formData.meetingRoomEmail}
            onChange={handleInputChange}
          />
          <TextField
            id="meetingRoomEmailDisplayName"
            label="Room mailbox display name (for exchange integration only)"
            variant="outlined"
            type="text"
            margin="normal"
            fullWidth
            name="meetingRoomEmailDisplayName"
            value={formData.meetingRoomEmailDisplayName}
            onChange={handleInputChange}
          />
          <Grid
            container
            direction="row-reverse"
            alignItems="center"
            spacing={3}
            className={classes.marginNormal}
          >
            <Grid item>
              <Button
                onClick={handleAction}
                disabled={isLoading}
                variant="contained"
                color="primary"
              >
                {isLoading ? "Loading..." : activeData.id ? "Update" : "Add"}
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleClose} variant="contained">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RoomModal;
