import { Box, Grid, Typography } from "@material-ui/core";
import PubNub from "pubnub";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Slider from "react-slick";
import api from "../../utils/api";
import Loader from "../../utils/loader";

const pubnub = new PubNub({
  publishKey: "pub-c-f3c50488-4e44-4a88-8a67-bc0c4a1b6838",
  subscribeKey: "sub-c-7e34b8f8-f0ff-11ea-a728-4ec3aefbf636"
});

const channels = ["esri"];

class Display extends Component {
  state = {
    url: "https://nus-gis.dannydang.me/nus-app-test/",
    loading: true,
    pointValues: [
      { pointName: "percentSanitized", pointValue: "--", quality: "Bad" },
      { pointName: "energyUsageIntensity", pointValue: "--", quality: "Bad" },
      { pointName: "weeklySocialViolation", pointValue: "--", quality: "Bad" }
    ]
  };

  styles = {
    good: {
      color: "green"
    },
    img: {
      width: 120,
      height: "auto"
    },
    cardTitle: {
      color: "#fff",
      fontSize: 48,
      textAlign: "center"
    },
    cardBackground: {
      padding: 12,
      borderRadius: 12,
      backgroundRepeat: "no-repeat",
      backgroundAttachment: "fixed",
      backgroundPosition: "center",
      height: 640,
      marginTop: 60,
      position: "relative",
      boxShadow: "6px 6px 6px #00000030"
    },
    iconCircle: {
      width: 200,
      height: 200,
      backgroundColor: "#fff",
      borderRadius: "50%",
      boxShadow: "10px 10px 10px #00000030",
      overflow: "hidden",
      margin: "20px auto"
    },
    iconImg: {
      maxWidth: 200,
      maxHeight: 200,
      margin: "0px auto",
      padding: 12
    },
    valueBox: {
      height: 148,
      borderRadius: 12,
      margin: "20px auto",
      padding: 4,
      backgroundColor: "#00000020",
      flex: 1
    },
    valuePretext: {
      color: "#fff",
      fontSize: 24,
      margin: 0,
      padding: 0,
      textTransform: "uppercase",
      textAlign: "center"
    },
    valueText: {
      fontSize: 64,
      padding: 0,
      margin: "4px 0",
      lineHeight: 0.8,
      color: "#fff"
    },
    valuePosttext: {
      color: "#fff",
      fontSize: 24,
      textAlign: "center",
      margin: 0,
      padding: 0
    },
    bottomText: {
      color: "#fff",
      lineHeight: 1.2,
      fontSize: 20,
      textAlign: "center"
    }
  };

  componentDidMount() {
    // setTimeout(() => {
    (async () => {
      let data = await api(
        "GET",
        "business/getOtherSettings/" + this.props.business.id,
        null,
        false
      );

      this.setState({
        ...this.state,
        pointValues: data.pointValues
      });
    })();
    this.fetchValues();
    this.setState({ ...this.state, loading: false });
    // }, 3000);

    pubnub.subscribe({ channels });
    pubnub.addListener({
      message: messageEvent => {
        this.setState({
          url: `https://nus-gis.dannydang.me/nus-app-test/?start=VMS&end=${messageEvent.message.description}&flag=1`
        });
        setTimeout(() => {
          this.setState({ url: `https://nus-gis.dannydang.me/nus-app-test/` });
        }, 30000);
      }
    });
  }

  fetchValues() {
    setInterval(async () => {
      let data = await api(
        "GET",
        "business/getOtherSettings/" + this.props.business.id,
        null,
        false
      );
      this.setState({
        ...this.state,
        pointValues: data.pointValues
      });
    }, 60000);
  }

  render() {
    const settings = {
      dots: false,
      // dots: true,
      infinite: true,
      // autoplay: true,
      autoplaySpeed: 9000,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return this.state.loading ? (
      <Loader />
    ) : (
      <div>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=0.7, shrink-to-fit=no"
          />
          <link rel="manifest" href="/displaymanifest.json" />
        </Helmet>
        <Grid container style={{ height: "100vh" }} spacing={3}>
          <Grid item xs={12} style={{ height: "30%", paddingTop: 48 }}>
            <Slider {...settings}>
              <div style={{ padding: 24 }}>
                <Typography
                  variant="button"
                  component="h2"
                  align="center"
                  style={{
                    fontSize: 42,
                    lineHeight: 0.8,
                    fontWeight: 900,
                    color: "dodgerblue"
                  }}
                >
                  Sustainability
                </Typography>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  style={{ padding: 24 }}
                >
                  <Grid item xs={4}>
                    <div
                      style={{
                        ...this.styles.cardBackground,
                        backgroundColor: "#ffb229"
                      }}
                    >
                      <div style={this.styles.iconCircle}>
                        <img
                          src="/images/energyIntensity.png"
                          style={this.styles.iconImg}
                          alt="icon"
                        ></img>
                      </div>
                      <Typography
                        variant="h1"
                        component="h2"
                        style={{ ...this.styles.cardTitle }}
                      >
                        Energy
                        <br /> Intensity
                      </Typography>

                      <Box style={this.styles.valueBox}>
                        <Typography
                          variant="button"
                          component="h2"
                          style={this.styles.valuePretext}
                          align="center"
                        >
                          Year to Date
                        </Typography>
                        <Typography
                          variant="h1"
                          style={{
                            ...this.styles.valueText
                          }}
                          align="center"
                        >
                          {this.state.pointValues.find(
                            item =>
                              item.pointName === "VENTUS_HPD-EnergyIntensity"
                          ) &&
                            Math.round(
                              parseFloat(
                                this.state.pointValues.find(
                                  item =>
                                    item.pointName ===
                                    "VENTUS_HPD-EnergyIntensity"
                                ).pointValue
                              )
                            )}

                          <br />
                        </Typography>
                        <Typography style={this.styles.valuePosttext}>
                          kWh/m<sup>2</sup>
                        </Typography>
                      </Box>

                      <Box
                        height={2}
                        style={{ backgroundColor: "#00000030" }}
                        my={1}
                      ></Box>

                      <Typography
                        variant="body1"
                        align="center"
                        style={this.styles.bottomText}
                      >
                        Super Low Energy Building Benchmark ≤ 100 kWh/m
                        <sup>2</sup>
                        /yr
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      style={{
                        ...this.styles.cardBackground,
                        backgroundColor: "#00b3f1",
                        position: "relative",
                        boxShadow: "6px 6px 6px #00000030"
                      }}
                    >
                      {" "}
                      <div style={this.styles.iconCircle}>
                        <img
                          src={"/images/waterConsumption.png"}
                          style={this.styles.iconImg}
                          alt="icon"
                        ></img>
                      </div>
                      <Typography
                        variant="h1"
                        component="h2"
                        style={{ ...this.styles.cardTitle }}
                      >
                        Water
                        <br /> Consumption
                      </Typography>
                      <Box style={this.styles.valueBox}>
                        <Typography
                          variant="button"
                          component="h2"
                          style={this.styles.valuePretext}
                          align="center"
                        >
                          Conserved
                        </Typography>
                        <Typography
                          variant="h1"
                          style={{
                            ...this.styles.valueText
                          }}
                          align="center"
                        >
                          {/* {this.state.pointValues.find(
                          item =>
                            item.pointName === "VENTUS_HPD-TotalViolationWeek"
                        ) &&
                          this.state.pointValues.find(
                            item =>
                              item.pointName === "VENTUS_HPD-TotalViolationWeek"
                          ).pointValue} */}
                          2200 <br />
                        </Typography>
                        <Typography style={this.styles.valuePosttext}>
                          m<sup>3</sup>/year
                        </Typography>
                      </Box>
                      <Box
                        height={2}
                        style={{ backgroundColor: "#00000030" }}
                        my={1}
                      ></Box>
                      <Typography
                        variant="body1"
                        align="center"
                        style={this.styles.bottomText}
                      >
                        Equiv. to 136 households of 4 Room HDB annual water
                        supply
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      style={{
                        ...this.styles.cardBackground,
                        backgroundColor: "#676767"
                      }}
                    >
                      <div style={this.styles.iconCircle}>
                        <img
                          src="/images/carbonEmission.png"
                          style={this.styles.iconImg}
                          alt="icon"
                        ></img>
                      </div>

                      <Typography
                        variant="h1"
                        component="h2"
                        style={{ ...this.styles.cardTitle }}
                      >
                        Carbon
                        <br /> Emission
                      </Typography>

                      <Box style={this.styles.valueBox}>
                        <Typography
                          variant="button"
                          component="h2"
                          style={this.styles.valuePretext}
                          align="center"
                        >
                          Emission Offset
                        </Typography>
                        <Typography
                          variant="h1"
                          style={{ ...this.styles.valueText }}
                          align="center"
                        >
                          {/* {this.state.pointValues.find(
                          item =>
                            item.pointName === "VENTUS_HPD-TotalViolationWeek"
                        ) &&
                          this.state.pointValues.find(
                            item =>
                              item.pointName === "VENTUS_HPD-TotalViolationWeek"
                          ).pointValue} */}
                          145.9 <br />
                        </Typography>
                        <Typography style={this.styles.valuePosttext}>
                          kWh/yr
                        </Typography>
                      </Box>

                      <Box
                        height={2}
                        style={{ backgroundColor: "#00000030" }}
                        my={1}
                      ></Box>

                      <Typography
                        variant="body1"
                        align="center"
                        style={this.styles.bottomText}
                      >
                        Equiv. to 68 matured trees absorbing CO<sub>2</sub> for
                        40 years
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div style={{ padding: 24 }}>
                <Typography
                  variant="button"
                  component="h2"
                  align="center"
                  style={{
                    fontSize: 42,
                    lineHeight: 0.8,
                    fontWeight: 900,
                    color: "#009325"
                  }}
                >
                  Space and Safety
                </Typography>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  style={{ padding: 24 }}
                >
                  <Grid item xs={4}>
                    <div
                      style={{
                        ...this.styles.cardBackground,
                        backgroundColor: "#c50303"
                      }}
                    >
                      <div style={this.styles.iconCircle}>
                        <img
                          src="/images/spaceUtilization.png"
                          style={this.styles.iconImg}
                          alt="icon"
                        ></img>
                      </div>
                      <Typography
                        variant="h1"
                        component="h2"
                        style={{ ...this.styles.cardTitle }}
                      >
                        Space
                        <br /> Utilization
                      </Typography>
                      <Box style={this.styles.valueBox}>
                        <Typography
                          variant="h1"
                          style={{
                            ...this.styles.valueText
                          }}
                          align="center"
                        >
                          {/* {this.state.pointValues.find(
                          item =>
                            item.pointName === "VENTUS_HPD-TotalViolationWeek"
                        ) &&
                          this.state.pointValues.find(
                            item =>
                              item.pointName === "VENTUS_HPD-TotalViolationWeek"
                          ).pointValue} */}
                          55
                          {"%"}
                        </Typography>
                        <Typography style={this.styles.valuePretext}>
                          utilized
                        </Typography>
                      </Box>
                      <Box
                        height={2}
                        style={{ backgroundColor: "#00000030" }}
                        my={1}
                      ></Box>
                      <Typography
                        variant="body1"
                        align="center"
                        style={this.styles.bottomText}
                      >
                        Meeting space utilized for this week during office hours
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      style={{
                        ...this.styles.cardBackground,
                        backgroundColor: "#009325",
                        position: "relative",
                        boxShadow: "6px 6px 6px #00000030"
                      }}
                    >
                      <div style={this.styles.iconCircle}>
                        <img
                          src={"/images/socialDistancing.png"}
                          style={this.styles.iconImg}
                          alt="icon"
                        ></img>
                      </div>

                      <Typography
                        variant="h1"
                        component="h2"
                        style={{ ...this.styles.cardTitle }}
                      >
                        Social
                        <br /> Distancing
                      </Typography>
                      <Box style={this.styles.valueBox}>
                        <Typography
                          variant="h1"
                          style={{
                            ...this.styles.valueText
                          }}
                          align="center"
                        >
                          {this.state.pointValues.find(
                            item =>
                              item.pointName === "VENTUS_HPD-TotalViolationWeek"
                          ) &&
                            this.state.pointValues.find(
                              item =>
                                item.pointName ===
                                "VENTUS_HPD-TotalViolationWeek"
                            ).pointValue}
                        </Typography>
                        <Typography style={this.styles.valuePretext}>
                          this week
                        </Typography>
                      </Box>
                      <Box
                        height={2}
                        style={{ backgroundColor: "#00000030" }}
                        my={1}
                      ></Box>

                      <Typography
                        variant="body1"
                        align="center"
                        style={this.styles.bottomText}
                      >
                        Number of Social Distancing Notified
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      style={{
                        ...this.styles.cardBackground,
                        backgroundColor: "#9d27c8",
                        position: "relative",
                        boxShadow: "6px 6px 6px #00000030"
                      }}
                    >
                      <div style={this.styles.iconCircle}>
                        <img
                          src="/images/meetingSpaces.png"
                          style={this.styles.iconImg}
                          alt="icon"
                        ></img>
                      </div>

                      <Typography
                        variant="h1"
                        component="h2"
                        style={{ ...this.styles.cardTitle }}
                      >
                        Meeting
                        <br /> Spaces
                      </Typography>
                      <Box style={this.styles.valueBox}>
                        <Typography
                          variant="h1"
                          style={{
                            ...this.styles.valueText
                          }}
                          align="center"
                        >
                          {this.state.pointValues.find(
                            item =>
                              item.pointName ===
                              "VENTUS_HPD-TotSanitisationPerct"
                          ) &&
                            this.state.pointValues.find(
                              item =>
                                item.pointName ===
                                "VENTUS_HPD-TotSanitisationPerct"
                            ).pointValue}
                          {"%"}
                        </Typography>
                        <Typography style={this.styles.valuePretext}>
                          Sanitized
                        </Typography>
                      </Box>

                      <Box
                        height={2}
                        style={{ backgroundColor: "#00000030" }}
                        my={1}
                      ></Box>

                      <Typography
                        variant="body1"
                        align="center"
                        style={this.styles.bottomText}
                      >
                        Index compares Sanitization rate against usage rate of
                        meeting space
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div style={{ padding: 24 }}>
                <Typography
                  variant="button"
                  component="h2"
                  align="center"
                  style={{
                    fontSize: 42,
                    lineHeight: 0.8,
                    fontWeight: 900,
                    color: "#234f1e"
                  }}
                >
                  Awards
                </Typography>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  style={{ padding: 24 }}
                >
                  <Grid item xs={4}>
                    <div
                      style={{
                        ...this.styles.cardBackground,
                        backgroundColor: "#028a0f"
                      }}
                    >
                      <div
                        style={{
                          ...this.styles.iconCircle,
                          width: 300,
                          height: 300
                        }}
                      >
                        <img
                          src="/images/skyrise_greenery_awards.jpg"
                          alt="icon"
                          style={{
                            ...this.styles.iconImg,
                            maxWidth: 300,
                            maxHeight: 300,
                            height: 300
                          }}
                        ></img>
                      </div>

                      <Typography
                        variant="h1"
                        component="h2"
                        style={{
                          ...this.styles.cardTitle,
                          fontSize: 40,
                          marginTop: 100
                        }}
                      >
                        Skyrise
                        <br /> Greenery Awards
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      style={{
                        ...this.styles.cardBackground,
                        backgroundColor: "#234F1E"
                      }}
                    >
                      <div
                        style={{
                          ...this.styles.iconCircle,
                          width: 300,
                          height: 300
                        }}
                      >
                        <img
                          src="/images/LEAF logo.jpg"
                          alt="icon"
                          style={{
                            ...this.styles.iconImg,
                            maxWidth: 300,
                            maxHeight: 300,
                            padding: "60px 0"
                          }}
                        ></img>
                      </div>

                      <Typography
                        variant="h1"
                        component="h2"
                        style={{
                          ...this.styles.cardTitle,
                          fontSize: 40,
                          marginTop: 100
                        }}
                      >
                        Landscape Excellence
                        <br /> Assesment Framework
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div
                      style={{
                        ...this.styles.cardBackground,
                        backgroundColor: "#3cb043"
                      }}
                    >
                      <div
                        style={{
                          ...this.styles.iconCircle,
                          width: 300,
                          height: 300
                        }}
                      >
                        <img
                          src="/images/BCA GREEN MARK.png"
                          alt="icon"
                          style={{
                            ...this.styles.iconImg,
                            maxWidth: 300,
                            maxHeight: 300
                          }}
                        ></img>
                      </div>

                      <Typography
                        variant="h1"
                        component="h2"
                        style={{
                          ...this.styles.cardTitle,
                          fontSize: 40,
                          marginTop: 100
                        }}
                      >
                        BCA GREEN MARK
                        <br /> PLATINUM
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Slider>
          </Grid>
          <Grid item xs={12} style={{ height: "38%" }}>
            <iframe
              title="esri"
              style={{
                width: "100%",
                height: "100%",
                border: "1px solid #aaa"
              }}
              src={this.state.url}
            ></iframe>
          </Grid>
          <Grid item xs={12} style={{ height: "32%", padding: 0 }}>
            <video
              autoPlay
              src="https://s3.ap-south-1.amazonaws.com/img.thesentinel.io/video/A+Changing+Campus.mp4"
              width="100%"
              height="100%"
              loop
              muted
            ></video>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  business: state.business
});

export default connect(mapStateToProps, null)(Display);
