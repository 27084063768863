import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  IconButton,
  Radio,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  List,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles, withStyles } from "@material-ui/styles";
import { captureException } from "@sentry/browser";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toastAction } from "../../actions";
import { KeyboardBackspace } from "@material-ui/icons";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import api from "src/utils/api";
import getAvatarStyle from "../../utils/getAvatarStyle";
import getInitials from "../../utils/getInitials";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import { CSVLink } from "react-csv";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));

const filter = createFilterOptions();

const RosterDataModal = props => {
  const { close, dataList } = props;
  const user = useSelector(state => state.user);
  const orgSettings = useSelector(state => state.orgSettings);
  const primary = orgSettings.visitorPrimaryIdentifier;
  const [responseDialogOpen, setResponseDialogOpen] = useState(false);
  const [rosterData, setRosterData] = useState(dataList || null);
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [mon, setmon] = useState(false);
  const [tue, settue] = useState(false);
  const [wed, setwed] = useState(false);
  const [thu, setthu] = useState(false);
  const [fri, setfri] = useState(false);
  const [sat, setsat] = useState(false);
  const [sun, setsun] = useState(false);
  const [newParticipants, setNewParticipants] = useState([]);
  const [employeeAccessIds, setEmployeeAccessIds] = useState([]);
  const [existingParticipants, setExistingParticipants] = useState([]);
  const [participantsToRemove, setParticipantsToRemove] = useState([]);
  const [openSuggestion, setOpenSuggestion] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [selectWeekDays, setSelectWeekDays] = useState(false);
  const [buttonDisable, setbuttonDisable] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    if (rosterData) {
      setName(rosterData.name);
      setDescription(rosterData.description);
      setStartDate(
        moment
          .utc(new Date(parseInt(rosterData.startDate)))
          .format("YYYY-MM-DD")
      );
      setEndDate(
        moment.utc(new Date(parseInt(rosterData.endDate))).format("YYYY-MM-DD")
      );
      setStartTime(rosterData.startTime);
      setEndTime(rosterData.endTime);
      if (rosterData.repeat === "All days") {
        setSelectWeekDays(false);
      } else {
        setSelectWeekDays(true);
        let selectedWeekDays = rosterData.repeat.split(",");
        if (selectedWeekDays.includes("Monday")) setmon(true);
        else setmon(false);
        if (selectedWeekDays.includes("Tuesday")) settue(true);
        else settue(false);
        if (selectedWeekDays.includes("Wednesday")) setwed(true);
        else setwed(false);
        if (selectedWeekDays.includes("Thursday")) setthu(true);
        else setthu(false);
        if (selectedWeekDays.includes("Friday")) setfri(true);
        else setfri(false);
        if (selectedWeekDays.includes("Saturday")) setsat(true);
        else setsat(false);
        if (selectedWeekDays.includes("Sunday")) setsun(true);
        else setsun(false);
      }
      setExistingParticipants(
        rosterData.accessIds.map(item => {
          if (item.userAccess.employee) return { ...item.userAccess.employee };
          if (item.userAccess.staff) return item.userAccess.staff;
          if (item.userAccess.visitor) return item.userAccess.visitor;
        })
      );
      setEmployeeAccessIds(
        rosterData.accessIds.map(item => item.userAccess.id)
      );
      (async () => {
        var data = await api(
          "GET",
          "rosters/questionResponseList/" + rosterData.id,
          null,
          true
        );
        let cdata = data.data.map(item => {
          let qna = {};
          item.response.forEach(qitem => {
            qna[qitem.question] = qitem.answer;
          });
          return {
            Name: item.employeeName,
            Email: item.employeeEmail,
            ...qna
          };
        });
        setCsvData(cdata);
      })();
    } else {
      setNewParticipants([]);
      setExistingParticipants([]);
    }
  }, [rosterData]);

  useEffect(() => {
    if (!openSuggestion) {
      setSuggestionList([]);
    } else {
      setLoading(true);
      (async () => {
        try {
          var data = await api(
            "POST",
            "rosters/searchUsers",
            { keyword: email },
            true
          );
          let existingEmployeeIdArray =
            rosterData?.accessIds.map(item => item.userAccess.employeeId) ?? [];
          let alreadyAddedEmployeeIdArray = newParticipants.map(
            item => item.id
          );
          var suggestions = [...data.employee].filter(
            employee =>
              ![
                ...existingEmployeeIdArray,
                ...alreadyAddedEmployeeIdArray
              ].includes(employee.id)
          );
          setSuggestionList(suggestions);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          captureException(error);
        }
      })();
    }
  }, [openSuggestion, email]);

  const handleCreateUpdateRoster = async () => {
    let data = {
      name,
      description,
      startDate: moment(startDate).format("MM-DD-YY") + " 00:00",
      endDate: moment(endDate).format("MM-DD-YY") + " 00:00",
      startTime,
      endTime,
      repeat: "all"
    };

    let selectedWeekDays = [];
    if (selectWeekDays) {
      if (mon) {
        selectedWeekDays.push("Monday");
      }
      if (tue) {
        selectedWeekDays.push("Tuesday");
      }
      if (wed) {
        selectedWeekDays.push("Wednesday");
      }
      if (thu) {
        selectedWeekDays.push("Thursday");
      }
      if (fri) {
        selectedWeekDays.push("Friday");
      }
      if (sat) {
        selectedWeekDays.push("Saturday");
      }
      if (sun) {
        selectedWeekDays.push("Sunday");
      }
      if (selectedWeekDays.length > 0) {
        data.repeat = selectedWeekDays.join();
      } else {
        // This case happens when user opts to "select weekdays" but chooses none of the days
        data.repeat = "All days";
      }
    } else {
      data.repeat = "All days";
    }
    try {
      if (!rosterData) {
        const res = await api("POST", "rosters", data, true);
        const access = await api(
          "POST",
          "rosters/mapRosterUser",
          {
            rosterId: res.id,
            employeeAccessIds
          },
          true
        );
        dispatch(
          toastAction({
            status: true,
            message: "Roster successfully created",
            type: "success"
          })
        );
        close();
      } else {
        dispatch(
          toastAction({
            status: true,
            message: "Updating roster",
            type: "info"
          })
        );
        const res = await api("PATCH", "rosters/" + rosterData.id, data, true);
        const access = await api(
          "POST",
          "rosters/mapRosterUser",
          {
            rosterId: rosterData.id,
            employeeAccessIds
          },
          true
        );
        dispatch(
          toastAction({
            status: true,
            message: "Roster successfully updated",
            type: "success"
          })
        );
        // close();
        setNewParticipants([]);
        setParticipantsToRemove([]);
        reloadRoster();
      }
    } catch (error) {
      console.log(error);
      alert("Error");
    }
  };

  const handleDeleteRoster = async () => {
    try {
      if (rosterData) {
        const res = await api("DELETE", "rosters/" + rosterData.id, null, true);
        dispatch(
          toastAction({
            status: true,
            message: "Roster successfully deleted",
            type: "success"
          })
        );
        close();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelection = newValue => {
    setNewParticipants([...newParticipants, newValue]);
    setEmployeeAccessIds([...employeeAccessIds, newValue.accessDetails.id]);
    setEmail("");
  };

  const handleRemove = removeItem => {
    setNewParticipants([
      ...newParticipants.filter(item => item.id !== removeItem.id)
    ]);
    setEmployeeAccessIds([
      ...employeeAccessIds.filter(item => item !== removeItem.accessDetails.id)
    ]);
  };

  const undoRemove = employee => {
    // Find employee access ID
    let employeeAccessId = rosterData.accessIds.find(
      item => item.userAccess.employee.id == employee.id
    ).userAccess.id;
    setEmployeeAccessIds([...employeeAccessIds, employeeAccessId]);

    setParticipantsToRemove([
      ...participantsToRemove.filter(item => item.id !== employee.id)
    ]);
  };

  const handleExistingParticipantsRemove = removeItem => {
    // Add to remove list - only for display purposes
    setParticipantsToRemove([...participantsToRemove, removeItem]);
    // Remove from update list - for sending in API
    setExistingParticipants([
      ...existingParticipants.filter(item => item.id !== removeItem.id)
    ]);

    setEmployeeAccessIds([
      ...employeeAccessIds.filter(item => item !== removeItem.accessDetails.id)
    ]);
  };

  const acceptEmployeeRequest = async rosterUserMapItem => {
    try {
      var data = await api(
        "POST",
        `rosters/updateEmployeeRequest/${rosterUserMapItem.id}`,
        { status: "accepted" },
        true
      );
      dispatch(
        toastAction({
          status: true,
          message: "Employee request accepted",
          type: "success"
        })
      );
      reloadRoster();
      // close();
    } catch (error) {
      console.log(error);
    }
  };

  const rejectEmployeeRequest = async rosterUserMapItem => {
    try {
      var data = await api(
        "POST",
        `rosters/updateEmployeeRequest/${rosterUserMapItem.id}`,
        { status: "declined" },
        true
      );
      dispatch(
        toastAction({
          status: true,
          message: "Employee request declined",
          type: "success"
        })
      );
      reloadRoster();
      // close();
    } catch (error) {
      console.log(error);
    }
  };

  const reloadRoster = async () => {
    try {
      const res = await api("GET", "rosters/" + rosterData.id, null, true);
      console.log(res);
      setRosterData(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Grid alignItems="center" container spacing={3} direction="row">
        <Grid item>
          <IconButton
            aria-label="close"
            onClick={e => {
              close();
            }}
            size="small"
          >
            <KeyboardBackspace />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography
            variant="h6"
            onClick={e => {
              close();
            }}
            color="textPrimary"
            className={classes.backButton}
          >
            Back to Rosters List
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.container} container spacing={1}>
        <Grid item md={6} xs={12}>
          <Card>
            <CardContent>
              <Typography component="h2" gutterBottom variant="h4">
                {rosterData && rosterData.id
                  ? "Update Roster"
                  : "Create New Roster"}
              </Typography>
              <Grid container direction="row" spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label="Roster Name"
                    id="name"
                    variant="outlined"
                    type="text"
                    margin="none"
                    value={name}
                    disabled={
                      rosterData?.rosterType === "daily" ||
                      user.role === "businessAdmin"
                    }
                    onChange={e => {
                      setName(e.target.value);
                    }}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    id="descroption"
                    variant="outlined"
                    type="text"
                    margin="none"
                    value={description}
                    disabled={
                      rosterData?.rosterType === "daily" ||
                      user.role === "businessAdmin"
                    }
                    onChange={e => {
                      setDescription(e.target.value);
                    }}
                    required
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container direction="row" spacing={3}>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="Start Date"
                        type="date"
                        id="startDate"
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={startDate}
                        disabled={
                          rosterData?.rosterType === "daily" ||
                          user.role === "businessAdmin"
                        }
                        onChange={e => {
                          setStartDate(
                            moment(new Date(e.target.value)).format(
                              "YYYY-MM-DD"
                            )
                          );
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="End Date"
                        type="date"
                        id="endDate"
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={endDate}
                        disabled={
                          rosterData?.rosterType === "daily" ||
                          user.role === "businessAdmin"
                        }
                        onChange={e => {
                          setEndDate(
                            moment(new Date(e.target.value)).format(
                              "YYYY-MM-DD"
                            )
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={3}>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="Start Time"
                        id="startTime"
                        type="time"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={startTime}
                        disabled={
                          rosterData?.rosterType === "daily" ||
                          user.role === "businessAdmin"
                        }
                        onChange={e => {
                          setStartTime(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="End Time"
                        id="endTime"
                        type="time"
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={endTime}
                        disabled={
                          rosterData?.rosterType === "daily" ||
                          user.role === "businessAdmin"
                        }
                        onChange={e => {
                          setEndTime(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      value="selectWeekDays"
                      control={
                        <Checkbox
                          checked={selectWeekDays}
                          onChange={e =>
                            setSelectWeekDays(
                              e.target.checked &&
                                !(rosterData?.rosterType == "daily")
                            )
                          }
                        />
                      }
                      label="Select weekdays"
                      disabled={
                        rosterData?.rosterType === "daily" ||
                        user.role === "businessAdmin"
                      }
                    />
                  </Grid>
                  <br />
                  {selectWeekDays && !(rosterData?.rosterType == "daily") && (
                    <FormControl component="fieldset" fullWidth>
                      <FormLabel component="legend">Select days</FormLabel>

                      <Grid container alignItems="center" spacing={3}>
                        <Grid item xs={3}>
                          <FormControlLabel
                            value="monday"
                            control={
                              <Checkbox
                                checked={mon}
                                onChange={e => setmon(e.target.checked)}
                              />
                            }
                            label="Monday"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            value="tuesday"
                            control={
                              <Checkbox
                                checked={tue}
                                onChange={e => settue(e.target.checked)}
                              />
                            }
                            label="Tuesday"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            value="wednesday"
                            control={
                              <Checkbox
                                checked={wed}
                                onChange={e => setwed(e.target.checked)}
                              />
                            }
                            label="Wednesday"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            value="thursday"
                            control={
                              <Checkbox
                                checked={thu}
                                onChange={e => setthu(e.target.checked)}
                              />
                            }
                            label="Thursday"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            value="friday"
                            control={
                              <Checkbox
                                checked={fri}
                                onChange={e => setfri(e.target.checked)}
                              />
                            }
                            label="Friday"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            value="saturday"
                            control={
                              <Checkbox
                                checked={sat}
                                onChange={e => setsat(e.target.checked)}
                              />
                            }
                            label="Saturday"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            value="sunday"
                            control={
                              <Checkbox
                                checked={sun}
                                onChange={e => setsun(e.target.checked)}
                              />
                            }
                            label="Sunday"
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          {user.role !== "businessAdmin" && (
            <>
              <Card>
                <CardContent>
                  <Typography component="h2" gutterBottom variant="h4">
                    Users
                  </Typography>
                  <Grid item xs={12}>
                    {newParticipants.length === 0 && (
                      <Typography component="h2" gutterBottom variant="body1">
                        No participant added
                      </Typography>
                    )}
                    {newParticipants.length > 0 && (
                      <List>
                        {newParticipants.map((item, index) => (
                          <ListItem key={index} disableGutters>
                            <ListItemAvatar>
                              <Avatar style={getAvatarStyle(item.email)}>
                                {getInitials(item.name)}
                              </Avatar>
                            </ListItemAvatar>

                            <ListItemText
                              primary={
                                <Typography variant="h5">
                                  {item.name}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {item.role &&
                                    item[orgSettings.employeePrimaryIdentifier]}
                                  {!item.role &&
                                    item[orgSettings.visitorPrimaryIdentifier]}
                                </Typography>
                              }
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                edge="end"
                                color="primary"
                                onClick={() => handleRemove(item)}
                              >
                                <X />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      fullWidth
                      id="addParticipants"
                      key={newParticipants.length}
                      open={openSuggestion}
                      noOptionsText={"No user found"}
                      options={suggestionList}
                      loading={loading}
                      onOpen={() => {
                        setOpenSuggestion(true);
                      }}
                      onClose={() => {
                        setOpenSuggestion(false);
                      }}
                      onChange={(event, newValue) => {
                        handleSelection(newValue);
                      }}
                      filterOptions={(options, params) => {
                        return filter(options, params);
                      }}
                      filterOptions={(options, state) => options}
                      getOptionLabel={option => {
                        return option.name;
                      }}
                      renderOption={item => {
                        return (
                          <ListItem
                            dense
                            disableGutters
                            className="suggestedParticipant"
                          >
                            <ListItemAvatar>
                              <Avatar style={getAvatarStyle(item.email)}>
                                {getInitials(item.inputValue ? "+" : item.name)}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography variant="h5">
                                  {item.name}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {item.role &&
                                    item[orgSettings.employeePrimaryIdentifier]}
                                  {!item.role &&
                                    item[orgSettings.visitorPrimaryIdentifier]}
                                </Typography>
                              }
                            />
                          </ListItem>
                        );
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={"Search participant by email or phone number"}
                          variant="outlined"
                          onChange={e => setEmail(e.target.value)}
                          value={email}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                  </Grid>
                </CardContent>
              </Card>
              <br />
            </>
          )}

          {rosterData?.id && (
            <>
              {participantsToRemove.length > 0 && (
                <>
                  <Card>
                    <CardContent>
                      <Typography component="h2" gutterBottom variant="h4">
                        Roster Members to Remove
                      </Typography>
                      <Grid item xs={12}>
                        {participantsToRemove.length > 0 && (
                          <List>
                            {participantsToRemove.map((item, index) => (
                              <ListItem key={index} disableGutters>
                                <ListItemAvatar>
                                  <Avatar style={getAvatarStyle(item.email)}>
                                    {getInitials(item.name)}
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={
                                    <Typography variant="h5">
                                      {item.name}
                                    </Typography>
                                  }
                                  secondary={
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {item.role &&
                                        item[
                                          orgSettings.employeePrimaryIdentifier
                                        ]}
                                      {!item.role &&
                                        item[
                                          orgSettings.visitorPrimaryIdentifier
                                        ]}
                                    </Typography>
                                  }
                                />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    edge="end"
                                    color="primary"
                                    onClick={() => undoRemove(item)}
                                  >
                                    <SettingsBackupRestoreIcon />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                  <br />
                </>
              )}
              <Card>
                <CardContent>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Typography component="span" gutterBottom variant="h4">
                        Roster Members
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={e => {
                          setResponseDialogOpen(true);
                        }}
                      >
                        View responses
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      {rosterData.accessIds.length === 0 && (
                        <Typography component="h2" gutterBottom variant="body1">
                          No participant added
                        </Typography>
                      )}
                      {rosterData.accessIds.length > 0 && (
                        <List>
                          {rosterData.accessIds.map((item, index) => (
                            <ListItem
                              key={index}
                              disableGutters
                              style={{
                                opacity: participantsToRemove
                                  .map(item => item.id)
                                  .includes(item.userAccess.employee.id)
                                  ? 0.5
                                  : 1
                              }}
                            >
                              <ListItemAvatar>
                                <Avatar
                                  style={getAvatarStyle(
                                    item.userAccess.employee.email
                                  )}
                                >
                                  {getInitials(item.userAccess.employee.name)}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <Typography variant="h5">
                                    {item.userAccess.employee.name}
                                  </Typography>
                                }
                                secondary={
                                  <>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {item.userAccess.employee.role &&
                                        item.userAccess.employee[
                                          orgSettings.employeePrimaryIdentifier
                                        ]}
                                      {!item.userAccess.employee.role &&
                                        item.userAccess.employee[
                                          orgSettings.visitorPrimaryIdentifier
                                        ]}{" "}
                                      | Added by:{" "}
                                      {item.addedBy === "hod"
                                        ? "HOD"
                                        : item.addedBy.charAt(0).toUpperCase() +
                                          item.addedBy.slice(1)}{" "}
                                      | Status: {item.status}
                                    </Typography>
                                    {item.addedBy === "employee" &&
                                      item.status === "requested" &&
                                      user.role !== "businessAdmin" && (
                                        <ButtonGroup>
                                          <Button
                                            color="primary"
                                            size="small"
                                            variant="text"
                                            onClick={() =>
                                              acceptEmployeeRequest(item)
                                            }
                                          >
                                            Accept
                                          </Button>
                                          <Button
                                            color="secondary"
                                            size="small"
                                            variant="text"
                                            onClick={() =>
                                              rejectEmployeeRequest(item)
                                            }
                                          >
                                            Reject
                                          </Button>
                                        </ButtonGroup>
                                      )}
                                  </>
                                }
                              />
                              {user.role !== "businessAdmin" &&
                                !participantsToRemove
                                  .map(item => item.id)
                                  .includes(item.userAccess.employee.id) && (
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      edge="end"
                                      color="primary"
                                      onClick={() =>
                                        handleExistingParticipantsRemove({
                                          ...item.userAccess.employee,
                                          accessDetails: item.userAccess
                                        })
                                      }
                                    >
                                      <X />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                )}
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Card>
            {error !== "" && (
              <Alert
                severity="error"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setError("");
                    }}
                  >
                    <X />
                  </Button>
                }
              >
                {error}
              </Alert>
            )}
          </Card>
        </Grid>
        {user.role !== "businessAdmin" && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container direction="row-reverse" spacing={3}>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      id="submit"
                      onClick={handleCreateUpdateRoster}
                      disabled={buttonDisable}
                      startIcon={
                        buttonDisable ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          undefined
                        )
                      }
                    >
                      {rosterData && "Update Roster"}
                      {!rosterData && "Create Roster"}
                    </Button>
                  </Grid>
                  {rosterData && (
                    <Grid item>
                      <Button
                        color="secondary"
                        variant="contained"
                        id="submit"
                        onClick={handleDeleteRoster}
                      >
                        Delete Roster
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={responseDialogOpen}
        onClose={e => setResponseDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Responses by members</DialogTitle>
        <DialogContent>
          {csvData.map((item, index) => {
            console.log(item);
            let questions = [];
            let answers = [];
            for (const x in item) {
              if (x !== "Name" && x !== "Email") {
                questions.push(x);
                answers.push(item[x]);
              }
            }
            return (
              <>
                <Typography variant="h5" color="primary">
                  {item.Name} <small> {item.Email} </small>
                </Typography>
                {questions.map((qitem, qindex) => (
                  <Typography variant="h6" color="textPrimary">
                    <bold> {qitem}</bold>
                    {"   "} {answers[qindex]}
                  </Typography>
                ))}
                <br />
              </>
            );
          })}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RosterDataModal;
