import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import RestoreIcon from "@material-ui/icons/Restore";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import EditIcon from "@material-ui/icons/Edit";
import { captureException } from "@sentry/react";
import React, { useState } from "react";
import { PlusCircle } from "react-feather";
import api from "src/utils/api";
import Loader from "../../../utils/loader";
import ConfirmationDialog from "../common/ConfirmDialog";
import OrganizationModal from "./OrganizationModal";
import { useDispatch, useSelector } from "react-redux";
import { DropzoneDialog } from "material-ui-dropzone";
import { business as businessDispatch, toastAction } from "../../../actions";
import axios from "axios";
import baseurl from "../../../utils/baseurl";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  button: {
    marginBottom: 10
  }
});

export default function Organizations() {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [restoreopen, setrestoreOpen] = useState(false);
  const [isLoading, setLoader] = useState(true);
  const [error, setError] = useState("");
  const [isLoadingDelete, setDeleteLoader] = useState(false);
  const [isLoadingRestore, setRestoreLoader] = useState(false);
  const [organizationList, setOrganizationList] = useState([]);
  const [activeData, setData] = useState({});
  const [countData, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [floorList, setFloorList] = useState([]);
  const [dropzoneOpen, setDropzoneOpen] = useState(false);
  const [dropzone, setDropzone] = useState({});
  const [viewOpen, setViewOpen] = useState(false);
  const [viewUrl, setViewUrl] = useState("");
  const [viewType, setViewType] = useState("");
  const [viewOrganization, setViewOrganization] = useState(
    new Array(rowsPerPage).fill(false)
  );
  const business = useSelector(state => state.business);
  const [businessDetails, setBusinessDetails] = useState(business);
  const dispatch = useDispatch();

  React.useEffect(() => {
    (async () => {
      const floorL = await api("POST", "floor/find", null, true);
      setFloorList(floorL);
      organizationCount();
    })();
  }, []);

  const organizationCount = async () => {
    try {
      const countPayload = {
        sort: { key: "organization.createdAt", order: "ASC" },
        skip: 0,
        take: 10
      };
      const res = await api(
        "POST",
        "organization/findCount",
        countPayload,
        true
      );
      setCount(res);
    } catch (error) {
      captureException(error);
    }
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setViewOrganization(new Array(event.target.value).fill(false));
    setPage(0);
  };

  React.useEffect(() => {
    fetchData();
  }, [rowsPerPage, page]);

  const fetchData = async () => {
    setLoader(true);
    try {
      const payload = {
        sort: {
          key: "organization.name",
          order: "ASC"
        },
        skip: page * rowsPerPage,
        take: rowsPerPage
      };
      const organization = await api(
        "POST",
        "organization/find",
        payload,
        true
      );
      const res = organization || [];
      setOrganizationList(res);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const handleViewDialog = (url, type) => {
    setViewUrl(url);
    setViewType(type);
    setViewOpen(true);
  };

  const handleUploadDalog = (title, key, type, flag) => {
    setDropzone({
      title,
      key,
      type,
      flag
    });
    setDropzoneOpen(true);
  };

  const handleUpdateIdentity = async files => {
    dispatch(
      toastAction({
        status: true,
        message: "Updating identity..",
        type: "info",
        noIcon: true
      })
    );
    await api(
      "PATCH",
      "business",
      {
        name: businessDetails.name
      },
      true
    );
    dispatch(businessDispatch({ ...business, name: businessDetails.name }));
    dispatch(
      toastAction({
        status: true,
        message: "Identity updated",
        type: "success"
      })
    );
  };
  const handleUpload = async files => {
    dispatch(
      toastAction({
        status: true,
        message: "Uploading file ... ",
        type: "info",
        noIcon: true
      })
    );
    const url = await upload(files[0], "businessLogo");
    dispatch(
      toastAction({
        status: true,
        message: "Updating identity..",
        type: "info",
        noIcon: true
      })
    );
    await api(
      "PATCH",
      "business",
      {
        logo: url
      },
      true
    );
    dispatch(businessDispatch({ ...business, logo: url }));
    setDropzoneOpen(false);
    setBusinessDetails({
      ...businessDetails,
      logo: url
    });
    dispatch(
      toastAction({
        status: true,
        message: "Identity updated",
        type: "success"
      })
    );
  };

  const upload = async (file, folder) => {
    const token = localStorage ? localStorage.getItem("token") : null;
    let filenameSplit = file.name.split(".");
    const formData = new FormData();
    formData.append(
      "file",
      file,
      Date.now() + "." + filenameSplit[filenameSplit.length - 1]
    );
    formData.append("fileStorage", folder);

    try {
      let response = await axios({
        method: "POST",
        url: baseurl + "file/upload",
        data: formData,
        headers: {
          Authorization: "Bearer " + token
        },
        onUploadProgress: progressEvent => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          dispatch(
            toastAction({
              status: true,
              message: "Uploading file ... " + percentCompleted + "%",
              type: "info",
              noIcon: true
            })
          );
        }
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const editOrganizations = data => {
    setData(data);
    setIsOpen(true);
  };

  const editOrganizationsToArray = item => {
    fetchData();
  };

  const addOrganizationsToArray = item => {
    // setOrganizationList([...organizationList, item]);
    fetchData();
  };

  const handleModal = () => {
    setData({});
    setIsOpen(true);
  };

  const deleteOrganization = async () => {
    setDeleteLoader(true);
    try {
      const organization = await api(
        "PATCH",
        `organization/${activeData.id}`,
        { isActive: false },
        true
      );
      if (organization.id) {
        organizationCount();
        fetchData();
        setOpen(false);
        setError("");
      }
      setDeleteLoader(false);
    } catch (error) {
      setDeleteLoader(false);
      setError("Some things went wrong please try again");
    }
  };

  const restoreOrganization = async () => {
    setRestoreLoader(true);
    try {
      const organization = await api(
        "PATCH",
        `organization/${activeData.id}`,
        { isActive: true },
        true
      );
      if (organization.id) {
        organizationCount();
        fetchData();
        setrestoreOpen(false);
        setError("");
      }
      setRestoreLoader(false);
    } catch (error) {
      setRestoreLoader(false);
      setError("Some things went wrong please try again");
    }
  };

  const conformRoomModal = row => {
    if (row && row.id) {
      setOpen(true);
      setData(row);
    } else {
      setData({});
      setOpen(false);
    }
  };
  const conformOrgRestore = row => {
    if (row && row.id) {
      setrestoreOpen(true);
      setData(row);
    } else {
      setData({});
      setrestoreOpen(false);
    }
  };

  const DialogButtons = (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => conformRoomModal()}
      >
        NO
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={isLoadingDelete}
        onClick={() => deleteOrganization()}
      >
        {isLoadingDelete ? "Loading..." : "Yes, delete"}
      </Button>
    </React.Fragment>
  );
  const RestoreDialogButtons = (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => conformOrgRestore()}
      >
        NO
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={isLoadingRestore}
        onClick={() => restoreOrganization()}
      >
        {isLoadingRestore ? "Loading..." : "Yes, Restore"}
      </Button>
    </React.Fragment>
  );

  if (isLoading) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <Loader />
        </Grid>
        <Grid item xs={4} />
      </Grid>
    );
  }
  return (
    <React.Fragment>
      <Typography variant="h4" color="primary">
        Identity
      </Typography>
      <br />
      <Card>
        <CardContent>
          <Typography color="textPrimary" variant="h5">
            Logo
          </Typography>
          <br />
          <Grid container direction="row" spacing={3}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={e => handleViewDialog(businessDetails.logo, "image")}
              >
                View
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={e => handleUploadDalog("Logo", "logo", "image")}
              >
                Upload
              </Button>
            </Grid>
          </Grid>
          <br />
          <br />
          <Typography color="textPrimary" variant="h5">
            Name
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            margin="normal"
            onChange={e =>
              setBusinessDetails({
                ...businessDetails,
                name: e.target.value
              })
            }
            value={businessDetails.name}
          />
          <Button
            color="primary"
            className={classes.button}
            variant="contained"
            onClick={handleUpdateIdentity}
          >
            Update
          </Button>
        </CardContent>
      </Card>
      <br />

      <Grid
        alignItems="flex-start"
        container
        justify="space-between"
        spacing={3}
        direction="row"
      >
        <Grid item>
          <Typography variant="h4" color="primary">
            Organizations
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            className={classes.button}
            startIcon={<PlusCircle />}
            variant="outlined"
            onClick={handleModal}
          >
            Add Organization
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Logo</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizationList.map((row, ind) => (
              <React.Fragment key={ind}>
                <TableRow>
                  <TableCell align="left">{row.name || "-"}</TableCell>
                  <TableCell align="left">{row.logo || "-"}</TableCell>
                  <TableCell align="center">
                    {row.isActive ? "Active" : "Inactive"}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      aria-label="edit"
                      onClick={() => editOrganizations(row)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    {row.isActive ? (
                      <IconButton
                        aria-label="delete"
                        onClick={() => conformRoomModal(row)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="restore"
                        onClick={() => conformOrgRestore(row)}
                      >
                        <RestoreIcon fontSize="small" />
                      </IconButton>
                    )}
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        setViewOrganization(
                          viewOrganization.map((vitem, vindex) => {
                            if (vindex === ind) {
                              return !vitem;
                            } else {
                              return vitem;
                            }
                          })
                        );
                      }}
                    >
                      {!viewOrganization[ind] && (
                        <ExpandMoreIcon fontSize="small" />
                      )}
                      {viewOrganization[ind] && (
                        <ExpandLessIcon fontSize="small" />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ padding: 0 }} colSpan={4}>
                    <Collapse
                      in={viewOrganization[ind]}
                      style={{ display: "block" }}
                    >
                      <Box margin={3}>
                        <Typography variant="h4">{row.name}</Typography>
                        <Typography variant="h5">
                          Buildings and Floors
                        </Typography>
                        <List>
                          {row.building.map((bitem, bindex) => (
                            <ListItem style={{ paddingLeft: 0 }} key={bindex}>
                              <ListItemText
                                primary={
                                  floorList.find(
                                    floor => floor.id === bitem.floorId
                                  )?.name
                                }
                                secondary={
                                  floorList.find(
                                    floor => floor.id === bitem.floorId
                                  )?.building?.name
                                }
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={countData}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      {isOpen && (
        <OrganizationModal
          open={isOpen}
          organizationCount={organizationCount}
          activeData={activeData}
          addOrganizationsToArray={addOrganizationsToArray}
          editOrganizationsToArray={editOrganizationsToArray}
          close={() => setIsOpen(false)}
        />
      )}
      {open && (
        <ConfirmationDialog
          open={open}
          error={error}
          handleClose={() => setOpen(false)}
          title={"Delete Organization"}
          content={"Are you sure you want to delete this organization?"}
          btnActions={DialogButtons}
        />
      )}
      {restoreopen && (
        <ConfirmationDialog
          open={restoreopen}
          error={error}
          handleClose={() => setrestoreOpen(false)}
          title={"Restore Organization"}
          content={"Are you sure you want to restore this organization?"}
          btnActions={RestoreDialogButtons}
        />
      )}
      <Dialog
        open={viewOpen}
        onClose={() => setViewOpen(false)}
        disableAutoFocus={true}
      >
        {viewType === "image" && (
          <img
            src={viewUrl}
            alt="preview"
            style={{ maxHeight: "90vh", maxWidth: "100%" }}
          />
        )}
        {viewType === "video" && (
          <video src={viewUrl} width="100%" height="100%" controls></video>
        )}
      </Dialog>

      <DropzoneDialog
        open={dropzoneOpen}
        onSave={files => {
          handleUpload(files);
        }}
        acceptedFiles={[dropzone.type + "/*"]}
        showPreviews={true}
        filesLimit={1}
        maxFileSize={5000000000}
        dialogTitle={dropzone.title}
        onClose={() => setDropzoneOpen(false)}
        submitButtonText="Upload"
        cancelButtonText="Cancel"
      />
    </React.Fragment>
  );
}
