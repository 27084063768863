import {
  Avatar,
  Badge,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  Divider
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import { makeStyles, withStyles } from "@material-ui/styles";
import { captureException } from "@sentry/browser";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { X, Plus } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Page from "src/components/Page";
import { toastAction } from "../../actions";
import api from "../../utils/api";
import getAvatarStyle from "../../utils/getAvatarStyle";
import getInitials from "../../utils/getInitials";
import rolename from "../../utils/rolename";
import { AddModal, Header } from "./components";

const filter = createFilterOptions();
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));
const StyledBadge = withStyles(theme => ({
  badge: {
    right: -48,
    top: 10,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px"
  }
}))(Badge);
const CreateVisit = props => {
  const navigate = useNavigate();
  const user = useSelector(state => state.user);
  const orgSettings = useSelector(state => state.orgSettings);
  const primary = orgSettings.visitorPrimaryIdentifier;
  const [error, setError] = useState("");
  const [purpose, setPurpose] = useState("");
  const [localDate, setLocalDate] = useState(moment().format("YYYY-MM-DD"));
  const [localStartTime, setLocalStartTime] = useState(
    moment()
      .add(2, "minutes")
      .format("HH:mm")
  );
  const [localEndTime, setLocalEndTime] = useState(
    moment()
      .add(32, "minutes")
      .format("HH:mm")
  );
  const [startTime, setStartTime] = useState(
    moment
      .utc(new Date())
      .add(2, "minutes")
      .format("MM-DD-YYYY HH:mm")
  );
  const [endTime, setEndTime] = useState(
    moment
      .utc(new Date())
      .add(32, "minutes")
      .format("MM-DD-YYYY HH:mm")
  );
  const [participants, setParticipants] = useState(
    user.role === "visitor" ? user.userId : []
  );
  const [employees, setEmployees] = useState([]);
  const [visitors, setVisitors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [openSuggestion, setOpenSuggestion] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [addParticipantModal, setAddParticipantModal] = useState(false);
  const [buttonDisable, setbuttonDisable] = useState(false);
  const [baggage, setbaggage] = useState([]);
  const [baggageModal, setbaggageModal] = useState(false);
  const [newBaggageError, setnewBaggageError] = useState("");
  const [newBaggageName, setnewBaggageName] = useState("");
  const [newBaggageDescription, setnewBaggageDescription] = useState("");
  const [newBaggageModel, setnewBaggageModel] = useState("");
  const [newBaggageSerial, setnewBaggageSerial] = useState("");
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!openSuggestion) {
      setSuggestionList([]);
    } else {
      setLoading(true);
      (async () => {
        try {
          var data = await api(
            "POST",
            "meeting/searchUsersWithKeyword",
            { keyword: email, organizationId: user.organizationId },
            true
          );
          // var suggestions = [...data.employee, ...data.visitor].filter(
          //   item =>
          //     item.id !== user.userId &&
          //     !participants.map(item => item.id).includes(item.id)
          // );
          setSuggestionList(data.visitor);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          captureException(error);
        }
      })();
    }
  }, [openSuggestion, email]);

  const openAddParticipantModal = email => {
    setEmail(email);
    setAddParticipantModal(true);
  };
  const closeAddParticipantModal = () => {
    setAddParticipantModal(false);
  };
  const isEmail = email => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  };
  const handleEmailChange = event => {
    setEmail(event.target.value);
  };
  const handleSelection = data => {
    data && data.role
      ? setEmployees([...employees, data.id])
      : setVisitors([...visitors, data.id]);
    data && setParticipants([...participants, data]);
  };
  const handleRemove = data => {
    setParticipants(participants.filter(item => item.id !== data.id));
  };
  const handleCreateVisit = () => {
    setbuttonDisable(true);
    setError("");
    if (user.loginType === "employee" && visitors.length === 0) {
      setError("Please add atleast one visitor to the visit");
      setbuttonDisable(false);
      return;
    }
    if (
      moment(localDate)
        .local()
        .isBefore(
          moment()
            .startOf("day")
            .local()
        )
    ) {
      setError("Visit date can not be before today");
      setbuttonDisable(false);
      return;
    }
    if (
      moment(new Date(localDate + "T" + localStartTime))
        .local()
        .isBefore(moment().local())
    ) {
      setError("Start time for today's meeting can not be before current time");
      setbuttonDisable(false);
      return;
    }
    if (moment(endTime).isBefore(moment(startTime))) {
      setError("End time can not be before start time");
      setbuttonDisable(false);
      return;
    }
    dispatch(
      toastAction({
        status: true,
        message: "Creating meeting...",
        type: "info",
        noIcon: true
      })
    );

    (async () => {
      try {
        await api(
          "POST",
          "checkIn",
          user.loginType === "visitor"
            ? {
                purpose,
                startTime,
                endTime,
                visitorId: user.userId,
                baggage
              }
            : {
                purpose,
                startTime,
                endTime,
                visitorId: participants[0].id
              },
          true
        );
        dispatch(
          toastAction({
            status: true,
            message: "Visit successfully created",
            type: "success"
          })
        );
        setTimeout(() => {
          navigate("/visit");
        }, 600);
      } catch (error) {
        error.response && error.response.data && setError(error.response.data);
        setbuttonDisable(false);
      }
    })();
  };

  return (
    <Page className={classes.root} title="Create Visit">
      <Header />
      <Grid className={classes.container} container spacing={1}>
        <Grid item md={user.role === "visitor" ? 12 : 8} xs={12}>
          <Card>
            <CardContent>
              <Typography component="h2" gutterBottom variant="h4">
                Visit Details
              </Typography>
              <Grid container direction="row" spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    label="Purpose"
                    id="purpose"
                    variant="outlined"
                    type="text"
                    margin="none"
                    value={purpose}
                    onChange={e => {
                      setPurpose(e.target.value);
                    }}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" spacing={3}>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="Date"
                        type="date"
                        id="date"
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={localDate}
                        onChange={e => {
                          setStartTime(
                            moment
                              .utc(
                                new Date(e.target.value + "T" + localStartTime)
                              )
                              .format("MM-DD-YYYY HH:mm")
                          );
                          setLocalDate(e.target.value);
                          setEndTime(
                            moment
                              .utc(
                                new Date(e.target.value + "T" + localEndTime)
                              )
                              .format("MM-DD-YYYY HH:mm")
                          );
                          setLocalDate(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                      <TextField
                        label="Start Time"
                        id="startTime"
                        type="time"
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={localStartTime}
                        onChange={e => {
                          setStartTime(
                            moment
                              .utc(new Date(localDate + "T" + e.target.value))
                              .format("MM-DD-YYYY HH:mm")
                          );
                          setLocalStartTime(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item sm={3} xs={6}>
                      <TextField
                        label="End Time"
                        id="endTime"
                        type="time"
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={localEndTime}
                        onChange={e => {
                          setEndTime(
                            moment
                              .utc(new Date(localDate + "T" + e.target.value))
                              .format("MM-DD-YYYY HH:mm")
                          );
                          setLocalEndTime(e.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <br />
          {user.loginType === "visitor" &&
            orgSettings.baggageBusinessEnable && (
              <Card>
                <CardContent>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography component="h2" gutterBottom variant="h4">
                        Baggage Details
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="outlined"
                        id="submit"
                        size="large"
                        onClick={e => {
                          setbaggageModal(true);
                        }}
                        startIcon={<Plus />}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>

                  <Dialog
                    open={baggageModal}
                    onClose={e => setbaggageModal(false)}
                    maxWidth="xs"
                    fullWidth
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogContent>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography component="h2" gutterBottom variant="h4">
                            Add Baggage
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            edge="end"
                            color="primary"
                            onClick={() => setbaggageModal(false)}
                          >
                            <X />
                          </IconButton>
                        </Grid>
                      </Grid>
                      {newBaggageError !== "" && (
                        <Alert
                          severity="error"
                          action={
                            <Button
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setnewBaggageError("");
                              }}
                            >
                              <X />
                            </Button>
                          }
                        >
                          {newBaggageError}
                        </Alert>
                      )}
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        style={{ width: "100%" }}
                        id="baggageSelect"
                      >
                        <InputLabel id="building"> Type</InputLabel>
                        <Select
                          labelId="type"
                          id="type"
                          label="Type"
                          value={newBaggageName}
                          onChange={e => {
                            setnewBaggageError("");
                            setnewBaggageName(e.target.value);
                          }}
                          autoWidth
                        >
                          {JSON.parse(orgSettings.baggageItems).map(bitem => (
                            <MenuItem value={bitem}>
                              {bitem.charAt(0).toUpperCase() + bitem.slice(1)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <br />
                      <br />
                      <TextField
                        label="Description"
                        id="description"
                        variant="outlined"
                        type="text"
                        margin="none"
                        value={newBaggageDescription}
                        onChange={e => {
                          setnewBaggageError("");
                          setnewBaggageDescription(e.target.value);
                        }}
                        fullWidth
                      />
                      <br />
                      <br />
                      {JSON.parse(orgSettings.baggageFields).includes(
                        "model"
                      ) && (
                        <>
                          <TextField
                            label="Make and model"
                            id="model"
                            variant="outlined"
                            type="text"
                            margin="none"
                            value={newBaggageModel}
                            onChange={e => {
                              setnewBaggageError("");
                              setnewBaggageModel(e.target.value);
                            }}
                            fullWidth
                          />
                          <br />
                          <br />
                        </>
                      )}
                      {JSON.parse(orgSettings.baggageFields).includes(
                        "model"
                      ) && (
                        <>
                          <TextField
                            label="Serial no."
                            id="model"
                            variant="outlined"
                            type="text"
                            margin="none"
                            value={newBaggageSerial}
                            onChange={e => {
                              setnewBaggageError("");
                              setnewBaggageSerial(e.target.value);
                            }}
                            fullWidth
                          />
                          <br />
                          <br />
                        </>
                      )}

                      <Button
                        color="primary"
                        variant="contained"
                        id="submit"
                        size="large"
                        fullWidth
                        onClick={e => {
                          if (newBaggageName !== "") {
                            setbaggage([
                              ...baggage,
                              {
                                name: newBaggageName,
                                description: newBaggageDescription,
                                model: newBaggageModel,
                                serial: newBaggageSerial
                              }
                            ]);
                            setnewBaggageName("");
                            setnewBaggageDescription("");
                            setnewBaggageModel("");
                            setnewBaggageSerial("");
                            setbaggageModal(false);
                          } else {
                            setnewBaggageError("Please fill all the fields");
                          }
                        }}
                      >
                        Add Baggage to List
                      </Button>
                      <br />
                      <br />
                    </DialogContent>
                  </Dialog>
                  <List dense>
                    <Divider />
                    {baggage.map((bag, index) => (
                      <ListItem divider key={index}>
                        <ListItemText
                          primary={
                            bag.name.charAt(0).toUpperCase() + bag.name.slice(1)
                          }
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                              >
                                {bag.description}
                              </Typography>
                              {bag.model !== "" &&
                                bag.model !== null &&
                                " - " + bag.model}
                              {bag.serial !== "" &&
                                bag.serial !== null &&
                                " - " + bag.serial}
                            </React.Fragment>
                          }
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            onClick={e => {
                              setbaggage(baggage.filter((b, i) => i !== index));
                            }}
                          >
                            <X />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            )}{" "}
        </Grid>
        {user.role !== "visitor" && (
          <Grid item md={4} xs={12}>
            <Card>
              <CardContent>
                <Typography component="h2" gutterBottom variant="h4">
                  Invitee
                </Typography>

                {participants.length === 0 && (
                  <Autocomplete
                    fullWidth
                    id="addParticipants"
                    key={participants.length}
                    open={openSuggestion}
                    clearOnBlur
                    clearOnEscape
                    noOptionsText={"No user found"}
                    options={suggestionList}
                    loading={loading}
                    onOpen={() => {
                      setOpenSuggestion(true);
                    }}
                    onClose={() => {
                      setOpenSuggestion(false);
                    }}
                    onChange={(event, newValue) => {
                      if (newValue && newValue.inputValue) {
                        if (primary === "email") {
                          if (isEmail(newValue.inputValue))
                            openAddParticipantModal(newValue.inputValue);
                          else alert("Email is not valid");
                        } else {
                          openAddParticipantModal(newValue.inputValue);
                        }
                      } else if (newValue) {
                        if (!participants.includes(newValue) && newValue.id)
                          handleSelection(newValue);
                      }
                    }}
                    filterOptions={(options, params) =>
                      params.inputValue !== "" && user.loginType === "employee"
                        ? [
                            ...options,
                            {
                              inputValue: params.inputValue,
                              name: `New Visitor`,
                              email: params.inputValue,
                              mobile: params.inputValue
                            }
                          ]
                        : options
                    }
                    getOptionLabel={option => {
                      return option.name;
                    }}
                    renderOption={item => (
                      <ListItem
                        dense
                        disableGutters
                        className="suggestedParticipant"
                      >
                        <ListItemAvatar>
                          <Avatar style={getAvatarStyle(item.email)}>
                            {getInitials(item.inputValue ? "+" : item.name)}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <StyledBadge
                              badgeContent={
                                item.role
                                  ? item.role
                                  : item.inputValue
                                  ? "new"
                                  : "visitor"
                              }
                              color={item.role ? "primary" : "secondary"}
                            >
                              <Typography variant="h5">{item.name}</Typography>
                            </StyledBadge>
                          }
                          secondary={
                            <Typography variant="body2" color="textSecondary">
                              {item.role &&
                                item[orgSettings.employeePrimaryIdentifier]}
                              {!item.role &&
                                item[orgSettings.visitorPrimaryIdentifier]}
                            </Typography>
                          }
                        />
                      </ListItem>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={"Search participant by email or phone number"}
                        variant="outlined"
                        onChange={handleEmailChange}
                        value={email}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                )}
                {participants.length > 0 && (
                  <List>
                    {participants.map((item, index) => (
                      <ListItem key={index} disableGutters>
                        <ListItemAvatar>
                          <Avatar style={getAvatarStyle(item.email)}>
                            {getInitials(item.name)}
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                          primary={
                            <StyledBadge
                              badgeContent={
                                item.role ? rolename(item.role) : "Visitor"
                              }
                              color={item.role ? "primary" : "secondary"}
                            >
                              <Typography variant="h5">{item.name}</Typography>
                            </StyledBadge>
                          }
                          secondary={
                            <Typography variant="body2" color="textSecondary">
                              {item.role &&
                                item[orgSettings.employeePrimaryIdentifier]}
                              {!item.role &&
                                item[orgSettings.visitorPrimaryIdentifier]}
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            color="primary"
                            onClick={() => handleRemove(item)}
                          >
                            <X />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                )}
              </CardContent>
            </Card>
          </Grid>
        )}{" "}
        <Grid item xs={12}>
          <Card>
            {error !== "" && (
              <Alert
                severity="error"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setError("");
                    }}
                  >
                    <X />
                  </Button>
                }
              >
                {error}
              </Alert>
            )}
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <Grid container direction="row-reverse">
              <CardContent>
                <Button
                  color="primary"
                  variant="contained"
                  id="submit"
                  onClick={handleCreateVisit}
                  disabled={buttonDisable}
                  startIcon={
                    buttonDisable ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      undefined
                    )
                  }
                >
                  Send Request
                </Button>
              </CardContent>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <AddModal
        open={addParticipantModal}
        close={closeAddParticipantModal}
        addParticipantToArray={handleSelection}
        newEmail={email}
        primary={primary}
      />
    </Page>
  );
};

export default CreateVisit;
