import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  makeStyles,
  Typography,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  Avatar,
  List,
  ListItemText,
  ListItemSecondaryAction
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import api from "../../../../utils/api";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { clearToastAction, toastAction } from "src/actions";
import moment from "moment";
import getAvatarStyle from "src/utils/getAvatarStyle";
import getInitials from "src/utils/getInitials";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

function AddReportee(props) {
  const { open, close, id } = props;
  const [supervisor, setSupervisor] = useState(null);
  const [reportees, setReportees] = useState([]);
  const classes = useStyles();
  const [suggestionList, setSuggestionList] = useState([]);
  const [loading, setloading] = useState(false);
  const [openSuggestion, setopenSuggestion] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const dispatch = useDispatch();
  const orgSettings = useSelector(state => state.orgSettings);
  const user = useSelector(state => state.user);

  React.useEffect(() => {
    (async () => {
      try {
        if (id) {
          const supervisorData = await api(
            "GET",
            "employee/employeeSupervisorMap/supervisor/" + id,
            null,
            true
          );
          setSupervisor(supervisorData);
          const reporteeData = await api(
            "GET",
            "employee/employeeSupervisorMap/employees/" + id,
            null,
            true
          );
          setReportees(reporteeData);
        }
      } catch (error) {}
    })();
  }, [open, id]);
  const handleClose = (next) => {
    setReportees([]);
    setSupervisor(null);
    setSearchInput("");
    setAutocompleteValue(null);
    setSuggestionList([]);

    close(next);
  };

  useEffect(() => {
    if (!openSuggestion) {
      setSuggestionList([]);
    } else {
      setloading(true);
      (async () => {
        try {
          var data = await api(
            "POST",
            "employee/find",
            {
              search: [
                {
                  key: "employee.email",
                  value: searchInput,
                  comparison: "LIKE"
                },
                {
                  key: "employee.organizationId",
                  value: user.organizationId,
                  comparison: "="
                }
              ],
              take: 10
            },
            true
          );
          var suggestions = data;

          setSuggestionList(suggestions);
          setloading(false);
 
        } catch (error) {
          // captureException(error);
        }
      })();
    }
  }, [openSuggestion, searchInput]);

  const handleSubmit = async () => {
    try {
      const supervisorData = await api(
        "POST",
        "employee/employeeSupervisorMapBulk",
        {
          supervisorId: id,
          employeeIds: reportees.map(item => item.id)
        },
        true
      );
      handleClose(true);
    } catch (error) {
      // captureException(error);
    }
  };

  return (
    <Dialog open={open} close={e => handleClose(false)} maxWidth="sm" fullWidth>
      <DialogTitle disableTypography>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography component="h1" variant="h3" color="primary">
              Supervisors and Reportees
            </Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={e => handleClose(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {supervisor?.id && (
          <>
            <DialogContentText>Supervisor:</DialogContentText>
            <ListItem dense disableGutters style={{ overflowX: "hidden" }}>
              <ListItemAvatar>
                <Avatar style={getAvatarStyle(supervisor.email)}>
                  {getInitials(supervisor.name)}
                </Avatar>
              </ListItemAvatar>

              <ListItemText
                primary={
                  <Typography variant="h5">{supervisor.name}</Typography>
                }
                secondary={
                  <Typography variant="body2" color="textSecondary">
                    {supervisor[orgSettings.employeePrimaryIdentifier]}
                  </Typography>
                }
              />
            </ListItem>
          </>
        )}
        <br />
        <DialogContentText>Reportees:</DialogContentText>
        <List>
          {reportees.map(reportee => (
            <ListItem dense disableGutters style={{ overflowX: "hidden" }}>
              <ListItemAvatar>
                <Avatar style={getAvatarStyle(reportee.email)}>
                  {getInitials(reportee.name)}
                </Avatar>
              </ListItemAvatar>

              <ListItemText
                primary={<Typography variant="h5">{reportee.name} <small>{reportee.superVisorMap ? " - Already assigned" : ""}</small></Typography>}
                secondary={
                  <Typography variant="body2" color="textSecondary">
                    {reportee[orgSettings.employeePrimaryIdentifier]}
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={e =>
                    setReportees(reportees.filter(x => x.id !== reportee.id))
                  }
                >
                  <CloseIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <br />
        <Autocomplete
          fullWidth
          id="user"
          open={openSuggestion}
          clearOnBlur
          clearOnEscape
          noOptionsText={"No user found"}
          options={suggestionList}
          loading={loading}
          onOpen={() => {
            setopenSuggestion(true);
          }}
          onClose={() => {
            setopenSuggestion(false);
          }}
          onChange={(event, newValue) => {
            if (newValue) {
              setReportees([...reportees, newValue]);
              setSearchInput("");
              setAutocompleteValue(null);
            }
          }}
          value={autocompleteValue}
          filterOptions={(options, params) => {
            return options.filter(option => {
              return !reportees.some(x => x.id === option.id);
            });
          }}
          getOptionLabel={option => {
            return option.name;
          }}
          renderOption={item => {
            return item ? (
              <ListItem dense disableGutters className="suggestedParticipant">
                <ListItemAvatar>
                  <Avatar style={getAvatarStyle(item.email)}>
                    {getInitials(item.inputValue ? "+" : item.name)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="h5">{item.name} <small>{item.superVisorMap ? " - Already assigned" : ""}</small></Typography>}
                  secondary={
                    <Typography variant="body2" color="textSecondary">
                      {item.role && item[orgSettings.employeePrimaryIdentifier]}
                      {!item.role && item[orgSettings.staffPrimaryIdentifier]}
                    </Typography>
                  }
                />
              </ListItem>
            ) : (
              <></>
            );
          }}
          renderInput={params => (
            <TextField
              {...params}
              label={"Search employees"}
              variant="outlined"
              onChange={e => setSearchInput(e.target.value)}
              value={searchInput}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
        />

        <Grid
          container
          direction="row-reverse"
          alignItems="center"
          spacing={3}
          className={classes.marginNormal}
        >
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Save and next
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={e=>handleClose(true)}>
              Next
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default AddReportee;
