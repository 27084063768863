import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { captureException } from "@sentry/react";
import React, { useState } from "react";
import { PlusCircle } from "react-feather";
import api from "src/utils/api";
import Loader from "../../../utils/loader";
import ConfirmationDialog from "../common/ConfirmDialog";
import FloorModal from "./FloorModal";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  button: {
    marginBottom: 10
  }
});

export default function Floors() {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setLoader] = useState(true);
  const [error, setError] = useState("");
  const [buildingList, setBuildingList] = useState([]);
  const [isLoadingDelete, setDeleteLoader] = useState(false);
  const [floorList, setFloorList] = useState([]);
  const [activeData, setData] = useState({});
  const [countData, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  React.useEffect(() => {
    (async () => {
      const countPayload = {
        sort: { key: "floor.createdAt", order: "ASC" },
        skip: 0,
        take: 10
      };
      const building = await api("POST", "building/find", null, true);
      const floor = await api("POST", "floor/findCount", countPayload, true);
      Promise.all([building, floor])
        .then(data => {
          setBuildingList(data[0]);
          setCount(data[1]);
        })
        .catch(error => {
          setLoader(false);
        });
    })();
  }, []);

  const floorCount = async () => {
    try {
      const countPayload = {
        sort: { key: "floor.createdAt", order: "ASC" },
        skip: 0,
        take: 10
      };
      const res = await api("POST", "floor/findCount", countPayload, true);
      setCount(res);
    } catch (error) {
      captureException(error);
    }
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    fetchData();
  }, [rowsPerPage, page]);

  const fetchData = async () => {
    setLoader(true);
    try {
      const payload = {
        sort: {
          key: "floor.createdAt",
          order: "ASC"
        },
        skip: page * rowsPerPage,
        take: rowsPerPage
      };
      const floor = await api("POST", "floor/find", payload, true);
      const res = floor || [];
      setFloorList(res);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const editFloors = data => {
    setData(data);
    setIsOpen(true);
  };

  const editFloorsToArray = item => {
    const clone = [...floorList];
    const ind = clone.findIndex(l => l.id === item.id);
    clone[ind] = {
      ...clone[ind],
      ...item
    };
    setFloorList(clone);
  };
  const addFloorsToArray = item => {
    setFloorList([item, ...floorList]);
  };

  const handleModal = () => {
    setData({});
    setIsOpen(true);
  };

  const deleteFloor = async () => {
    setDeleteLoader(true);
    try {
      const floor = await api("DELETE", `floor/${activeData.id}`, null, true);
      if (floor.id) {
        floorCount();
        fetchData();
        setOpen(false);
        setError("");
      }
      setDeleteLoader(false);
    } catch (error) {
      setDeleteLoader(false);
      setError("Some things went wrong please try again");
    }
  };

  const conformRoomModal = row => {
    if (row && row.id) {
      setOpen(true);
      setData(row);
    } else {
      setData({});
      setOpen(false);
    }
  };

  const DialogButtons = (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => conformRoomModal()}
      >
        NO
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={isLoadingDelete}
        onClick={() => deleteFloor()}
      >
        {isLoadingDelete ? "Loading..." : "Yes delete"}
      </Button>
    </React.Fragment>
  );

  if (isLoading) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <Loader />
        </Grid>
        <Grid item xs={4} />
      </Grid>
    );
  }
  return (
    <React.Fragment>
      <Grid
        alignItems="flex-start"
        container
        justify="space-between"
        spacing={3}
        direction="row"
      >
        <Grid item>
          <Typography variant="h4" color="primary">
            Floors
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            className={classes.button}
            startIcon={<PlusCircle />}
            variant="outlined"
            onClick={handleModal}
          >
            Add Floor
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Building</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {floorList.map((row, ind) => (
              <TableRow key={ind}>
                <TableCell align="left">{row.name || "-"}</TableCell>
                <TableCell align="left">{row.building.name || "-"}</TableCell>
                <TableCell align="center">
                  <IconButton aria-label="edit" onClick={() => editFloors(row)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => conformRoomModal(row)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={countData}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      {isOpen && (
        <FloorModal
          open={isOpen}
          floorCount={floorCount}
          activeData={activeData}
          addFloorsToArray={addFloorsToArray}
          editFloorsToArray={editFloorsToArray}
          buildingList={buildingList}
          close={() => setIsOpen(false)}
        />
      )}
      {open && (
        <ConfirmationDialog
          open={open}
          error={error}
          handleClose={() => setOpen(false)}
          title={"Delete Account"}
          content={"Are you sure you want to delete floor?"}
          btnActions={DialogButtons}
        />
      )}
    </React.Fragment>
  );
}
