import {
  Avatar,
  Card,
  CardContent,
  Chip,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import getAvatarStyle from "../../../utils/getAvatarStyle";
import rolename from "../../../utils/rolename";
import getInitials from "../../../utils/getInitials";

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100,
    fontSize: "3.125rem"
  },
  capitalize: {
    textTransform: "capitalize"
  }
}));

const Profile = ({ className, userProfile, ...rest }) => {
  const classes = useStyles();
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item>
            {userProfile.photo && (
              <Avatar
                style={getAvatarStyle(userProfile.email)}
                src={userProfile.photo}
                className={classes.avatar}
              >
                {getInitials(userProfile.name)}
              </Avatar>
            )}
            {!userProfile.photo && (
              <Avatar
                style={getAvatarStyle(userProfile.email)}
                className={classes.avatar}
              >
                {getInitials(userProfile.name)}
              </Avatar>
            )}
          </Grid>
          <Grid item>
            <Typography color="textPrimary" gutterBottom variant="h3">
              {userProfile.name}{" "}
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="textSecondary" variant="body1">
              {`${userProfile.email}`}
              {userProfile.mobile &&
                ` | ${userProfile.countryCode} ${userProfile.mobile}`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1"
            >
              {`User since: ${moment(
                new Date(parseInt(userProfile.createdAt)).toISOString()
              )
                .local()
                .format("LT, ll")}`}
            </Typography>
          </Grid>

          {userProfile.role && (
            <Grid item>
              <Typography
                className={classes.dateText}
                color="textSecondary"
                variant="body1"
              >
                {`${userProfile.designation ??
                  "-"} | ${userProfile.department ?? "-"}`}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <Chip
              label={
                !userProfile.organization
                  ? "Business Admin"
                  : userProfile.role
                  ? rolename(userProfile.role)
                  : "Visitor"
              }
              color="primary"
              className={classes.capitalize}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
