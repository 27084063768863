import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import api from "../../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { DropzoneDialog } from "material-ui-dropzone";
import axios from "axios";
import { organizationSettings, toastAction } from "../../../actions";
import baseurl from "../../../utils/baseurl";

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({ className, userProfile, handleUpdate, ...rest }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    name: userProfile.name,
    email: userProfile.email,
    countryCode: userProfile.countryCode || "",
    mobile: userProfile.mobile || "",
    department: userProfile.department || "",
    designation: userProfile.designation || "",
    workPhone: userProfile.workPhone || "",
    workFax: userProfile.workFax || "",
    website: userProfile.website || "",
    address: userProfile.address || "",
    building: userProfile.building || "",
    organizationId: userProfile.organization ? userProfile.organization.id : ""
  });
  const [buildings, setBuildings] = useState([]);
  const org = useSelector(state => state.org);
  const [dropzoneOpen, setDropzoneOpen] = useState(false);
  const [dropzone, setDropzone] = useState({});

  useEffect(() => {
    (async () => {
      const buildings = await api(
        "POST",
        "building/find",
        {
          search: [{ key: "building.isActive", value: "", comparison: "=" }],
          sort: { key: "building.createdAt", order: "ASC" },
          skip: 0,
          take: 10
        },
        true
      );
      setBuildings(buildings);
    })();
  }, []);

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (userProfile.role) handleUpdate(values);
    else
      handleUpdate({
        name: values.name,
        mobile: values.mobile,
        countryCode: values.countryCode,
        email: values.email,
        organizationId: values.organizationId
      });
  };

  const handleUploadDalog = (title, key, type, flag) => {
    setDropzone({
      title,
      key,
      type,
      flag
    });
    setDropzoneOpen(true);
  };

  const handleUpload = async files => {
    dispatch(
      toastAction({
        status: true,
        message: "Uploading file ... ",
        type: "info",
        noIcon: true
      })
    );
    const url = await upload(files[0], "employee_profile");
    setLoading(true);

    handleUpdate({
      photo: url
    });

    setDropzoneOpen(false);
    setLoading(false);
  };

  const upload = async (file, folder) => {
    const token = localStorage ? localStorage.getItem("token") : null;
    let filenameSplit = file.name.split(".");
    const formData = new FormData();
    formData.append(
      "file",
      file,
      Date.now() + "." + filenameSplit[filenameSplit.length - 1]
    );
    formData.append("fileStorage", folder);

    try {
      let response = await axios({
        method: "POST",
        url: baseurl + "file/upload",
        data: formData,
        headers: {
          Authorization: "Bearer " + token
        },
        onUploadProgress: progressEvent => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          dispatch(
            toastAction({
              status: true,
              message: "Uploading file ... " + percentCompleted + "%",
              type: "info",
              noIcon: true
            })
          );
        }
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return (
    <form
      autoComplete="off"
      className={clsx(classes.root, className)}
      onSubmit={handleSubmit}
      {...rest}
    >
      <Card>
        <CardHeader title="Update Profile" />

        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the full name"
                label="Full name"
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                onChange={handleChange}
                required
                disabled
                margin="normal"
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <PhoneInput
                country={org?.countrycode ?? "in"}
                enableSearch
                searchClass="MuiOutlinedInput-input MuiOutlinedInput-inputMarginDense"
                preferredCountries={["sg", "in", "us"]}
                inputStyle={{ width: "100%" }}
                specialLabel="Phone number"
                countryCodeEditable={false}
                value={values.countryCode.replace("+", "") + values.mobile}
                onChange={(value, country) => {
                  setValues({
                    ...values,
                    mobile: value.replace(country.dialCode, ""),
                    countryCode: "+" + country.dialCode
                  });
                }}
              />
            </Grid>

            {userProfile.role && (
              <>
                <Grid item md={6} xs={12}>
                  <PhoneInput
                    country={org?.countrycode ?? "in"}
                    enableSearch
                    searchClass="MuiOutlinedInput-input MuiOutlinedInput-inputMarginDense"
                    preferredCountries={["sg", "in", "us"]}
                    inputStyle={{ width: "100%" }}
                    specialLabel="Work Phone number"
                    countryCodeEditable={false}
                    value={
                      values.countryCode.replace("+", "") + values.workPhone
                    }
                    onChange={(value, country) => {
                      setValues({
                        ...values,
                        workPhone: value.replace(country.dialCode, ""),
                        countryCode: "+" + country.dialCode
                      });
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <PhoneInput
                    country={org?.countrycode ?? "in"}
                    enableSearch
                    searchClass="MuiOutlinedInput-input MuiOutlinedInput-inputMarginDense"
                    preferredCountries={["sg", "in", "us"]}
                    inputStyle={{ width: "100%" }}
                    specialLabel="Work Fax"
                    countryCodeEditable={false}
                    value={values.countryCode.replace("+", "") + values.workFax}
                    onChange={(value, country) => {
                      setValues({
                        ...values,
                        workFax: value.replace(country.dialCode, ""),
                        countryCode: "+" + country.dialCode
                      });
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    label="Department"
                    fullWidth
                    variant="outlined"
                    type="text"
                    margin="normal"
                    name="department"
                    value={values.department}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    label="Designation"
                    fullWidth
                    variant="outlined"
                    type="text"
                    margin="normal"
                    name="designation"
                    value={values.designation}
                    onChange={handleChange}
                  />
                </Grid>
              </>
            )}

            {userProfile.role &&
              userProfile.role !== "superadmin" &&
              userProfile.role !== "businessAdmin" && (
                <Grid item md={6} xs={12}>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel id="building"> Building</InputLabel>
                    <Select
                      labelId="building"
                      id="building"
                      label="Building"
                      value={values.building}
                      onChange={handleChange}
                      autoWidth
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {buildings.map(building => {
                        return (
                          <MenuItem value={building.id} key={building.id}>
                            {building.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
            {userProfile.role && (
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Website"
                    name="website"
                    onChange={handleChange}
                    value={values.website}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="address"
                    onChange={handleChange}
                    value={values.address}
                    variant="outlined"
                  />
                </Grid>{" "}
              </>
            )}
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            color="primary"
            variant="outlined"
            onClick={e => {
              e.preventDefault();
              handleUploadDalog("Profile Picture");
            }}
          >
            Upload picture
          </Button>
          <Button color="primary" variant="contained" type="submit">
            Update Profile
          </Button>
        </Box>
      </Card>
      <DropzoneDialog
        open={dropzoneOpen}
        onSave={files => {
          handleUpload(files);
        }}
        acceptedFiles={["image/*"]}
        showPreviews={true}
        filesLimit={1}
        maxFileSize={5000000000}
        dialogTitle={dropzone.title}
        onClose={() => setDropzoneOpen(false)}
        submitButtonText="Upload"
        cancelButtonText="Cancel"
      />
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
