import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Input,
  Typography,
  CardContent,
  ListItem,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableHead
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import { captureException } from "@sentry/browser";
import React, { useEffect, useState } from "react";
import { X, Phone } from "react-feather";
import "react-phone-input-2/lib/material.css";
import { useDispatch, useSelector } from "react-redux";
import { clearToastAction, toastAction } from "../../../../actions";
import api from "../../../../utils/api";
import { format } from "date-fns";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

const OrderModal = props => {
  const { open, close, type, orderId } = props;
  const classes = useStyles();
  const user = useSelector(state => {
    return state.user;
  });
  const org = useSelector(state => {
    return state.org;
  });

  const [orderDetail, setOrderDetail] = useState([]);
  const [orderStatus, setOrderStatus] = useState("pending");
  const [deliverAt, setOrderDeliveryTime] = useState(format(new Date(), "HH:mm"));
  const [role, setRole] = useState("pantry");
  const [error, setError] = useState("");

  const orgSettings = useSelector(state => {
    return state.orgSettings;
  });

  const dispatch = useDispatch();
  console.log("user", user.organizationName);


  useEffect(() => {
    (async () => {
      try {
        const orderDetail = await api(
          "GET",
          "pantry/order/" + orderId,
          {
            sort: {
              key: "organization.name",
              order: "ASC"
            }
          },
          true
        );
        setOrderDetail(orderDetail);
        //setOrderStatus(orderDetail.status);
        //setOrderDeliveryTime(orderDetail.deliverAt)
      } catch (error) {
        captureException(error);
      }
    })();
  }, [open]);



  const handleClose = () => {
    setOrderStatus("pending");
    setOrderDeliveryTime(format(new Date(), "HH:mm"))
    setError("");
    close();
  };

  const handleChangeStatus = (value) => {
    setOrderStatus(value);
  }

  const handleChangeTime = (value) => {
    setOrderDeliveryTime(value);
  }

  const handleUpdate = () => {
    dispatch(
      toastAction({ status: true, message: "Updating Order", type: "success" })
    );
    setError("");
    var body = {
      status: orderStatus,
      deliverAt: new Date(format(new Date(), "dd-MM-yyyy") + " " + deliverAt).getTime()
    };
    for (const [key, value] of Object.entries(body)) {
      if (value === "") delete body[key];
    }

    (async () => {
      try {
        await api("PATCH", `pantry/order/${orderDetail.id}`, body, true);
        dispatch(clearToastAction({}));
        handleClose();
      } catch (error) {
        error.response && error.response.data && setError(error.response.data);
        dispatch(clearToastAction({}));
      }
    })();
  };


  return (

    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle disableTypography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography component="h1" variant="h3" color="primary">
              Order Details
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {error !== "" && (
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setError("");
                  }}
                >
                  <X />
                </Button>
              }
            >
              {error}
            </Alert>
          )}
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Order#</TableCell>
                <TableCell>{orderId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Order By</TableCell>
                <TableCell>
                  {orderDetail.employee?.name}<br />
                  <Phone style={{ width: 18, position: "relative", top: 6 }} /> {orderDetail.employee?.mobile}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Pantry</TableCell>
                <TableCell>{orderDetail.pantry?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell style={{textTransform: "uppercase"}}>{orderDetail.status}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid item>
                        <Box minWidth={280} flexBasis={1}>
                          <ListItem disableGutters>
                            <Typography>Order Items</Typography>
                          </ListItem>
                        </Box>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Item Name</TableCell>
                            <TableCell>Quantity</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {orderDetail.pantryItems?.map((item, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell>{item.pantryItem.name}</TableCell>
                                <TableCell>{item.quantity}</TableCell>
                              </TableRow>
                            )
                          })}
                          {orderDetail.deliverAt !== null && (
                            <TableRow>
                              <TableCell>Estimated Delivery</TableCell>
                              <TableCell>
                                {moment(parseInt(orderDetail.deliverAt)).local().format("LT")}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                    size="small"
                  >
                    <Select
                      id="status"
                      defaultValue={orderStatus}
                      value={orderStatus}
                      displayEmpty
                      onChange={e => handleChangeStatus(e.target.value)}
                      autoWidth
                    >
                      <MenuItem value="pending">Pending</MenuItem>
                      <MenuItem value="accepted">Accepted</MenuItem>
                      <MenuItem value="rejected">Rejected</MenuItem>
                      <MenuItem value="delivered">Delivered</MenuItem>
                      <MenuItem value="unavailable">Not Available</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                    size="small"
                  >
                    <Input type="time" value={deliverAt} onChange={e => handleChangeTime(e.target.value)} />
                  </FormControl>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  <Button variant="contained" color="primary" onClick={handleUpdate}>Update</Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default OrderModal;
