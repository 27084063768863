import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { KeyboardBackspace } from "@material-ui/icons";
import RoomControlDetailsDialog from "./RoomControlDetailsDialog";
import api from "src/utils/api";
import { PlusCircle } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { toastAction } from "../../../actions";
import RoomControlAdd from "./RoomControlAdd";

function RoomControlDialog(props) {
  const { close, modalData } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedControl, setSelectedControl] = useState({});
  const [isLoading, setLoader] = useState(false);
  const [controlList, setControList] = useState([]);
  const [countData, setCount] = useState(0);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const handleClose = () => {
    close();
  };
  useEffect(() => {
    (async () => {
      const data = await api(
        "GET",
        "room/controls/" + modalData.id,
        null,
        true
      );
      console.log(data);
      setControList(data);
      setLoader(true);
    })();
  }, [modalData.id, detailsOpen]);

  const handleViewModal = row => {
    setSelectedControl(row);
    setDetailsOpen(true);
  };

  return (
    <React.Fragment>
      <Grid alignItems="center" container spacing={3} direction="row">
        <Grid item>
          <IconButton aria-label="close" onClick={close} size="small">
            <KeyboardBackspace />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography>Back to All Rooms</Typography>
        </Grid>
      </Grid>
      <br />
      <Grid
        container
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="h4" color="primary">
            {modalData.name}
          </Typography>
        </Grid>
        <Grid>
          <Button
            color="primary"
            startIcon={<PlusCircle />}
            variant="outlined"
            onClick={() => {
              setDetailsOpen(true);
              setSelectedControl({ roomId: modalData.id });
            }}
          >
            Add Control
          </Button>
        </Grid>
      </Grid>
      <br />

      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Control Name</TableCell>
              <TableCell align="center">Control Type</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {controlList.map((row, ind) => (
              <TableRow key={ind}>
                <TableCell align="center">{row.controlName}</TableCell>
                <TableCell align="center">{row.controlType}</TableCell>
                <TableCell align="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleViewModal(row)}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <RoomControlDetailsDialog
        open={detailsOpen}
        close={() => setDetailsOpen(false)}
        dataList={selectedControl}
      />
    </React.Fragment>
  );
}

export default RoomControlDialog;
