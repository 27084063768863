import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  Container,
  Box,
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Dialog,
  DialogContent,
  OutlinedInput,
  InputAdornment
} from "@material-ui/core";
import moment from "moment";
import {
  ArrowBack,
  Clear,
  PersonOutline,
  Camera,
  Cancel,
  CheckCircle
} from "@material-ui/icons";
import {
  Calendar,
  Clipboard,
  Clock,
  Info,
  MapPin,
  ArrowDown,
  ArrowUp
} from "react-feather";
import { useLocation } from "react-router-dom";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import { captureException } from "@sentry/browser";

import getAvatarStyle from "../../utils/getAvatarStyle";
import getInitials from "../../utils/getInitials";
import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useDispatch, useSelector } from "react-redux";
import { clearToastAction, toastAction } from "../../actions";
import api from "../../utils/api";
import Loader from "../../utils/loader";
import Page from "../../components/Page";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

const OtpDialog = props => {
  const [pin, setPin] = useState("");
  const orgSettings = useSelector(state => state.orgSettings);

  const verifyOTP = async otp => {
    try {
      props.setToast({
        severity: "info",
        message: "Verifying OTP"
      });
      await api("POST", "visitor/mobile/verifyOtpInvite", {
        id: props.otpId,
        organizationId: props.organizationId,
        otp: otp
      });
      props.validateOTP();
      props.setToast({
        severity: "success",
        message: "OTP verified"
      });
      props.close();
    } catch (error) {
      setPin("");
      props.setToast({
        severity: "error",
        message: "Incorrect OTP!"
      });
    }
  };

  return (
    <Dialog open={props.open} aria-labelledby="otpDialog">
      <Box bgcolor="primary.main" pb={1} pt={2} style={{ maxWidth: 320 }}>
        <Typography variant="h1" component="h2" style={{ color: "#fff" }}>
          <IconButton
            size="medium"
            style={{ paddingTop: 8 }}
            onClick={props.close}
          >
            <ArrowBack style={{ color: "#fff", fontSize: 36 }} />
          </IconButton>
          Enter OTP
        </Typography>
      </Box>
      <DialogContent style={{ maxWidth: 320 }}>
        <Box py={1}>
          <Typography variant="h6" component="h2" color="primary">
            Enter the one time password you just received
          </Typography>

          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              id="pin"
              type="password"
              disabled
              value={pin}
              inputProps={{ style: { textAlign: "center", height: 20 } }}
              endAdornment={
                pin === "" ? null : (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setPin("");
                      }}
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                )
              }
              style={{
                padding: 0,
                paddingLeft: 8,
                fontSize: 32,
                letterSpacing: 24
              }}
            />
          </FormControl>
        </Box>
        <Box py={1}>
          <Grid container direction="row" spacing={1}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, "", 0, ""].map((item, index) =>
              item === "" ? (
                <Grid item xs={4} key={index}></Grid>
              ) : (
                <Grid item xs={4} key={index}>
                  <Button
                    style={{
                      width: "100%",
                      fontSize: 24,
                      backgroundColor: "#757de830"
                    }}
                    disabled={pin.length > 3}
                    onClick={() => {
                      setPin(pin + item);
                      if ((pin + item).length === 4) {
                        verifyOTP(pin + item);
                      }
                    }}
                  >
                    {item}
                  </Button>
                </Grid>
              )
            )}
          </Grid>
        </Box>
      </DialogContent>
      <Box bgcolor="primary.main" pb={1} pt={2}></Box>
    </Dialog>
  );
};

const InvitationResponse = props => {
  var query = {};
  const location = useLocation();
  location.search
    .slice(1)
    .split("&")
    .forEach(item => {
      query[item.split("=")[0]] = item.split("=")[1];
    });
  const classes = useStyles();
  const [bus, setBus] = useState({});
  const [org, setOrg] = useState({});
  const [meeting, setMeeting] = useState({});
  const [email, setEmail] = useState("");
  const [visitorName, setVisitorName] = useState("");
  const [mobile, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [organization, setOrganization] = useState("");
  const [buildings, setBuildings] = useState([]);
  const [building, setBuilding] = useState("");
  const [error, setError] = useState("");
  const [orgSettings, setOrgSettings] = useState({});
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [otpDialog, setOtpDialog] = useState(false);
  const [otpId, setOtpId] = useState(0);
  const [step, setStep] = useState(0);
  const [visitorFound, setVisitorFound] = useState(false);
  const [foundVisitor, setFoundVisitor] = useState(null);
  const [foundVisitorToken, setFoundVisitorToken] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      console.log(query);
      const data = await api(
        "GET",
        "meeting/invite/" + query.inviteId,
        null,
        false
      );
      setMeeting(data);
      setOrganization(data.organizationId);
      const business = await api(
        "GET",
        "business/" + data.businessId,
        null,
        true
      );
      setBus(business);
      const organization = await api(
        "GET",
        "organization/" + data.organizationId,
        null,
        true
      );
      setOrg(organization);
      const businessSettings = await api(
        "GET",
        "business/getBusinessSettings/" + data.businessId,
        null,
        true
      );
      const organizationSettings = await api(
        "GET",
        "organization/getOrgSettings/" + data.organizationId,
        null,
        true
      );
      setOrgSettings({
        ...businessSettings,
        ...organizationSettings
      });
      setLoading(false);
    })();
  }, []);

  const handleSendOTP = async () => {
    dispatch(
      toastAction({
        status: true,
        severity: "info",
        message: "Sending OTP"
      })
    );

    try {
      let data = await api(
        "POST",
        "visitor/mobile/sendOtpInvite",
        {
          email: email,
          organizationId: org.id
        },
        false,
        true
      );
      setOtpDialog(true);
      setOtpId(data.data);
      if (data.user.hasOwnProperty("id")) {
        setVisitorFound(true);
        setFoundVisitor(data.user);
        setFoundVisitorToken(data.token);
      } else {
        setVisitorFound(false);
      }
      dispatch(
        toastAction({
          status: true,
          severity: "success",
          message: "OTP successfully  sent"
        })
      );
    } catch (error) {
      dispatch(
        toastAction({
          status: true,
          severity: "error",
          message: error.response?.data?.message || "Something went wrong"
        })
      );
    }
  };

  const handleAdd = () => {
    setError("");
    var body = {
      name: visitorName,
      mobile,
      countryCode,
      email: email,
      organizationId: org.id,
      businessId: bus.id
    };
    for (const [key, value] of Object.entries(body)) {
      if (value === "") delete body[key];
    }
    dispatch(
      toastAction({
        status: true,
        message: "Signing up ...",
        type: "info",
        noIcon: true
      })
    );
    (async () => {
      try {
        let result = await api("POST", "visitor/visitorSignup", body, false);
        dispatch(
          toastAction({
            status: true,
            message: "Sign up successful",
            type: "success"
          })
        );
        const existingVisitors = meeting.participants
          .filter(item => item.visitorId)
          .map(item => item.visitor.id);

        await api(
          "PATCH",
          "meeting/" + meeting.id,
          {
            purpose: meeting.purpose,
            startTime: moment
              .utc(new Date(parseInt(meeting.startTime)).toISOString())
              .format("MM-DD-YYYY HH:mm"),
            endTime: moment
              .utc(new Date(parseInt(meeting.endTime)).toISOString())
              .format("MM-DD-YYYY HH:mm"),
            buildingId: meeting.buildingId,
            roomId: meeting.roomId,
            employees: meeting.participants
              .filter(item => item.employeeId)
              .map(item => item.employee.id),
            visitors: [...existingVisitors, result.user.id]
          },
          false,
          false,
          false,
          false,
          result.token
        );
        setSuccess(true);
      } catch (error) {
        dispatch(
          toastAction({
            status: true,
            message: "Something went wrong ",
            type: "error"
          })
        );
        error.response && error.response.data && setError(error.response.data);
      }
    })();
  };

  const handleFoundAndVerified = () => {
    setError("");

    dispatch(
      toastAction({
        status: true,
        message: "Accepting invite ...",
        type: "info",
        noIcon: true
      })
    );
    (async () => {
      try {
        const existingVisitors = meeting.participants
          .filter(item => item.visitorId)
          .map(item => item.visitor.id);

        await api(
          "PATCH",
          "meeting/" + meeting.id,
          {
            purpose: meeting.purpose,
            startTime: moment
              .utc(new Date(parseInt(meeting.startTime)).toISOString())
              .format("MM-DD-YYYY HH:mm"),
            endTime: moment
              .utc(new Date(parseInt(meeting.endTime)).toISOString())
              .format("MM-DD-YYYY HH:mm"),
            buildingId: meeting.buildingId,
            roomId: meeting.roomId,
            employees: meeting.participants
              .filter(item => item.employeeId)
              .map(item => item.employee.id),
            visitors: [...existingVisitors, foundVisitor.id]
          },
          false,
          false,
          false,
          false,
          foundVisitorToken
        );
        setSuccess(true);
      } catch (error) {
        dispatch(
          toastAction({
            status: true,
            message: "Something went wrong ",
            type: "error"
          })
        );
        error.response && error.response.data && setError(error.response.data);
      }
    })();
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Page title="Meeting Invitation">
        <Container maxWidth="md" style={{ padding: 0 }}>
          <Box p={3} my={3} style={{ backgroundColor: "#fff" }}>
            {bus.logo && bus.name && (
              <>
                <Box
                  mb={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img src={bus.logo} style={{ maxWidth: 180 }} alt="logo" />
                </Box>
                <Typography
                  color="primary"
                  variant="h1"
                  component="h2"
                  align="center"
                  style={{ fontWeight: 900 }}
                >
                  Welcome to {bus.name}
                </Typography>
              </>
            )}
            <br />
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography
                component="h1"
                variant="h3"
                color="primary"
                align="center"
              >
                Meeting Invitation
              </Typography>
            </Grid>

            <DialogContentText>
              Hello!, You have been invited to a meeting at {org.name} by{" "}
              {meeting.requestEmployee.name} <br />
              Please fill up your profile details to sign up and join accept the
              invitation. Ignore if already done.
            </DialogContentText>
            {error !== "" && (
              <Alert
                severity="error"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setError("");
                    }}
                  >
                    <X />
                  </Button>
                }
              >
                {error}
              </Alert>
            )}
            {success && (
              <Alert severity="success">
                Invitation accepted successfully. Please check your email for
                credentials
              </Alert>
            )}
            {!success && (
              <>
                <Typography component="h2" gutterBottom variant="h4">
                  Meeting Details
                </Typography>
                <br />
                <Grid container direction="column" spacing={1}>
                  <Typography variant="h5">Invited by</Typography>
                  <Grid item>
                    <Box flexBasis={1}>
                      <ListItem disableGutters>
                        <ListItemAvatar>
                          <Avatar
                            style={getAvatarStyle(
                              meeting.requestEmployee.email || ""
                            )}
                          >
                            {getInitials(meeting.requestEmployee.name)}
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                          primary={
                            <Typography variant="h5">
                              {meeting.requestEmployee.name}
                            </Typography>
                          }
                          secondary={
                            <Typography variant="body2" color="textSecondary">
                              {
                                meeting.requestEmployee[
                                  orgSettings.employeePrimaryIdentifier
                                ]
                              }
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <Grid item>
                        <Box width={30}>
                          <Clipboard />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box width={80}>
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                <Typography variant="h5">Purpose</Typography>
                              }
                            />
                          </ListItem>
                        </Box>
                      </Grid>
                      <Grid item>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              <Typography variant="body1">
                                {meeting.purpose}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                  </Grid>
                  {meeting.room && (
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Grid item>
                          <Box width={30}>
                            <MapPin />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box width={80}>
                            <ListItem disableGutters>
                              <ListItemText
                                primary={
                                  <Typography variant="h5">Venue</Typography>
                                }
                              />
                            </ListItem>
                          </Box>
                        </Grid>
                        <Grid item>
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                <Typography variant="body1">
                                  {meeting.room.name}
                                  {", "}
                                  {meeting.building.name}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <Grid item>
                        <Box width={30}>
                          <Calendar />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box width={80}>
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                <Typography variant="h5">Date</Typography>
                              }
                            />
                          </ListItem>
                        </Box>
                      </Grid>
                      <Grid item>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              <Typography variant="body1">
                                {moment(parseInt(meeting.startTime))
                                  .local()
                                  .format("Do MMM YYYY")}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <Grid item>
                        <Box width={30}>
                          <Clock />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box width={80}>
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                <Typography variant="h5">Time</Typography>
                              }
                            />
                          </ListItem>
                        </Box>
                      </Grid>
                      <Grid item>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              <Typography variant="body1">
                                {moment(parseInt(meeting.startTime))
                                  .local()
                                  .format("LT")}
                                {" to "}
                                {moment(parseInt(meeting.endTime))
                                  .local()
                                  .format("LT")}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <Grid item>
                        <Box width={30}>
                          <Info />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box width={80}>
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                <Typography variant="h5">Status</Typography>
                              }
                            />
                          </ListItem>
                        </Box>
                      </Grid>
                      <Grid item>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              <Typography variant="button">
                                {meeting.status}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {step === 0 && (
                  <>
                    <TextField
                      // disabled
                      label="Email address"
                      variant="outlined"
                      type="email"
                      margin="normal"
                      required={JSON.parse(
                        orgSettings.visitorRequiredFields || "[]"
                      ).includes("email")}
                      fullWidth
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                    />
                    <Grid
                      container
                      direction="row-reverse"
                      alignItems="center"
                      spacing={3}
                      className={classes.marginNormal}
                    >
                      <Grid item>
                        <Button
                          onClick={handleSendOTP}
                          variant="contained"
                          color="primary"
                        >
                          Send OTP
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
                {step === 1 && !visitorFound && (
                  <>
                    <TextField
                      label="Full name"
                      variant="outlined"
                      type="text"
                      margin="normal"
                      required
                      fullWidth
                      value={visitorName}
                      onChange={e => {
                        setVisitorName(e.target.value);
                      }}
                    />
                    <TextField
                      // disabled
                      label="Email address"
                      variant="outlined"
                      type="email"
                      margin="normal"
                      required={JSON.parse(
                        orgSettings.visitorRequiredFields || "[]"
                      ).includes("email")}
                      fullWidth
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                    />

                    <PhoneInput
                      enableSearch
                      country={org?.countrycode ?? "in"}
                      preferredCountries={["sg", "in", "us"]}
                      inputStyle={{ width: "100%" }}
                      searchClass="MuiOutlinedInput-input MuiOutlinedInput-inputMarginDense"
                      specialLabel={
                        JSON.parse(
                          orgSettings.visitorRequiredFields || "[]"
                        ).includes("mobile")
                          ? "Phone number *"
                          : "Phone number"
                      }
                      countryCodeEditable={false}
                      value={countryCode.replace("+", "") + mobile}
                      onChange={(value, country) => {
                        setCountryCode("+" + country.dialCode);
                        setMobile(value.replace(country.dialCode, ""));
                      }}
                    />
                    <Grid
                      container
                      direction="row-reverse"
                      alignItems="center"
                      spacing={3}
                      className={classes.marginNormal}
                    >
                      <Grid item>
                        <Button
                          onClick={handleAdd}
                          variant="contained"
                          color="primary"
                        >
                          Sign up
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Box>
        </Container>
        <OtpDialog
          open={otpDialog}
          close={() => setOtpDialog(false)}
          otpId={otpId}
          validateOTP={() => {
            console.log("validated");
            // Check if user exists
            // if not
            setStep(1);
            if (foundVisitor) {
              handleFoundAndVerified();
            }
          }}
          organizationId={org.id}
          setToast={t => {
            dispatch(toastAction(t));
          }}
        />
      </Page>
    </div>
  );
};

export default InvitationResponse;
