import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import { captureException } from "@sentry/browser";
import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import "react-phone-input-2/lib/material.css";
import { useDispatch, useSelector } from "react-redux";
import { clearToastAction, toastAction } from "../../../../actions";
import api from "../../../../utils/api";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

const AddModal = props => {
  const { open, close, type, pantryData } = props;
  const classes = useStyles();
  const user = useSelector(state => {
    return state.user;
  });
  const org = useSelector(state => {
    return state.org;
  });

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  const [company, setCompany] = useState("");
  const [organizationList, setOrganizationList] = useState([]);
  const [organization, setOrganization] = useState(org && org.id ? org.id : "");
  const [buildings, setBuildings] = useState([]);
  const [building, setBuilding] = useState("");
  const [forwardOrder, setforwardOrder] = useState(false);
  const [forwardOrderDelay, setforwardOrderDelay] = useState(0);
  const [receiveForwardedOrder, setreceiveForwardedOrder] = useState(false);
  const [role, setRole] = useState("pantry");
  const [error, setError] = useState("");

  const orgSettings = useSelector(state => {
    return state.orgSettings;
  });

  const dispatch = useDispatch();
  console.log("user", user.organizationName);


  useEffect(() => {
    (async () => {
      try {
        if (type === 'pantry') {
          const organizations = await api(
            "POST",
            "organization/find",
            {
              sort: {
                key: "organization.name",
                order: "ASC"
              }
            },
            true
          );
          setOrganizationList(organizations);

          const data = await api(
            "POST",
            "building/find",
            {
              search: [
                {
                  key: "building.businessId",
                  value: user.businessId,
                  comparison: "="
                }
              ],
              sort: { key: "building.createdAt", order: "ASC" }
            },
            true
          );
          setBuildings(data);
        }
      } catch (error) {
        captureException(error);
      }
    })();
  }, [open]);

  useEffect(() => {
    if (pantryData && pantryData.id) {
      setName(pantryData.name ?? "");
      setOrganization(pantryData.organization.id ?? "");
      setBuilding(pantryData.building.id ?? "");
      setforwardOrder(pantryData.forwardOrder ?? false);
      setreceiveForwardedOrder(pantryData.receiveForwardedOrder ?? false);
      setforwardOrderDelay(pantryData.forwardOrderDelay ?? 0);

    }
  }, [pantryData]);

  const handleClose = () => {
    setEmail("");
    setName("");
    setMobile("");
    setBuilding("");
    setDesignation("");
    setDepartment("");
    setError("");
    close();
  };

  const handleAdd = () => {
    setError("");
    var body;
    if (type === "pantry") {
      body = {
        name,
        forwardOrderDelay,
        forwardOrder,
        receiveForwardedOrder,
        buildingId: building,
        organizationId: org && org.id ? org.id : organization
      };
    }
    for (const [key, value] of Object.entries(body)) {
      if (value === "") delete body[key];
    }
    dispatch(
      toastAction({
        status: true,
        message: "Adding " + type + "...",
        type: "info",
        noIcon: true
      })
    );
    (async () => {
      try {
        await api("POST", type, body, true);
        dispatch(
          toastAction({
            status: true,
            message: "Successfully added " + type,
            type: "success"
          })
        );
        handleClose();
      } catch (error) {
        dispatch(
          toastAction({
            status: true,
            message: "Something went wrong ",
            type: "error"
          })
        );
        error.response && error.response.data && setError(error.response.data);
      }
    })();
  };

  const handleUpdate = () => {
    dispatch(
      toastAction({ status: true, message: "Updating Pantry", type: "success" })
    );
    setError("");
    var body;
    if (type === "pantry") {
      body = {
        name,
        forwardOrderDelay,
        forwardOrder,
        receiveForwardedOrder,
        buildingId: building,
        organizationId: org && org.id ? org.id : organization
      };
    } /* else if (type === "staff") {
      body = {
        email,
        name,
        mobile,
        countryCode
      };
    } else {
      body = {
        email,
        name,
        mobile,
        countryCode,
        organizationId: org && org.id ? org.id : organization
      };
    } */
    for (const [key, value] of Object.entries(body)) {
      if (value === "") delete body[key];
    }

    (async () => {
      try {
        await api("PATCH", `${type}/${pantryData.id}`, body, true);
        dispatch(clearToastAction({}));
        handleClose();
      } catch (error) {
        error.response && error.response.data && setError(error.response.data);
        dispatch(clearToastAction({}));
      }
    })();
  };
  const handleDelete = () => {
    setError("");
    const body =
      type === "pantry"
        ? {
          isActive: !pantryData.isActive
        }
        : {
          isActive: false
        };
    (async () => {
      try {
        await api("PATCH", `${type}/${pantryData.id}`, body, true);
        handleClose();
      } catch (error) {
        captureException(error);
        error.response && error.response.data && setError(error.response.data);
      }
    })();
  };

  return (

    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle disableTypography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography component="h1" variant="h3" color="primary">
              {pantryData ? "Edit" : "Add new "} {type}
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {!pantryData && (
            <DialogContentText>
              To add a new {type} fill up the following details and click on the
              'Add' button. We will generate a password and notify the new{" "}
              {type}.
            </DialogContentText>
          )}
          {error !== "" && (
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setError("");
                  }}
                >
                  <X />
                </Button>
              }
            >
              {error}
            </Alert>
          )}

          <TextField
            label="Pantry name"
            variant="outlined"
            type="text"
            margin="normal"
            required
            fullWidth
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
          />

          {user.role === "businessAdmin" && (
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              className={classes.marginNormal}
            >
              <InputLabel id="organization"> Organization </InputLabel>
              <Select
                labelId="organization"
                id="organization"
                label="Organization "
                value={organization}
                displayEmpty
                onChange={e => {
                  setOrganization(e.target.value);
                }}
                autoWidth
              >
                {organizationList.map(organization => {
                  return (
                    <MenuItem value={organization.id} key={organization.id}>
                      {organization.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}

          {type === "pantry" && (
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              className={classes.marginNormal}
            >
              <InputLabel id="building"> Building</InputLabel>
              <Select
                labelId="building"
                id="building"
                label="Building "
                value={building}
                onChange={e => {
                  setBuilding(e.target.value);
                }}
                autoWidth
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {buildings.map(building => {
                  return (
                    <MenuItem value={building.id} key={building.id}>
                      {building.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
                    <TextField
            label="Forward order delay (in minutes)"
            variant="outlined"
            type="number"
            margin="normal"
            required
            fullWidth
            value={forwardOrderDelay}
            onChange={e => {
              setforwardOrderDelay(e.target.value);
            }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={forwardOrder}
                onChange={e => {
                  setforwardOrder(e.target.checked);
                }}
                color="primary"
              />
            }
            label="Forward order"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={receiveForwardedOrder}
                onChange={e => {
                  setreceiveForwardedOrder(e.target.checked);
                }}
                color="primary"
              />
            }
            label="Receive forwarded order"
          />


          <Grid
            container
            direction="row-reverse"
            alignItems="center"
            spacing={3}
            className={classes.marginNormal}
          >
            {!pantryData ? (
              <Grid item>
                <Button onClick={handleAdd} variant="contained" color="primary">
                  Add
                </Button>
              </Grid>
            ) : (
              <>
                <Grid item>
                  <Button
                    onClick={handleUpdate}
                    variant="contained"
                    color="primary"
                  >
                    Update
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleDelete}
                    variant="contained"
                    color="secondary"
                  >
                    {type === "pantry" && !pantryData.isActive
                      ? "Restore"
                      : "Archive"}
                  </Button>
                </Grid>
              </>
            )}
            <Grid item>
              <Button onClick={handleClose} variant="contained">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddModal;
