import { Container, Grid, makeStyles } from "@material-ui/core";
import { captureException } from "@sentry/browser";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Page from "src/components/Page";
import api from "src/utils/api";
import Loader from "src/utils/loader";
import DoughnutChart from "./charts/DoughnutChart";
import LineChart from "./charts/LineChart";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Analytics = () => {
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(new Date());
  const [totalVisitOfMonth, setTotalVisitOfMonth] = useState([]);
  const [todayMeetingData, setTodayMeeting] = useState([]);
  const [tomorrowMeetingData, setTomorrowMeeting] = useState([]);
  const [todayVisitorsData, setTodayVisitors] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const user = useSelector(state => state.user);

  React.useEffect(() => {
    const start = moment()
      .startOf("day")
      .utc()
      .format("MM-DD-YYYY HH:mm");
    const end = moment()
      .endOf("day")
      .utc()
      .format("MM-DD-YYYY HH:mm");
    const data = {
      from: start,
      to: end
    };
    const tomorrowStart = moment()
      .startOf("day")
      .add(1, "day")
      .utc()
      .format("MM-DD-YYYY HH:mm");
    const tomorrowEnd = moment()
      .endOf("day")
      .add(1, "day")
      .utc()
      .format("MM-DD-YYYY HH:mm");
    const tomorrow = {
      from: tomorrowStart,
      to: tomorrowEnd
    };
    if (user.role === "admin" || user.role === "superadmin" || user.role === "businessAdmin") {
      todayVisitors(data);
    }
    dateChangeData();
    todayMeeting(data);
    tomorrowMeeting(tomorrow);
  }, []);

  const meetingAnalytics = async payload => {
    try {
      const res = await api("POST", "meeting/analytics", payload, true);
      if (res.length > 0) {
        setTotalVisitOfMonth(res);
        setisLoading(false);
      }
    } catch (error) {
      captureException(error);
    }
  };

  const todayMeeting = async payload => {
    try {
      const res = await api("POST", "analytics/meeting/status", payload, true);
      if (res.length > 0) {
        setTodayMeeting(res);
      }
    } catch (error) {
      captureException(error);
    }
  };

  const tomorrowMeeting = async payload => {
    try {
      const res = await api("POST", "analytics/meeting/status", payload, true);
      if (res.length > 0) {
        setTomorrowMeeting(res);
      }
    } catch (error) {
      captureException(error);
    }
  };

  const todayVisitors = async payload => {
    try {
      const res = await api("POST", "analytics/visitor", payload, true);
      if (res.length > 0) {
        setTodayVisitors(res);
      }
    } catch (error) {
      captureException(error);
    }
  };

  const dateChangeData = async data => {
    handleDateChange(data);
    let startDate = "";
    let endDate = "";
    if (data) {
      startDate = moment(moment(data))
        .startOf("month")
        .format("MM-DD-YYYY");
      endDate = moment(moment(data))
        .endOf("month")
        .format("MM-DD-YYYY");
    } else {
      startDate = moment()
        .startOf("month")
        .utc()
        .format("MM-DD-YYYY HH:mm");
      endDate = moment()
        .endOf("month")
        .utc()
        .format("MM-DD-YYYY HH:mm");
    }
    const payload = {
      startTime: startDate,
      endTime: endDate
    };
    meetingAnalytics(payload);
  };

  // Today's meeting chart
  const dataList = (todayMeetingData || []).map(l => Number(l.meetingCount));
  const labelsList = (todayMeetingData || []).map(l => l.meetingStatus);

  // Tomorrow's meeting chart
  const tomorrowMeetingDataList = tomorrowMeetingData.map(l =>
    Number(l.meetingCount)
  );
  const tomorrowMeetingLabels = tomorrowMeetingData.map(l => l.meetingStatus);

  // Today's visitors
  const todayVisitorsDataList = todayVisitorsData.map(l =>
    Number(l.visitorCount)
  );
  const todayVisitorsLabels = todayVisitorsData.map(l => l.status);

  const month = totalVisitOfMonth.map(l => moment(l.date).format("DD/MMM"));
  const totalCount = (totalVisitOfMonth || []).map(l => l.totalCount);
  const instantCount = (totalVisitOfMonth || []).map(l => l.instantCount);
  const scheduleCount = (totalVisitOfMonth || []).map(l => l.scheduleCount);

  if (isLoading) {
    return <Loader />;
  } else {
    if (
      user.role === "superadmin" ||
      user.role === "admin" ||
      user.role === "businessAdmin"
    ) {
      return (
        <Page className={classes.root} title="Analytics">
          <Container maxWidth={false}>
            <Grid container spacing={3}>
              {
                <Grid item sm={4} xs={12}>
                  <DoughnutChart
                    title="Today's meeting"
                    dataList={dataList}
                    labelsList={labelsList}
                  />
                </Grid>
              }
              {
                <Grid item sm={4} xs={12}>
                  <DoughnutChart
                    title="Tomorrow's meeting"
                    dataList={tomorrowMeetingDataList}
                    labelsList={tomorrowMeetingLabels}
                  />
                </Grid>
              }
              {
                <Grid item sm={4} xs={12}>
                  <DoughnutChart
                    title="Today's visitors"
                    dataList={todayVisitorsDataList}
                    labelsList={todayVisitorsLabels}
                  />
                </Grid>
              }
              <Grid item md={12} xs={12}>
                <LineChart
                  month={month}
                  selectedDate={selectedDate}
                  dateChangeData={dateChangeData}
                  totalCount={totalCount}
                  instantCount={instantCount}
                  scheduleCount={scheduleCount}
                />
              </Grid>
            </Grid>
          </Container>
        </Page>
      );
    } else if (user.role === "employee" || user.role === "visitor") {
      return (
        <Page className={classes.root} title="Analytics">
          <Container maxWidth={false}>
            <Grid container spacing={3}>
              {dataList.length > 0 && (
                <Grid item sm={4} xs={12}>
                  <DoughnutChart
                    title="Today's meeting"
                    dataList={dataList}
                    labelsList={labelsList}
                  />
                </Grid>
              )}
              {tomorrowMeetingDataList.length > 0 && (
                <Grid item sm={4} xs={12}>
                  <DoughnutChart
                    title="Tomorrow's meeting"
                    dataList={tomorrowMeetingDataList}
                    labelsList={tomorrowMeetingLabels}
                  />
                </Grid>
              )}
              <Grid item md={12} xs={12}>
                <LineChart
                  month={month}
                  selectedDate={selectedDate}
                  dateChangeData={dateChangeData}
                  totalCount={totalCount}
                  instantCount={instantCount}
                  scheduleCount={scheduleCount}
                />
              </Grid>
            </Grid>
          </Container>
        </Page>
      );
    }
  }
};

export default Analytics;
