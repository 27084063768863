import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearToastAction } from "src/actions";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Toast() {
  const tostMessage = useSelector(state => state.tostMessage);
  const dispatch = useDispatch();

  const clearToast = () => {
    dispatch(clearToastAction({}));
  };

  return (
    <Snackbar
      open={tostMessage.status}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={clearToast}
    >
      {tostMessage.noIcon === true ? (
        <Alert severity={tostMessage.type} onClose={clearToast} icon={false}>
          {tostMessage.message}
        </Alert>
      ) : (
        <Alert severity={tostMessage.type} onClose={clearToast}>
          {tostMessage.message}
        </Alert>
      )}
    </Snackbar>
  );
}
