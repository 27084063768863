
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import CheckinRulesList from "./CheckinRulesList";
import Page from "src/components/Page";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));
const CheckinRules = () => {
  const classes = useStyles();
 return (
    <Page className={classes.root} title="Checkin Management">
      <CheckinRulesList />
    </Page>
  );
};

export default CheckinRules;
