import {
  Chip,
  FormControl,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import { captureException } from "@sentry/browser";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { clearToastAction, toastAction } from "../../../../actions";
import api from "../../../../utils/api";
import AddModal from "../AddModal";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end"
  },
  capitalize: {
    textTransform: "capitalize"
  },
  chipButton: {
    paddingLeft: 12,
    paddingRight: 12
  },
  redButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.dark
    },
    "&:disabled": {
      backgroundColor: theme.palette.error.light
    }
  }
}));

const Pantries = props => {
  const { className, reload, ...rest } = props;
  const classes = useStyles();
  const [pantries, setPantries] = useState([]);
  const [organizationList, setOrganizationList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filters, setFilters] = useState({});
  const [currentSort, setCurrentSort] = useState({
    label: "",
    direction: "asc"
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [query, setQuery] = useState({
    search: [],
    sort: { key: "pantry.createdAt", order: "DESC" },
    skip: page * rowsPerPage,
    take: rowsPerPage
  });
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  var columns = [
    {
      label: "name",
      dataType: "text",
      field: "name"
    },
    {
      label: "organization",
      dataType: "text",
      field: "organization.name"
    },
    {
      label: "building",
      dataType: "text",
      field: "building.name"
    },
    {
      label: "is Active",
      dataType: "boolean",
      field: "isActive"
    },
    {
      label: "forward Order",
      dataType: "boolean",
      field: "forwardOrder"
    },
    {
      label: "OrderDelay",
      dataType: "text",
      field: "forwardOrderDelay"
    },
    {
      label: "Receive Forwarded Order",
      dataType: "boolean",
      field: "receiveForwardedOrder"
    }
  ];

  useEffect(() => {
    if (user.role === "businessAdmin") {
      (async () => {
        const organization = await api(
          "POST",
          "organization/find",
          {
            sort: {
              key: "organization.name",
              order: "ASC"
            }
          },
          true
        );
        const res = organization || [];
        setOrganizationList(res);
      })();
    }
  }, []);

  useEffect(() => {
    console.log();
    (async () => {
      handleQuery({ ...query, skip: page * rowsPerPage, take: rowsPerPage });
      setQuery({ ...query, skip: page * rowsPerPage, take: rowsPerPage });
    })();
  }, [page, rowsPerPage, modalOpen, reload]);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const handleChangeFilter = (label, field, value) => {
    let allFilters = filters;

    if (value.trim() !== "") {
      allFilters[label] = value;
      setQuery({
        ...query,
        search: [
          ...query.search.filter(item => item.key !== "pantry." + field),
          {
            key: "pantry." + field,
            value: value,
            comparison: label === "organization" ? "=" : "LIKE"
          }
        ],
        skip: 0
      });
    } else if (allFilters.hasOwnProperty(label)) {
      setQuery({
        ...query,
        search: [
          ...query.search.filter(item => item.key !== "pantry." + field)
        ],
        skip: 0
      });
      delete allFilters[label];
    }

    setFilters({ ...allFilters });
  };

  const handleDeleteChip = key => {
    let allFilters = filters;
    delete allFilters[key];
    var field = key === "phone number" ? "mobile" : key;
    setQuery({
      ...query,
      search: [
        ...query.search.filter(item => item.key !== "pantry." + field)
      ],
      skip: 0
    });
    if (Object.keys(allFilters).length === 0) {
      handleQuery({
        ...query,
        search: []
      });
    }
    setFilters({ ...allFilters });
  };

  const handleEditRow = data => {
    setEditData(data);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSort = label => {
    var direction = "asc";
    var key = "pantry." + (label === "phone number" ? "mobile" : label);
    if (currentSort.label === label && currentSort.direction === "asc")
      direction = "desc";
    const order = direction.toUpperCase();
    handleQuery({ ...query, sort: { key, order }, skip: 0 });
    setQuery({ ...query, sort: { key, order }, skip: 0 });
    setCurrentSort({ label, direction });
  };

  const handleQuery = async customQuery => {
    dispatch(
      toastAction({
        status: true,
        message: "Loading...",
        type: "info",
        noIcon: true
      })
    );
    try {
      const data = customQuery
        ? await api("POST", "pantry/find", customQuery, true)
        : await api("POST", "pantry/find", query, true);
      const countData = customQuery
        ? await api("POST", "pantry/findCount", customQuery, true)
        : await api("POST", "pantry/findCount", query, true);
      setCount(countData);
      setPantries(data);
      dispatch(clearToastAction({}));
    } catch (error) {
      captureException(error);
      dispatch(clearToastAction({}));
    }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <PerfectScrollbar>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((col, i) => (
                  <TableCell className={classes.capitalize} key={i}>
                    <TableSortLabel
                      active={currentSort.label === col.label}
                      direction={currentSort.direction}
                      onClick={() => handleSort(col.label)}
                    >
                      {col.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {columns.map((col, i) => (
                  <TableCell key={i} size="small" style={{ padding: 6 }}>
                    {col.label == "building" || col.label == "is Active" ? (<></>) : col.label !== "organization" ?
                      (
                        <TextField
                          onChange={e => {
                            handleChangeFilter(
                              col.label,
                              col.field,
                              e.target.value
                            );
                          }}
                          size="small"
                          name={col.label}
                          placeholder={`Search by ${col.label}`}
                          variant="outlined"
                          value={filters[col.label] || ""}
                        />
                      ) : (
                        <FormControl
                          variant="outlined"
                          style={{ width: "100%" }}
                          size="small"
                        >
                          <Select
                            id="Organization"
                            value={filters[col.label] || ""}
                            displayEmpty
                            onChange={e =>
                              handleChangeFilter(
                                col.label,
                                "organization",
                                e.target.value
                              )
                            }
                            autoWidth
                          >
                            <MenuItem value="" disabled>
                              Filter by Organization
                            </MenuItem>
                            {organizationList.map((item, i) => (
                              <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                  </TableCell>
                ))}
              </TableRow>
              {Object.keys(filters).length > 0 && (
                <>
                  <TableRow selected>
                    <TableCell colSpan={columns.length} align="center">
                      <Typography variant="h6" component="p">
                        Selected filters: &nbsp;
                      </Typography>
                      {Object.keys(filters).map((filter, i) => {
                        return (
                          <React.Fragment key={i}>
                            <Chip
                              variant="outlined"
                              color="primary"
                              onDelete={() => {
                                handleDeleteChip(filter);
                              }}
                              label={
                                <>
                                  <b className={classes.capitalize}>{filter}</b>
                                  :
                                  {
                                    (filter === "organization") ? organizationList.find(item => item.id == filters[filter]).name :
                                      filters[filter]
                                  }
                                </>
                              }
                            />
                            &nbsp; &nbsp;
                          </React.Fragment>
                        );
                      })}
                      <Chip
                        color="primary"
                        icon={<SearchIcon />}
                        className={classes.chipButton}
                        clickable
                        onClick={() => {
                          handleQuery();
                        }}
                        label={<b>Search</b>}
                      />
                    </TableCell>
                  </TableRow>
                </>
              )}
              {pantries.map(pantry => (
                <TableRow
                  hover
                  key={pantry.id}
                  onClick={() => handleEditRow(pantry)}
                >
                  {columns.map((col, i) => (
                    <TableCell key={i}>
                      {col.dataType === "boolean"
                        ? (pantry[col.field] ? "Yes" : "No") 
                        : col.field
                          .split(".")
                          .reduce((ox, ix) => ox[ix], pantry)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={count}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <AddModal
        open={modalOpen}
        close={closeModal}
        type="pantry"
        pantryData={editData}
      />
    </div>
  );
};

Pantries.propTypes = {
  className: PropTypes.string,
  pantries: PropTypes.array.isRequired,
  reload: PropTypes.bool
};

Pantries.defaultProps = {
  pantries: []
};

export default Pantries;
