import Button from "@material-ui/core/Button";
import { Card } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import { PlusCircle } from "react-feather";
import api from "src/utils/api";
import UserAccessTable from "./UserAccessTable";
import AddAccessModal from "./AddAccessModal";
import AddCredentialsModal from "./AddCredentialsModal";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  button: {
    marginBottom: 10
  },
  content: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0
    }
  }
});

export default function BleAccessControl() {
  const classes = useStyles();
  const [addAccessModalOpen, setAddAccessModalOpen] = useState(false);
  const [addCredentialsModalOpen, setAddCredentialsModalOpen] = useState(false);

  const [userData, setUserData] = useState([]);


  React.useEffect(() => {
    fetch();
  }, [addAccessModalOpen, addCredentialsModalOpen]);

  const fetch = async () => {
    let userAccessData = await api("GET", "ble/user/getAllUsers", null, true);
    setUserData(userAccessData.data.Data);
  };

  return (
    <React.Fragment>
      <Grid
        alignItems="flex-start"
        container
        justify="space-between"
        spacing={3}
        direction="row"
      >
        <Grid item>
          <Typography variant="h4" color="primary">
            BLE Access Control
          </Typography>
        </Grid>
        <Grid item>
          <Grid
            alignItems="flex-start"
            container
            justify="space-between"
            spacing={3}
            direction="row"
          >
            <Grid item>
              <Button
                color="primary"
                className={classes.button}
                startIcon={<PlusCircle />}
                variant="outlined"
                onClick={e => {
                  setAddAccessModalOpen(true);
                }}
              >
                Add New User
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                className={classes.button}
                startIcon={<PlusCircle />}
                variant="outlined"
                onClick={e => {
                  setAddCredentialsModalOpen(true);
                }}
              >
                Add New Credentials
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Card>
        <UserAccessTable dataList={userData} fetch={fetch}/>
      </Card>
      <AddAccessModal
        open={addAccessModalOpen}
        onClose={e => setAddAccessModalOpen(false)}
      />
      <AddCredentialsModal
        open={addCredentialsModalOpen}
        onClose={e => setAddCredentialsModalOpen(false)}
      />
      <br />
    </React.Fragment>
  );
}
