import {
  Grid,
  Badge,
  Typography,
  CircularProgress,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText
} from "@material-ui/core";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/styles";
import "react-phone-input-2/lib/material.css";
import { useDispatch, useSelector } from "react-redux";
import api from "src/utils/api";
import getAvatarStyle from "src/utils/getAvatarStyle";
import getInitials from "src/utils/getInitials";
import { toastAction } from "../../../actions";
import rolename from "src/utils/rolename";

const StyledBadge = withStyles(theme => ({
  badge: {
    right: -48,
    top: 10,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px"
  }
}))(Badge);

function VipRoomAccessDetail(props) {
  const { newModal, setnewModal, modalData, premiseId } = props;
  const [error, setError] = useState("");
  const [vipUser, setVipUser] = useState({});
  const [suggestionList, setSuggestionList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [user, setUser] = useState("");
  const [loading, setloading] = useState(false);
  const [openSuggestion, setopenSuggestion] = useState(false);
  const orgSettings = useSelector(state => state.orgSettings);

  const dispatch = useDispatch();
  const [localStartDate, setLocalStartDate] = useState(
    moment()
      .startOf("day")
      .format("YYYY-MM-DD")
  );
  const [localEndDate, setLocalEndDate] = useState(
    moment()
      .startOf("day")
      .format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment.utc(localStartDate).format("MM-DD-YYYY")
  );
  const [startDate, setStartDate] = useState(
    moment.utc(localStartDate).format("MM-DD-YYYY")
  );

  useEffect(() => {
    if (openSuggestion) {
      (async () => {
        try {
          let dataEmployee = await api(
            "POST",
            "employee/find",
            {
              search: [
                {
                  key: "employee.name",
                  value: searchInput,
                  comparison: "LIKE"
                }
              ],
              sort: {
                key: "employee.name",
                order: "DESC"
              },
              skip: 0,
              take: 10
            },
            true
          );
          let dataStaff = await api(
            "POST",
            "staff/find",
            {
              search: [
                {
                  key: "staff.name",
                  value: searchInput,
                  comparison: "LIKE"
                }
              ],
              sort: {
                key: "staff.name",
                order: "DESC"
              },
              skip: 0,
              take: 10
            },
            true
          );
          // console.log(data);
          setSuggestionList([...dataEmployee, ...dataStaff]);
        } catch (error) {
          console.log(error);
        }
      })();
    } else {
      setSuggestionList([]);
    }
  }, [searchInput]);

  useEffect(() => {
    console.log(modalData);
    if (modalData.id) {
      setLocalStartDate(
        moment
          .utc(new Date(parseInt(modalData.startAccessTs)).toISOString())
          .local()
          .format("YYYY-MM-DD")
      );
      setLocalEndDate(
        moment
          .utc(new Date(parseInt(modalData.endAccessTs)).toISOString())
          .local()
          .format("YYYY-MM-DD")
      );
    } else {
      setLocalStartDate(
        moment()
          .startOf("day")
          .format("YYYY-MM-DD")
      );
      setLocalEndDate(
        moment()
          .startOf("day")
          .format("YYYY-MM-DD")
      );
    }
  }, [newModal]);

  const handleClose = () => {
    setnewModal(false);
    setLocalStartDate(
      moment()
        .startOf("day")
        .format("YYYY-MM-DD")
    );
    setLocalEndDate(
      moment()
        .startOf("day")
        .format("YYYY-MM-DD")
    );
    setVipUser({});
  };

  //call create api
  const handleSubmit = () => {
    setError("");

    let body = {
      userAccessId: vipUser.accessDetails?.id,
      startAccessTs: new Date(startDate).getTime(),
      endAccessTs: new Date(endDate).getTime(),
      premiseId: premiseId
    };

    if (!body.userAccessId) {
      dispatch(
        toastAction({
          status: true,
          message: "Please select user" + "..",
          type: "error",
          noIcon: true
        })
      );
      return;
    }
    if (!body.startAccessTs) {
      dispatch(
        toastAction({
          status: true,
          message: "Please select start time" + "..",
          type: "error",
          noIcon: true
        })
      );
      return;
    }
    if (!body.endAccessTs) {
      dispatch(
        toastAction({
          status: true,
          message: "Please select end time",
          type: "error",
          noIcon: true
        })
      );
      return;
    }
    if (body.endAccessTs < body.startAccessTs) {
      dispatch(
        toastAction({
          status: true,
          message: "Start time should be before end time",
          type: "error",
          noIcon: true
        })
      );
      return;
    }

    dispatch(
      toastAction({
        status: true,
        message: "Adding " + "...",
        type: "info",
        noIcon: true
      })
    );
    (async () => {
      try {
        await api("POST", "premise/", body, true);
        dispatch(
          toastAction({
            status: true,
            message: "Successfully added ",
            type: "success"
          })
        );
        handleClose();
      } catch (error) {
        dispatch(
          toastAction({
            status: true,
            message: error.response.data,
            type: "error"
          })
        );
        error.response && error.response.data && setError(error.response.data);
      }
    })();
  };

  //call delete API
  const handleDelete = () => {
    setError("");

    dispatch(
      toastAction({
        status: true,
        message: "Deleting Access",
        type: "info",
        noIcon: true
      })
    );
    (async () => {
      try {
        await api("DELETE", "premise/" + modalData.id, null, true);
        dispatch(
          toastAction({
            status: true,
            message: "Successfully Deleted ",
            type: "success"
          })
        );
        handleClose();
      } catch (error) {
        dispatch(
          toastAction({
            status: true,
            message: "Something went wrong",
            type: "error"
          })
        );
        error.response && error.response.data && setError(error.response.data);
      }
    })();
  };

  //call update APi
  const handleUpdate = () => {
    setError("");
    let body = {
      startAccessTs: new Date(startDate).getTime(),
      endAccessTs: new Date(endDate).getTime()
    };

    if (body.endAccessTs <= body.startAccessTs) {
      dispatch(
        toastAction({
          status: true,
          message: "Start time should be before end time" + "..",
          type: "info",
          noIcon: true
        })
      );
      return;
    }

    dispatch(
      toastAction({
        status: true,
        message: "Adding " + "...",
        type: "info",
        noIcon: true
      })
    );
    (async () => {
      try {
        await api("PATCH", "premise/" + modalData.id, body, true);
        dispatch(
          toastAction({
            status: true,
            message: "Successfully updated ",
            type: "success"
          })
        );
        handleClose();
      } catch (error) {
        dispatch(
          toastAction({
            status: true,
            message: "Something went wrong",
            type: "error"
          })
        );
        error.response && error.response.data && setError(error.response.data);
      }
    })();
  };

  const [query, setQuery] = useState({
    search: [],
    sort: { key: "premiseAccess.createdAt", order: "DESC" },
    skip: 0,
    take: 10
  });

  return (
    <div>
      <Dialog
        open={newModal}
        onClose={e => {
          setnewModal(false);
        }}
      >
        <DialogTitle>
          <Typography component="h1" variant="h3" color="primary">
            {modalData?.id ? "Edit Access Details" : "Add User Access"}
          </Typography>
          <br />
          {!modalData?.id && (
            <Autocomplete
              open={openSuggestion}
              options={suggestionList}
              noOptionsText={"No user found"}
              onOpen={() => {
                setopenSuggestion(true);
              }}
              onClose={() => {
                setopenSuggestion(false);
              }}
              onChange={(event, newValue) => {
                setVipUser(newValue);
              }}
              getOptionLabel={option => {
                return option.name;
              }}
              renderOption={item => {
                return (
                  <ListItem
                    dense
                    disableGutters
                    className="suggestedParticipant"
                  >
                    <ListItemAvatar>
                      <Avatar style={getAvatarStyle(item.email)}>
                        {getInitials(item.inputValue ? "+" : item.name)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <StyledBadge
                          badgeContent={
                            item.role ? rolename(item.role) : "Staff"
                          }
                          color={item.role ? "primary" : "secondary"}
                        >
                          <Typography variant="h5">{item.name}</Typography>
                        </StyledBadge>
                      }
                      secondary={
                        <Typography variant="body2" color="textSecondary">
                          {item.role &&
                            item[orgSettings.employeePrimaryIdentifier]}
                          {!item.role &&
                            item[orgSettings.staffPrimaryIdentifier]}
                        </Typography>
                      }
                    />
                  </ListItem>
                );
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Search user by Name"
                  fullWidth
                  variant="outlined"
                  value={searchInput}
                  onChange={e => setSearchInput(e.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
          )}
          <TextField
            label="Start Date"
            id="startDate"
            type="date"
            inputProps={{
              min: moment().format("YYYY-MM-DD")
            }}
            margin="normal"
            variant="outlined"
            required
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            value={localStartDate}
            onChange={e => {
              setLocalStartDate(
                moment(e.target.value)
                  .startOf("day")
                  .format("YYYY-MM-DD")
              );
              setStartDate(
                moment.utc(
                  moment(e.target.value)
                    .startOf("day")
                    .format("YYYY-MM-DD")
                )
              );
            }}
          />
          <TextField
            label="End Date"
            id="EndDate"
            type="date"
            inputProps={{
              min: moment().format("YYYY-MM-DD")
            }}
            margin="normal"
            variant="outlined"
            required
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            value={localEndDate}
            onChange={e => {
              setLocalEndDate(
                moment(e.target.value)
                  .endOf("day")
                  .format("YYYY-MM-DD")
              );
              setEndDate(
                moment.utc(
                  moment(e.target.value)
                    .endOf("day")
                    .format("YYYY-MM-DD")
                )
              );
            }}
          />
          <Grid
            container
            direction="row-reverse"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Button
                onClick={modalData?.id ? handleUpdate : handleSubmit}
                variant="contained"
                color="primary"
              >
                {modalData?.id ? "Update" : "Add"}
              </Button>
            </Grid>
            {modalData?.id && (
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button onClick={handleClose} variant="outlined">
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
      </Dialog>
    </div>
  );
}

export default VipRoomAccessDetail;
