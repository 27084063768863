import { Grid, makeStyles, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { X } from "react-feather";
import "react-phone-input-2/lib/material.css";
import { useSelector } from "react-redux";
import api from "src/utils/api";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

const initialState = {
  name: "",
  logo: "",
  floorIds: []
};

const OrganizationModal = props => {
  const classes = useStyles();
  const {
    open,
    close,
    organizationCount,
    addOrganizationsToArray,
    editOrganizationsToArray,
    activeData
  } = props;
  const [formData, setFormData] = useState(initialState);
  const [isLoading, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [floorList, setFloorList] = useState([]);
  const [selectableFloorList, setSelectableFloorList] = useState([]);
  const [buildingsSelected, setBuildingsSelected] = useState([]);

  const user = useSelector(state => state.user);

  const handleClose = () => {
    close();
  };

  React.useEffect(() => {
    setSelectableFloorList(
      floorList.filter(floor =>
        buildingsSelected.map(item => item.id).includes(floor.building.id)
      )
    );
  }, [floorList, buildingsSelected]);

  React.useEffect(() => {
    (async () => {
      try {
        const floorL = await api("POST", "floor/find", null, true);
        // All the floors that exists
        setFloorList(floorL);

        // Buildings that the floors belongs to
        let allBuildings = floorL.map(floor => floor.building);

        // All the buildingids within the organization. activeData.building is actually the floors
        let selectedBuildingIds = activeData.building.map(
          item => item.buildingId
        );

        let selectedBuil = allBuildings
          .filter(item => selectedBuildingIds.includes(item.id))
          // filter duplicates
          .filter(
            (value, index, self) =>
              self.indexOf(self.find(item => item.id === value.id)) === index
          );
        console.log({
          activeData,
          allBuildings,
          selectedBuildingIds,
          selectedBuil
        });
        setBuildingsSelected(selectedBuil);
        if (activeData.id) {
          setFormData({
            ...activeData,
            floorIds: activeData.building
              .filter(item => item.building && item.floor)
              .map(item => item.floorId)
          });
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleAction = () => {
    if (activeData.id) {
      handleUpdate();
    } else {
      handleAdd();
    }
  };

  const handleAdd = async () => {
    if (formData.name && formData.logo) {
      setLoader(true);
      try {
        const data = await api(
          "POST",
          "organization",
          {
            name: formData.name,
            logo: formData.logo,
            timezone: "+05:30",
            timezoneName: "Asia/Kolkata",
            floorIds: formData.floorIds
          },
          true
        );

        if (data.id) {
          handleClose();
          organizationCount();
          addOrganizationsToArray(data);
          setLoader(false);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          setError(error.response.data);
        }
        setLoader(false);
      }
    } else {
      setError("Please fill all the fields");
    }
  };

  const handleUpdate = async () => {
    if (formData.name) {
      setLoader(true);
      try {
        const data = await api(
          "PATCH",
          `organization/${activeData.id}`,
          {
            name: formData.name,
            logo: formData.logo,
            floorIds: formData.floorIds
          },
          true
        );
        if (data.id) {
          handleClose();
          editOrganizationsToArray(data);
          setLoader(false);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          setError(error.response.data);
        }
        setLoader(false);
      }
    } else {
      setError("Please fill all the fields");
    }
  };

  const handleInputChange = event => {
    event.persist();
    const { name, value } = event.target;
    if (name === "number" || name === "subLocationId") {
      const re = /^[0-9\b]+$/;
      if (value === "" || re.test(value)) {
        setFormData(formData => ({
          ...formData,
          [name]: value
        }));
      }
    } else {
      setFormData(formData => ({
        ...formData,
        [name]: value
      }));
    }
  };

  const onBuildingChange = (event, values) => {
    setBuildingsSelected(values);
  };

  const onFloorChange = (event, values) => {
    setFormData(formData => ({
      ...formData,
      floorIds: values.map(item => item.id)
    }));
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle disableTypography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography component="h1" variant="h3" color="primary">
              {activeData.id ? "Edit" : "Add"} Organizations
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {error !== "" && (
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setError("");
                  }}
                >
                  <X />
                </Button>
              }
            >
              {error}
            </Alert>
          )}
          <TextField
            label="Name"
            variant="outlined"
            type="email"
            margin="normal"
            required
            fullWidth
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
          <TextField
            label="Logo URL"
            variant="outlined"
            type="text"
            margin="normal"
            required
            fullWidth
            name="logo"
            value={formData.logo}
            onChange={handleInputChange}
          />

          <Grid item xs={12}>
            <br />
            <Autocomplete
              id="building"
              options={floorList
                .map(item => item.building)
                .filter((value, index, self) => {
                  return (
                    self.indexOf(self.find(item => item.id === value.id)) ===
                    index
                  );
                })}
              getOptionLabel={option => {
                return option.name;
              }}
              onChange={onBuildingChange}
              value={buildingsSelected}
              multiple
              disableClearable
              renderInput={params => (
                <TextField
                  {...params}
                  label="Buildings"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <br />
            <Autocomplete
              id="floor"
              options={selectableFloorList}
              getOptionLabel={option => option?.name ?? ""}
              onChange={onFloorChange}
              value={formData.floorIds.map(id =>
                floorList.find(item => item.id === id)
              )}
              multiple
              disableClearable
              renderInput={params => (
                <TextField
                  {...params}
                  label="Floors"
                  fullWidth
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid
            container
            direction="row-reverse"
            alignItems="center"
            spacing={3}
            className={classes.marginNormal}
          >
            <Grid item>
              <Button
                onClick={handleAction}
                disabled={isLoading}
                variant="contained"
                color="primary"
              >
                {isLoading ? "Loading..." : activeData.id ? "Update" : "Add"}
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleClose} variant="contained">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default OrganizationModal;
