import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import { captureException } from "@sentry/browser";
import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import "react-phone-input-2/lib/material.css";
import { useDispatch, useSelector } from "react-redux";
import { clearToastAction, toastAction } from "../../../../actions";
import api from "../../../../utils/api";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

const AddModal = props => {
  const { open, close, type, pantryItemData } = props;
  const classes = useStyles();
  const user = useSelector(state => {
    return state.user;
  });
  const org = useSelector(state => {
    return state.org;
  });

  const [name, setName] = useState("");
  const [pantryList, setPantryList] = useState([]);
  const [pantry, setPantry] = useState("");
  const [role, setRole] = useState("pantry");
  const [error, setError] = useState("");

  const orgSettings = useSelector(state => {
    return state.orgSettings;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        if (user.role === "businessAdmin") {
          const pantries = await api(
            "POST",
            "pantry/find",
            {
              sort: {
                key: "pantry.name",
                order: "ASC"
              }
            },
            true
          );
          setPantryList(pantries);
        }
      } catch (error) {
        captureException(error);
      }
    })();
  }, [open]);

  useEffect(() => {
    if (pantryItemData && pantryItemData.id) {
      setName(pantryItemData.name || "");
    }
  }, [pantryItemData]);

  const handleClose = () => {
    setName("");
    setError("");
    close();
  };

  const handleAdd = () => {
    setError("");
    var body;
    body = {
      name
    }; 
    for (const [key, value] of Object.entries(body)) {
      if (value === "") delete body[key];
    }
    dispatch(
      toastAction({
        status: true,
        message: "Adding " + type + "...",
        type: "info",
        noIcon: true
      })
    );
    (async () => {
      try {
        await api("POST", "pantry/item", body, true);
        dispatch(
          toastAction({
            status: true,
            message: "Successfully added " + type,
            type: "success"
          })
        );
        handleClose();
      } catch (error) {
        dispatch(
          toastAction({
            status: true,
            message: "Something went wrong ",
            type: "error"
          })
        );
        error.response && error.response.data && setError(error.response.data);
      }
    })();
  };

  const handleUpdate = () => {
    dispatch(
      toastAction({ status: true, message: "Updating user", type: "success" })
    );
    setError("");
    var body;
    if (type === "pantry") {
      body = {
        name,
        pantryId: pantry
      };
    } 
    for (const [key, value] of Object.entries(body)) {
      if (value === "") delete body[key];
    }

    (async () => {
      try {
        await api("PATCH", `pantry/item/${pantryItemData.id}`, body, true);
        dispatch(clearToastAction({}));
        handleClose();
      } catch (error) {
        error.response && error.response.data && setError(error.response.data);
        dispatch(clearToastAction({}));
      }
    })();
  };
  const handleDelete = () => {
    setError("");
    const body =
      type === "pantry"
        ? {
            isActive: !pantryItemData.isActive
          }
        : {
            isActive: false,
            organizationId: user.organizationId
          };
    (async () => {
      try {
        await api("PATCH", `pantry/item/${pantryItemData.id}`, body, true);
        handleClose();
      } catch (error) {
        captureException(error);
        error.response && error.response.data && setError(error.response.data);
      }
    })();
  };

  return (
    
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle disableTypography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography component="h1" variant="h3" color="primary">
              {pantryItemData ? "Edit" : "Add new "} {type} {"item"}
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {!pantryItemData && (
            <DialogContentText>
              To add a new Item fill up the following details and click on the
              'Add' button. We will generate a password and notify the new{" "}
              {type} item .
            </DialogContentText>
          )}
          {error !== "" && (
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setError("");
                  }}
                >
                  <X />
                </Button>
              }
            >
              {error}
            </Alert>
          )}
          <TextField
            label="Item name"
            variant="outlined"
            type="text"
            margin="normal"
            required
            fullWidth
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
          />
         {/*  <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              className={classes.marginNormal}
            >
              <InputLabel id="pantry"> Pantry </InputLabel>
              <Select
                labelId="pantry"
                id="pantry"
                label="pantry "
                value={pantry}
                displayEmpty
                onChange={e => {
                  setPantry(e.target.value);
                }}
                autoWidth
              >
                {pantryList.map(pantry => {
                  return (
                    <MenuItem value={pantry.id} key={pantry.id}>
                      {pantry.name}
                    </MenuItem>
                  );
                })}
              </Select>
          </FormControl> */}

          <Grid
            container
            direction="row-reverse"
            alignItems="center"
            spacing={3}
            className={classes.marginNormal}
          >
            {!pantryItemData ? (
              <Grid item>
                <Button onClick={handleAdd} variant="contained" color="primary">
                  Add
                </Button>
              </Grid>
            ) : (
              <>
                <Grid item>
                  <Button
                    onClick={handleUpdate}
                    variant="contained"
                    color="primary"
                  >
                    Update
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleDelete}
                    variant="contained"
                    color="secondary"
                  >
                    {type === "pantry" && !pantryItemData.isActive
                      ? "Restore"
                      : "Archive"}
                  </Button>
                </Grid>
              </>
            )}
            <Grid item>
              <Button onClick={handleClose} variant="contained">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddModal;
