import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";

function AddAccessModal(props) {
  const { open, onClose, data } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={handleClose}>
      <DialogTitle id="alert-dialog-title"> User Credentials</DialogTitle>
      <DialogContent>
        <Box border={1} borderColor="grey.300" borderRadius={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Facility Code</TableCell>
                <TableCell align="center">Card Id</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(card => (
                <TableRow key={card.tDesc}>
                  <TableCell align="center">{card.iSiteCode}</TableCell>
                  <TableCell align="center">{card.iCardCode}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddAccessModal;
