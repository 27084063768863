import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState } from "react";
import { PlusCircle } from "react-feather";
import api from "src/utils/api";
import Loader from "../../../utils/loader";
import ConfirmationDialog from "../common/ConfirmDialog";
import RoomModal from "./RoomModal";

const useStyles = makeStyles({
  root: {},
  table: {
    minWidth: 650
  },
  button: {
    marginBottom: 10
  }
});

export default function Rooms() {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setLoader] = useState(true);
  const [isLoadingDelete, setDeleteLoader] = useState(false);
  const [error, setError] = useState("");
  const [floorList, setFloorList] = useState([]);
  const [activeData, setData] = useState({});
  const [buildingList, setBuildingList] = useState([]);
  const [roomsList, setRoomList] = useState([]);
  const [countData, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  React.useEffect(() => {
    (async () => {
      const countPayload = {
        search: [
          {
            key: "room.allowMeeting",
            value: "",
            comparison: "="
          }
        ],
        sort: { key: "room.createdAt", order: "ASC" },
        skip: 0,
        take: 10
      };
      const building = await api("POST", "building/find", null, true);
      const floor = await api("POST", "floor/find", null, true);
      const room = await api("POST", "room/findCount", countPayload, true);
      Promise.all([building, floor, room])
        .then(data => {
          setBuildingList(data[0]);
          setFloorList(data[1]);
          setCount(data[2]);
        })
        .catch(error => {
          setLoader(false);
        });
    })();
  }, []);

  const roomCount = async () => {
    try {
      const countPayload = {
        search: [
          {
            key: "room.allowMeeting",
            value: "",
            comparison: "="
          }
        ],
        sort: { key: "room.createdAt", order: "ASC" },
        skip: 0,
        take: 10
      };
      const res = await api("POST", "room/findCount", countPayload, true);
      setCount(res);
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchData();
  }, [rowsPerPage, page]);

  const fetchData = async () => {
    setLoader(true);
    try {
      const payload = {
        search: [
          {
            key: "room.allowMeeting",
            value: "",
            comparison: "="
          }
        ],
        sort: {
          key: "room.createdAt",
          order: "ASC"
        },
        skip: page * rowsPerPage,
        take: rowsPerPage
      };
      const building = await api("POST", "room/find", payload, true);
      const res = building || [];
      setRoomList(res);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const editRoom = data => {
    setData(data);
    setIsOpen(true);
  };

  const editRoomToArray = () => {
    fetchData();
  };

  const handleModal = () => {
    setData({});
    setIsOpen(true);
  };

  const deleteRoom = async () => {
    setDeleteLoader(true);
    try {
      const room = await api(
        "DELETE",
        `room/${activeData.id}`,
        null,
        true,
        true
      );
      if (room.id) {
        roomCount();
        fetchData();
        setOpen(false);
        setError("");
      }
      setDeleteLoader(false);
    } catch (error) {
      setError("Some things went wrong please try again");
      setDeleteLoader(false);
    }
  };

  const conformRoomModal = row => {
    if (row && row.id) {
      setOpen(true);
      setData(row);
    } else {
      setData({});
      setOpen(false);
    }
  };

  const DialogButtons = (
    <React.Fragment>
      <Button variant="outlined" color="primary" onClick={() => setOpen(false)}>
        NO
      </Button>
      <Button
        variant="contained"
        disabled={isLoadingDelete}
        color="primary"
        onClick={() => deleteRoom()}
      >
        {isLoadingDelete ? "Loading..." : "Yes delete"}
      </Button>
    </React.Fragment>
  );

  if (isLoading) {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Loader />
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Grid
        alignItems="flex-start"
        container
        justify="space-between"
        spacing={3}
        direction="row"
      >
        <Grid item>
          <Typography variant="h4" color="primary">
            Meeting Rooms
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            className={classes.button}
            startIcon={<PlusCircle />}
            variant="outlined"
            onClick={handleModal}
          >
            Add Room
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Room Name</TableCell>
              <TableCell align="center">Floor</TableCell>
              <TableCell align="center">Building</TableCell>
              <TableCell align="center">Occupancy</TableCell>
              <TableCell align="center">Minimum Occupancy</TableCell>
              <TableCell align="center">Maximum Occupancy</TableCell>
              <TableCell align="center">Type</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roomsList.map((row, ind) => (
              <TableRow key={ind}>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.floor.name}</TableCell>
                <TableCell align="center">{row.building.name}</TableCell>
                <TableCell align="center">{row.occupancy || "-"}</TableCell>
                <TableCell align="center">{row.minOccupancy || "-"}</TableCell>
                <TableCell align="center">{row.maxOccupancy || "-"}</TableCell>
                <TableCell align="center">{row.type || "-"}</TableCell>
                <TableCell align="center">
                  <IconButton aria-label="edit" onClick={() => editRoom(row)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => conformRoomModal(row)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={countData}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      {isOpen && (
        <RoomModal
          open={isOpen}
          roomCount={roomCount}
          fetchData={fetchData}
          activeData={activeData}
          editRoomToArray={editRoomToArray}
          buildingList={buildingList}
          floorList={floorList}
          close={() => setIsOpen(false)}
        />
      )}
      {open && (
        <ConfirmationDialog
          open={open}
          error={error}
          handleClose={() => setOpen(false)}
          title={"Delete Account"}
          content={"Are you sure you want to delete room?"}
          btnActions={DialogButtons}
        />
      )}
    </React.Fragment>
  );
}
