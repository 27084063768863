import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import { UserPlus } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Page from "src/components/Page";
import {
  login,
  business,
  organizationSettings,
  organization
} from "../../actions";
import api from "../../utils/api";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%"
  },
  container: {
    padding: theme.spacing(3),
    backgroundColor: "#fff"
  },
  img: {
    padding: theme.spacing(3)
  }
}));

const RegisterView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const org = useSelector(state => state.org);
  const orgSettings = useSelector(state => state.orgSettings);
  const businessOnState = useSelector(state => state.business);
  const [domain, setDomain] = useState(
    businessOnState && businessOnState.id ? businessOnState.domain : ""
  );
  const [companyList, setCompanyList] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    (async () => {
      try {
        if (businessOnState) {
          setDomain(businessOnState.domain);
          const orgList = await api(
            "GET",
            "organization/getOrganization/" + businessOnState.id,
            null,
            false
          );
          setCompanyList(orgList);
          if (orgList.length === 1) {
            dispatch(organization(orgList[0]));
          }
        }
      } catch (error) {
        setError("Could not fetch organization list in this for this account");
      }
    })();
  }, [businessOnState]);

  const handleSubmit = async e => {

  };

  const findDomainFromInput = async () => {
    try {
      const validateDomain = await api(
        "GET",
        "business/searchByDomain/" + domain,
        null,
        false
      );
      if (validateDomain.id) {
        const orgSettingsNew = await api(
          "GET",
          "business/getBusinessSettings/" + validateDomain.id,
          null,
          true
        );
        dispatch(organizationSettings(orgSettingsNew));
        dispatch(business(validateDomain));
        setError("");
      } else {
        setError("Could not find account for this domain");
      }
    } catch (error) {
      setError("Could not find account for this domain");
    }
  };

  const handleSelectOrganization = async orgId => {
    dispatch(organization(companyList.find(item => (item.id = orgId))));
  };

  return (
    <Page className={classes.root} title="Register">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="flexStart"
        className={classes.container}
      >
        <Grid container direction="row" spacing={3} alignItems="center">
          <Grid item md={8} xs={12}>
            <Container maxWidth="md">
              <img
                src="/static/banner.png"
                style={{ maxWidth: "100%", padding: "60px 0" }}
                className={classes.img}
                alt="banner"
              />
            </Container>
          </Grid>
          <Grid item md={4} xs={12}>
            {businessOnState && businessOnState.id && (
              <Box mb={3}>
                <Box>
                  <img
                    src={businessOnState.logo}
                    style={{ maxWidth: "100%" }}
                    className={classes.img}
                    alt="logo"
                  />
                </Box>
                <Typography
                  color="primary"
                  variant="h1"
                  component="span"
                  style={{ fontWeight: 900 }}
                >
                  Welcome to {businessOnState.name}
                </Typography>
              </Box>
            )}

            <form onSubmit={handleSubmit}>
              <Box mb={1}>
                <Typography color="textPrimary" variant="h2">
                  Book a meeting
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  Fillup the following form to book a meeting.
                </Typography>
              </Box>

              {error !== "" && (
                <Alert
                  onClose={() => {
                    setError("");
                  }}
                  severity="error"
                >
                  {error}
                </Alert>
              )}

              {(!businessOnState || !businessOnState.id) && (
                <TextField
                  fullWidth
                  label="Domain"
                  margin="normal"
                  name="domain"
                  id="domain"
                  onBlur={findDomainFromInput}
                  onChange={e => setDomain(e.target.value)}
                  type="text"
                  value={domain}
                  variant="outlined"
                />
              )}
              {companyList.length > 1 && (
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  margin="normal"
                >
                  <InputLabel id="company"> Select company</InputLabel>
                  <Select
                    labelId="company"
                    id="company"
                    label="Select company"
                    displayEmpty
                    onChange={e => {
                      handleSelectOrganization(e.target.value);
                    }}
                    autoWidth
                  >
                    {companyList.map(item => (
                      <MenuItem value={item.id} key={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {businessOnState?.id &&
                orgSettings.visitorPrimaryIdentifier === "mobile" && (
                  <PhoneInput
                    country={org?.countrycode ?? "in"}
                    enableSearch
                    searchClass="MuiOutlinedInput-input MuiOutlinedInput-inputMarginDense"
                    preferredCountries={["sg", "in", "us"]}
                    inputStyle={{ width: "100%" }}
                    specialLabel="Phone number"
                    countryCodeEditable={false}
                    onChange={(value, country) => {
                      setCountryCode("+" + country.dialCode);
                      setMobile(value.replace(country.dialCode, ""));
                    }}
                  />
                )}

              {/* <TextField
                fullWidth
                label="Full name"
                margin="normal"
                name="name"
                id="name"
                onChange={e => setName(e.target.value)}
                type="password"
                value={name}
                variant="outlined"
              />
              <TextField
                fullWidth
                label="Email Address"
                margin="normal"
                name="email"
                id="email"
                onChange={e => setEmail(e.target.value.trim())}
                type="text"
                value={email}
                variant="outlined"
              />

              <TextField
                fullWidth
                label="Password"
                margin="normal"
                name="password"
                id="password"
                onChange={e => setPassword(e.target.value)}
                type="password"
                value={password}
                variant="outlined"
              /> */}
              <Box my={2}>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  id="submit"
                  type="submit"
                  variant="contained"
                >
                  Continue
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default RegisterView;
