import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  TextField,
  Typography,
  FormLabel,
  RadioGroup,
  Radio,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import { captureException } from "@sentry/react";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { organizationSettings } from "../../../actions";
import api from "../../../utils/api";
import Loader from "../../../utils/loader";

const useStyles = makeStyles({
  root: {},
  item: {
    display: "flex",
    flexDirection: "column"
  }
});

const countries = [
  { code: "AF", name: "Afghanistan" },
  { code: "AX", name: "Åland Islands" },
  { code: "AL", name: "Albania" },
  { code: "DZ", name: "Algeria" },
  { code: "AS", name: "American Samoa" },
  { code: "AD", name: "Andorra" },
  { code: "AO", name: "Angola" },
  { code: "AI", name: "Anguilla" },
  { code: "AQ", name: "Antarctica" },
  { code: "AG", name: "Antigua and Barbuda" },
  { code: "AR", name: "Argentina" },
  { code: "AM", name: "Armenia" },
  { code: "AW", name: "Aruba" },
  { code: "AU", name: "Australia" },
  { code: "AT", name: "Austria" },
  { code: "AZ", name: "Azerbaijan" },
  { code: "BS", name: "Bahamas" },
  { code: "BH", name: "Bahrain" },
  { code: "BD", name: "Bangladesh" },
  { code: "BB", name: "Barbados" },
  { code: "BY", name: "Belarus" },
  { code: "BE", name: "Belgium" },
  { code: "BZ", name: "Belize" },
  { code: "BJ", name: "Benin" },
  { code: "BM", name: "Bermuda" },
  { code: "BT", name: "Bhutan" },
  { code: "BO", name: "Bolivia" },
  { code: "BQ", name: "Bonaire, Sint Eustatius and Saba" },
  { code: "BA", name: "Bosnia and Herzegovina" },
  { code: "BW", name: "Botswana" },
  { code: "BV", name: "Bouvet Island" },
  { code: "BR", name: "Brazil" },
  { code: "IO", name: "British Indian Ocean Territory" },
  { code: "BN", name: "Brunei Darussalam" },
  { code: "BG", name: "Bulgaria" },
  { code: "BF", name: "Burkina Faso" },
  { code: "BI", name: "Burundi" },
  { code: "KH", name: "Cambodia" },
  { code: "CM", name: "Cameroon" },
  { code: "CA", name: "Canada" },
  { code: "CV", name: "Cape Verde" },
  { code: "KY", name: "Cayman Islands" },
  { code: "CF", name: "Central African Republic" },
  { code: "TD", name: "Chad" },
  { code: "CL", name: "Chile" },
  { code: "CN", name: "China" },
  { code: "CX", name: "Christmas Island" },
  { code: "CC", name: "Cocos (Keeling) Islands" },
  { code: "CO", name: "Colombia" },
  { code: "KM", name: "Comoros" },
  { code: "CG", name: "Congo, Republic of the (Brazzaville)" },
  { code: "CD", name: "Congo, the Democratic Republic of the (Kinshasa)" },
  { code: "CK", name: "Cook Islands" },
  { code: "CR", name: "Costa Rica" },
  { code: "CI", name: "Côte d'Ivoire, Republic of" },
  { code: "HR", name: "Croatia" },
  { code: "CU", name: "Cuba" },
  { code: "CW", name: "Curaçao" },
  { code: "CY", name: "Cyprus" },
  { code: "CZ", name: "Czech Republic" },
  { code: "DK", name: "Denmark" },
  { code: "DJ", name: "Djibouti" },
  { code: "DM", name: "Dominica" },
  { code: "DO", name: "Dominican Republic" },
  { code: "EC", name: "Ecuador" },
  { code: "EG", name: "Egypt" },
  { code: "SV", name: "El Salvador" },
  { code: "GQ", name: "Equatorial Guinea" },
  { code: "ER", name: "Eritrea" },
  { code: "EE", name: "Estonia" },
  { code: "ET", name: "Ethiopia" },
  { code: "FK", name: "Falkland Islands (Islas Malvinas)" },
  { code: "FO", name: "Faroe Islands" },
  { code: "FJ", name: "Fiji" },
  { code: "FI", name: "Finland" },
  { code: "FR", name: "France" },
  { code: "GF", name: "French Guiana" },
  { code: "PF", name: "French Polynesia" },
  { code: "TF", name: "French Southern and Antarctic Lands" },
  { code: "GA", name: "Gabon" },
  { code: "GM", name: "Gambia, The" },
  { code: "GE", name: "Georgia" },
  { code: "DE", name: "Germany" },
  { code: "GH", name: "Ghana" },
  { code: "GI", name: "Gibraltar" },
  { code: "GR", name: "Greece" },
  { code: "GL", name: "Greenland" },
  { code: "GD", name: "Grenada" },
  { code: "GP", name: "Guadeloupe" },
  { code: "GU", name: "Guam" },
  { code: "GT", name: "Guatemala" },
  { code: "GG", name: "Guernsey" },
  { code: "GN", name: "Guinea" },
  { code: "GW", name: "Guinea-Bissau" },
  { code: "GY", name: "Guyana" },
  { code: "HT", name: "Haiti" },
  { code: "HM", name: "Heard Island and McDonald Islands" },
  { code: "VA", name: "Holy See (Vatican City)" },
  { code: "HN", name: "Honduras" },
  { code: "HK", name: "Hong Kong" },
  { code: "HU", name: "Hungary" },
  { code: "IS", name: "Iceland" },
  { code: "IN", name: "India" },
  { code: "ID", name: "Indonesia" },
  { code: "IR", name: "Iran, Islamic Republic of" },
  { code: "IQ", name: "Iraq" },
  { code: "IE", name: "Ireland" },
  { code: "IM", name: "Isle of Man" },
  { code: "IL", name: "Israel" },
  { code: "IT", name: "Italy" },
  { code: "JM", name: "Jamaica" },
  { code: "JP", name: "Japan" },
  { code: "JE", name: "Jersey" },
  { code: "JO", name: "Jordan" },
  { code: "KZ", name: "Kazakhstan" },
  { code: "KE", name: "Kenya" },
  { code: "KI", name: "Kiribati" },
  { code: "KP", name: "Korea, Democratic People's Republic of" },
  { code: "KR", name: "Korea, Republic of" },
  { code: "KW", name: "Kuwait" },
  { code: "KG", name: "Kyrgyzstan" },
  { code: "LA", name: "Laos" },
  { code: "LV", name: "Latvia" },
  { code: "LB", name: "Lebanon" },
  { code: "LS", name: "Lesotho" },
  { code: "LR", name: "Liberia" },
  { code: "LY", name: "Libya" },
  { code: "LI", name: "Liechtenstein" },
  { code: "LT", name: "Lithuania" },
  { code: "LU", name: "Luxembourg" },
  { code: "MO", name: "Macao" },
  { code: "MK", name: "Macedonia, Republic of" },
  { code: "MG", name: "Madagascar" },
  { code: "MW", name: "Malawi" },
  { code: "MY", name: "Malaysia" },
  { code: "MV", name: "Maldives" },
  { code: "ML", name: "Mali" },
  { code: "MT", name: "Malta" },
  { code: "MH", name: "Marshall Islands" },
  { code: "MQ", name: "Martinique" },
  { code: "MR", name: "Mauritania" },
  { code: "MU", name: "Mauritius" },
  { code: "YT", name: "Mayotte" },
  { code: "MX", name: "Mexico" },
  { code: "FM", name: "Micronesia, Federated States of" },
  { code: "MD", name: "Moldova" },
  { code: "MC", name: "Monaco" },
  { code: "MN", name: "Mongolia" },
  { code: "ME", name: "Montenegro" },
  { code: "MS", name: "Montserrat" },
  { code: "MA", name: "Morocco" },
  { code: "MZ", name: "Mozambique" },
  { code: "MM", name: "Myanmar" },
  { code: "NA", name: "Namibia" },
  { code: "NR", name: "Nauru" },
  { code: "NP", name: "Nepal" },
  { code: "NL", name: "Netherlands" },
  { code: "NC", name: "New Caledonia" },
  { code: "NZ", name: "New Zealand" },
  { code: "NI", name: "Nicaragua" },
  { code: "NE", name: "Niger" },
  { code: "NG", name: "Nigeria" },
  { code: "NU", name: "Niue" },
  { code: "NF", name: "Norfolk Island" },
  { code: "MP", name: "Northern Mariana Islands" },
  { code: "NO", name: "Norway" },
  { code: "OM", name: "Oman" },
  { code: "PK", name: "Pakistan" },
  { code: "PW", name: "Palau" },
  { code: "PS", name: "Palestine, State of" },
  { code: "PA", name: "Panama" },
  { code: "PG", name: "Papua New Guinea" },
  { code: "PY", name: "Paraguay" },
  { code: "PE", name: "Peru" },
  { code: "PH", name: "Philippines" },
  { code: "PN", name: "Pitcairn" },
  { code: "PL", name: "Poland" },
  { code: "PT", name: "Portugal" },
  { code: "PR", name: "Puerto Rico" },
  { code: "QA", name: "Qatar" },
  { code: "RE", name: "Réunion" },
  { code: "RO", name: "Romania" },
  { code: "RU", name: "Russian Federation" },
  { code: "RW", name: "Rwanda" },
  { code: "BL", name: "Saint Barthélemy" },
  { code: "SH", name: "Saint Helena, Ascension and Tristan da Cunha" },
  { code: "KN", name: "Saint Kitts and Nevis" },
  { code: "LC", name: "Saint Lucia" },
  { code: "MF", name: "Saint Martin" },
  { code: "PM", name: "Saint Pierre and Miquelon" },
  { code: "VC", name: "Saint Vincent and the Grenadines" },
  { code: "WS", name: "Samoa" },
  { code: "SM", name: "San Marino" },
  { code: "ST", name: "Sao Tome and Principe" },
  { code: "SA", name: "Saudi Arabia" },
  { code: "SN", name: "Senegal" },
  { code: "RS", name: "Serbia" },
  { code: "SC", name: "Seychelles" },
  { code: "SL", name: "Sierra Leone" },
  { code: "SG", name: "Singapore" },
  { code: "SX", name: "Sint Maarten (Dutch part)" },
  { code: "SK", name: "Slovakia" },
  { code: "SI", name: "Slovenia" },
  { code: "SB", name: "Solomon Islands" },
  { code: "SO", name: "Somalia" },
  { code: "ZA", name: "South Africa" },
  { code: "GS", name: "South Georgia and South Sandwich Islands" },
  { code: "SS", name: "South Sudan" },
  { code: "ES", name: "Spain" },
  { code: "LK", name: "Sri Lanka" },
  { code: "SD", name: "Sudan" },
  { code: "SR", name: "Suriname" },
  { code: "SZ", name: "Swaziland" },
  { code: "SE", name: "Sweden" },
  { code: "CH", name: "Switzerland" },
  { code: "SY", name: "Syrian Arab Republic" },
  { code: "TW", name: "Taiwan" },
  { code: "TJ", name: "Tajikistan" },
  { code: "TZ", name: "Tanzania, United Republic of" },
  { code: "TH", name: "Thailand" },
  { code: "TL", name: "Timor-Leste" },
  { code: "TG", name: "Togo" },
  { code: "TK", name: "Tokelau" },
  { code: "TO", name: "Tonga" },
  { code: "TT", name: "Trinidad and Tobago" },
  { code: "TN", name: "Tunisia" },
  { code: "TR", name: "Turkey" },
  { code: "TM", name: "Turkmenistan" },
  { code: "TC", name: "Turks and Caicos Islands" },
  { code: "TV", name: "Tuvalu" },
  { code: "UG", name: "Uganda" },
  { code: "UA", name: "Ukraine" },
  { code: "AE", name: "United Arab Emirates" },
  { code: "GB", name: "United Kingdom" },
  { code: "US", name: "United States" },
  { code: "UM", name: "United States Minor Outlying Islands" },
  { code: "UY", name: "Uruguay" },
  { code: "UZ", name: "Uzbekistan" },
  { code: "VU", name: "Vanuatu" },
  { code: "VE", name: "Venezuela, Bolivarian Republic of" },
  { code: "VN", name: "Vietnam" },
  { code: "VG", name: "Virgin Islands, British" },
  { code: "VI", name: "Virgin Islands, U.S." },
  { code: "WF", name: "Wallis and Futuna" },
  { code: "EH", name: "Western Sahara" },
  { code: "YE", name: "Yemen" },
  { code: "ZM", name: "Zambia" },
  { code: "ZW", name: "Zimbabwe" }
];

const User = ({ className, ...rest }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [newOrgSettings, setNewOrgSettings] = useState({});
  const user = useSelector(state => state.user);
  const orgSettings = useSelector(state => state.orgSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(organizationSettings({ ...orgSettings, ...newOrgSettings }));
  }, [loading]);
  useEffect(() => {
    (async () => {
      try {
        const newdata = await api(
          "POST",
          "business/getBusinessSettingsFilteredByKeys/" + user.businessId,
          {
            keys: [
              "searchInstantMeetingUser",
              "searchInstantMeetingUserFields",
              "employeePrimaryIdentifier",
              "employeePrimaryCommunication",
              "employeeRequiredFields",
              "visitorPrimaryIdentifier",
              "visitorPrimaryCommunication",
              "visitorRequiredFields",
              "visitorAdditionalFields",
              "staffPrimaryIdentifier",
              "staffPrimaryCommunication",
              "staffRequiredFields",
              "masterOtp",
              "mobileCountryCode"
            ]
          },
          true
        );
        setNewOrgSettings(newdata);
        setLoading(false);
      } catch (error) {
        captureException(Error);
      }
    })();
  }, []);

  useEffect(() => {
    if (newOrgSettings.employeePrimaryIdentifier) {
      setNewOrgSettings({
        ...newOrgSettings,
        employeeRequiredFields: JSON.stringify([
          ...JSON.parse(newOrgSettings.employeeRequiredFields).filter(
            item => item !== newOrgSettings.employeePrimaryIdentifier
          ),
          newOrgSettings.employeePrimaryIdentifier
        ]),
        employeePrimaryCommunication: JSON.stringify([
          ...JSON.parse(newOrgSettings.employeePrimaryCommunication).filter(
            item => item !== newOrgSettings.employeePrimaryIdentifier
          ),
          newOrgSettings.employeePrimaryIdentifier
        ])
      });
    }
  }, [newOrgSettings.employeePrimaryIdentifier]);

  useEffect(() => {
    if (newOrgSettings.employeeRequiredFields) {
      setNewOrgSettings({
        ...newOrgSettings,
        employeePrimaryCommunication: JSON.stringify(
          JSON.parse(newOrgSettings.employeePrimaryCommunication).filter(item =>
            JSON.parse(newOrgSettings.employeeRequiredFields).includes(item)
          )
        )
      });
    }
  }, [newOrgSettings.employeeRequiredFields]);

  useEffect(() => {
    if (newOrgSettings.staffPrimaryIdentifier) {
      setNewOrgSettings({
        ...newOrgSettings,
        staffRequiredFields: JSON.stringify([
          ...JSON.parse(newOrgSettings.staffRequiredFields).filter(
            item => item !== newOrgSettings.staffPrimaryIdentifier
          ),
          newOrgSettings.staffPrimaryIdentifier
        ]),
        staffPrimaryCommunication: JSON.stringify([
          ...JSON.parse(newOrgSettings.staffPrimaryCommunication).filter(
            item => item !== newOrgSettings.staffPrimaryIdentifier
          ),
          newOrgSettings.staffPrimaryIdentifier
        ])
      });
    }
  }, [newOrgSettings.staffPrimaryIdentifier]);

  useEffect(() => {
    if (newOrgSettings.staffRequiredFields) {
      setNewOrgSettings({
        ...newOrgSettings,
        staffPrimaryCommunication: JSON.stringify(
          JSON.parse(newOrgSettings.staffPrimaryCommunication).filter(item =>
            JSON.parse(newOrgSettings.staffRequiredFields).includes(item)
          )
        )
      });
    }
  }, [newOrgSettings.staffRequiredFields]);

  useEffect(() => {
    if (newOrgSettings.visitorPrimaryIdentifier) {
      setNewOrgSettings({
        ...newOrgSettings,
        visitorRequiredFields: JSON.stringify([
          ...JSON.parse(newOrgSettings.visitorRequiredFields).filter(
            item => item !== newOrgSettings.visitorPrimaryIdentifier
          ),
          newOrgSettings.visitorPrimaryIdentifier
        ]),
        visitorPrimaryCommunication: JSON.stringify([
          ...JSON.parse(newOrgSettings.visitorPrimaryCommunication).filter(
            item => item !== newOrgSettings.visitorPrimaryIdentifier
          ),
          newOrgSettings.visitorPrimaryIdentifier
        ])
      });
    }
  }, [newOrgSettings.visitorPrimaryIdentifier]);

  useEffect(() => {
    if (newOrgSettings.visitorRequiredFields) {
      setNewOrgSettings({
        ...newOrgSettings,
        visitorPrimaryCommunication: JSON.stringify(
          JSON.parse(newOrgSettings.visitorPrimaryCommunication).filter(item =>
            JSON.parse(newOrgSettings.visitorRequiredFields).includes(item)
          )
        )
      });
    }
  }, [newOrgSettings.visitorRequiredFields]);

  const handleArrayChange = (key, value, selected) => {
    selected &&
      !JSON.parse(newOrgSettings[key]).includes(value) &&
      setNewOrgSettings({
        ...newOrgSettings,
        [key]: JSON.stringify([...JSON.parse(newOrgSettings[key]), value])
      });
    !selected &&
      setNewOrgSettings({
        ...newOrgSettings,
        [key]: JSON.stringify(
          JSON.parse(newOrgSettings[key]).filter(item => item !== value)
        )
      });
  };

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    try {
      await api(
        "PATCH",
        "business/settings/" + user.businessId,
        newOrgSettings,
        true
      );
      const newdata = await api(
        "POST",
        "business/getBusinessSettingsFilteredByKeys/" + user.businessId,
        {
          keys: [
            "searchInstantMeetingUser",
            "searchInstantMeetingUserFields",
            "employeePrimaryIdentifier",
            "employeePrimaryCommunication",
            "employeeRequiredFields",
            "visitorPrimaryIdentifier",
            "visitorPrimaryCommunication",
            "visitorRequiredFields",
            "visitorAdditionalFields",
            "staffPrimaryIdentifier",
            "staffPrimaryCommunication",
            "staffRequiredFields",
            "masterOtp",
            "mobileCountryCode"
          ]
        },
        true
      );
      setNewOrgSettings(newdata);
      setLoading(false);
    } catch (error) {
      captureException(error);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <Box width="100%" className={clsx(classes.root, className)} {...rest}>
      <form onSubmit={handleSubmit}>
        <Card>
          <CardContent>
            <Grid container spacing={6} wrap="wrap">
              <Grid className={classes.item} item md={12} sm={12}>

              <Typography color="textPrimary" variant="h4">

                  Search employee for instant meeting
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={newOrgSettings.searchInstantMeetingUser}

                      onChange={e => {

                        setNewOrgSettings({
                          ...newOrgSettings,
                          searchInstantMeetingUser: e.target.checked
                        });

                        }
                      }
                      name="searchInstantMeetingUser"
                    />
                  }
                  label="Allow"
                />
                {newOrgSettings.searchInstantMeetingUser && (
                  <TextField
                    label="Searchable fields"
                    fullWidth
                    variant="outlined"
                    type="text"
                    margin="normal"
                    value={JSON.parse(
                      newOrgSettings.searchInstantMeetingUserFields
                    ).join(", ")}
                    name="searchInstantMeetingUserFields"
                    onChange={e => {
                      setNewOrgSettings({
                        ...newOrgSettings,
                        searchInstantMeetingUserFields: JSON.stringify(
                          e.target.value.split(", ")
                        )
                      });
                    }}
                  />
                )}
                <br />
                <br />
                <TextField
                  label="Master OTP for user verification"
                  fullWidth
                  variant="outlined"
                  type="text"
                  margin="normal"
                  value={newOrgSettings.masterOtp}
                  name="masterOtp"
                  onChange={e =>
                    setNewOrgSettings({
                      ...newOrgSettings,
                      masterOtp: e.target.value
                    })
                  }
                />
                <br />
                <br />
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel id="countryCode">
                    {" "}
                    Default country for phone number input
                  </InputLabel>
                  <Select
                    labelId="countryCode"
                    id="countryCode"
                    label=" Default country for phone number input"
                    value={
                      newOrgSettings.mobileCountryCode?.toUpperCase() ?? "IN"
                    }
                    onChange={e =>
                      setNewOrgSettings({
                        ...newOrgSettings,
                        mobileCountryCode: e.target.value.toLowerCase()
                      })
                    }
                    autoWidth
                  >
                    {countries.map(country => {
                      return (
                        <MenuItem value={country.code} key={country.code}>
                          {country.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid className={classes.item} item md={4} sm={12}>
                <Typography color="textPrimary" gutterBottom variant="h4">
                  Employee
                </Typography>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Employee profile identifier
                  </FormLabel>
                  <RadioGroup
                    row
                    name="employeePrimaryIdentifier"
                    value={newOrgSettings.employeePrimaryIdentifier}
                    onChange={e => {
                      setNewOrgSettings({
                        ...newOrgSettings,
                        employeePrimaryIdentifier: e.target.value
                      });
                    }}
                  >
                    <FormControlLabel
                      value="email"
                      label="Email"
                      control={<Radio color="secondary" />}
                    />
                    <FormControlLabel
                      value="mobile"
                      control={<Radio color="secondary" />}
                      label="Phone number"
                    />
                  </RadioGroup>
                </FormControl>
                <br />
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Employee Required Fields
                  </FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            newOrgSettings.employeePrimaryIdentifier === "email"
                          }
                          checked={JSON.parse(
                            newOrgSettings.employeeRequiredFields
                          ).includes("email")}
                          onChange={e => {
                            handleArrayChange(
                              "employeeRequiredFields",
                              "email",
                              e.target.checked
                            );
                          }}
                        />
                      }
                      label="Email"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            newOrgSettings.employeePrimaryIdentifier ===
                            "mobile"
                          }
                          checked={JSON.parse(
                            newOrgSettings.employeeRequiredFields
                          ).includes("mobile")}
                          onChange={e => {
                            handleArrayChange(
                              "employeeRequiredFields",
                              "mobile",
                              e.target.checked
                            );
                          }}
                        />
                      }
                      label="Phone number"
                    />
                  </FormGroup>
                </FormControl>
                <br />
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Employee Transactional Communication
                  </FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            newOrgSettings.employeePrimaryIdentifier ===
                              "email" ||
                            !JSON.parse(
                              newOrgSettings.employeeRequiredFields
                            ).includes("email")
                          }
                          checked={JSON.parse(
                            newOrgSettings.employeePrimaryCommunication
                          ).includes("email")}
                          onChange={e => {
                            handleArrayChange(
                              "employeePrimaryCommunication",
                              "email",
                              e.target.checked
                            );
                          }}
                        />
                      }
                      label="Email"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            newOrgSettings.employeePrimaryIdentifier ===
                              "mobile" ||
                            !JSON.parse(
                              newOrgSettings.employeeRequiredFields
                            ).includes("mobile")
                          }
                          checked={JSON.parse(
                            newOrgSettings.employeePrimaryCommunication
                          ).includes("mobile")}
                          onChange={e => {
                            handleArrayChange(
                              "employeePrimaryCommunication",
                              "mobile",
                              e.target.checked
                            );
                          }}
                        />
                      }
                      label="SMS"
                    />
                  </FormGroup>
                </FormControl>
                <br />
              </Grid>
              <br />
              <br />
              <Grid className={classes.item} item md={4} sm={12}>
                <Typography color="textPrimary" gutterBottom variant="h4">
                  Staff
                </Typography>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Staff profile identifier
                  </FormLabel>
                  <RadioGroup
                    row
                    name="staffPrimaryIdentifier"
                    value={newOrgSettings.staffPrimaryIdentifier}
                    onChange={e => {
                      setNewOrgSettings({
                        ...newOrgSettings,
                        staffPrimaryIdentifier: e.target.value
                      });
                    }}
                  >
                    <FormControlLabel
                      value="email"
                      label="Email"
                      control={<Radio color="secondary" />}
                    />
                    <FormControlLabel
                      value="mobile"
                      control={<Radio color="secondary" />}
                      label="Phone number"
                    />
                  </RadioGroup>
                </FormControl>
                <br />
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Staff Required Fields
                  </FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            newOrgSettings.staffPrimaryIdentifier === "email"
                          }
                          checked={JSON.parse(
                            newOrgSettings.staffRequiredFields
                          ).includes("email")}
                          onChange={e => {
                            handleArrayChange(
                              "staffRequiredFields",
                              "email",
                              e.target.checked
                            );
                          }}
                        />
                      }
                      label="Email"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            newOrgSettings.staffPrimaryIdentifier === "mobile"
                          }
                          checked={JSON.parse(
                            newOrgSettings.staffRequiredFields
                          ).includes("mobile")}
                          onChange={e => {
                            handleArrayChange(
                              "staffRequiredFields",
                              "mobile",
                              e.target.checked
                            );
                          }}
                        />
                      }
                      label="Phone number"
                    />
                  </FormGroup>
                </FormControl>
                <br />
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Staff Transactional Communication
                  </FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            newOrgSettings.staffPrimaryIdentifier === "email" ||
                            !JSON.parse(
                              newOrgSettings.staffRequiredFields
                            ).includes("email")
                          }
                          checked={JSON.parse(
                            newOrgSettings.staffPrimaryCommunication
                          ).includes("email")}
                          onChange={e => {
                            handleArrayChange(
                              "staffPrimaryCommunication",
                              "email",
                              e.target.checked
                            );
                          }}
                        />
                      }
                      label="Email"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            newOrgSettings.staffPrimaryIdentifier ===
                              "mobile" ||
                            !JSON.parse(
                              newOrgSettings.staffRequiredFields
                            ).includes("mobile")
                          }
                          checked={JSON.parse(
                            newOrgSettings.staffPrimaryCommunication
                          ).includes("mobile")}
                          onChange={e => {
                            handleArrayChange(
                              "staffPrimaryCommunication",
                              "mobile",
                              e.target.checked
                            );
                          }}
                        />
                      }
                      label="SMS"
                    />
                  </FormGroup>
                </FormControl>
                <br />
              </Grid>
              <br />
              <br />
              <Grid className={classes.item} item md={4} sm={12}>
                <Typography color="textPrimary" gutterBottom variant="h4">
                  Visitor
                </Typography>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Visitor profile identifier
                  </FormLabel>
                  <RadioGroup
                    row
                    name="visitorPrimaryIdentifier"
                    value={newOrgSettings.visitorPrimaryIdentifier}
                    onChange={e => {
                      setNewOrgSettings({
                        ...newOrgSettings,
                        visitorPrimaryIdentifier: e.target.value
                      });
                    }}
                  >
                    <FormControlLabel
                      value="email"
                      label="Email"
                      control={<Radio color="secondary" />}
                    />
                    <FormControlLabel
                      value="mobile"
                      control={<Radio color="secondary" />}
                      label="Phone number"
                    />
                  </RadioGroup>
                </FormControl>
                <br />
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Visitor Required Fields
                  </FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            newOrgSettings.visitorPrimaryIdentifier === "email"
                          }
                          checked={JSON.parse(
                            newOrgSettings.visitorRequiredFields
                          ).includes("email")}
                          onChange={e => {
                            handleArrayChange(
                              "visitorRequiredFields",
                              "email",
                              e.target.checked
                            );
                          }}
                        />
                      }
                      label="Email"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            newOrgSettings.visitorPrimaryIdentifier === "mobile"
                          }
                          checked={JSON.parse(
                            newOrgSettings.visitorRequiredFields
                          ).includes("mobile")}
                          onChange={e => {
                            handleArrayChange(
                              "visitorRequiredFields",
                              "mobile",
                              e.target.checked
                            );
                          }}
                        />
                      }
                      label="Phone number"
                    />
                  </FormGroup>
                </FormControl>
                <br />
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Visitor Transactional Communication
                  </FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            newOrgSettings.visitorPrimaryIdentifier ===
                              "email" ||
                            !JSON.parse(
                              newOrgSettings.visitorRequiredFields
                            ).includes("email")
                          }
                          checked={JSON.parse(
                            newOrgSettings.visitorPrimaryCommunication
                          ).includes("email")}
                          onChange={e => {
                            handleArrayChange(
                              "visitorPrimaryCommunication",
                              "email",
                              e.target.checked
                            );
                          }}
                        />
                      }
                      label="Email"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={
                            newOrgSettings.visitorPrimaryIdentifier ===
                              "mobile" ||
                            !JSON.parse(
                              newOrgSettings.visitorRequiredFields
                            ).includes("mobile")
                          }
                          checked={JSON.parse(
                            newOrgSettings.visitorPrimaryCommunication
                          ).includes("mobile")}
                          onChange={e => {
                            handleArrayChange(
                              "visitorPrimaryCommunication",
                              "mobile",
                              e.target.checked
                            );
                          }}
                        />
                      }
                      label="SMS"
                    />
                  </FormGroup>
                </FormControl>
                <br />
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Additional data fields
                  </FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={JSON.parse(
                            newOrgSettings.visitorAdditionalFields
                          ).includes("company")}
                          onChange={e => {
                            handleArrayChange(
                              "visitorAdditionalFields",
                              "company",
                              e.target.checked
                            );
                          }}
                        />
                      }
                      label="Company"
                    />
                  </FormGroup>
                </FormControl>
                <br />
              </Grid>
              <br />
              <br />
            </Grid>
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <Box display="flex" justifyContent="flex-end">
              <Button color="primary" variant="contained" type="submit">
                Update
              </Button>
            </Box>
          </CardContent>
        </Card>
      </form>
    </Box>
  );
};

User.propTypes = {
  className: PropTypes.string
};

export default User;
