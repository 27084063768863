import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { captureException } from "@sentry/react";
import React, { useState } from "react";
import { PlusCircle } from "react-feather";
import { useDispatch } from "react-redux";
import api from "src/utils/api";
import { toastAction } from "../../actions";
import Loader from "../../utils/loader";
import CheckinRuleModal from "./CheckinRuleModal";
import moment from "moment";

const useStyles = makeStyles({
  root: {},
  table: {
    minWidth: 650
  },
  button: {
    marginBottom: 10
  }
});

export default function CheckinRulesList() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [index, setIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoader] = useState(true);
  const [isLoadingDelete, setDeleteLoader] = useState(false);
  const [floorList, setFloorList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [countData, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeRowInfo, setActiveRow] = useState({});

  React.useEffect(() => {
    deviceCount();
  }, []);

  const deviceCount = async () => {
    try {
      const res = await api("POST", "checkInRules/findCount", {}, true);
      setCount(res);
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchData();
  }, [rowsPerPage, page, modalOpen]);

  const fetchData = async () => {
    setLoader(true);
    
    try {
      const payload = {
        sort: {
          key: "checkInRules.createdAt",
          order: "DESC"
        },
        skip: page * rowsPerPage,
        take: rowsPerPage
      };
      try {
        await deviceCount();
        const rules = await api("POST", "checkInRules/find", payload, true);
        const res = rules || [];
        setDataList(res);
        setLoader(false);
      } catch (error) {
        captureException(error);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleModal = () => {
    setModalOpen(true);
  };

  const handleDevicemodal = id => {
    setIndex(id);
    setModalOpen(true);
  };

  if (isLoading) {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Loader />
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </React.Fragment>
    );
  }

  if (modalOpen)
    return (
      <CheckinRuleModal
        open={modalOpen}
        dataList={index !== null ? dataList[index] : null}
        close={() => {
          setIndex(null);
          setModalOpen(false);
        }}
      />
    );

  return (
    <React.Fragment>
      <Grid
        alignItems="flex-start"
        container
        justify="space-between"
        spacing={3}
        direction="row"
      >
        <Grid item>
          <Typography variant="h4" color="primary">
            Checkin Rules
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            className={classes.button}
            startIcon={<PlusCircle />}
            variant="outlined"
            onClick={handleModal}
          >
            Add New Rule
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Sl No</TableCell>
              <TableCell align="center">Name</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">From</TableCell>
              <TableCell align="center">To</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map((row, ind) => (
              <TableRow key={ind}>
                <TableCell align="center">
                  {rowsPerPage * page - 1 + ind + 2 || ""}
                </TableCell>
                <TableCell align="center">{row.name || "-"}</TableCell>
                <TableCell align="center">{row.description || "-"}</TableCell>
                <TableCell align="center">
                  {moment(new Date(parseInt(row.startDate))).format(
                    "YYYY-MM-DD"
                  ) || "-"}
                </TableCell>
                <TableCell align="center">
                  {moment(new Date(parseInt(row.endDate))).format(
                    "YYYY-MM-DD"
                  ) || "-"}
                </TableCell>

                <TableCell align="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleDevicemodal(ind)}
                  >
                    View Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={countData}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </React.Fragment>
  );
}
