import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import React, { useState } from "react";
import api from "src/utils/api";
import Loader from "src/utils/loader";
import RoomControlDialog from "./RoomControlDialog";

const useStyles = makeStyles({
  root: {},
  table: {
    minWidth: 650
  },
  button: {
    marginBottom: 10
  }
});

export default function RoomControls() {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setLoader] = useState(true);
  const [deviceOpen, setDeviceOpen] = useState(false);
  const [floorList, setFloorList] = useState([]);
  const [buildingList, setBuildingList] = useState([]);
  const [roomsList, setRoomList] = useState([]);
  const [countData, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedControl, setSelectedControl] = useState({});

  React.useEffect(() => {
    (async () => {
      const countPayload = {
        sort: { key: "room.createdAt", order: "ASC" },
        search: [
          {
            key: "room.allowMeeting",
            value: "",
            comparison: "="
          }
        ],
        skip: 0,
        take: 10
      };
      const building = await api("POST", "building/find", null, true);
      const floor = await api("POST", "floor/find", null, true);
      const room = await api("POST", "room/findCount", countPayload, true);
      Promise.all([building, floor, room])
        .then(data => {
          setBuildingList(data[0]);
          setFloorList(data[1]);
          setCount(data[2]);
        })
        .catch(error => {
          setLoader(false);
        });
    })();
  }, []);

  const roomCount = async () => {
    try {
      const countPayload = {
        sort: { key: "room.createdAt", order: "ASC" },
        skip: 0,
        take: 10
      };
      const res = await api("POST", "room/findCount", countPayload, true);
      setCount(res);
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchData();
  }, [rowsPerPage, page]);

  const fetchData = async () => {
    setLoader(true);
    try {
      const payload = {
        search: [
          {
            key: "room.allowMeeting",
            value: "",
            comparison: "="
          }
        ],
        sort: {
          key: "room.createdAt",
          order: "ASC"
        },
        skip: page * rowsPerPage,
        take: rowsPerPage
      };
      const building = await api("POST", "room/find", payload, true);
      const res = building || [];
      setRoomList(res);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleVipModal = row => {
    setSelectedControl(row);
    setDeviceOpen(true);
  };

  if (isLoading) {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Loader />
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </React.Fragment>
    );
  }

  if (deviceOpen)
    return (
      <RoomControlDialog
        close={() => setDeviceOpen(false)}
        modalData={selectedControl}
      />
    );

  return (
    <React.Fragment>
      <Grid
        alignItems="flex-start"
        container
        justify="space-between"
        spacing={3}
        direction="row"
      >
        <Grid item>
          <Typography variant="h4" color="primary">
            Room Control Management
          </Typography>
          <br />
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Room Name</TableCell>
              <TableCell align="center">Floor</TableCell>
              <TableCell align="center">Building</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roomsList.map((row, ind) => (
              <TableRow key={ind}>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.floor.name}</TableCell>
                <TableCell align="center">{row.building.name}</TableCell>
                <TableCell align="center">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleVipModal(row)}
                  >
                    View Controls
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={countData}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </React.Fragment>
  );
}
