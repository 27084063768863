
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import RostersList from "./RostersList";
import Page from "src/components/Page";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));
const RosterManagement = () => {
  const classes = useStyles();
 return (
    <Page className={classes.root} title="Roster Management">
      <RostersList />
    </Page>
  );
};

export default RosterManagement;
