import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  TextField,
  CircularProgress,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  DialogActions,
  Button
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { captureException } from "@sentry/react";
import api from "src/utils/api";
import getAvatarStyle from "src/utils/getAvatarStyle";
import getInitials from "src/utils/getInitials";
import { useSelector, useDispatch } from "react-redux";
import { toastAction } from "../../../actions";

function AddCredentialsModal(props) {
  const { open, onClose } = props;
  const dispatch = useDispatch();
  const [suggestionList, setSuggestionList] = useState([]);
  const [loading, setloading] = useState(false);
  const [openSuggestion, setopenSuggestion] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [accessGroups, setAccessGroups] = useState([]);

  const [userId, setuserId] = useState("");
  const [accessGroupId, setaccessGroupId] = useState("");
  const [facilityCode, setFacilityCode] = useState("");
  const [cardId, setCardId] = useState("");
  const [hasAccess, setHasAccess] = useState(true);

  const orgSettings = useSelector(state => state.orgSettings);

  useEffect(() => {
    if (!openSuggestion) {
      setSuggestionList([]);
    } else {
      setloading(true);
      (async () => {
        try {
          var data = await api(
            "POST",
            "ble/user/searchUser",
            {
              search: [
                {
                  key: "name",
                  value: searchInput,
                  comparison: "LIKE"
                }
              ],
              sort: {
                key: "userAccess.createdAt",
                order: "ASC"
              },
              skip: 0,
              take: 10
            },
            true
          );
          setSuggestionList(data);
          setloading(false);
        } catch (error) {
          setloading(false);
          captureException(error);
        }
      })();
    }
  }, [searchInput, openSuggestion]);

  useEffect(() => {
    (async () => {
      try {
        var data = await api(
          "GET",
          "ble/groups/getAllAccessGroups",
          null,
          true
        );
        setAccessGroups(data.data.Data);
      } catch (error) {
        captureException(error);
      }
    })();
  }, []);
  const handleClose = () => {
    onClose();
    setSearchInput("");
    setuserId("");
    setHasAccess(true);
    setaccessGroupId("");
    setFacilityCode("");
    setCardId("");
  };

  const handleSubmit = async () => {
    if (userId === "") {
      dispatch(
        toastAction({
          status: true,
          message: "Please select a user",
          type: "error",
          noIcon: true
        })
      );
      return;
    }

    if (facilityCode === "") {
      dispatch(
        toastAction({
          status: true,
          message: "Please enter a facility code",
          type: "error",
          noIcon: true
        })
      );
      return;
    }

    if (facilityCode > 255) {
      dispatch(
        toastAction({
          status: true,
          message: "Facility code can't exceed 255",
          type: "error",
          noIcon: true
        })
      );
      return;
    }

    if (cardId === "") {
      dispatch(
        toastAction({
          status: true,
          message: "Please enter a card id",
          type: "error",
          noIcon: true
        })
      );
      return;
    }

    if (cardId > 65535) {
      dispatch(
        toastAction({
          status: true,
          message: "Card Id cannot exceed 65535",
          type: "error",
          noIcon: true
        })
      );
      return;
    }

    if (!hasAccess && accessGroupId === "") {
      dispatch(
        toastAction({
          status: true,
          message: "Please select an access group",
          type: "error",
          noIcon: true
        })
      );
      return;
    }

    (async () => {
      try {
        await api(
          "POST",
          "ble/user/addCredentials",
          {
            employeeId: userId,
            facilityCode: facilityCode,
            bleCardId: cardId,
            rosslareGroupId: !hasAccess ? accessGroupId : null
          },
          true
        );
        handleClose();
        dispatch(
          toastAction({
            status: true,
            message: "User added successfully",
            type: "success",
            noIcon: true
          })
        );
      } catch (error) {
        captureException(error);
        dispatch(
          toastAction({
            status: true,
            message: "Something went wrong",
            type: "error",
            noIcon: true
          })
        );
      }
    })();
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={handleClose}>
      <DialogTitle id="alert-dialog-title"> Add New User</DialogTitle>
      <DialogContent>
        <Autocomplete
          fullWidth
          id="user"
          open={openSuggestion}
          noOptionsText={"No user found"}
          options={suggestionList}
          onOpen={() => {
            setopenSuggestion(true);
          }}
          onClose={() => {
            setopenSuggestion(false);
          }}
          onChange={(event, newValue) => {
            let bleCards = newValue?.bleCards ?? [];
            if (bleCards.length > 0 && bleCards[0].rosslareUserId !== -1) {
              setHasAccess(true);
            } else {
              setHasAccess(false);
            }
            setuserId(newValue?.id ?? "");
          }}
          getOptionLabel={option => {
            return option.name;
          }}
          renderOption={item => {
            return (
              <ListItem dense disableGutters className="suggestedParticipant">
                <ListItemAvatar>
                  <Avatar style={getAvatarStyle(item.email)}>
                    {getInitials(item.inputValue ? "+" : item.name)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<Typography variant="h5">{item.name}</Typography>}
                  secondary={
                    <Typography variant="body2" color="textSecondary">
                      {item.role && item[orgSettings.employeePrimaryIdentifier]}
                      {!item.role && item[orgSettings.staffPrimaryIdentifier]}
                    </Typography>
                  }
                />
              </ListItem>
            );
          }}
          renderInput={params => (
            <TextField
              {...params}
              label={"Search user by name"}
              variant="outlined"
              onChange={e => setSearchInput(e.target.value)}
              value={searchInput}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
        />
        <br />
        <TextField
          label={"Facility Code"}
          variant="outlined"
          type="number"
          value={facilityCode}
          onChange={e => setFacilityCode(e.target.value)}
          fullWidth
        />
        <br />
        <br />
        <TextField
          label={"Card Id"}
          variant="outlined"
          type="number"
          value={cardId}
          onChange={e => setCardId(e.target.value)}
          fullWidth
        />
        <br />
        <br />
        {!hasAccess && (
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <InputLabel id="accessGroup">Access Group</InputLabel>
            <Select
              labelId="accessGroup"
              id="accessGroupDropdown"
              label="Access Group"
              value={accessGroupId}
              onChange={e => {
                setaccessGroupId(e.target.value);
              }}
              autoWidth
            >
              {accessGroups.map(group => {
                return (
                  <MenuItem key={group.ID} value={group.ID}>
                    {group.tDesc}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddCredentialsModal;
