import * as actionTypes from "../actions";

export const tostState = {
  status: false,
  message: "",
  type: "",
  closeFlag: false
};

const initialState = {
  user: {},
  loggedIn: false,
  meeting: {},
  org: {},
  business: {},
  orgSettings: {},
  tostMessage: tostState
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BUSINESS: {
      return {
        ...state,
        business: action.payload
      };
    }
    case actionTypes.ORGANIZATION: {
      return {
        ...state,
        org: action.payload
      };
    }
    case actionTypes.ORGANIZATIONSETTINGS: {
      return {
        ...state,
        orgSettings: action.payload
      };
    }
    case actionTypes.LOGIN: {
      return {
        ...state,
        user: action.payload.data,
        loggedIn: true,
      };
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        user: null
      };
    }
    case actionTypes.MEETING: {
      return {
        ...state,
        meeting: action.payload
      };
    }
    case actionTypes.TOAST_MESSAGE:
      return {
        ...state,
        tostMessage: {
          status: action.payload.status,
          message: action.payload.message,
          type: action.payload.type,
          noIcon: action.payload.noIcon,
          closeFlag: true
        }
      };
    case actionTypes.CLEAR_TOAST_MESSAGE:
      return {
        ...state,
        tostMessage: {
          status: false,
          message: "",
          type: "",
          closeFlag: false
        }
      };
    default: {
      return state;
    }
  }
};

export default rootReducer;
