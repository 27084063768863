import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  Container,
  Box
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useLocation } from "react-router-dom";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import { captureException } from "@sentry/browser";
import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useDispatch, useSelector } from "react-redux";
import { clearToastAction, toastAction } from "../../actions";
import api from "../../utils/api";
import Loader from "../../utils/loader";
import Page from "../../components/Page";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

const EmployeeSignUp = props => {
  var query = {};
  const location = useLocation();
  location.search
    .slice(1)
    .split("&")
    .forEach(item => {
      query[item.split("=")[0]] = item.split("=")[1];
    });
  const classes = useStyles();
  const [bus, setBus] = useState({});
  const [org, setOrg] = useState({});
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [designation, setDesignation] = useState("");
  const [department, setDepartment] = useState("");
  const [organization, setOrganization] = useState("");
  const [buildings, setBuildings] = useState([]);
  const [building, setBuilding] = useState("");
  const [role, setRole] = useState("employee");
  const [error, setError] = useState("");
  const [orgSettings, setOrgSettings] = useState({});
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const data = await api(
        "POST",
        "building/findwithCustomToken",
        {
          search: [
            {
              key: "organization.organizationId",
              value: organization,
              comparison: "="
            }
          ],
          sort: { key: "building.createdAt", order: "ASC" }
        },
        false,
        false,
        false,
        false,
        query.token
      );
      setBuildings(data);
    })();
  }, [organization]);

  useEffect(() => {
    (async () => {
      const data = await api(
        "POST",
        "auth/validateSignupToken",
        {
          token: query.token
        },
        false
      );
      setEmail(data.data.email);
      setOrganization(data.data.organizationId);
      setRole(data.data.role);
      const business = await api(
        "GET",
        "business/" + data.data.businessId,
        null,
        true
      );
      setBus(business);
      const organization = await api(
        "GET",
        "organization/" + data.data.organizationId,
        null,
        true
      );
      setOrg(organization);
      const businessSettings = await api(
        "GET",
        "business/getBusinessSettings/" + data.data.businessId,
        null,
        true
      );
      const organizationSettings = await api(
        "GET",
        "organization/getOrgSettings/" + data.data.organizationId,
        null,
        true
      );
      setOrgSettings({
        ...businessSettings,
        ...organizationSettings
      });
      setLoading(false);
    })();
  }, []);

  const handleAdd = () => {
    setError("");
    var body = {
      name,
      mobile,
      countryCode,
      department,
      designation,
      building
    };
    for (const [key, value] of Object.entries(body)) {
      if (value === "") delete body[key];
    }
    dispatch(
      toastAction({
        status: true,
        message: "Signing up ...",
        type: "info",
        noIcon: true
      })
    );
    (async () => {
      try {
        await api(
          "POST",
          "employee/employeeSignup",
          body,
          false,
          false,
          false,
          false,
          query.token
        );
        dispatch(
          toastAction({
            status: true,
            message: "Sign up successful",
            type: "success"
          })
        );
        setSuccess(true);
      } catch (error) {
        dispatch(
          toastAction({
            status: true,
            message: "Something went wrong ",
            type: "error"
          })
        );
        error.response && error.response.data && setError(error.response.data);
      }
    })();
  };

  return loading ? (
    <Loader />
  ) : (
    <div>
      <Page title="Employee Signup">
        <Container maxWidth="md" style={{ padding: 0 }}>
          <Box p={3} my={3} style={{ backgroundColor: "#fff" }}>
            {bus.logo && bus.name && (
              <>
                <Box
                  mb={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img src={bus.logo} style={{ maxWidth: 180 }} alt="logo" />
                </Box>
                <Typography
                  color="primary"
                  variant="h1"
                  component="h2"
                  align="center"
                  style={{ fontWeight: 900 }}
                >
                  Welcome to {bus.name}
                </Typography>
              </>
            )}
            <br />
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography
                component="h1"
                variant="h3"
                color="primary"
                align="center"
              >
                Employee Signup
              </Typography>
            </Grid>

            <DialogContentText>
              Please fill up the following details about yourself and click on
              the 'Sign up' button. We will generate a password and share with
              you via email.
            </DialogContentText>
            {error !== "" && (
              <Alert
                severity="error"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setError("");
                    }}
                  >
                    <X />
                  </Button>
                }
              >
                {error}
              </Alert>
            )}
            {success && <Alert severity="success">Sign up successful. Please check your email for credentials</Alert>}
            {!success && (
              <>
                <TextField
                  label="Full name"
                  variant="outlined"
                  type="text"
                  margin="normal"
                  required
                  fullWidth
                  value={name}
                  onChange={e => {
                    setName(e.target.value);
                  }}
                />
                <TextField
                  disabled
                  label="Email address"
                  variant="outlined"
                  type="email"
                  margin="normal"
                  required={JSON.parse(
                    orgSettings.employeeRequiredFields || "[]"
                  ).includes("email")}
                  fullWidth
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                  }}
                />

                <PhoneInput
                  enableSearch
                  country={org?.countrycode ?? "in"}
                  preferredCountries={["sg", "in", "us"]}
                  inputStyle={{ width: "100%" }}
                  searchClass="MuiOutlinedInput-input MuiOutlinedInput-inputMarginDense"
                  specialLabel={
                    JSON.parse(
                      orgSettings.employeeRequiredFields || "[]"
                    ).includes("mobile")
                      ? "Phone number *"
                      : "Phone number"
                  }
                  countryCodeEditable={false}
                  value={countryCode.replace("+", "") + mobile}
                  onChange={(value, country) => {
                    setCountryCode("+" + country.dialCode);
                    setMobile(value.replace(country.dialCode, ""));
                  }}
                />
                <TextField
                  label="Department"
                  variant="outlined"
                  type="text"
                  margin="normal"
                  fullWidth
                  value={department}
                  onChange={e => {
                    setDepartment(e.target.value);
                  }}
                />
                <TextField
                  label="Designation"
                  variant="outlined"
                  type="text"
                  margin="normal"
                  fullWidth
                  value={designation}
                  onChange={e => {
                    setDesignation(e.target.value);
                  }}
                />

                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  className={classes.marginNormal}
                >
                  <InputLabel id="building"> Building</InputLabel>
                  <Select
                    labelId="building"
                    id="building"
                    label="Building "
                    value={building}
                    onChange={e => {
                      setBuilding(e.target.value);
                    }}
                    autoWidth
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {buildings.map(building => {
                      return (
                        <MenuItem value={building.id} key={building.id}>
                          {building.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Grid
                  container
                  direction="row-reverse"
                  alignItems="center"
                  spacing={3}
                  className={classes.marginNormal}
                >
                  <Grid item>
                    <Button
                      onClick={handleAdd}
                      variant="contained"
                      color="primary"
                    >
                      Sign up
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Container>
      </Page>
    </div>
  );
};

export default EmployeeSignUp;
