import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import { captureException } from "@sentry/react";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { organizationSettings } from "../../../actions";
import api from "../../../utils/api";
import Loader from "../../../utils/loader";

const useStyles = makeStyles({
  root: {},
  item: {
    display: "flex",
    flexDirection: "column"
  }
});

const Checkout = ({ className, ...rest }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [newOrgSettings, setNewOrgSettings] = useState({});
  const user = useSelector(state => state.user);
  const orgSettings = useSelector(state => state.orgSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(organizationSettings({ ...orgSettings, ...newOrgSettings }));
  }, [loading]);
  useEffect(() => {
    (async () => {
      try {
        const newdata = await api(
          "POST",
          "business/getBusinessSettingsFilteredByKeys/" + user.businessId,
          {
            keys: [
              "checkoutDispenseType",
              "checkoutSyncTime",
              "checkoutOTPEnable"
            ]
          },
          true
        );
        setNewOrgSettings(newdata);
        setLoading(false);
      } catch (error) {
        captureException(Error);
      }
    })();
  }, []);

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    try {
      await api(
        "PATCH",
        "business/settings/" + user.businessId,
        newOrgSettings,
        true
      );
      const newdata = await api(
        "POST",
        "business/getBusinessSettingsFilteredByKeys/" + user.businessId,
        {
          keys: [
            "checkoutDispenseType",
            "checkoutSyncTime",
            "checkoutOTPEnable"
          ]
        },
        true
      );
      setNewOrgSettings(newdata);
      setLoading(false);
    } catch (error) {
      captureException(error);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <Box width="100%" className={clsx(classes.root, className)} {...rest}>
      <form onSubmit={handleSubmit}>
        <Card>
          <CardContent>
            <Grid container spacing={6} wrap="wrap">
              <Grid className={classes.item} item md={6} sm={12}>
                <Typography color="textPrimary" gutterBottom variant="h5">
                  Visitor Checkout Process
                </Typography>
                <FormControl component="fieldset">
                  <Typography color="textPrimary" gutterBottom variant="h6">
                    Request approval
                  </Typography>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            newOrgSettings.checkoutDispenseType === "ble"
                          }
                          onChange={e => {
                            e.target.checked &&
                              setNewOrgSettings({
                                ...newOrgSettings,
                                checkoutDispenseType: "ble"
                              });
                          }}
                        />
                      }
                      label="Submit BLE"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newOrgSettings.checkoutDispenseType === "qr"}
                          onChange={e => {
                            e.target.checked &&
                              setNewOrgSettings({
                                ...newOrgSettings,
                                checkoutDispenseType: "qr"
                              });
                          }}
                        />
                      }
                      label="Scan QR"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            newOrgSettings.checkoutDispenseType === "host"
                          }
                          onChange={e => {
                            e.target.checked &&
                              setNewOrgSettings({
                                ...newOrgSettings,
                                checkoutDispenseType: "host"
                              });
                          }}
                        />
                      }
                      label="Checkout by Host"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            newOrgSettings.checkoutDispenseType === "auto"
                          }
                          onChange={e => {
                            e.target.checked &&
                              setNewOrgSettings({
                                ...newOrgSettings,
                                checkoutDispenseType: "auto"
                              });
                          }}
                        />
                      }
                      label="Automatic checkout based on meeting end time"
                    />
                  </FormGroup>
                </FormControl>
                {newOrgSettings.checkoutDispenseType === "auto" && (
                  <TextField
                    label="Visitor checkout delay"
                    fullWidth
                    variant="outlined"
                    type="number"
                    margin="normal"
                    value={newOrgSettings.checkoutSyncTime}
                    name="checkoutSyncTime"
                    onChange={e =>
                      setNewOrgSettings({
                        ...newOrgSettings,
                        checkoutSyncTime: e.target.value
                      })
                    }
                  />
                )}
                <br />
                <br />
                <Typography color="textPrimary" gutterBottom variant="h6">
                  OTP Verification for self checkout
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={newOrgSettings.checkoutOTPEnable}
                      onChange={e=> setNewOrgSettings({
                        ...newOrgSettings,
                        checkoutOTPEnable: e.target.checked
                      })}
                      name="checkoutOTPEnable"
                    />
                  }
                  label="Enable"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <Box display="flex" justifyContent="flex-end">
              <Button color="primary" variant="contained" type="submit">
                Update
              </Button>
            </Box>
          </CardContent>
        </Card>
      </form>
    </Box>
  );
};

Checkout.propTypes = {
  className: PropTypes.string
};

export default Checkout;
