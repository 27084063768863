import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import { captureException } from "@sentry/browser";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { organizationSettings, toastAction } from "../../../actions";
import api from "../../../utils/api";
import Loader from "../../../utils/loader";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";

const useStyles = makeStyles({
  root: {},
  item: {
    display: "flex",
    flexDirection: "column"
  }
});

const Integration = ({ className, ...rest }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [newOrgSettings, setNewOrgSettings] = useState({});
  const user = useSelector(state => state.user);
  const orgSettings = useSelector(state => state.orgSettings);
  const dispatch = useDispatch();
  let location,
    { search } = useLocation();
  let navigate = useNavigate();
  useEffect(() => {
    dispatch(organizationSettings({ ...orgSettings, ...newOrgSettings }));
  }, [loading]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let params = new URLSearchParams(search);
        let type = params.get("type");
        let code = params.get("code");
        if (code && type === "google") {
          const response = await api(
            "POST",
            "integration/update",
            {
              code,
              type,
              redirect_url: `${window.location.origin}/settings/integration/google`
            },
            true
          );
          await fetchData();
          dispatch(
            toastAction({
              type: "success",
              message: "Google integration updated successfully"
            })
          );
          navigate("#integration");
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    })();
  }, []);

  const fetchData = async () => {
    try {
      const newdata =
        user.role !== "businessAdmin"
          ? await api(
              "POST",
              "organization/getOrgSettingsFilteredByKeys/" +
                user.organizationId,
              {
                keys: [
                  "meetingKeywords",
                  "outLookTimeOverlap",
                  "meetingRoomManagement",
                  "meetingRoomRelease",
                  "meetingRoomReleaseTime",
                  "outlookIntegration",
                  "googleIntegration",
                  "salesforceIntegration"
                ]
              },
              true
            )
          : {};
      const newdatabusiness = await api(
        "POST",
        "business/getBusinessSettingsFilteredByKeys/" + user.businessId,
        {
          keys: [
            "outlookBusinesIntegration",
            "googleBusinesIntegration",
            "businessmeetingRoomManagement",
            "businessPantryManagement",
            "salesforceBusinesIntegration"
          ]
        },
        true
      );
      setNewOrgSettings({ ...newdata, ...newdatabusiness });
      console.log(newdata);
      setLoading(false);
    } catch (error) {
      captureException(error);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    try {
      user.role !== "businessAdmin" &&
        (await api(
          "PATCH",
          "organization/settings/" + user.organizationId,
          {
            meetingKeywords: newOrgSettings.meetingKeywords,
            outLookTimeOverlap: newOrgSettings.outLookTimeOverlap,
            meetingRoomManagement: newOrgSettings.meetingRoomManagement,
            meetingRoomRelease: newOrgSettings.meetingRoomRelease,
            meetingRoomReleaseTime: newOrgSettings.meetingRoomReleaseTime,
            outlookIntegration: newOrgSettings.outlookIntegration,
            googleIntegration: newOrgSettings.googleIntegration,
            salesforceIntegration: newOrgSettings.salesforceIntegration
          },
          true
        ));

      user.role === "businessAdmin" &&
        (await api(
          "PATCH",
          "business/settings/" + user.businessId,
          {
            outlookBusinesIntegration: newOrgSettings.outlookBusinesIntegration,
            googleBusinesIntegration: newOrgSettings.googleBusinesIntegration,
            businessmeetingRoomManagement:
              newOrgSettings.businessmeetingRoomManagement,
            businessPantryManagement: newOrgSettings.businessPantryManagement,
            salesforceBusinesIntegration:
              newOrgSettings.salesforceBusinesIntegration
          },
          true
        ));
      await fetchData();
    } catch (error) {
      captureException(error);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <Paper>
      <Box
        width="100%"
        className={clsx(classes.root, className)}
        {...rest}
        p={3}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={6} wrap="wrap">
            <Grid className={classes.item} item md={6} sm={12}>
              {user.role === "businessAdmin" && (
                <>
                  <Typography color="textPrimary" gutterBottom variant="h5">
                    Calendar Integration:
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newOrgSettings.outlookBusinesIntegration}
                        onChange={e => {
                          setNewOrgSettings({
                            ...newOrgSettings,
                            outlookBusinesIntegration: e.target.checked
                          });
                        }}
                        name="Outlook"
                      />
                    }
                    label="Office 365 Calendar"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newOrgSettings.googleBusinesIntegration}
                        onChange={e => {
                          setNewOrgSettings({
                            ...newOrgSettings,
                            googleBusinesIntegration: e.target.checked
                          });
                        }}
                        name="Google"
                      />
                    }
                    label="Google Workspace Calendar"
                  />
                  <br />
                  <br />
                </>
              )}
              {(newOrgSettings.outlookBusinesIntegration ||
                newOrgSettings.googleBusinesIntegration) && (
                <Typography color="textPrimary" gutterBottom variant="h5">
                  Calendar Integration:
                </Typography>
              )}
              {newOrgSettings.outlookBusinesIntegration &&
                user.role !== "businessAdmin" && (
                  <>
                    <FormControl component="fieldset">
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={newOrgSettings.outlookIntegration}
                              onChange={e => {
                                !e.target.checked &&
                                  setNewOrgSettings({
                                    ...newOrgSettings,
                                    outlookIntegration: e.target.checked
                                  });
                              }}
                              name="Outlook"
                            />
                          }
                        />
                        <Button
                          size="large"
                          disabled={newOrgSettings.outlookIntegration}
                          variant="contained"
                          startIcon={
                            <img
                              src="https://i.ibb.co/dtCr25t/200px-Microsoft-Office-logo-2019-present-svg.png"
                              alt="o365"
                              style={{ height: 20 }}
                            />
                          }
                          style={{ maxWidth: 156 }}
                          href={`https://login.microsoftonline.com/common/adminconsent?client_id=bc5b9a9f-8f73-48a9-80b6-6a8ce4d8e622&state=${
                            user.organizationId
                          }&redirect_uri=${
                            process.env.REACT_APP_ENV.trim() === "prod"
                              ? "https://xelh4x5hi3.execute-api.us-east-1.amazonaws.com/"
                              : "https://rrp7ixj615.execute-api.us-east-1.amazonaws.com"
                          }`}
                        >
                          Office 365
                        </Button>
                      </FormGroup>
                      <br />
                    </FormControl>
                    {newOrgSettings.outlookIntegration && (
                      <TextField
                        label="Meeting sync criteria (Subject keywords)"
                        fullWidth
                        variant="outlined"
                        type="text"
                        margin="normal"
                        value={newOrgSettings.meetingKeywords}
                        onChange={e => {
                          setNewOrgSettings({
                            ...newOrgSettings,
                            meetingKeywords: e.target.value
                          });
                        }}
                      />
                    )}

                    <br />
                    <br />
                  </>
                )}
              {newOrgSettings.googleBusinesIntegration &&
                user.role !== "businessAdmin" && (
                  <>
                    <FormControl component="fieldset">
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={newOrgSettings.googleIntegration}
                              onChange={e => {
                                !e.target.checked &&
                                  setNewOrgSettings({
                                    ...newOrgSettings,
                                    googleIntegration: e.target.checked
                                  });
                              }}
                              name="Outlook"
                            />
                          }
                        />
                        <Button
                          size="large"
                          disabled={newOrgSettings.googleIntegration}
                          variant="contained"
                          startIcon={
                            <img
                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/2048px-Google_%22G%22_Logo.svg.png"
                              alt="Google Integration"
                              style={{ height: 20 }}
                            />
                          }
                          style={{ maxWidth: 240 }}
                          href={`https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?redirect_uri=${window.location.origin}/settings/integration/google&prompt=consent&response_type=code&client_id=75007441741-fnj34l7ohcc8cq022bbbagpvpejmkuob.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadmin.directory.resource.calendar&access_type=offline&flowName=GeneralOAuthFlow`}
                          target="_blank"
                        >
                          Google Workspace
                        </Button>
                      </FormGroup>
                      <br />
                    </FormControl>
                    <br />
                    <br />
                  </>
                )}
              {user.role === "businessAdmin" && (
                <>
                  <Typography color="textPrimary" gutterBottom variant="h5">
                    Salesforce Integration:
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newOrgSettings.salesforceBusinesIntegration}
                        onChange={e => {
                          setNewOrgSettings({
                            ...newOrgSettings,
                            salesforceBusinesIntegration: e.target.checked
                          });
                        }}
                        name="Outlook"
                      />
                    }
                    label="Enable"
                  />
                  <br />
                  <br />
                </>
              )}
              {newOrgSettings.salesforceBusinesIntegration &&
                user.role !== "businessAdmin" && (
                  <>
                    <Typography color="textPrimary" gutterBottom variant="h5">
                      Salesforce Integration:
                    </Typography>
                    <FormControl component="fieldset">
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={newOrgSettings.salesforceIntegration}
                              disabled={!newOrgSettings.salesforceIntegration}
                              onChange={e => {
                                !e.target.checked &&
                                  setNewOrgSettings({
                                    ...newOrgSettings,
                                    salesforceIntegration: e.target.checked
                                  });
                              }}
                              name="salesforce"
                            />
                          }
                        />
                        <Button
                          size="large"
                          disabled={newOrgSettings.salesforceIntegration}
                          variant="contained"
                          startIcon={
                            <img
                              src="https://upload.wikimedia.org/wikipedia/en/8/83/Salesforce_logo.svg"
                              alt="Salesforce"
                              style={{ height: 20 }}
                            />
                          }
                          style={{ maxWidth: 156 }}
                          href={`https://login.salesforce.com/services/oauth2/authorize?client_id=3MVG9fe4g9fhX0E5cRfGX2Dw18aty8X2MSwWgNr6PnevHkgPKS7POfV574ngX1bH3FhsMIzxXAxwcpwMvV8Cb&redirect_uri=${
                            process.env.REACT_APP_ENV.trim() === "prod"
                              ? "https://904scuilm9.execute-api.us-east-1.amazonaws.com"
                              : "https://r9fdwcj8uh.execute-api.us-east-1.amazonaws.com"
                          }&response_type=code&state=${user.organizationId}`}
                          target="_blank"
                        >
                          {!newOrgSettings.salesforceIntegration && "Integrate"}
                          {newOrgSettings.salesforceIntegration && "Integrated"}
                        </Button>
                      </FormGroup>
                      <br />
                    </FormControl>

                    <br />
                    <br />
                  </>
                )}

              <Typography color="textPrimary" gutterBottom variant="h5">
                Employee Database Integration:
              </Typography>
              <FormControl component="fieldset">
                <FormGroup row>
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={newOrgSettings.outlookBusinesIntegration}
                        onChange={e => {
                          setNewOrgSettings({
                            ...newOrgSettings,
                            outlookBusinesIntegration: e.target.checked
                          });
                        }}
                        name="Outlook"
                      />
                    }
                  /> */}
                  <Button
                    size="large"
                    disabled
                    variant="contained"
                    startIcon={
                      <img
                        src="https://img.favpng.com/16/19/23/active-directory-microsoft-azure-lightweight-directory-access-protocol-user-png-favpng-7zuNDnk3SdDHQdUG2etjZYWr2.jpg"
                        alt="Active Directory"
                        style={{ height: 20 }}
                      />
                    }
                    style={{ maxWidth: 360 }}
                    href={`https://google.com`}
                    target="_blank"
                  >
                    Active Directory
                  </Button>
                </FormGroup>
              </FormControl>
              <br />
              <br />

              {user.role === "businessAdmin" && (
                <>
                  <Typography color="textPrimary" gutterBottom variant="h5">
                    Meeting Room Management
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newOrgSettings.businessmeetingRoomManagement}
                        onChange={e => {
                          setNewOrgSettings({
                            ...newOrgSettings,
                            businessmeetingRoomManagement: e.target.checked
                          });
                        }}
                        name="Outlook"
                      />
                    }
                    label="Enable"
                  />
                </>
              )}
              {user.role !== "businessAdmin" &&
                newOrgSettings.businessmeetingRoomManagement && (
                  <>
                    <Typography color="textPrimary" gutterBottom variant="h5">
                      Meeting Room Management
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newOrgSettings.meetingRoomManagement}
                          onChange={e => {
                            setNewOrgSettings({
                              ...newOrgSettings,
                              meetingRoomManagement: e.target.checked
                            });
                          }}
                          name="Outlook"
                        />
                      }
                      label="Enable"
                    />
                    {newOrgSettings.meetingRoomManagement && (
                      <>
                        <br />
                        <br />
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h5"
                        >
                          Overlap meeting room when booking from outlook
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={newOrgSettings.outLookTimeOverlap}
                              onChange={e => {
                                setNewOrgSettings({
                                  ...newOrgSettings,
                                  outLookTimeOverlap: e.target.checked
                                });
                              }}
                              name="Outlook"
                            />
                          }
                          label="Enable"
                        />
                        <br />
                        <br />
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h5"
                        >
                          Release meeting room if visitor doesn't check in
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={newOrgSettings.meetingRoomRelease}
                              onChange={e => {
                                setNewOrgSettings({
                                  ...newOrgSettings,
                                  meetingRoomRelease: e.target.checked
                                });
                              }}
                              name="Outlook"
                            />
                          }
                          label="Enable"
                        />
                        {newOrgSettings.meetingRoomRelease && (
                          <TextField
                            label="Meeting room release waiting time in (minutes)"
                            fullWidth
                            variant="outlined"
                            type="number"
                            margin="normal"
                            value={newOrgSettings.meetingRoomReleaseTime}
                            onChange={e => {
                              setNewOrgSettings({
                                ...newOrgSettings,
                                meetingRoomReleaseTime: e.target.value
                              });
                            }}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              <br />
              <br />
              {user.role === "businessAdmin" && (
                <>
                  <Typography color="textPrimary" gutterBottom variant="h5">
                    Pantry Management
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newOrgSettings.businessPantryManagement}
                        onChange={e => {
                          setNewOrgSettings({
                            ...newOrgSettings,
                            businessPantryManagement: e.target.checked
                          });
                        }}
                        name="Outlook"
                      />
                    }
                    label="Enable"
                  />
                </>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Box display="flex" justifyContent="flex-start" py={2}>
            <Button color="primary" variant="contained" type="submit">
              Update
            </Button>
          </Box>
        </form>
      </Box>
    </Paper>
  );
};

export default Integration;
