import { Card, CardContent, Divider, Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Page from "src/components/Page";
import {
  Items,
  Pantries,
  EmployeesArchive,
  Header,
  Orders
} from "./components";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0
    }
  }
}));

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}
const PantryList = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [reload, setReload] = React.useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    console.log();
  }, [reload]);

  return (
    <Page className={classes.root} title="Pantry List">
      <Header setReload={setReload} />
      <Card>
        <CardContent className={classes.content}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Pantry" {...a11yProps(0)} />
            <Tab label="Pantry Items" {...a11yProps(1)} />
            <Tab label="Orders" {...a11yProps(2)} />
            {/* <Tab label="Archived Employees" {...a11yProps(3)} /> */}
          </Tabs>
          <Divider />
          <TabPanel value={value} index={0}>
            <Pantries reload={reload} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Items reload={reload} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Orders reload={reload} />
          </TabPanel>
          {/* <TabPanel value={value} index={3}>
            <EmployeesArchive reload={reload} />
          </TabPanel> */}
        </CardContent>
      </Card>
    </Page>
  );
};

export default PantryList;
