import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  IconButton,
  Radio,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  List,
  Avatar
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles, withStyles } from "@material-ui/styles";
import { captureException } from "@sentry/browser";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toastAction } from "../../actions";
import { KeyboardBackspace } from "@material-ui/icons";
import api from "src/utils/api";
import getAvatarStyle from "../../utils/getAvatarStyle";
import getInitials from "../../utils/getInitials";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));

const filter = createFilterOptions();

const CheckinRuleModal = props => {
  const { close, dataList } = props;
  const user = useSelector(state => state.user);
  const orgSettings = useSelector(state => state.orgSettings);
  const primary = orgSettings.visitorPrimaryIdentifier;
  const [ruleData, setRuleData] = useState(dataList || null);
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [mon, setmon] = useState(false);
  const [monStart, setmonStart] = useState("");
  const [monEnd, setmonEnd] = useState("");
  const [tue, settue] = useState(false);
  const [tueStart, settueStart] = useState("");
  const [tueEnd, settueEnd] = useState("");
  const [wed, setwed] = useState(false);
  const [wedStart, setwedStart] = useState("");
  const [wedEnd, setwedEnd] = useState("");
  const [thu, setthu] = useState(false);
  const [thuStart, setthuStart] = useState("");
  const [thuEnd, setthuEnd] = useState("");
  const [fri, setfri] = useState(false);
  const [friStart, setfriStart] = useState("");
  const [friEnd, setfriEnd] = useState("");
  const [sat, setsat] = useState(false);
  const [satStart, setsatStart] = useState("");
  const [satEnd, setsatEnd] = useState("");
  const [sun, setsun] = useState(false);
  const [sunStart, setsunStart] = useState("");
  const [sunEnd, setsunEnd] = useState("");
  const [participants, setParticipants] = useState([]);
  const [openSuggestion, setOpenSuggestion] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [repeat, setRepeat] = useState(true);
  const [buttonDisable, setbuttonDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    if (ruleData) {
      setName(ruleData.name);
      setDescription(ruleData.description);
      setStartDate(
        moment(new Date(parseInt(ruleData.startDate))).format("YYYY-MM-DD")
      );
      setEndDate(
        moment(new Date(parseInt(ruleData.endDate))).format("YYYY-MM-DD")
      );
      ruleData.timings.forEach(item => {
        if (item.weekDay === "Monday") {
          setmon(true);
          setmonStart(item.startTime);
          setmonEnd(item.endTime);
        }
        if (item.weekDay === "Tuesday") {
          settue(true);
          settueStart(item.startTime);
          settueEnd(item.endTime);
        }
        if (item.weekDay === "Wednesday") {
          setwed(true);
          setwedStart(item.startTime);
          setwedEnd(item.endTime);
        }
        if (item.weekDay === "Thursday") {
          setthu(true);
          setthuStart(item.startTime);
          setthuEnd(item.endTime);
        }
        if (item.weekDay === "Friday") {
          setfri(true);
          setfriStart(item.startTime);
          setfriEnd(item.endTime);
        }
        if (item.weekDay === "Saturday") {
          setsat(true);
          setsatStart(item.startTime);
          setsatEnd(item.endTime);
        }
        if (item.weekDay === "Sunday") {
          setsun(true);
          setsunStart(item.startTime);
          setsunEnd(item.endTime);
        }
      });
      (async () => {
        try {
          const res = await api(
            "GET",
            "checkInRules/" + ruleData.id,
            null,
            true
          );
          setParticipants([
            ...res.accessIds.map(item => {
              if (item.userAccess.employee)
                return { ...item.userAccess.employee };
              if (item.userAccess.staff) return item.userAccess.staff;
              if (item.userAccess.visitor) return item.userAccess.visitor;
            })
          ]);
        } catch (error) {}
      })();
    } else {
      setParticipants([]);
    }
  }, [ruleData]);

  useEffect(() => {
    if (!openSuggestion) {
      setSuggestionList([]);
    } else {
      setLoading(true);
      (async () => {
        try {
          var data = await api(
            "POST",
            "checkInRules/searchUsers",
            { keyword: email },
            true
          );
          var suggestions = [...data.employee, ...data.staff].filter(
            item =>
              item.id !== user.userId &&
              !participants.map(item => item.id).includes(item.id)
          );
          setSuggestionList(suggestions);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          captureException(error);
        }
      })();
    }
  }, [openSuggestion, email]);

  const handleCreateMeeting = async () => {
    let data = {
      name,
      description,
      startDate: moment(startDate).format("MM-DD-YY") + " 00:00",
      endDate: moment(endDate).format("MM-DD-YY") + " 23:59",
      repeatWeekly: repeat,
      timings: []
    };

    if (mon)
      data.timings.push({
        weekDay: "Monday",
        startTime: monStart,
        endTime: monEnd
      });
    if (tue)
      data.timings.push({
        weekDay: "Tuesday",
        startTime: tueStart,
        endTime: tueEnd
      });
    if (wed)
      data.timings.push({
        weekDay: "Wednesday",
        startTime: wedStart,
        endTime: wedEnd
      });
    if (thu)
      data.timings.push({
        weekDay: "Thursday",
        startTime: thuStart,
        endTime: thuEnd
      });
    if (fri)
      data.timings.push({
        weekDay: "Friday",
        startTime: friStart,
        endTime: friEnd
      });
    if (sat)
      data.timings.push({
        weekDay: "Saturday",
        startTime: satStart,
        endTime: satEnd
      });
    if (sun)
      data.timings.push({
        weekDay: "Sunday",
        startTime: sunStart,
        endTime: sunEnd
      });
    try {
      if (!ruleData) {
        const res = await api("POST", "checkInRules", data, true);
        const access = await api(
          "POST",
          "checkInRules/mapRulesUser",
          {
            ruleId: res.id,
            accessIds: participants.map(item => item.accessDetails.id)
          },
          true
        );
        dispatch(
          toastAction({
            status: true,
            message: "Checkin rule successfully created",
            type: "success"
          })
        );
        close();
      } else {
        const res = await api(
          "PATCH",
          "checkInRules/" + ruleData.id,
          data,
          true
        );
        console.log(participants.map(item => item.accessDetails.id));
        const access = await api(
          "POST",
          "checkInRules/mapRulesUser",
          {
            ruleId: ruleData.id,
            accessIds: participants.map(item => item.accessDetails.id)
          },
          true
        );
        dispatch(
          toastAction({
            status: true,
            message: "Checkin rule successfully updated",
            type: "success"
          })
        );
        close();
      }
    } catch (error) {
      console.log(error);
      alert("Error");
    }
  };

  const handleDeleteMeeting = async () => {
    try {
      if (ruleData) {
        const res = await api(
          "DELETE",
          "checkInRules/" + ruleData.id,
          null,
          true
        );
        dispatch(
          toastAction({
            status: true,
            message: "Checkin rule successfully deleted",
            type: "success"
          })
        );
        close();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelection = newValue => {
    setParticipants([...participants, newValue]);
    console.log([...participants, newValue]);
    setEmail("");
  };

  const handleRemove = removeItem => {
    setParticipants([
      ...participants.filter(item => item.id !== removeItem.id)
    ]);
  };

  return (
    <div>
      <Grid alignItems="center" container spacing={3} direction="row">
        <Grid item>
          <IconButton
            aria-label="close"
            onClick={e => {
              close();
            }}
            size="small"
          >
            <KeyboardBackspace />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography
            variant="h6"
            onClick={e => {
              close();
            }}
            color="textPrimary"
            className={classes.backButton}
          >
            All Rules
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.container} container spacing={1}>
        <Grid item md={6} xs={12}>
          <Card>
            <CardContent>
              <Typography component="h2" gutterBottom variant="h4">
                Rule Details
              </Typography>
              <Grid container direction="row" spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    label="Rule Name"
                    id="name"
                    variant="outlined"
                    type="text"
                    margin="none"
                    value={name}
                    onChange={e => {
                      setName(e.target.value);
                    }}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    id="descroption"
                    variant="outlined"
                    type="text"
                    margin="none"
                    value={description}
                    onChange={e => {
                      setDescription(e.target.value);
                    }}
                    required
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid container direction="row" spacing={3}>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="Start Date"
                        type="date"
                        id="startDate"
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={startDate}
                        onChange={e => {
                          setStartDate(
                            moment(new Date(e.target.value)).format(
                              "YYYY-MM-DD"
                            )
                          );
                        }}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        label="End Date"
                        type="date"
                        id="endDate"
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={endDate}
                        onChange={e => {
                          setEndDate(
                            moment(new Date(e.target.value)).format(
                              "YYYY-MM-DD"
                            )
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      value="repeat"
                      control={
                        <Checkbox
                          checked={repeat}
                          onChange={e => setRepeat(e.target.checked)}
                        />
                      }
                      label="Repeat weekly"
                    />
                  </Grid>
                  <br />

                  <FormControl component="fieldset" fullWidth>
                    <FormLabel component="legend">Select days</FormLabel>

                    <Grid container alignItems="center" spacing={3}>
                      <Grid item xs={4}>
                        <FormControlLabel
                          value="monday"
                          control={
                            <Checkbox
                              checked={mon}
                              onChange={e => setmon(e.target.checked)}
                            />
                          }
                          label="Monday"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Start Time"
                          id="startTime"
                          type="time"
                          margin="normal"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={monStart}
                          onChange={e => {
                            setmonStart(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="End Time"
                          id="endTime"
                          type="time"
                          margin="normal"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={monEnd}
                          onChange={e => {
                            setmonEnd(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={3}>
                      <Grid item xs={4}>
                        <FormControlLabel
                          value="tuesday"
                          control={
                            <Checkbox
                              checked={tue}
                              onChange={e => settue(e.target.checked)}
                            />
                          }
                          label="Tuesday"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Start Time"
                          id="startTime"
                          type="time"
                          margin="normal"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={tueStart}
                          onChange={e => {
                            settueStart(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="End Time"
                          id="endTime"
                          type="time"
                          margin="normal"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={tueEnd}
                          onChange={e => {
                            settueEnd(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={3}>
                      <Grid item xs={4}>
                        <FormControlLabel
                          value="wednesday"
                          control={
                            <Checkbox
                              checked={wed}
                              onChange={e => setwed(e.target.checked)}
                            />
                          }
                          label="Wednesday"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Start Time"
                          id="startTime"
                          type="time"
                          margin="normal"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={wedStart}
                          onChange={e => {
                            setwedStart(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="End Time"
                          id="endTime"
                          type="time"
                          margin="normal"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={wedEnd}
                          onChange={e => {
                            setwedEnd(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={3}>
                      <Grid item xs={4}>
                        <FormControlLabel
                          value="thursday"
                          control={
                            <Checkbox
                              checked={thu}
                              onChange={e => setthu(e.target.checked)}
                            />
                          }
                          label="Thursday"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Start Time"
                          id="startTime"
                          type="time"
                          margin="normal"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={thuStart}
                          onChange={e => {
                            setthuStart(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="End Time"
                          id="endTime"
                          type="time"
                          margin="normal"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={thuEnd}
                          onChange={e => {
                            setthuEnd(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={3}>
                      <Grid item xs={4}>
                        <FormControlLabel
                          value="friday"
                          control={
                            <Checkbox
                              checked={fri}
                              onChange={e => setfri(e.target.checked)}
                            />
                          }
                          label="Friday"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Start Time"
                          id="startTime"
                          type="time"
                          margin="normal"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={friStart}
                          onChange={e => {
                            setfriStart(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="End Time"
                          id="endTime"
                          type="time"
                          margin="normal"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={friEnd}
                          onChange={e => {
                            setfriEnd(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={3}>
                      <Grid item xs={4}>
                        <FormControlLabel
                          value="saturday"
                          control={
                            <Checkbox
                              checked={sat}
                              onChange={e => setsat(e.target.checked)}
                            />
                          }
                          label="Saturday"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Start Time"
                          id="startTime"
                          type="time"
                          margin="normal"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={satStart}
                          onChange={e => {
                            setsatStart(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="End Time"
                          id="endTime"
                          type="time"
                          margin="normal"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={satEnd}
                          onChange={e => {
                            setsatEnd(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={3}>
                      <Grid item xs={4}>
                        <FormControlLabel
                          value="sunday"
                          control={
                            <Checkbox
                              checked={sun}
                              onChange={e => setsun(e.target.checked)}
                            />
                          }
                          label="Sunday"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="Start Time"
                          id="startTime"
                          type="time"
                          margin="normal"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={sunStart}
                          onChange={e => {
                            setsunStart(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          label="End Time"
                          id="endTime"
                          type="time"
                          margin="normal"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={sunEnd}
                          onChange={e => {
                            setsunEnd(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Card>
            <CardContent>
              <Typography component="h2" gutterBottom variant="h4">
                Users
              </Typography>
              <Grid item xs={12}>
                {participants.length === 0 && (
                  <Typography component="h2" gutterBottom variant="body1">
                    No participant added
                  </Typography>
                )}
                {participants.length > 0 && (
                  <List>
                    {participants.map((item, index) => (
                      <ListItem key={index} disableGutters>
                        <ListItemAvatar>
                          <Avatar style={getAvatarStyle(item.email)}>
                            {getInitials(item.name)}
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                          primary={
                            <Typography variant="h5">{item.name}</Typography>
                          }
                          secondary={
                            <Typography variant="body2" color="textSecondary">
                              {item.role &&
                                item[orgSettings.employeePrimaryIdentifier]}
                              {!item.role &&
                                item[orgSettings.visitorPrimaryIdentifier]}
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            color="primary"
                            onClick={() => handleRemove(item)}
                          >
                            <X />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  id="addParticipants"
                  key={participants.length}
                  open={openSuggestion}
                  noOptionsText={"No user found"}
                  options={suggestionList}
                  loading={loading}
                  onOpen={() => {
                    setOpenSuggestion(true);
                  }}
                  onClose={() => {
                    setOpenSuggestion(false);
                  }}
                  onChange={(event, newValue) => {
                    handleSelection(newValue);
                  }}
                  filterOptions={(options, params) => {
                    return filter(options, params);
                  }}
                  filterOptions={(options, state) => options}
                  getOptionLabel={option => {
                    return option.name;
                  }}
                  renderOption={item => {
                    return (
                      <ListItem
                        dense
                        disableGutters
                        className="suggestedParticipant"
                      >
                        <ListItemAvatar>
                          <Avatar style={getAvatarStyle(item.email)}>
                            {getInitials(item.inputValue ? "+" : item.name)}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography variant="h5">{item.name}</Typography>
                          }
                          secondary={
                            <Typography variant="body2" color="textSecondary">
                              {item.role &&
                                item[orgSettings.employeePrimaryIdentifier]}
                              {!item.role &&
                                item[orgSettings.visitorPrimaryIdentifier]}
                            </Typography>
                          }
                        />
                      </ListItem>
                    );
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={"Search participant by email or phone number"}
                      variant="outlined"
                      onChange={e => setEmail(e.target.value)}
                      value={email}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            {error !== "" && (
              <Alert
                severity="error"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setError("");
                    }}
                  >
                    <X />
                  </Button>
                }
              >
                {error}
              </Alert>
            )}
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container direction="row-reverse" spacing={3}>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    id="submit"
                    onClick={handleCreateMeeting}
                    disabled={buttonDisable}
                    startIcon={
                      buttonDisable ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        undefined
                      )
                    }
                  >
                    {ruleData && "Update Checkin Rule"}
                    {!ruleData && "Create Checkin Rule"}
                  </Button>
                </Grid>
                {ruleData && (
                  <Grid item>
                    <Button
                      color="secondary"
                      variant="contained"
                      id="submit"
                      onClick={handleDeleteMeeting}
                    >
                      Delete Checkin Rule
                    </Button>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default CheckinRuleModal;
