export default email => {
  const i = email ? email.length : 10;
  const styles = [
    { backgroundColor: "saddlebrown" },
    { backgroundColor: "yellowgreen" },
    { backgroundColor: "springgreen" },
    { backgroundColor: "rosybrown" },
    { backgroundColor: "teal" },
    { backgroundColor: "pink" },
    { backgroundColor: "darkslategray" },
    { backgroundColor: "blueviolet" },
    { backgroundColor: "cornflowerblue" },
    { backgroundColor: "brown" },
    { backgroundColor: "chocolate" },
    { backgroundColor: "darkgreen" },
    { backgroundColor: "darkorange" },
    { backgroundColor: "darkblue" },
    { backgroundColor: "dimgray" },
    { backgroundColor: "gold" },
    { backgroundColor: "fuchsia" },
    { backgroundColor: "lightcoral" },
    { backgroundColor: "lightseagreen" },
    { backgroundColor: "mediumblue" },
    { backgroundColor: "darkviolet" },
    { backgroundColor: "red" },
    { backgroundColor: "peru" },
    { backgroundColor: "saddlebrown" },
    { backgroundColor: "yellowgreen" },
    { backgroundColor: "springgreen" },
    { backgroundColor: "rosybrown" },
    { backgroundColor: "teal" },
    { backgroundColor: "pink" },
    { backgroundColor: "darkslategray" },
    { backgroundColor: "blueviolet" },
    { backgroundColor: "cornflowerblue" },
    { backgroundColor: "brown" },
    { backgroundColor: "chocolate" },
    { backgroundColor: "darkgreen" },
    { backgroundColor: "darkorange" },
    { backgroundColor: "darkblue" },
    { backgroundColor: "dimgray" },
    { backgroundColor: "gold" },
    { backgroundColor: "fuchsia" },
    { backgroundColor: "lightcoral" },
    { backgroundColor: "lightseagreen" },
    { backgroundColor: "mediumblue" },
    { backgroundColor: "darkviolet" },
    { backgroundColor: "red" },
    { backgroundColor: "peru" },
    { backgroundColor: "saddlebrown" },
    { backgroundColor: "yellowgreen" },
    { backgroundColor: "springgreen" },
    { backgroundColor: "rosybrown" },
    { backgroundColor: "teal" },
    { backgroundColor: "pink" },
    { backgroundColor: "darkslategray" },
    { backgroundColor: "blueviolet" },
    { backgroundColor: "cornflowerblue" },
    { backgroundColor: "brown" },
    { backgroundColor: "chocolate" },
    { backgroundColor: "darkgreen" },
    { backgroundColor: "darkorange" },
    { backgroundColor: "darkblue" },
    { backgroundColor: "dimgray" },
    { backgroundColor: "gold" },
    { backgroundColor: "fuchsia" },
    { backgroundColor: "lightcoral" },
    { backgroundColor: "lightseagreen" },
    { backgroundColor: "mediumblue" },
    { backgroundColor: "darkviolet" },
    { backgroundColor: "red" },
    { backgroundColor: "peru" },
    { backgroundColor: "saddlebrown" },
    { backgroundColor: "yellowgreen" },
    { backgroundColor: "springgreen" },
    { backgroundColor: "rosybrown" },
    { backgroundColor: "teal" },
    { backgroundColor: "pink" },
    { backgroundColor: "darkslategray" },
    { backgroundColor: "blueviolet" },
    { backgroundColor: "cornflowerblue" },
    { backgroundColor: "brown" },
    { backgroundColor: "chocolate" },
    { backgroundColor: "darkgreen" },
    { backgroundColor: "darkorange" },
    { backgroundColor: "darkblue" },
    { backgroundColor: "dimgray" },
    { backgroundColor: "gold" },
    { backgroundColor: "fuchsia" },
    { backgroundColor: "lightcoral" },
    { backgroundColor: "lightseagreen" },
    { backgroundColor: "mediumblue" },
    { backgroundColor: "darkviolet" },
    { backgroundColor: "red" },
    { backgroundColor: "peru" },
    { backgroundColor: "saddlebrown" },
    { backgroundColor: "yellowgreen" },
    { backgroundColor: "springgreen" },
    { backgroundColor: "rosybrown" },
    { backgroundColor: "teal" },
    { backgroundColor: "pink" },
    { backgroundColor: "darkslategray" },
    { backgroundColor: "blueviolet" },
    { backgroundColor: "cornflowerblue" },
    { backgroundColor: "brown" },
    { backgroundColor: "chocolate" },
    { backgroundColor: "darkgreen" },
    { backgroundColor: "darkorange" },
    { backgroundColor: "darkblue" },
    { backgroundColor: "dimgray" },
    { backgroundColor: "gold" },
    { backgroundColor: "fuchsia" },
    { backgroundColor: "lightcoral" },
    { backgroundColor: "lightseagreen" },
    { backgroundColor: "mediumblue" },
    { backgroundColor: "darkviolet" },
    { backgroundColor: "red" },
    { backgroundColor: "peru" },
    { backgroundColor: "saddlebrown" },
    { backgroundColor: "yellowgreen" },
    { backgroundColor: "springgreen" },
    { backgroundColor: "rosybrown" },
    { backgroundColor: "teal" },
    { backgroundColor: "pink" },
    { backgroundColor: "darkslategray" },
    { backgroundColor: "blueviolet" },
    { backgroundColor: "cornflowerblue" },
    { backgroundColor: "brown" },
    { backgroundColor: "chocolate" },
    { backgroundColor: "darkgreen" },
    { backgroundColor: "darkorange" },
    { backgroundColor: "darkblue" },
    { backgroundColor: "dimgray" },
    { backgroundColor: "gold" },
    { backgroundColor: "fuchsia" },
    { backgroundColor: "lightcoral" },
    { backgroundColor: "lightseagreen" },
    { backgroundColor: "mediumblue" },
    { backgroundColor: "darkviolet" },
    { backgroundColor: "red" },
    { backgroundColor: "peru" },
    { backgroundColor: "saddlebrown" },
    { backgroundColor: "yellowgreen" },
    { backgroundColor: "springgreen" },
    { backgroundColor: "rosybrown" },
    { backgroundColor: "teal" },
    { backgroundColor: "pink" },
    { backgroundColor: "darkslategray" },
    { backgroundColor: "blueviolet" },
    { backgroundColor: "cornflowerblue" },
    { backgroundColor: "brown" },
    { backgroundColor: "chocolate" },
    { backgroundColor: "darkgreen" },
    { backgroundColor: "darkorange" },
    { backgroundColor: "darkblue" },
    { backgroundColor: "dimgray" },
    { backgroundColor: "gold" },
    { backgroundColor: "fuchsia" },
    { backgroundColor: "lightcoral" },
    { backgroundColor: "lightseagreen" },
    { backgroundColor: "mediumblue" },
    { backgroundColor: "darkviolet" },
    { backgroundColor: "red" },
    { backgroundColor: "peru" },
    { backgroundColor: "saddlebrown" },
    { backgroundColor: "yellowgreen" },
    { backgroundColor: "springgreen" },
    { backgroundColor: "rosybrown" },
    { backgroundColor: "teal" },
    { backgroundColor: "pink" },
    { backgroundColor: "darkslategray" },
    { backgroundColor: "blueviolet" },
    { backgroundColor: "cornflowerblue" },
    { backgroundColor: "brown" },
    { backgroundColor: "chocolate" },
    { backgroundColor: "darkgreen" },
    { backgroundColor: "darkorange" },
    { backgroundColor: "darkblue" },
    { backgroundColor: "dimgray" },
    { backgroundColor: "gold" },
    { backgroundColor: "fuchsia" },
    { backgroundColor: "lightcoral" },
    { backgroundColor: "lightseagreen" },
    { backgroundColor: "mediumblue" },
    { backgroundColor: "darkviolet" },
    { backgroundColor: "red" },
    { backgroundColor: "peru" }
  ];
  return styles[i];
};
