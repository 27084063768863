export const BUSINESS = "BUSINESS";
export const ORGANIZATION = "ORGANIZATION";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const MEETING = "MEETING";
export const ORGANIZATIONSETTINGS = "ORGANIZATIONSETTINGS";
export const TOAST_MESSAGE = "TOAST_MESSAGE";
export const CLEAR_TOAST_MESSAGE = "CLEAR_TOAST_MESSAGE";

export const login = data => dispatch =>
  dispatch({
    type: LOGIN,
    payload: data
  });

export const business = data => dispatch =>
  dispatch({
    type: BUSINESS,
    payload: data
  });

export const organization = data => dispatch =>
  dispatch({
    type: ORGANIZATION,
    payload: data
  });

export const organizationSettings = data => dispatch =>
  dispatch({
    type: ORGANIZATIONSETTINGS,
    payload: data
  });

export const logout = () => dispatch =>
  dispatch({
    type: LOGOUT
  });

export const meeting = data => dispatch =>
  dispatch({
    type: MEETING,
    payload: data
  });

export const toastAction = data => dispatch =>
  dispatch({
    type: TOAST_MESSAGE,
    payload: data
  });

export const clearToastAction = data => dispatch =>
  dispatch({
    type: CLEAR_TOAST_MESSAGE,
    payload: data
  });
