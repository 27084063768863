import axios from "axios";
import baseurl from "./baseurl";

const api = async (
  apiType,
  apiName,
  data,
  includeToken,
  includeInstantKey,
  includeAPIKey,
  apiKey,
  customToken
) => {
  const token = localStorage ? localStorage.getItem("token") : null;
  const instantToken = localStorage
    ? localStorage.getItem("instantToken")
    : null;
  try {
    let response = await axios({
      method: apiType,
      url: baseurl + apiName,
      data: data || null,
      headers: customToken
        ? {
            Authorization: "Bearer " + customToken,
            "Content-Type": "application/json"
          }
        : includeToken
        ? {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json"
          }
        : includeInstantKey
        ? {
            Authorization: "Bearer " + instantToken,
            "Content-Type": "application/json"
          }
        : includeAPIKey
        ? {
            apikey: apiKey,
            "Content-Type": "application/json"
          }
        : { "Content-Type": "application/json" }
    });
    if (process.env.NODE_ENV !== "production") {
      console.group(
        `%cAPI%c ${apiType} %c${apiName}`,
        `color: blue; font-weight: 700;padding: 0 2px; border: 1px solid blue; border-radius: 2px`,
        `color: green; font-weight: 700`,
        `color: white;`
      );
      console.log(`%c[REQ][DATA] `, `color: orangered;font-weight: 700`, data);
      console.log(
        `%c[RES][DATA] `,
        `color: green;font-weight: 700`,
        response.data
      );
      console.groupEnd();
    }

    return response.data;
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      console.group(
        `%cAPI%c ${apiType} %c${apiName}`,
        `color: blue; font-weight: 700;padding: 0 2px; border: 1px solid blue; border-radius: 2px`,
        `color: green; font-weight: 700`,
        `color: white;`
      );
      console.log(
        `%c[REQ][DATA] `,
        `color: darkslategray;font-weight: 700`,
        data
      );
      console.log(
        `%c[RES][${error.response.status}] `,
        `color: red;font-weight: 700`,
        error.response.data
      );
      console.groupEnd();
    }
    return Promise.reject(error);
  }
};

export default api;
