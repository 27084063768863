import {
  Avatar,
  Box,
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
  OutlinedInput,
  Snackbar,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider,
  Select,
  List,
  MenuItem,
  ListItemSecondaryAction
} from "@material-ui/core";
import {
  ArrowBack,
  Clear,
  PersonOutline,
  Camera,
  Cancel,
  CheckCircle
} from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { X, Plus } from "react-feather";
import moment from "moment";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { organizationSettings, business } from "../../actions";
import Page from "../../components/Page";
import api from "../../utils/api";
import upload from "../../utils/upload";
import getAvatarStyle from "../../utils/getAvatarStyle";
import getInitials from "../../utils/getInitials";
import Loader from "../../utils/loader";
import Webcam from "react-webcam";

const OtpDialog = props => {
  const [pin, setPin] = useState("");
  const orgSettings = useSelector(state => state.orgSettings);

  const verifyOTP = async otp => {
    try {
      props.setToast({
        severity: "info",
        text: "Verifying OTP"
      });
      await api(
        "POST",
        "visitor/mobile/verifyOtpInstant",
        orgSettings.visitorPrimaryIdentifier === "email"
          ? {
              id: props.otpId,
              organizationId: props.organizationId,
              otp: otp
            }
          : {
              id: props.otpId,
              organizationId: props.organizationId,
              mobileOtp: otp
            },
        false,
        true
      );
      props.validateOTP();
      props.setToast({
        severity: "success",
        text: "OTP verified"
      });
      props.close();
    } catch (error) {
      setPin("");
      props.setToast({
        severity: "error",
        text: "Incorrect OTP!"
      });
    }
  };

  return (
    <Dialog open={props.open} aria-labelledby="otpDialog">
      <Box bgcolor="primary.main" pb={1} pt={2} style={{ maxWidth: 320 }}>
        <Typography variant="h1" component="h2" style={{ color: "#fff" }}>
          <IconButton
            size="medium"
            style={{ paddingTop: 8 }}
            onClick={props.close}
          >
            <ArrowBack style={{ color: "#fff", fontSize: 36 }} />
          </IconButton>
          Enter OTP
        </Typography>
      </Box>
      <DialogContent style={{ maxWidth: 320 }}>
        <Box py={1}>
          <Typography variant="h6" component="h2" color="primary">
            Enter the one time password you just received
          </Typography>

          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              id="pin"
              type="password"
              disabled
              value={pin}
              inputProps={{ style: { textAlign: "center", height: 20 } }}
              endAdornment={
                pin === "" ? null : (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setPin("");
                      }}
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                )
              }
              style={{
                padding: 0,
                paddingLeft: 8,
                fontSize: 32,
                letterSpacing: 24
              }}
            />
          </FormControl>
        </Box>
        <Box py={1}>
          <Grid container direction="row" spacing={1}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, "", 0, ""].map((item, index) =>
              item === "" ? (
                <Grid item xs={4} key={index}></Grid>
              ) : (
                <Grid item xs={4} key={index}>
                  <Button
                    style={{
                      width: "100%",
                      fontSize: 24,
                      backgroundColor: "#757de830"
                    }}
                    disabled={pin.length > 3}
                    onClick={() => {
                      setPin(pin + item);
                      if ((pin + item).length === 4) {
                        verifyOTP(pin + item);
                      }
                    }}
                  >
                    {item}
                  </Button>
                </Grid>
              )
            )}
          </Grid>
        </Box>
      </DialogContent>
      <Box bgcolor="primary.main" pb={1} pt={2}></Box>
    </Dialog>
  );
};

const OrgSelector = props => {
  return (
    <Dialog
      open={props.open}
      aria-labelledby="otpDialog"
      maxWidth="xs"
      fullWidth
    >
      <Box bgcolor="primary.main" pb={1} pt={2}></Box>
      <DialogContent style={{ maxWidth: 320 }}>
        <Typography variant="h3" color="primary">
          Select organization
        </Typography>
        {props.orgList.map(item => (
          <ListItem
            key={item.id}
            onClick={e => {
              props.onSelect(item.id);
              props.close();
            }}
          >
            <ListItemAvatar>
              <Avatar alt="item.name" src={item.logo}></Avatar>
            </ListItemAvatar>

            <ListItemText
              primary={<Typography variant="h5">{item.name}</Typography>}
            />
          </ListItem>
        ))}
      </DialogContent>
      <Box bgcolor="primary.main" pb={1} pt={2}></Box>
    </Dialog>
  );
};

const ActionSelector = props => {
  return (
    <Dialog
      open={props.open}
      aria-labelledby="otpDialog"
      maxWidth="xs"
      fullWidth
    >
      <Box bgcolor="primary.main" pb={1} pt={2}></Box>
      <DialogContent style={{ maxWidth: 320 }}>
        <Typography variant="button" color="primary">
          Select one
        </Typography>
        {props.actionList.map((item, index) => (
          <ListItem
            key={index}
            onClick={e => {
              props.onSelect(item);
              props.close();
            }}
          >
            <ListItemText
              primary={<Typography variant="h5">Booking a {item}</Typography>}
            />
          </ListItem>
        ))}
      </DialogContent>
      <Box bgcolor="primary.main" pb={1} pt={2}></Box>
    </Dialog>
  );
};

const WebcamCapture = props => {
  const webcamRef = React.useRef(null);
  const [captured, setcaptured] = useState("");

  const videoConstraints = {
    width: window.innerWidth,
    height: window.innerHeight,
    facingMode: "user"
  };
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setcaptured(imageSrc);
  }, [webcamRef]);

  return (
    <>
      {captured === "" && (
        <Webcam
          audio={false}
          height={window.innerHeight}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={window.innerWidth}
          videoConstraints={videoConstraints}
        />
      )}
      {captured !== "" && <img src={captured} width={window.innerWidth} />}
      <div
        style={{ position: "absolute", bottom: 5, width: "100vw", height: 120 }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-around"
        >
          {captured === "" && (
            <Grid item>
              <IconButton color="inherit" onClick={capture}>
                <Camera fontSize="large" color="primary" />
              </IconButton>
            </Grid>
          )}
          {captured !== "" && (
            <>
              <Grid item>
                <IconButton color="inherit" onClick={e => setcaptured("")}>
                  <Cancel fontSize="large" color="error" />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton color="inherit" onClick={e => props.done(captured)}>
                  <CheckCircle fontSize="large" color="action" />
                </IconButton>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </>
  );
};

const InstantMeeting = props => {
  const location = useLocation();
  const qrToken = location.search.split("=")[1];
  const [apiKey, setApiKey] = useState(true);
  const [loading, setLoading] = useState(true);
  const [suggestionLoading, setSuggestionLoading] = useState(true);
  const [orgSelectorOpen, setOrgSelectorOpen] = useState(true);
  const [webcamDialog, setwebcamDialog] = useState(false);
  const [capturedImage, setcapturedImage] = useState(null);
  const [actionSelectorOpen, setActionSelectorOpen] = useState(false);
  const [actionList, setactionList] = useState([]);
  const [bookingType, setBookingType] = useState("Visit");
  const [toast, setToast] = useState({ severity: "", text: "" });
  const [disableButton, setDisableButton] = useState(true);
  const [organization, setOrganization] = useState({});
  const [org, setOrg] = useState({});
  const [organizationList, setOrganizationList] = useState([]);
  const [organizationId, setOrganizationId] = useState("");
  const [step, setStep] = useState(0);
  const [countryCode, setCountryCode] = useState("");
  const [mobile, setMobile] = useState("");
  const [visitorEmail, setVisitorEmail] = useState("");
  const [company, setCompany] = useState("");
  const [visitorEmailValid, setVisitorEmailValid] = useState(true);
  const [otpId, setOtpId] = useState("");
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [visitorEmailVerified, setVisitorEmailVerified] = useState(false);
  const [visitorFound, setVisitorFound] = useState(false);
  const [foundVisitor, setFoundVisitor] = useState({});
  const [visitorName, setVisitorName] = useState("");
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [additionalParticipantCount, setadditionalParticipantCount] = useState(
    ""
  );
  const [newEmail, setNewEmail] = useState("");
  const [otherParticipants, setOtherParticipants] = useState([]);
  const [purpose, setPurpose] = useState("");
  const [localStartTime, setLocalStartTime] = useState(
    moment().format("HH:mm")
  );
  const [startTime, setStartTime] = useState(
    moment.utc(new Date()).format("MM-DD-YYYY HH:mm")
  );
  const [localEndTime, setLocalEndTime] = useState(
    moment()
      .add(30, "minutes")
      .format("HH:mm")
  );
  const [endTime, setEndTime] = useState(
    moment
      .utc(new Date())
      .add(30, "minutes")
      .format("MM-DD-YYYY HH:mm")
  );
  const [openSuggestion, setOpenSuggestion] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestionValue, setSuggestionValue] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [baggage, setbaggage] = useState([]);
  const [baggageModal, setbaggageModal] = useState(false);
  const [newBaggageError, setnewBaggageError] = useState("");
  const [newBaggageName, setnewBaggageName] = useState("");
  const [newBaggageDescription, setnewBaggageDescription] = useState("");
  const [newBaggageModel, setnewBaggageModel] = useState("");
  const [newBaggageSerial, setnewBaggageSerial] = useState("");
  var orgSettings = useSelector(state => state.orgSettings);
  const dispatch = useDispatch();
  const localDate = moment().format("YYYY-MM-DD");
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const navigate = useNavigate();

  const clearToast = () => {
    setToast({
      severity: "",
      text: ""
    });
  };
  useEffect(() => {
    (async () => {
      try {
        let validateDevice = await api(
          "POST",
          "auth/mobile/getAuthDetailsFromToken",
          {
            token: qrToken
          },
          false
        );
        localStorage.setItem("instantToken", qrToken);
        // console.log(validateDevice);
        const orgSettingsNew = await api(
          "GET",
          "business/getBusinessSettings/" + validateDevice.data.businessId,
          null,
          true
        );
        let theActionList = [];
        if (orgSettingsNew.instantMeetingEnable) theActionList.push("Meeting");
        if (orgSettingsNew.checkInSystemEnable) theActionList.push("Visit");
        setactionList(theActionList);
        dispatch(organizationSettings(orgSettingsNew));
        setApiKey(validateDevice.data.apiKey);

        setOrganization({
          name: validateDevice.data.businessName,
          logo: validateDevice.data.businessLogo,
        });
        const orgList = await api(
          "GET",
          "device/getOrganizations",
          null,
          false,
          false,
          true,
          validateDevice.data.apiKey
        );
        setOrganizationList(orgList.data);
        setLoading(false);
      } catch (error) {
        console.log("QRError", error);
        alert("QR code scanned is not valid");
      }
    })();
  }, []);

  const selectOrg = async orgId => {
    setOrganizationId(orgId);

    const orgDetails = await api(
      "GET",
      "organization/" + orgId,
      null,
      false,
      true
    );
    setOrg(orgDetails);
    
    const orgSettingsNew = await api(
      "GET",
      "organization/getOrgSettings/" + orgId,
      null,
      false,
      true
    );
    dispatch(organizationSettings({ ...orgSettings, orgSettingsNew }));

    if (actionList.length === 1) {
      setBookingType(actionList[0]);
    } else {
      setActionSelectorOpen(true);
    }
  };

  const searchHost = async keyword => {
    try {
      console.log(apiKey);
      let data = await api(
        "POST",
        "meeting/mobile/searchInstanMeetingUsers",
        {
          keyword: keyword,
          entity: "employee",
          organizationId: organizationId
        },
        false,
        false,
        true,
        apiKey
      );

      setSuggestionLoading(false);
      setSuggestionList(data.data);
    } catch (error) {
      setSuggestionLoading(false);
      setSuggestionList([]);
    }
  };

  const handleSuggestionValueChange = event => {
    setSuggestionValue(event.target.value);
  };

  const dataURItoBlob = dataURI => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    //New Code
    return new Blob([ab], { type: mimeString });
  };

  const handleSubmit = async () => {
    if (step === 0) {
      if (!visitorEmailVerified) {
        if (!visitorEmailValid) {
          // Show validation error
          setToast({
            severity: "error",
            text: "Invalid email address"
          });
        } else {
          //send OTP
          setToast({
            severity: "info",
            text: "Sending OTP"
          });
          try {
            let data = await api(
              "POST",
              "visitor/mobile/sendOtpInstant",
              orgSettings.visitorPrimaryIdentifier === "email"
                ? {
                    email: visitorEmail,
                    organizationId: organizationId
                  }
                : {
                    organizationId: organizationId,
                    countryCode,
                    mobile
                  },
              false,
              true
            );
            setOtpId(data.data);
            setOtpModalOpen(true);
            if (data.user.hasOwnProperty("id")) {
              setVisitorFound(true);
              setFoundVisitor(data.user);
              setVisitorEmail(data.user.email);
              setCountryCode(data.user.countryCode);
              setMobile(data.user.mobile);
              setCompany(data.user.company);
              setVisitorName(data.user.name);
            } else {
              setVisitorFound(false);
            }
            setToast({
              severity: "success",
              text: "OTP successfully  sent"
            });
          } catch (error) {
            setToast({
              severity: "error",
              text: error.response.data.message || "Something went wrong"
            });
          }
        }
      } else {
        if (visitorFound) {
          let body = {
            name: visitorName,
            organizationId: organizationId,
            id: foundVisitor.id,
            company: company
          };

          if (orgSettings.visitorRequiredFields.includes("email")) {
            if (visitorEmail === "") {
              setToast({
                severity: "error",
                text: "Please enter a valid email address"
              });
              return;
            }
            body.email = visitorEmail;
          }
          if (orgSettings.visitorRequiredFields.includes("mobile")) {
            if (mobile === "" || countryCode === "") {
              setToast({
                severity: "error",
                text: "Please enter country code and phone number"
              });
              return;
            }
            body.mobile = mobile;
            body.countryCode = countryCode;
          }
          // Update Visitor
          var file;
          if (capturedImage) {
            let blob = dataURItoBlob(capturedImage);

            file = new File([blob], Date.now() + ".png", {
              type: "image/png"
            });
            setToast({
              message: "Uploading image ... ",
              severity: "info"
            });
            const url = await upload(file, "visitorprofile");
            console.log(url);
            body.photo = url;
          }
          setToast({
            message: "Uploading image ... ",
            severity: "info"
          });

          try {
            await api(
              "PATCH",
              "visitor/mobile/update",
              body,
              false,
              false,
              true,
              apiKey
            );
            // go to next step
            setStep(1);
          } catch (error) {
            if (error.response.data && error.response.data.message)
              alert(error.response.data.message);
          }
        } else {
          // Create Visitor
          setToast({
            severity: "info",
            text: "Registering as a visitor"
          });
          // if success setStep(1)
          try {
            let data = await api(
              "POST",
              "visitor/mobile/create",
              orgSettings.visitorPrimaryIdentifier === "email"
                ? {
                    name: visitorName,
                    email: visitorEmail,
                    organizationId: organizationId,
                    company: company
                  }
                : {
                    name: visitorName,
                    countryCode,
                    mobile,
                    organizationId: organizationId,
                    company: company
                  },
              false,
              false,
              true,
              apiKey
            );
            // go to next step
            setStep(1);
            setFoundVisitor(data.data);
          } catch (error) {
            if (error.response.data && error.response.data.message)
              alert(error.response.data.message);
          }
        }
      }
    } else if (step === 1) {
      if (
        bookingType === "Meeting" &&
        orgSettings.EmployeePrimaryIdentifier === "email" &&
        !emailRegex.test(String(employeeEmail).toLowerCase())
      ) {
        setToast({
          severity: "error",
          text: "Employee's email address is not valid"
        });
        return;
      } else if (purpose.trim() === "") {
        setToast({
          severity: "error",
          text: "Please enter the purpose of meeting!"
        });
        return;
      } else if (
        moment(new Date(localDate + "T" + localStartTime))
          .local()
          .isBefore(moment().local())
      ) {
        setToast({
          severity: "error",
          text: "Start time can not be before current time!"
        });
        return;
      }
      if (moment(endTime).isBefore(moment(startTime))) {
        setToast({
          severity: "error",
          text: "End time can not be before start time"
        });
        return;
      }
      let meetingdata = {
        endTime: endTime,
        hostEmail: employeeEmail,
        otherParticipants: otherParticipants,
        organizationId: organizationId,
        purpose: purpose,
        startTime: startTime,
        visitorId: foundVisitor.id,
        baggage: baggage,
        additionalParticipantCount: additionalParticipantCount
      };

      let visitData = {
        endTime: endTime,
        organizationId: organizationId,
        purpose: purpose,
        startTime: startTime,
        visitorId: foundVisitor.id,
        baggage: baggage
      };
      try {
        setDisableButton(true);
        var data;
        if (bookingType === "Visit") {
          data = await api(
            "POST",
            "checkIn/mobile/create",
            visitData,
            false,
            false,
            true,
            apiKey
          );
        } else if (bookingType === "Meeting") {
          data = await api(
            "POST",
            "meeting/mobile/create",
            meetingdata,
            false,
            false,
            true,
            apiKey
          );
        }
        setSuccessMessage(data.message);
        setToast({
          severity: "success",
          text: data.message
        });
        setStep(2);
        if (
          orgSettings.showSafetySurvey &&
          orgSettings.safetySurveyWhileBooking &&
          bookingType === "Meeting"
        ) {
          orgSettings.allowAllAnswers &&
            navigate(
              "/checkinSteps?action=survey&token=" +
                data.token +
                "&participantId=" +
                data.data.participantId +
                "&allow=true"
            );
          !orgSettings.allowAllAnswers &&
            navigate(
              "/checkinSteps?action=survey&token=" +
                data.token +
                "&participantId=" +
                data.data.participantId
            );
        }
      } catch (error) {
        setDisableButton(false);
        if (error.response.data && error.response.data.message) {
          setToast({
            severity: "error",
            text: error.response.data.message
          });
        }
      }
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <Page title="Instant Meeting">
      <Container maxWidth="sm">
        <Box p={3} m={3} style={{ backgroundColor: "#fff" }}>
          <Box
            mb={3}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <img src={organization.logo} style={{ maxWidth: 180 }} alt="logo" />
          </Box>
          <Typography
            color="primary"
            variant="h1"
            component="h2"
            align="center"
            style={{ fontWeight: 900 }}
          >
            Welcome to {organization.name}
          </Typography>
          <Box mt={3} mb={2}>
            <Typography variant="h5" component="h2" align="center">
              {step === 0 &&
                !visitorEmailVerified &&
                "Please provide your " + orgSettings.visitorPrimaryIdentifier}
              {step === 0 && visitorEmailVerified && "Your visitor profile"}
              {step === 1 && "Meeting request form"}
            </Typography>
          </Box>
          {step === 0 && (
            <>
              <Box mb={3}>
                {orgSettings.visitorPrimaryIdentifier === "email" && (
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="email">
                      {"Your " + orgSettings.visitorPrimaryIdentifier}
                    </InputLabel>
                    <OutlinedInput
                      id="email"
                      type="text"
                      disabled={visitorEmailVerified}
                      label={"Your " + orgSettings.visitorPrimaryIdentifier}
                      onChange={e => {
                        setVisitorEmail(e.target.value.trim());
                        if (
                          emailRegex.test(
                            String(e.target.value.trim()).toLowerCase()
                          )
                        ) {
                          setVisitorEmailValid(true);
                          setDisableButton(false);
                        } else {
                          setVisitorEmailValid(false);
                          setDisableButton(true);
                        }
                      }}
                      value={visitorEmail}
                      style={{ paddingRight: 0 }}
                    />
                  </FormControl>
                )}

                {orgSettings.visitorPrimaryIdentifier === "mobile" && (
                  <PhoneInput
                    country={org?.countrycode ?? "in"}
                    enableSearch
                    searchClass="MuiOutlinedInput-input MuiOutlinedInput-inputMarginDense"
                    preferredCountries={["sg", "in", "us"]}
                    inputStyle={{ width: "100%" }}
                    specialLabel="Phone number"
                    countryCodeEditable={false}
                    disabled={visitorEmailVerified}
                    onChange={(value, country) => {
                      setCountryCode("+" + country.dialCode);
                      setMobile(value.replace(country.dialCode, ""));
                      setVisitorEmailValid(true);
                      setDisableButton(false);
                    }}
                  />
                )}
              </Box>

              {visitorEmailVerified && (
                <>
                  <Box my={3}>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      id="name"
                      value={visitorName}
                      onChange={e => setVisitorName(e.target.value)}
                      label="Full name"
                    />
                  </Box>
                  <Box mb={3}>
                    {orgSettings.visitorPrimaryIdentifier !== "email" &&
                      JSON.parse(orgSettings.visitorRequiredFields).includes(
                        "email"
                      ) && (
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel htmlFor="email">Your email</InputLabel>
                          <OutlinedInput
                            id="email"
                            type="text"
                            label={"Your email"}
                            onChange={e => {
                              setVisitorEmail(e.target.value.trim());
                              if (
                                emailRegex.test(
                                  String(e.target.value.trim()).toLowerCase()
                                )
                              ) {
                                setVisitorEmailValid(true);
                                setDisableButton(false);
                              } else {
                                setVisitorEmailValid(false);
                                setDisableButton(true);
                              }
                            }}
                            value={visitorEmail}
                            style={{ paddingRight: 0 }}
                          />
                        </FormControl>
                      )}

                    {orgSettings.visitorPrimaryIdentifier !== "mobile" &&
                      JSON.parse(orgSettings.visitorRequiredFields).includes(
                        "mobile"
                      ) && (
                        <PhoneInput
                          country={org?.countrycode ?? "in"}
                          enableSearch
                          searchClass="MuiOutlinedInput-input MuiOutlinedInput-inputMarginDense"
                          preferredCountries={["sg", "in", "us"]}
                          inputStyle={{ width: "100%" }}
                          specialLabel="Phone number"
                          countryCodeEditable={false}
                          value={
                            countryCode && mobile
                              ? countryCode.replace("+", "") + mobile
                              : ""
                          }
                          onChange={(value, country) => {
                            setCountryCode("+" + country.dialCode);
                            setMobile(value.replace(country.dialCode, ""));
                            setVisitorEmailValid(true);
                            setDisableButton(false);
                          }}
                        />
                      )}
                    {JSON.parse(orgSettings.visitorAdditionalFields).includes(
                      "company"
                    ) && (
                      <TextField
                        label="Company"
                        variant="outlined"
                        type="text"
                        margin="normal"
                        required
                        fullWidth
                        value={company}
                        onChange={e => {
                          setCompany(e.target.value);
                        }}
                      />
                    )}
                    {bookingType === "Visit" &&
                      orgSettings.checkInVisitProfilePhoto && (
                        <>
                          <Typography variant="h5"> Profile picture</Typography>
                          <Typography variant="body2">
                            Click on the picture below
                          </Typography>
                          <br />
                          <Card
                            onClick={e => {
                              // if (document.requestFullscreen) {
                              //   document.requestFullscreen();
                              // } else if (document.webkitRequestFullscreen) {
                              //   /* Safari */
                              //   document.webkitRequestFullscreen();
                              // } else if (document.msRequestFullscreen) {
                              //   /* IE11 */
                              //   document.msRequestFullscreen();
                              // }
                              setwebcamDialog(true);
                            }}
                          >
                            <CardMedia
                              image={
                                capturedImage ??
                                "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                              }
                              title="profile picture"
                              style={{ height: 300 }}
                            />
                          </Card>
                        </>
                      )}
                  </Box>
                </>
              )}
            </>
          )}
          {step === 1 && (
            <Box mb={3}>
              <Grid container direction="row" spacing={3}>
                {bookingType === "Visit" && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        id="purpose"
                        variant="outlined"
                        fullWidth
                        type="text"
                        label="Purpose of Meeting"
                        onChange={e => {
                          setPurpose(e.target.value);
                        }}
                        value={purpose}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Start Time"
                        id="startTime"
                        type="time"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={localStartTime}
                        onChange={e => {
                          setStartTime(
                            moment
                              .utc(new Date(localDate + "T" + e.target.value))
                              .format("MM-DD-YYYY HH:mm")
                          );
                          setLocalStartTime(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="End Time"
                        id="endTime"
                        type="time"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={localEndTime}
                        onChange={e => {
                          setEndTime(
                            moment
                              .utc(new Date(localDate + "T" + e.target.value))
                              .format("MM-DD-YYYY HH:mm")
                          );
                          setLocalEndTime(e.target.value);
                        }}
                      />
                    </Grid>
                  </>
                )}
                {bookingType === "Meeting" && (
                  <>
                    <Grid item xs={12}>
                      {orgSettings?.searchInstantMeetingUser && (
                        <Autocomplete
                          fullWidth
                          id="addParticipants"
                          open={openSuggestion}
                          noOptionsText={"No user found"}
                          options={suggestionList}
                          loading={suggestionLoading}
                          onOpen={() => {
                            setOpenSuggestion(true);
                          }}
                          onClose={() => {
                            setOpenSuggestion(false);
                          }}
                          filterOptions={(options, state) => options}
                          onChange={(event, newValue) => {
                            searchHost(newValue);
                            setSuggestionValue(newValue);
                            setEmployeeEmail(
                              newValue[orgSettings.employeePrimaryIdentifier]
                            );
                          }}
                          onInputChange={(event, newInputValue) => {
                            setSuggestionValue(newInputValue);
                            searchHost(newInputValue);
                          }}
                          filterOptions={(options, state) => options}
                          getOptionLabel={option => {
                            return option.name;
                          }}
                          renderOption={item => {
                            return (
                              <ListItem
                                dense
                                disableGutters
                                className="suggestedParticipant"
                              >
                                <ListItemAvatar>
                                  <Avatar
                                    style={getAvatarStyle(item.email || "")}
                                  >
                                    {getInitials(
                                      item.inputValue ? "+" : item.name
                                    )}
                                  </Avatar>
                                </ListItemAvatar>

                                <ListItemText
                                  primary={
                                    <Typography variant="h5">
                                      {item.name}
                                    </Typography>
                                  }
                                  // secondary={
                                  //   <Typography
                                  //     variant="body2"
                                  //     color="textSecondary"
                                  //   >
                                  //     {item.role &&
                                  //       item[
                                  //         orgSettings.employeePrimaryIdentifier
                                  //       ]}
                                  //     {!item.role &&
                                  //       item[
                                  //         orgSettings.visitorPrimaryIdentifier
                                  //       ]}
                                  //   </Typography>
                                  // }
                                />
                              </ListItem>
                            );
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label={"Search host by name or contact details"}
                              variant="outlined"
                              onChange={handleSuggestionValueChange}
                              value={suggestionValue}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                      )}

                      {!orgSettings?.searchInstantMeetingUser && (
                        <TextField
                          id="employeeEmail"
                          variant="outlined"
                          fullWidth
                          type="email"
                          label="Enter host's email address"
                          onChange={e => {
                            setEmployeeEmail(e.target.value.trim());
                          }}
                          value={employeeEmail}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="purpose"
                        variant="outlined"
                        fullWidth
                        type="text"
                        label="Purpose of Meeting"
                        onChange={e => {
                          setPurpose(e.target.value);
                        }}
                        value={purpose}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Start Time"
                        id="startTime"
                        type="time"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={localStartTime}
                        onChange={e => {
                          setStartTime(
                            moment
                              .utc(new Date(localDate + "T" + e.target.value))
                              .format("MM-DD-YYYY HH:mm")
                          );
                          setLocalStartTime(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="End Time"
                        id="endTime"
                        type="time"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        value={localEndTime}
                        onChange={e => {
                          setEndTime(
                            moment
                              .utc(new Date(localDate + "T" + e.target.value))
                              .format("MM-DD-YYYY HH:mm")
                          );
                          setLocalEndTime(e.target.value);
                        }}
                      />
                    </Grid>

                    {orgSettings.baggageBusinessEnable && (
                      <Grid item xs={12}>
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <Typography
                              component="h2"
                              gutterBottom
                              variant="h4"
                            >
                              Baggage Details
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Button
                              color="primary"
                              variant="outlined"
                              id="submit"
                              size="large"
                              onClick={e => {
                                setbaggageModal(true);
                              }}
                              startIcon={<Plus />}
                            >
                              Add
                            </Button>
                          </Grid>
                        </Grid>
                        <Dialog
                          open={baggageModal}
                          onClose={e => setbaggageModal(false)}
                          maxWidth="xs"
                          fullWidth
                          aria-labelledby="form-dialog-title"
                        >
                          <DialogContent>
                            <Grid
                              container
                              direction="row"
                              justify="space-between"
                              alignItems="center"
                            >
                              <Grid item>
                                <Typography
                                  component="h2"
                                  gutterBottom
                                  variant="h4"
                                >
                                  Add Baggage
                                </Typography>
                              </Grid>
                              <Grid item>
                                <IconButton
                                  edge="end"
                                  color="primary"
                                  onClick={() => setbaggageModal(false)}
                                >
                                  <X />
                                </IconButton>
                              </Grid>
                            </Grid>
                            {newBaggageError !== "" && (
                              <Alert
                                severity="error"
                                action={
                                  <Button
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                      setnewBaggageError("");
                                    }}
                                  >
                                    <X />
                                  </Button>
                                }
                              >
                                {newBaggageError}
                              </Alert>
                            )}
                            <FormControl
                              variant="outlined"
                              style={{ width: "100%" }}
                              id="baggageSelect"
                            >
                              <InputLabel id="building"> Type</InputLabel>
                              <Select
                                labelId="type"
                                id="type"
                                label="Type"
                                value={newBaggageName}
                                onChange={e => {
                                  setnewBaggageError("");
                                  setnewBaggageName(e.target.value);
                                }}
                                autoWidth
                              >
                                {JSON.parse(orgSettings.baggageItems).map(
                                  bitem => (
                                    <MenuItem value={bitem}>
                                      {bitem.charAt(0).toUpperCase() +
                                        bitem.slice(1)}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                            <br />
                            <br />
                            <TextField
                              label="Description"
                              id="description"
                              variant="outlined"
                              type="text"
                              margin="none"
                              value={newBaggageDescription}
                              onChange={e => {
                                setnewBaggageError("");
                                setnewBaggageDescription(e.target.value);
                              }}
                              fullWidth
                            />
                            <br />
                            <br />

                            {JSON.parse(orgSettings.baggageFields).includes(
                              "model"
                            ) && (
                              <>
                                <TextField
                                  label="Make and model"
                                  id="model"
                                  variant="outlined"
                                  type="text"
                                  margin="none"
                                  value={newBaggageModel}
                                  onChange={e => {
                                    setnewBaggageError("");
                                    setnewBaggageModel(e.target.value);
                                  }}
                                  fullWidth
                                />
                                <br />
                                <br />
                              </>
                            )}
                            {JSON.parse(orgSettings.baggageFields).includes(
                              "model"
                            ) && (
                              <>
                                <TextField
                                  label="Serial no."
                                  id="model"
                                  variant="outlined"
                                  type="text"
                                  margin="none"
                                  value={newBaggageSerial}
                                  onChange={e => {
                                    setnewBaggageError("");
                                    setnewBaggageSerial(e.target.value);
                                  }}
                                  fullWidth
                                />
                                <br />
                                <br />
                              </>
                            )}

                            <Button
                              color="primary"
                              variant="contained"
                              id="submit"
                              size="large"
                              fullWidth
                              onClick={e => {
                                if (newBaggageName !== "") {
                                  setbaggage([
                                    ...baggage,
                                    {
                                      name: newBaggageName,
                                      description: newBaggageDescription,
                                      model: newBaggageModel,
                                      serial: newBaggageSerial
                                    }
                                  ]);
                                  setnewBaggageName("");
                                  setnewBaggageDescription("");
                                  setnewBaggageModel("");
                                  setnewBaggageSerial("");
                                  setbaggageModal(false);
                                } else {
                                  setnewBaggageError(
                                    "Please fill all the fields"
                                  );
                                }
                              }}
                            >
                              Add Baggage to List
                            </Button>
                            <br />
                            <br />
                          </DialogContent>
                        </Dialog>
                        <List dense>
                          <Divider />
                          {baggage.map((bag, index) => (
                            <ListItem divider key={index}>
                              <ListItemText
                                primary={
                                  bag.name.charAt(0).toUpperCase() +
                                  bag.name.slice(1)
                                }
                                secondary={
                                  <React.Fragment>
                                    <Typography
                                      component="span"
                                      variant="body2"
                                      color="textPrimary"
                                    >
                                      {bag.description}
                                    </Typography>
                                    {bag.model !== "" &&
                                      bag.model !== null &&
                                      " - " + bag.model}
                                    {bag.serial !== "" &&
                                      bag.serial !== null &&
                                      " - " + bag.serial}
                                  </React.Fragment>
                                }
                              />
                              <ListItemSecondaryAction>
                                <IconButton
                                  edge="end"
                                  onClick={e => {
                                    setbaggage(
                                      baggage.filter((b, i) => i !== index)
                                    );
                                  }}
                                >
                                  <X />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                    )}

                    {!orgSettings.addParticipantCountEnable && (
                      <>
                        <Grid item xs={12}>
                          <Typography variant="h6" color="primary">
                            Additional participants
                          </Typography>
                          <OutlinedInput
                            id="addEmail"
                            variant="outlined"
                            fullWidth
                            type="email"
                            value={newEmail}
                            onChange={e => setNewEmail(e.target.value.trim())}
                            placeholder={"Enter phone number or email"}
                            inputProps={{}}
                            endAdornment={
                              <InputAdornment position="end">
                                <Button
                                  color="primary"
                                  onClick={e => {
                                    if (
                                      emailRegex.test(
                                        String(newEmail).toLowerCase()
                                      )
                                    ) {
                                      if (
                                        !otherParticipants.includes(newEmail) &&
                                        newEmail !== employeeEmail &&
                                        newEmail !== foundVisitor.email
                                      ) {
                                        setOtherParticipants([
                                          ...otherParticipants,
                                          newEmail
                                        ]);
                                        setNewEmail("");
                                      } else {
                                        setToast({
                                          severity: "error",
                                          text: "Email address already added"
                                        });
                                      }
                                    } else {
                                      setToast({
                                        severity: "error",
                                        text: "Email address is not valid"
                                      });
                                    }
                                  }}
                                >
                                  Add
                                </Button>
                              </InputAdornment>
                            }
                          />
                        </Grid>

                        {otherParticipants.length > 0 && (
                          <Box width={"100%"} m={1.5} mt={0}>
                            {otherParticipants.map((item, index) => (
                              <Box width={"100%"} key={index} my={0.5}>
                                <Alert
                                  icon={<PersonOutline />}
                                  onClose={() =>
                                    setOtherParticipants(
                                      otherParticipants.filter(
                                        email => email !== item
                                      )
                                    )
                                  }
                                >
                                  {item}
                                </Alert>
                              </Box>
                            ))}
                          </Box>
                        )}
                      </>
                    )}
                    {orgSettings.addParticipantCountEnable && (
                      <>
                        <Grid item xs={12}>
                          <TextField
                            id="additionalCount"
                            variant="outlined"
                            fullWidth
                            type="number"
                            label="Additional Participant Count"
                            onChange={e => {
                              setadditionalParticipantCount(e.target.value);
                            }}
                            value={additionalParticipantCount}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Box>
          )}
          <Box my={3}>
            {step === 0 && !visitorEmailVerified && (
              <Alert severity="info">
                We will send an OTP for verification{" "}
              </Alert>
            )}
            {step === 0 && visitorEmailVerified && visitorFound && (
              <Alert severity="info">
                You can update your profile details or press 'Continue'
              </Alert>
            )}
            {step === 0 && visitorEmailVerified && !visitorFound && (
              <Alert severity="info">
                Provide your profile details and press 'Register and Continue'
              </Alert>
            )}
            {step === 1 && (
              <Alert severity="info">
                Fill up the form and press 'Submit' to send your {bookingType}{" "}
                request
              </Alert>
            )}
            {step === 2 && (
              <Alert severity="success">
                <AlertTitle>Success!</AlertTitle>
                {successMessage}
              </Alert>
            )}
          </Box>
          <Box xs={12} my={3}>
            <Button
              fullWidth
              disabled={disableButton}
              variant="contained"
              color="primary"
              size="large"
              onClick={() => handleSubmit()}
            >
              {step === 0 && !visitorEmailVerified && "Verify"}
              {step === 0 && visitorEmailVerified && visitorFound && "Continue"}
              {step === 0 &&
                visitorEmailVerified &&
                !visitorFound &&
                "Register and continue"}
              {step === 1 && "Create Meeting"}
              {step === 2 && "Done!"}
            </Button>
          </Box>
          <Grid
            container
            direction="row"
            spacing={1}
            alignItems="center"
            justify="center"
          >
            <Grid item>
              <Typography variant="h5" color="primary">
                Powered by
              </Typography>
            </Grid>
            <Grid item>
              <img
                src="https://hipla.io/vectors/hipla-logo.svg"
                style={{ width: 100 }}
                alt="hipla"
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Dialog open={webcamDialog} fullScreen>
        <WebcamCapture
          done={url => {
            // if (document.exitFullscreen) {
            //   document.exitFullscreen();
            // } else if (document.webkitExitFullscreen) {
            //   /* Safari */
            //   document.webkitExitFullscreen();
            // } else if (document.msExitFullscreen) {
            //   /* IE11 */
            //   document.msExitFullscreen();
            // }
            setcapturedImage(url);
            setwebcamDialog(false);
          }}
        />
      </Dialog>

      <OtpDialog
        open={otpModalOpen}
        close={() => setOtpModalOpen(false)}
        otpId={otpId}
        validateOTP={() => setVisitorEmailVerified(true)}
        organizationId={organizationId}
        setToast={setToast}
      />
      <OrgSelector
        open={orgSelectorOpen}
        close={e => {
          setOrgSelectorOpen(false);
        }}
        orgList={organizationList}
        onSelect={selectOrg}
      ></OrgSelector>
      <ActionSelector
        open={actionSelectorOpen}
        close={e => setActionSelectorOpen(false)}
        actionList={actionList}
        onSelect={item => setBookingType(item)}
      ></ActionSelector>
      <Snackbar
        open={toast.text !== ""}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={clearToast}
      >
        <Alert
          onClose={clearToast}
          variant="filled"
          severity={toast.severity || "info"}
        >
          {toast.text}
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default InstantMeeting;
