import {
  Button,
  Container,
  Grid,
  makeStyles,
  Card,
  Divider,
  CardHeader,
  CardContent,
  Typography
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { clearToastAction, toastAction } from "../../actions";
import { useParams } from "react-router-dom";
import Page from "src/components/Page";
import api from "../../utils/api";
import Loader from "../../utils/loader";
import Profile from "./Profile";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  mb4: {
    marginBottom: theme.spacing(3)
  }
}));

const VisitingCard = () => {
  const classes = useStyles();
  const params = useParams();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  useEffect(() => {
    (async () => {
      try {
        const data = await api("GET", `employee/${params.id}`, null, true);
        setUser(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setError("No data found!")
        setLoading(false);
      }
    })();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <Page className={classes.root} title="VisitingCard">
      <Container maxWidth="sm">
        {error && <Alert severity="error">No data found!</Alert>}
        {!error && <Profile userProfile={user} className={classes.mb4} />}
      </Container>
    </Page>
  );
};

export default VisitingCard;
