import {
  Chip,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Grid,
  Button,
  Box,
  Divider
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import { captureException } from "@sentry/browser";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { clearToastAction, toastAction } from "../../../../actions";
import api from "../../../../utils/api";
import AddModal from "../AddModal";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end"
  },
  capitalize: {
    textTransform: "capitalize"
  },
  chipButton: {
    paddingLeft: 12,
    paddingRight: 12
  },
  redButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.dark
    },
    "&:disabled": {
      backgroundColor: theme.palette.error.light
    }
  }
}));

const Visitors = props => {
  const { className, reload, ...rest } = props;
  const classes = useStyles();
  const [visitors, setVisitors] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [organizationList, setOrganizationList] = useState([]);
  const [filters, setFilters] = useState({});
  const [currentSort, setCurrentSort] = useState({
    label: "",
    direction: "asc"
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [query, setQuery] = useState({
    search: [],
    sort: { key: "visitor.createdAt", order: "DESC" },
    skip: page * rowsPerPage,
    take: rowsPerPage
  });

  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const orgSettings = useSelector(state => {
    return state.orgSettings;
  });

  var columns = [
    {
      label: "name",
      dataType: "text",
      field: "name"
    },
    {
      label: "email",
      dataType: "text",
      field: "email"
    },
    {
      label: "phone number",
      dataType: "text",
      field: "mobile"
    }
  ];

  if (user.role === "businessAdmin")
    columns.push({
      label: "organization",
      dataType: "text",
      field: "organization.name"
    });

  if (JSON.parse(orgSettings.visitorAdditionalFields).includes("company")) {
    columns.push({
      label: "visitor's company",
      dataType: "text",
      field: "company"
    });
  }

  useEffect(() => {
    (async () => {
      handleQuery({ ...query, skip: page * rowsPerPage, take: rowsPerPage });
      setQuery({ ...query, skip: page * rowsPerPage, take: rowsPerPage });
    })();
  }, [page, rowsPerPage, modalOpen, reload]);

  useEffect(() => {
    if (user.role === "businessAdmin") {
      (async () => {
        const organization = await api(
          "POST",
          "organization/find",
          {
            sort: {
              key: "organization.name",
              order: "ASC"
            }
          },
          true
        );
        const res = organization || [];
        setOrganizationList(res);
      })();
    }
  }, []);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const handleChangeFilter = (label, field, value) => {
    let allFilters = filters;

    if (value.trim() !== "") {
      allFilters[label] = value;
      setQuery({
        ...query,
        search: [
          ...query.search.filter(item => item.key !== "visitor." + field),
          {
            key: "visitor." + field,
            value: value,
            comparison: label === "organization" ? "=" : "LIKE"
          }
        ],
        skip: 0
      });
    } else if (allFilters.hasOwnProperty(label)) {
      setQuery({
        ...query,
        search: [
          ...query.search.filter(item => item.key !== "visitor." + field)
        ],
        skip: 0
      });
      delete allFilters[label];
    }

    setFilters({ ...allFilters });
  };

  const handleDeleteChip = key => {
    let allFilters = filters;
    delete allFilters[key];
    var field = key === "phone number" ? "mobile" : key;
    setQuery({
      ...query,
      search: [...query.search.filter(item => item.key !== "visitor." + field)],
      skip: 0
    });
    if (Object.keys(allFilters).length === 0) {
      handleQuery({
        ...query,
        search: []
      });
    }
    setFilters({ ...allFilters });
  };

  const handleEditRow = data => {
    setEditData(data);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSort = label => {
    var direction = "asc";
    var key = "visitor." + (label === "phone number" ? "mobile" : label);
    if (currentSort.label === label && currentSort.direction === "asc")
      direction = "desc";
    const order = direction.toUpperCase();
    handleQuery({ ...query, sort: { key, order }, skip: 0 });
    setQuery({ ...query, sort: { key, order }, skip: 0 });
    setCurrentSort({ label, direction });
  };

  const handleQuery = async customQuery => {
    dispatch(
      toastAction({
        status: true,
        message: "Loading...",
        type: "info",
        noIcon: true
      })
    );
    try {
      const data = customQuery
        ? await api("POST", "visitor/find", customQuery, true)
        : await api("POST", "visitor/find", query, true);
      const countData = customQuery
        ? await api("POST", "visitor/findCount", customQuery, true)
        : await api("POST", "visitor/findCount", query, true);
      setCount(countData);
      setVisitors(data);
      dispatch(clearToastAction({}));
    } catch (error) {
      captureException(error);
      dispatch(clearToastAction({}));
    }
  };

  const handleReport = async () => {
    const orderReportUrl = await api(
      "POST",
      "visitor/findVisitorReport",
      query,
      true
    );
    window.open(orderReportUrl, "_blank");
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Box px={1.5} py={1}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography color="textSecondary" variant="h6">
              {query.search.length === 0 ? "All Visitor" : "Filtered results"}
            </Typography>
            <Typography color="textSecondary" gutterBottom variant="caption">
              {count} Results found. Page {page + 1} of{" "}
              {Math.ceil(count / rowsPerPage)}
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={handleReport} variant="outlined" color="primary">Download Report</Button>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <PerfectScrollbar>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((col, i) => (
                  <TableCell className={classes.capitalize} key={i}>
                    <TableSortLabel
                      active={currentSort.label === col.label}
                      direction={currentSort.direction}
                      onClick={() => handleSort(col.label)}
                    >
                      {col.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {columns.map((col, i) => (
                  <TableCell key={i} size="small" style={{ padding: 6 }}>
                    {col.label !== "organization" ? (
                      <TextField
                        onChange={e => {
                          handleChangeFilter(
                            col.label,
                            col.field,
                            e.target.value
                          );
                        }}
                        size="small"
                        name={col.label}
                        placeholder={`Search by ${col.label}`}
                        variant="outlined"
                        value={filters[col.label] || ""}
                      />
                    ) : (
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        size="small"
                      >
                        <Select
                          id="Organization"
                          value={filters[col.label] || ""}
                          displayEmpty
                          onChange={e =>
                            handleChangeFilter(
                              col.label,
                              "organization",
                              e.target.value
                            )
                          }
                          autoWidth
                        >
                          <MenuItem value="" disabled>
                            Filter by Organization
                          </MenuItem>
                          {organizationList.map(item => (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </TableCell>
                ))}
              </TableRow>
              {Object.keys(filters).length > 0 && (
                <>
                  <TableRow selected>
                    <TableCell colSpan={columns.length} align="center">
                      <Typography variant="h6" component="p">
                        Selected filters: &nbsp;
                      </Typography>
                      {Object.keys(filters).map((filter, i) => {
                        return (
                          <React.Fragment key={i}>
                            <Chip
                              variant="outlined"
                              color="primary"
                              onDelete={() => {
                                handleDeleteChip(filter);
                              }}
                              label={
                                <>
                                  <b className={classes.capitalize}>{filter}</b>
                                  : {filters[filter]}
                                </>
                              }
                            />
                            &nbsp; &nbsp;
                          </React.Fragment>
                        );
                      })}
                      <Chip
                        color="primary"
                        icon={<SearchIcon />}
                        className={classes.chipButton}
                        clickable
                        onClick={() => {
                          handleQuery();
                        }}
                        label={<b>Search</b>}
                      />
                    </TableCell>
                  </TableRow>
                </>
              )}
              {visitors.map(visitor => (
                <TableRow
                  hover
                  key={visitor.id}
                  onClick={() => handleEditRow(visitor)}
                >
                  {columns.map((col, i) => (
                    <TableCell key={i}>
                      {col.field.split(".").reduce((ox, ix) => ox[ix], visitor)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={count}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <AddModal
        open={modalOpen}
        close={closeModal}
        type="visitor"
        userData={editData}
      />
    </div>
  );
};

Visitors.propTypes = {
  className: PropTypes.string,
  visitors: PropTypes.array.isRequired,
  reload: PropTypes.bool
};

Visitors.defaultProps = {
  visitors: []
};

export default Visitors;
