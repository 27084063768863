import { Grid, makeStyles, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useSelector, useDispatch } from "react-redux";
import { clearToastAction, toastAction } from "../../../../actions";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

const InviteModal = props => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { open, close, onSubmit, invitationId } = props;
  const [maxVisitor, setMaxVisitor] = useState(0);
  const [error, setError] = useState("");
  const user = useSelector(state => {
    return state.user;
  });
  const dispatch = useDispatch();
  const business = useSelector(state => {
    return state.business;
  });

  // const orgSettings= useSelector(state => {
  //   return state.orgSettings;
  // });

  const handleClose = () => {
    if (invitationId) {
      navigate("/meeting");
    }
    setMaxVisitor(0);
    setError("");
    close();
  };

  const handleSubmit = () => {
    onSubmit(maxVisitor);
    // handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        {invitationId && (
          <>
            <DialogTitle disableTypography>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Typography component="h1" variant="h3" color="primary">
                  Invite link
                </Typography>
                <IconButton aria-label="close" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <TextField
                variant="outlined"
                type="text"
                margin="normal"
                required
                fullWidth
                value={`https://${business.domain}.thesentinel.io/invitationResponse?inviteId=${invitationId}`}
                disabled
              />
              <Grid
                container
                direction="row-reverse"
                alignItems="center"
                spacing={3}
                className={classes.marginNormal}
              >
                <Grid item>
                  <Button
                    onClick={e => {
                      navigator.clipboard.writeText(
                        `https://${business.domain}.thesentinel.io/invitationResponse?inviteId=${invitationId}`
                      );
                      dispatch(
                        toastAction({
                          status: true,
                          message: "Invitation link copied to clipboard",
                          type: "success"
                        })
                      );
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Copy
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={handleClose} variant="contained">
                    Close
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )}
        {!invitationId && (
          <>
            <DialogTitle disableTypography>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Typography component="h1" variant="h3" color="primary">
                  Create invite link
                </Typography>
                <IconButton aria-label="close" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Specify the maximum number of visitors allowed that can join the
                meeting. Enter 0 (zero) for no limits
              </DialogContentText>
              {error !== "" && (
                <Alert
                  severity="error"
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setError("");
                      }}
                    >
                      <X />
                    </Button>
                  }
                >
                  {error}
                </Alert>
              )}

              <TextField
                label="Max visitors allowed"
                variant="outlined"
                type="number"
                margin="normal"
                required
                fullWidth
                value={maxVisitor}
                onChange={e => {
                  setMaxVisitor(e.target.value);
                }}
              />
              <Grid
                container
                direction="row-reverse"
                alignItems="center"
                spacing={3}
                className={classes.marginNormal}
              >
                <Grid item>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                  >
                    Create link
                  </Button>
                </Grid>
                <Grid item>
                  <Button onClick={handleClose} variant="contained">
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default InviteModal;
