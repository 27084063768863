import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  TextField,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  FormControlLabel,
  Checkbox,
  Divider
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import api from "../../../../utils/api";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { clearToastAction, toastAction } from "../../../../actions";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

function AddAccess(props) {
  const { open, close, id } = props;
  const [premiseList, setPremiseList] = useState([]);
  const [accessList, setAccessList] = useState([]);
  const [isMaster, setIsMaster] = useState(false);
  const [masterStartAccessTs, setMasterStartAccessTs] = useState("");
  const [masterEndAccessTs, setMasterEndAccessTs] = useState("");
  const [masterAllowAccessDND, setMasterllowAccessDND] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  React.useEffect(() => {
    (async () => {
      try {
        const viproom = await api(
          "POST",
          "room/find",
          {
            search: [
              {
                key: "room.allowMeeting",
                value: false,
                comparison: "="
              }
            ],
            sort: {
              key: "room.createdAt",
              order: "ASC"
            }
          },
          true
        );
        let premiseListToSet = viproom.map(room => {
          return {
            ...room,
            checked: false,
            startAccessTs: "",
            endAccessTs: ""
          };
        });
        if (id) {
          let accessData = await api(
            "GET",
            "premise/roomList/access/" + id,
            null,
            true
          );
          let accessListFromResponse = accessData.data.map(access => {
            return {
              ...access,
              checked: access.isActive,
              startAccessTs: access.startAccessTs,
              endAccessTs: access.endAccessTs,
              allowAccessDND: access.allowAccessDND
            };
          });
          setAccessList(accessListFromResponse);
          accessData.data.forEach(access => {
            let index = premiseListToSet.findIndex(
              premise => premise.id === access.premiseId
            );
            premiseListToSet[index] = {
              ...premiseListToSet[index],
              checked: access.isActive,
              startAccessTs: access.startAccessTs,
              endAccessTs: access.endAccessTs,
              allowAccessOnDND: access.allowAccessOnDND
            };
          });
        }
        setPremiseList(premiseListToSet);
      } catch (error) {}
    })();
  }, [open]);
  const handleClose = () => {
    setPremiseList([]);
    setIsMaster(false);
    setMasterStartAccessTs("");
    setMasterEndAccessTs("");
    setMasterllowAccessDND(false);
    close(false);
  };

  const handleSubmit = async () => {
    try {
      let userId = id;
      let loginType = "employee";
      let accessDetails = [];
      let errorMessage = "";
      let valid = true;
      if (!isMaster) {
        // find all the selected premises
        let selectedPremises = premiseList.filter(premise => premise.checked);

        // validate the parameters for selected premises
        selectedPremises.forEach(premise => {
          // check if startAccessTs & endAccessTs are valid
          let startAccessTs = moment(premise.startAccessTs, "x");
          let endAccessTS = moment(premise.endAccessTs, "x");
          valid = startAccessTs.isValid() && endAccessTS.isValid();
          if (!valid) {
            errorMessage = "Date not valid for premise " + premise.name;
          } else {
            valid = startAccessTs.isBefore(endAccessTS);
            if (!valid) {
              errorMessage =
                "Start date should be before end date for premise " +
                premise.name;
            }
          }
        });
        premiseList.forEach(premise => {
          // insert the access details for each seleceted premise
          if (premise.checked) {
            accessDetails.push({
              premiseId: premise.id,
              startAccessTs: premise.startAccessTs,
              endAccessTs: premise.endAccessTs,
              allowAccessOnDND: premise.allowAccessOnDND
            });
          } else {
            // remove the access details for each unselected premise that were previously seleceted
            let removedAccess = accessList.find(
              access => access.premiseId === premise.id
            );
            if (removedAccess) {
              accessDetails.push({
                premiseId: premise.id,
                isActive: false,
                startAccessTs: removedAccess.startAccessTs,
                endAccessTs: removedAccess.endAccessTs,
                allowAccessOnDND: removedAccess.allowAccessOnDND
              });
            }
          }
        });
      } else {
        // check if startAccessTs & endAccessTs are valid
        let startAccessTs = moment(masterStartAccessTs, "x");
        let endAccessTS = moment(masterEndAccessTs, "x");
        valid = startAccessTs.isValid() && endAccessTS.isValid();
        if (!valid) {
          errorMessage = "Date not valid for master access";
        } else {
          valid = startAccessTs.isBefore(endAccessTS);
          if (!valid) {
            errorMessage =
              "Start date should be before end date for master access";
          }
        }
        accessDetails = premiseList.map(premise => {
          return {
            premiseId: premise.id,
            startAccessTs: masterStartAccessTs,
            endAccessTs: masterEndAccessTs,
            allowAccessOnDND: masterAllowAccessDND
          };
        });
      }
      if (!valid) {
        dispatch(
          toastAction({
            status: true,
            message: errorMessage,
            type: "error",
            noIcon: true
          })
        );
        return;
      }
      const res = await api(
        "POST",
        "premise/roomList/access",
        {
          userId,
          loginType,
          accessDetails
        },
        true
      );
      dispatch(
        toastAction({
          status: true,
          message: "Access details saved successfully",
          type: "success",
          noIcon: true
        })
      );
      handleClose();
    } catch (error) {
      dispatch(
        toastAction({
          status: true,
          message: "Something went wrong",
          type: "error",
          noIcon: true
        })
      );
      console.log(error);
    }
  };

  return (
    <Dialog open={open} close={handleClose}>
      <DialogTitle disableTypography>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography component="h1" variant="h3" color="primary">
              Access Settings
            </Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select the rooms/premises to provide access for the user.
        </DialogContentText>

        <FormControlLabel
          control={
            <Checkbox
              checked={isMaster}
              onChange={e => setIsMaster(e.target.checked)}
            />
          }
          label="Master Access"
        />
        <Typography variant="body2">
          Allow access to all the rooms/premises
        </Typography>
        <br />
        {isMaster && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  label="Start Date"
                  id="startDate"
                  type="date"
                  inputProps={{
                    min: moment().format("YYYY-MM-DD")
                  }}
                  margin="normal"
                  variant="outlined"
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={moment(masterStartAccessTs, "x")
                    .add(moment().utcOffset(), "minutes")
                    .format("YYYY-MM-DD")}
                  onChange={e => {
                    let startAccessTs = moment(e.target.value)
                      .startOf("day")
                      .subtract(moment().utcOffset(), "minutes")
                      .format("x");
                    if (moment(e.target.value).isBefore(moment(), "day")) {
                      return;
                    }
                    setPremiseList([...premiseList]);
                    setMasterStartAccessTs(startAccessTs);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="End Date"
                  id="EndDate"
                  type="date"
                  inputProps={{
                    min: moment().format("YYYY-MM-DD")
                  }}
                  margin="normal"
                  variant="outlined"
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={moment(masterEndAccessTs, "x")
                    .add(moment().utcOffset(), "minutes")
                    .format("YYYY-MM-DD")}
                  onChange={e => {
                    let endAccessTs = moment(e.target.value)
                      .startOf("day")
                      .subtract(moment().utcOffset(), "minutes")
                      .format("x");
                    if (moment(e.target.value).isBefore(moment(), "day")) {
                      return;
                    }
                    setPremiseList([...premiseList]);
                    setMasterEndAccessTs(endAccessTs);
                  }}
                />
              </Grid>
            </Grid>
            <FormControlLabel
              control={
                <Checkbox
                  checked={masterAllowAccessDND}
                  onChange={e => setMasterllowAccessDND(e.target.checked)}
                />
              }
              label="Override DND for all VIP rooms"
            />
            <Typography variant="body2">
              Allow access to VIP rooms even when the VIP's status is set to DND
            </Typography>
            <br />
          </>
        )}
        <br />
        <Divider />

        {!isMaster &&
          premiseList.map((premise, index) => {
            return (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={premise.checked}
                      onChange={e => {
                        premiseList[index].checked = e.target.checked;
                        setPremiseList([...premiseList]);
                      }}
                    />
                  }
                  label={premise.name}
                />
                {premise.checked && (
                  <>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={premise.allowAccessOnDND}
                          onChange={e => {
                            premiseList[index].allowAccessOnDND =
                              e.target.checked;
                            setPremiseList([...premiseList]);
                          }}
                        />
                      }
                      label="Override DND"
                    />
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <TextField
                          label="Start Date"
                          id="startDate"
                          type="date"
                          inputProps={{
                            min: moment().format("YYYY-MM-DD")
                          }}
                          margin="normal"
                          variant="outlined"
                          required
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={moment(premise.startAccessTs, "x")
                            .add(moment().utcOffset(), "minutes")
                            .format("YYYY-MM-DD")}
                          onChange={e => {
                            premiseList[index].startAccessTs = moment(
                              e.target.value
                            )
                              .startOf("day")
                              .subtract(moment().utcOffset(), "minutes")
                              .format("x");
                            if (
                              moment(e.target.value).isBefore(moment(), "day")
                            ) {
                              return;
                            }
                            setPremiseList([...premiseList]);
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="End Date"
                          id="EndDate"
                          type="date"
                          inputProps={{
                            min: moment().format("YYYY-MM-DD")
                          }}
                          margin="normal"
                          variant="outlined"
                          required
                          fullWidth
                          InputLabelProps={{
                            shrink: true
                          }}
                          value={moment(premise.endAccessTs, "x")
                            .add(moment().utcOffset(), "minutes")
                            .format("YYYY-MM-DD")}
                          onChange={e => {
                            premiseList[index].endAccessTs = moment(
                              e.target.value
                            )
                              .startOf("day")
                              .subtract(moment().utcOffset(), "minutes")
                              .format("x");
                            if (
                              moment(e.target.value).isBefore(moment(), "day")
                            ) {
                              return;
                            }
                            setPremiseList([...premiseList]);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                <br />
              </>
            );
          })}
        <Grid
          container
          direction="row-reverse"
          alignItems="center"
          spacing={3}
          className={classes.marginNormal}
        >
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default AddAccess;
