import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import { captureException } from "@sentry/browser";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { clearToastAction, toastAction } from "../../../../actions";
import api from "../../../../utils/api";
import AddItemModal from "../AddItemModal";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end"
  },
  capitalize: {
    textTransform: "capitalize"
  },
  chipButton: {
    paddingLeft: 12,
    paddingRight: 12
  },
  redButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.dark
    },
    "&:disabled": {
      backgroundColor: theme.palette.error.light
    }
  }
}));

const PantryItems = props => {
  const { className, reload, ...rest } = props;
  const classes = useStyles();
  const [pantryItems, setPantryItems] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filters, setFilters] = useState({});
  const [currentSort, setCurrentSort] = useState({
    label: "",
    direction: "asc"
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState({});
  const [query, setQuery] = useState({
    search: [],
    sort: { key: "pantryItem.createdAt", order: "DESC" },
    skip: page * rowsPerPage,
    take: rowsPerPage
  });
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  var columns = [
    {
      label: "name",
      dataType: "text",
      field: "name"
    },
    {
      label: "is active",
      dataType: "boolean",
      field: "isActive"
    }
  ];

  useEffect(() => {
    console.log();
    (async () => {
      handleQuery({ ...query, skip: page * rowsPerPage, take: rowsPerPage });
      setQuery({ ...query, skip: page * rowsPerPage, take: rowsPerPage });
    })();
  }, [page, rowsPerPage, modalOpen, reload]);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const handleChangeFilter = (label, field, value) => {
    let allFilters = filters;

    if (value.trim() !== "") {
      allFilters[label] = value;
      setQuery({
        ...query,
        search: [
          ...query.search.filter(item => item.key !== "pantryItem." + field),
          { key: "pantryItem." + field, value: value, comparison: "LIKE" }
        ],
        skip: 0
      });
    } else if (allFilters.hasOwnProperty(label)) {
      setQuery({
        ...query,
        search: [
          ...query.search.filter(item => item.key !== "pantryItem." + field)
        ],
        skip: 0
      });
      delete allFilters[label];
    }

    setFilters({ ...allFilters });
  };

  const handleDeleteChip = key => {
    let allFilters = filters;
    delete allFilters[key];
    var field = key === "phone number" ? "mobile" : key;
    setQuery({
      ...query,
      search: [
        ...query.search.filter(item => item.key !== "staff." + field)
      ],
      skip: 0
    });
    if (Object.keys(allFilters).length === 0) {
      handleQuery({
        ...query,
        search: []
      });
    }
    setFilters({ ...allFilters });
  };

  const handleEditRow = data => {
    setEditData(data);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSort = label => {
    var direction = "asc";
    var key = "staff." + (label === "phone number" ? "mobile" : label);
    if (currentSort.label === label && currentSort.direction === "asc")
      direction = "desc";
    const order = direction.toUpperCase();
    handleQuery({ ...query, sort: { key, order }, skip: 0 });
    setQuery({ ...query, sort: { key, order }, skip: 0 });
    setCurrentSort({ label, direction });
  };

  const handleQuery = async customQuery => {
    dispatch(
      toastAction({
        status: true,
        message: "Loading...",
        type: "info",
        noIcon: true
      })
    );
    try {
      const data = customQuery
        ? await api("POST", "pantry/item/find", customQuery, true)
        : await api("POST", "pantry/item/find", query, true);
      const countData = customQuery
        ? await api("POST", "pantry/item/findCount", customQuery, true)
        : await api("POST", "pantry/item/findCount", query, true);
      setCount(countData);
      setPantryItems(data);
      dispatch(clearToastAction({}));
    } catch (error) {
      captureException(error);
      dispatch(clearToastAction({}));
    }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <PerfectScrollbar>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((col, i) => (
                  <TableCell className={classes.capitalize} key={i}>
                    <TableSortLabel
                      active={currentSort.label === col.label}
                      direction={currentSort.direction}
                      onClick={() => handleSort(col.label)}
                    >
                      {col.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {columns.map((col, i) => (
                    <TableCell key={i} size="small" style={{ padding: 6 }}>
                      {col.field == "isActive" ? (<></>) : (
                      <TextField
                        onChange={e => {
                          handleChangeFilter(
                            col.label,
                            col.field,
                            e.target.value
                          );
                        }}
                        size="small"
                        name={col.label}
                        placeholder={`Search by ${col.label}`}
                        variant="outlined"
                        value={filters[col.label] || ""}
                      />
                      )}
                    </TableCell>
                ))}
              </TableRow>
              {Object.keys(filters).length > 0 && (
                <>
                  <TableRow selected>
                    <TableCell colSpan={columns.length} align="center">
                      <Typography variant="h6" component="p">
                        Selected filters: &nbsp;
                      </Typography>
                      {Object.keys(filters).map((filter, i) => {
                        return (
                          <React.Fragment key={i}>
                            <Chip
                              variant="outlined"
                              color="primary"
                              onDelete={() => {
                                handleDeleteChip(filter);
                              }}
                              label={
                                <>
                                  <b className={classes.capitalize}>{filter}</b>
                                  : {filters[filter]}
                                </>
                              }
                            />
                            &nbsp; &nbsp;
                          </React.Fragment>
                        );
                      })}
                      <Chip
                        color="primary"
                        icon={<SearchIcon />}
                        className={classes.chipButton}
                        clickable
                        onClick={() => {
                          handleQuery();
                        }}
                        label={<b>Search</b>}
                      />
                    </TableCell>
                  </TableRow>
                </>
              )}
              {pantryItems.map(pantryItem => (
                <TableRow
                  hover
                  key={pantryItem.id}
                  onClick={() => handleEditRow(pantryItem)}
                >
                  {columns.map((col, i) => (
                    <TableCell key={i}>
                      {col.dataType == "boolean" ? (pantryItem?.isActive ? "Active" : "InActive") : col.field
                        .split(".")
                        .reduce((ox, ix) => ox[ix], pantryItem)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={count}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <AddItemModal
        open={modalOpen}
        close={closeModal}
        type="pantry"
        pantryItemData={editData}
      />
    </div>
  );
};

PantryItems.propTypes = {
  className: PropTypes.string,
  pantryItems: PropTypes.array.isRequired,
  reload: PropTypes.bool
};

PantryItems.defaultProps = {
  pantryItems: []
};

export default PantryItems;
