import { Grid, makeStyles, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useSelector } from "react-redux";
import api from "../../../../utils/api";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

const AddModal = props => {
  const classes = useStyles();
  const { open, close, addParticipantToArray, newEmail, primary } = props;
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [company, setCompany] = useState("");
  const [countryCode, setCountryCode] = useState("65");
  const [email, setEmail] = useState(primary === "email" ? newEmail : "");
  const [error, setError] = useState("");
  const user = useSelector(state => {
    return state.user;
  });
  const org = useSelector(state => {
    return state.org;
  });

  const orgSettings= useSelector(state => {
    return state.orgSettings;
  });

  useEffect(() => {
    if (primary === "email") {
      setEmail(newEmail);
    } else {
      setMobile(newEmail);
    }
  }, [open]);

  const handleClose = () => {
    setName("");
    setMobile("");
    setError("");

    close();
  };

  const handleAdd = () => {
    setError("");
    var body = {
      email,
      name,
      mobile,
      countryCode,
      company,
      organizationId: user.organizationId
    };
    for (const [key, value] of Object.entries(body)) {
      if (value === "") delete body[key];
    }
    (async () => {
      try {
        const data = await api("POST", "visitor", body, true);

        addParticipantToArray(data);
        handleClose();
      } catch (error) {
        error.response && error.response.data && setError(error.response.data);
      }
    })();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle disableTypography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography component="h1" variant="h3" color="primary">
              Add New Visitor
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a new visitor fill up the following details and click on the
            'Add' button. We will generate a password and notify the new visitor
          </DialogContentText>
          {error !== "" && (
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setError("");
                  }}
                >
                  <X />
                </Button>
              }
            >
              {error}
            </Alert>
          )}
          {primary === "email" && (
            <TextField
              label="Email address"
              variant="outlined"
              type="email"
              margin="normal"
              required
              fullWidth
              value={email}
              onChange={e => {
                setEmail(e.target.value);
              }}
            />
          )}
          {primary !== "email" && (
            <PhoneInput
              country={org?.countrycode ?? "in"}
              enableSearch
              searchClass="MuiOutlinedInput-input MuiOutlinedInput-inputMarginDense"
              preferredCountries={["sg", "in", "us"]}
              inputStyle={{ width: "100%" }}
              specialLabel="Phone number *"
              countryCodeEditable={false}
              value={countryCode + mobile}
              onChange={(value, country) => {
                setCountryCode("+" + country.dialCode);
                setMobile(value.replace(country.dialCode, ""));
              }}
            />
          )}
          <TextField
            label="Full name"
            variant="outlined"
            type="text"
            margin="normal"
            required
            fullWidth
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
          />
          {primary !== "email" && (
            <TextField
              label="Email address"
              variant="outlined"
              type="email"
              margin="normal"
              fullWidth
              value={email}
              disabled={primary === "email"}
              onChange={e => {
                setEmail(e.target.value);
              }}
            />
          )}
          {primary === "email" && (
            <PhoneInput
              country={org?.countrycode ?? "in"}
              enableSearch
              searchClass="MuiOutlinedInput-input MuiOutlinedInput-inputMarginDense"
              preferredCountries={["sg", "in", "us"]}
              inputStyle={{ width: "100%" }}
              specialLabel="Phone number"
              countryCodeEditable={false}
              // value={mobile}
              onChange={(value, country) => {
                setCountryCode("+" + country.dialCode);
                setMobile(value.replace(country.dialCode, ""));
              }}
            />
          )}

          {JSON.parse(orgSettings.visitorAdditionalFields).includes("company") && <TextField
            label="Company"
            variant="outlined"
            type="text"
            margin="normal"
            required
            fullWidth
            value={company}
            onChange={e => {
              setCompany(e.target.value);
            }}
          />}
          <Grid
            container
            direction="row-reverse"
            alignItems="center"
            spacing={3}
            className={classes.marginNormal}
          >
            <Grid item>
              <Button onClick={handleAdd} variant="contained" color="primary">
                Add
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleClose} variant="contained">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddModal;
