import LogRocket from "logrocket";
import { applyMiddleware, compose, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import rootReducer from "../reducers";

const loggerMiddleware = createLogger();

export default function configureStore(preloadedState = {}) {
  const middlewares =
    process.env.NODE_ENV === "production"
      ? [thunkMiddleware, LogRocket.reduxMiddleware()]
      : [thunkMiddleware, loggerMiddleware, LogRocket.reduxMiddleware()];
  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];

  const composedEnhancers = compose(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
}
