import {
  Avatar,
  Card,
  CardContent,
  Box,
  Grid,
  makeStyles,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Button,
  Divider,
  Fab
} from "@material-ui/core";
import clsx from "clsx";
import {
  Email,
  Phone,
  EmailOutlined,
  WorkOutline,
  BusinessOutlined,
  RingVolume,
  PersonAdd,
  LocationCity,
  Print,
  Language
} from "@material-ui/icons";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import getAvatarStyle from "../../utils/getAvatarStyle";
import rolename from "../../utils/rolename";
import getInitials from "../../utils/getInitials";
import vCardsJS from "vcards-js";
var vCard = vCardsJS();

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100,
    fontSize: "3.125rem"
  },
  capitalize: {
    textTransform: "capitalize"
  }
}));

const Profile = ({ className, userProfile, ...rest }) => {
  const classes = useStyles();

  const download = () => {
    //set properties
    const nameArray = userProfile.name.split(" ");
    vCard.firstName = nameArray[0] ?? "";
    vCard.lastName =
      nameArray.length > 1 ? nameArray[nameArray.length - 1] : "";
    vCard.middleName = userProfile.name
      .replace(vCard.firstName, "")
      .replace(vCard.lastName, "")
      .trim();
    vCard.cellPhone = userProfile.mobile
      ? `${userProfile.countryCode} ${userProfile.mobile}`
      : "";
    vCard.workPhone = userProfile.workPhone
      ? `${userProfile.countryCode} ${userProfile.workPhone}`
      : "";
    vCard.workFax = userProfile.workFax
      ? `${userProfile.countryCode} ${userProfile.workFax}`
      : "";
    vCard.workUrl = userProfile.website ?? "";
    // vCard.workAddress = userProfile.address ?? "";
    vCard.workEmail = userProfile.email ?? "";
    vCard.logo.attachFromUrl(
      userProfile.organization.logo,
      userProfile.organization.logo.split(".")[
        userProfile.organization.logo.split(".").length - 1
      ]
    );
    vCard.organization = userProfile.organization.name ?? "";
    userProfile.photo &&
      vCard.photo.attachFromUrl(
        userProfile.photo,
        userProfile.photo.split(".")[userProfile.photo.split(".").length - 1]
      );

    vCard.title = userProfile.designation ?? "";
    vCard.department = userProfile.department ?? "";
    vCard.note = "Downloaded from Sentinel - Powered by hipla";

    const url = window.URL.createObjectURL(
      new Blob([vCard.getFormattedString()], {
        type: "text/vcard;charset=utf-8"
      })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      userProfile.name.replace(" ", "_") + "_contact.vcf"
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container direction="column" alignItems="center" spacing={3}>
          <Grid item>
            {userProfile.photo && (
              <Avatar
                style={getAvatarStyle(userProfile.email)}
                src={userProfile.photo}
                className={classes.avatar}
              >
                {getInitials(userProfile.name)}
              </Avatar>
            )}
            {!userProfile.photo && (
              <Avatar
                style={getAvatarStyle(userProfile.email)}
                className={classes.avatar}
              >
                {getInitials(userProfile.name)}
              </Avatar>
            )}
          </Grid>
          <Grid item>
            <Typography color="textPrimary" gutterBottom variant="h3">
              {userProfile.name}{" "}
            </Typography>
          </Grid>
          <Box width="100%">
            <Divider width="100%" color="primary" />
            <Grid container justify="space-around" alignItems="stretch">
              <Grid item>
                <Box height="80px" p={1.5}>
                  <Button
                    size="small"
                    onClick={e => {
                      window.location =
                        "tel:" + userProfile.countryCode + userProfile.mobile;
                    }}
                  >
                    <Grid container direction="column" alignItems="center">
                      <Grid item>
                        <Phone />
                      </Grid>
                      <Grid item>Call</Grid>
                    </Grid>
                  </Button>
                </Box>
              </Grid>
              <Divider
                color="primary"
                orientation="vertical"
                style={{ height: 80 }}
              />
              <Grid item>
                <Box height="80px" p={1.5}>
                  <Button
                    size="small"
                    onClick={e => {
                      window.location = "mailto:" + userProfile.email;
                    }}
                  >
                    <Grid container direction="column" alignItems="center">
                      <Grid item>
                        <Email />
                      </Grid>
                      <Grid item> Email</Grid>
                    </Grid>
                  </Button>
                </Box>
              </Grid>
            </Grid>

            <Divider width="100%" color="primary" />
            <center>
              <Button
                size="large"
                color="primary"
                style={{ width: "100%", padding: 16 }}
                onClick={download}
              >
                <PersonAdd /> &nbsp; Add to contact
              </Button>
            </center>
            <Divider width="100%" color="primary" />
          </Box>

          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <EmailOutlined />
              </Avatar>
            </ListItemAvatar>
            <ListItemText secondary="Email" primary={userProfile.email} />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <Phone />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              secondary="Phone number"
              primary={
                userProfile.mobile
                  ? `${userProfile.countryCode} ${userProfile.mobile}`
                  : ""
              }
            />
          </ListItem>

          {userProfile.workPhone && (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <RingVolume />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                secondary="Work phone"
                primary={`${userProfile.countryCode} ${userProfile.workPhone}`}
              />
            </ListItem>
          )}
          {userProfile.workFax && (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Print />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                secondary="Fax"
                primary={`${userProfile.countryCode} ${userProfile.workFax}`}
              />
            </ListItem>
          )}

          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <WorkOutline />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              secondary="Designation"
              primary={userProfile.designation}
            />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <WorkOutline />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              secondary="Department"
              primary={userProfile.department}
            />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar
                alt="logo"
                src={userProfile.organization.logo}
                variant="circular"
                style={{
                  border: "0.1px solid lightgray"
                }}
                imgProps={{
                  style: {
                    objectFit: "contain"
                  }
                }}
              ></Avatar>
            </ListItemAvatar>
            <ListItemText
              secondary="Organization"
              primary={userProfile.organization.name}
            />
          </ListItem>

          {userProfile.address && (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <LocationCity />
                </Avatar>
              </ListItemAvatar>
              <ListItemText secondary="Address" primary={userProfile.address} />
            </ListItem>
          )}
          {userProfile.website && (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <Language />
                </Avatar>
              </ListItemAvatar>
              <ListItemText secondary="Website" primary={userProfile.website} />
            </ListItem>
          )}

          <br />
        </Grid>
      </CardContent>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
