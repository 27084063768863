import { Grid, makeStyles, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { X } from "react-feather";
import "react-phone-input-2/lib/material.css";
import { useSelector } from "react-redux";
import api from "src/utils/api";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

const initialState = {
  name: ""
};

const BuildingModal = props => {
  const classes = useStyles();
  const {
    open,
    close,
    buildingCount,
    addBuildingsToArray,
    editBuildingsToArray,
    activeData
  } = props;
  const [formData, setFormData] = useState(initialState);
  const [isLoading, setLoader] = useState(false);
  const [error, setError] = useState("");
  const business = useSelector(state => state.business);

  const handleClose = () => {
    close();
  };

  React.useEffect(() => {
    if (activeData.id) {
      setFormData(activeData);
    }
  }, []);

  const handleAction = () => {
    if (activeData.id) {
      handleUpdate();
    } else {
      handleAdd();
    }
  };

  const handleAdd = async () => {
    if (formData.name) {
      setLoader(true);
      try {
        const data = await api(
          "POST",
          "building",
          {
            name: formData.name,
            locationId: formData.locationId
          },
          true
        );

        if (data.id) {
          handleClose();
          buildingCount();
          addBuildingsToArray(data);
          setLoader(false);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          setError(error.response.data);
        }
        setLoader(false);
      }
    } else {
      setError("Please fill all the fields");
    }
  };

  const handleUpdate = async () => {
    if (formData.name) {
      setLoader(true);
      try {
        const data = await api(
          "PATCH",
          `building/${activeData.id}`,
          {
            name: formData.name,
            locationId: formData.locationId
          },
          true
        );
        if (data.id) {
          handleClose();
          editBuildingsToArray(data);
          setLoader(false);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          setError(error.response.data);
        }
        setLoader(false);
      }
    } else {
      setError("Please fill all the fields");
    }
  };

  const handleInputChange = event => {
    event.persist();
    const { name, value } = event.target;
    if (name === "number" || name === "locationId") {
      const re = /^[0-9\b]+$/;
      if (value === "" || re.test(value)) {
        setFormData(formData => ({
          ...formData,
          [name]: value
        }));
      }
    } else {
      setFormData(formData => ({
        ...formData,
        [name]: value
      }));
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle disableTypography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography component="h1" variant="h3" color="primary">
              {activeData.id ? "Edit" : "Add"} Buildings
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {error !== "" && (
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setError("");
                  }}
                >
                  <X />
                </Button>
              }
            >
              {error}
            </Alert>
          )}
          <TextField
            label="Name"
            variant="outlined"
            type="email"
            margin="normal"
            required
            fullWidth
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
          {business.domain === "nus" && (
            <TextField
              label="Sublocation Id"
              variant="outlined"
              type="text"
              margin="normal"
              required
              fullWidth
              name="locationId"
              value={formData.locationId}
              onChange={handleInputChange}
            />
          )}
          <Grid
            container
            direction="row-reverse"
            alignItems="center"
            spacing={3}
            className={classes.marginNormal}
          >
            <Grid item>
              <Button
                onClick={handleAction}
                disabled={isLoading}
                variant="contained"
                color="primary"
              >
                {isLoading ? "Loading..." : activeData.id ? "Update" : "Add"}
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleClose} variant="contained">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BuildingModal;
