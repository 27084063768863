import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Typography
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
  DialogHeading: {
    padding: "20px 24px 16px",
    "& .MuiTypography-root": {
      fontSize: "14px !important",
      color: "#1D273F",
      fontWeight: "normal"
    }
  },
  DialogContent: {
    padding: theme.spacing(0, 3)
  },
  DialogContentText: {
    fontSize: "14px !important",
    color: "#1D273F"
  },
  btnActionPadding: {
    padding: theme.spacing(2)
  },
  paper: {
    borderRadius: "0px",
    margin: "0px",
    position: "absolute",
    top: "0px"
  }
}));

export default function ConfirmationDialog({
  open,
  handleClose,
  title,
  content,
  btnActions,
  error
}) {
  const classes = useStyles();
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        className={classes.DialogHeading}
        id="alert-dialog-slide-title"
      >
        {title}
      </DialogTitle>
      <DialogContent className={classes.DialogContent}>
        <DialogContentText
          className={classes.DialogContentText}
          id="alert-dialog-slide-description"
        >
          {content}
        </DialogContentText>
        {error && <Typography color="error">{error}</Typography>}
      </DialogContent>
      <DialogActions className={classes.btnActionPadding}>
        {btnActions}
      </DialogActions>
    </Dialog>
  );
}
