import { Grid, makeStyles, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { X } from "react-feather";
import "react-phone-input-2/lib/material.css";
import api from "src/utils/api";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  formControl: {
    marginTop: "16px"
  },
  formSelect: {
    marginBottom: "5px"
  }
}));

const initialState = {
  macAddress: "",
  bleCard: "",
  beconId: ""
};

const BeconModal = props => {
  const classes = useStyles();
  const {
    open,
    close,
    activeData,
    fetchData,
    beconCount,
    editBeconToArray
  } = props;
  const [formData, setFormData] = useState(initialState);
  const [isLoading, setLoader] = useState(false);
  const [error, setError] = useState("");

  React.useEffect(() => {
    if (activeData.beconId) {
      setFormData(activeData);
    }
  }, []);

  const handleClose = () => {
    close();
  };

  const handleAction = () => {
    if (activeData.beconId) {
      handleUpdate();
    } else {
      handleAdd();
    }
  };

  const handleAdd = async () => {
    const { macAddress, bleCard, beconId } = formData;
    if (macAddress && bleCard && beconId) {
      setLoader(true);
      try {
        const res = await api(
          "POST",
          "becon",
          {
            macAddress: macAddress,
            bleCard: bleCard,
            beconId: beconId
          },
          true
        );
        if (res.beconId) {
          beconCount();
          fetchData();
          handleClose();
          setLoader(false);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          setError(error.response.data);
        }
        setLoader(false);
      }
    } else {
      setError("Please fill all the fields");
    }
  };

  const handleUpdate = async () => {
    const { macAddress, bleCard, beconId } = formData;
    if (macAddress && bleCard && beconId) {
      setLoader(true);
      try {
        const data = await api(
          "PATCH",
          `becon/${beconId}`,
          {
            macAddress: macAddress,
            bleCard: bleCard
          },
          true
        );
        if (data.beconId) {
          editBeconToArray(data);
          handleClose();
          setLoader(false);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          setError(error.response.data);
        }
        setLoader(false);
      }
    } else {
      setError("Please fill all the fields");
    }
  };

  const handleInputChange = event => {
    event.persist();
    const { name, value } = event.target;
    if (name === "beconId") {
      const re = /^[0-9\b]+$/;
      if (value === "" || re.test(value)) {
        setFormData(formData => ({
          ...formData,
          [name]: value
        }));
      }
    } else {
      setFormData(formData => ({
        ...formData,
        [name]: value
      }));
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle disableTypography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography component="h1" variant="h3" color="primary">
              Becon
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {error !== "" && (
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setError("");
                  }}
                >
                  <X />
                </Button>
              }
            >
              {error}
            </Alert>
          )}
          <TextField
            id="name"
            label="BLE mac id"
            variant="outlined"
            type="text"
            margin="normal"
            required
            fullWidth
            name="macAddress"
            value={formData.macAddress}
            onChange={handleInputChange}
          />
          <TextField
            id="number"
            label="Navigate Beacon id"
            variant="outlined"
            type="text"
            margin="normal"
            required
            disabled={
              (activeData && activeData.beconId && activeData.beconId.length) >
              0
            }
            fullWidth
            name="beconId"
            value={formData.beconId}
            onChange={handleInputChange}
          />
          <TextField
            id="occupancy"
            label="BLE Card QR"
            variant="outlined"
            type="text"
            margin="normal"
            required
            fullWidth
            name="bleCard"
            value={formData.bleCard}
            onChange={handleInputChange}
          />
          <Grid
            container
            direction="row-reverse"
            alignItems="center"
            spacing={3}
            className={classes.marginNormal}
          >
            <Grid item>
              <Button
                onClick={handleAction}
                disabled={isLoading}
                variant="contained"
                color="primary"
              >
                {isLoading
                  ? "Loading..."
                  : activeData.beconId
                  ? "Update"
                  : "Add"}
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleClose} variant="contained">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BeconModal;
