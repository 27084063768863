import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { captureException } from "@sentry/browser";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, Clipboard, Clock, Info, MapPin } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Page from "src/components/Page";
import * as actions from "../../actions";
import { toastAction, organizationSettings } from "../../actions";
import api from "../../utils/api";
import getAvatarStyle from "../../utils/getAvatarStyle";
import getInitials from "../../utils/getInitials";
import Loader from "../../utils/loader";
import PhotoModal from "./PhotoModal";
// import ResponseModal from "./ResponseModal";
import ViewModal from "./ViewModal";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));

const VisitDetails = props => {
  const { customToken, visitId } = props;
  const navigate = useNavigate();
  const params = useParams();
  const [meeting, setMeeting] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [participantModalId, setParticipantModalId] = useState("");
  const [photoModalOpen, setPhotoModalOpen] = useState(false);
  const [responseModalOpen, setResponseModalOpen] = useState(false);
  const [responseSent, setResponseSent] = useState(false);
  const [selectRoom, setSelectRoom] = useState(true);
  const [participantId, setParticipantId] = useState("");
  const [myParticipantId, setMyParticipantId] = useState(null);
  const [pendingMyResponse, setPendingMyResponse] = useState(false);
  const orgSettings = useSelector(state => state.orgSettings);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    if (customToken) {
      setTimeout(() => {
        (async () => {
          try {
            const data = await api(
              "GET",
              `checkIn/${visitId}`,
              null,
              false,
              false,
              false,
              false,
              customToken
            );

            const newOrgSettings = await api(
              "GET",
              "organization/getOrgSettings/" + data.organization.id,
              null,
              false,
              false,
              false,
              false,
              customToken
            );
            dispatch(
              organizationSettings({ ...orgSettings, ...newOrgSettings })
            );

            setMeeting(data);
            setLoading(false);
          } catch (error) {
            captureException(error);
          }
        })();
      }, 1000);
    } else {
      (async () => {
        try {
          const data = await api("GET", `checkIn/${params.id}`, null, true);
          setMeeting(data);
          setLoading(false);
        } catch (error) {
          captureException(error);
        }
      })();
    }
  }, [responseSent]);

  const handleResponse = async response => {
    try {
      await api(
        "PATCH",
        `checkIn/${visitId ? visitId : params.id}`,
        {
          status: response
        },
        !customToken,
        false,
        false,
        false,
        customToken
      );
      setResponseSent(true);
      dispatch(
        toastAction({
          status: true,
          message: "Visit request successfully " + response,
          type: "success"
        })
      );
    } catch (error) {
      captureException(error);
      dispatch(
        toastAction({
          status: true,
          message: "Something went wrong",
          type: "error"
        })
      );
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <Page className={classes.root} title="Meeting Details">
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}
        direction="row"
      >
        <Grid item>
          <Typography component="h1" variant="h3" color="primary">
            View Visit
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.container} container spacing={1}>
        <Grid item md={4} sm={12}>
          <Card>
            <CardContent>
              <Typography component="h2" gutterBottom variant="h4">
                Visit Details
              </Typography>
              {(() => {
                const item =
                  meeting.userAccess.employee ||
                  meeting.userAccess.visitor ||
                  meeting.userAccess.staff;
                return (
                  <React.Fragment>
                    <Divider />
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justify="space-between"
                    >
                      <Grid item>
                        <Box flexBasis={1}>
                          <ListItem disableGutters>
                            <ListItemAvatar>
                              <Avatar style={getAvatarStyle(item.email || "")}>
                                {getInitials(item.name)}
                              </Avatar>
                            </ListItemAvatar>

                            <ListItemText
                              primary={
                                <Typography variant="h5">
                                  {item.name}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {item.role &&
                                    item[orgSettings.employeePrimaryIdentifier]}
                                  {!item.role &&
                                    item[orgSettings.visitorPrimaryIdentifier]}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box>
                          {(item.photo || item.idPhoto) && (
                            <>
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  setPhotoModalOpen(true);
                                }}
                              >
                                Photos
                              </Button>
                              <PhotoModal
                                open={photoModalOpen}
                                close={() => {
                                  setPhotoModalOpen(false);
                                }}
                                profilePhoto={item.photo}
                                idPhoto={item.idPhoto}
                              />
                            </>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                    {meeting.status === "requested" && user.role !== "visitor" && (
                      <Grid container spacing={1}>
                        <Grid item>
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={() => {
                              handleResponse("accepted");
                            }}
                            color="primary"
                          >
                            Accept
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => {
                              handleResponse("declined");
                            }}
                            color="secondary"
                          >
                            Decline
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </React.Fragment>
                );
              })()}
              <br />
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <Box width={30}>
                        <Clipboard />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box width={80}>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              <Typography variant="h5">Purpose</Typography>
                            }
                          />
                        </ListItem>
                      </Box>
                    </Grid>
                    <Grid item>
                      <ListItem disableGutters>
                        <ListItemText
                          primary={
                            <Typography variant="body1">
                              {meeting.purpose}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <Box width={30}>
                        <Calendar />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box width={80}>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={<Typography variant="h5">Date</Typography>}
                          />
                        </ListItem>
                      </Box>
                    </Grid>
                    <Grid item>
                      <ListItem disableGutters>
                        <ListItemText
                          primary={
                            <Typography variant="body1">
                              {moment(parseInt(meeting.startTime))
                                .local()
                                .format("Do MMM YYYY")}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <Box width={30}>
                        <Clock />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box width={80}>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={<Typography variant="h5">Time</Typography>}
                          />
                        </ListItem>
                      </Box>
                    </Grid>
                    <Grid item>
                      <ListItem disableGutters>
                        <ListItemText
                          primary={
                            <Typography variant="body1">
                              {moment(parseInt(meeting.startTime))
                                .local()
                                .format("LT")}
                              {" to "}
                              {moment(parseInt(meeting.endTime))
                                .local()
                                .format("LT")}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <Box width={30}>
                        <Clock />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box width={80}>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              <Typography variant="h5">Status</Typography>
                            }
                          />
                        </ListItem>
                      </Box>
                    </Grid>
                    <Grid item>
                      <ListItem disableGutters>
                        {/* <ListItemText primary={meeting.status} /> */}
                        {meeting.activity.length === 0 && (
                          <ListItemText primary={meeting.status} />
                        )}
                        {meeting.activity.length > 0 && (
                          <ListItemText
                            primary={
                              meeting.activity[meeting.activity.length - 1]
                                .status
                            }
                          />
                        )}
                      </ListItem>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={8} sm={12}>
          <Card>
            <CardContent>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
              >
                <Grid item xs={12}>
                  {orgSettings.baggageBusinessEnable && (
                    <Grid item xs={12}>
                      <Typography
                        component="h2"
                        gutterBottom
                        variant="h4"
                        align="left"
                      >
                        Baggage
                      </Typography>
                      <Divider />

                      {meeting.baggages.length === 0 && (
                        <Typography
                          component="h2"
                          gutterBottom
                          variant="h5"
                          align="left"
                        >
                          No baggage added
                        </Typography>
                      )}
                      {meeting.baggages.map(bag => (
                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              bag.name.charAt(0).toUpperCase() +
                              bag.name.slice(1)
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="textPrimary"
                                >
                                  {bag.description}
                                </Typography>
                                {bag.model !== "" &&
                                  bag.model !== null &&
                                  " - " + bag.model}
                                {bag.serial !== "" &&
                                  bag.serial !== null &&
                                  " - " + bag.serial}
                              </React.Fragment>
                            }
                          />
                        </ListItem>
                      ))}
                      <br />
                    </Grid>
                  )}
                  <Typography component="h2" gutterBottom variant="h4">
                    Activities
                  </Typography>
                  <Divider />
                  {meeting.activity.length === 0 && (
                    <Typography
                      component="h2"
                      gutterBottom
                      variant="h5"
                      align="left"
                    >
                      No activities yet
                    </Typography>
                  )}
                  {meeting.activity.length > 0 &&
                    meeting.activity.map(eachActivity => (
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        key={eachActivity.id}
                        justify="space-between"
                      >
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <br />

                        <Grid item>
                          <Box minWidth={120} flexBasis={1}>
                            <Typography variant="h5" color="primary">
                              <b>Status</b>
                            </Typography>
                            <Typography variant="button">
                              {eachActivity.status}
                            </Typography>
                          </Box>
                        </Grid>
                        {eachActivity.inTime && (
                          <Grid item>
                            <Box minWidth={120} flexBasis={1}>
                              <Typography variant="h5" color="primary">
                                <b>In Time</b>
                              </Typography>
                              <Typography>
                                <b>
                                  {moment
                                    .utc(
                                      new Date(
                                        parseInt(eachActivity.inTime)
                                      ).toISOString()
                                    )
                                    .local()
                                    .format("LT")}{" "}
                                </b>
                                {moment
                                  .utc(
                                    new Date(
                                      parseInt(eachActivity.inTime)
                                    ).toISOString()
                                  )
                                  .local()
                                  .format("Do MMM YYYY")}
                              </Typography>
                            </Box>
                          </Grid>
                        )}
                        {eachActivity.outTime && (
                          <Grid item>
                            <Box minWidth={120} flexBasis={1}>
                              <Typography variant="h5" color="primary">
                                <b>Out Time</b>
                              </Typography>
                              <Typography>
                                <b>
                                  {moment
                                    .utc(
                                      new Date(
                                        parseInt(eachActivity.outTime)
                                      ).toISOString()
                                    )
                                    .local()
                                    .format("LT")}{" "}
                                </b>
                                {moment
                                  .utc(
                                    new Date(
                                      parseInt(eachActivity.outTime)
                                    ).toISOString()
                                  )
                                  .local()
                                  .format("Do MMM YYYY")}
                              </Typography>
                            </Box>
                          </Grid>
                        )}

                        {orgSettings.tempMeasure && (
                          <Grid item>
                            <Box minWidth={120} flexBasis={1}>
                              <Typography variant="h5" color="primary">
                                <b>Temperature</b>
                              </Typography>
                              <Typography>
                                {eachActivity.temperature}
                              </Typography>
                            </Box>
                          </Grid>
                        )}
                        {(eachActivity.checkInImage ||
                          eachActivity.idPhoto) && (
                          <>
                            <Grid item>
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  setPhotoModalOpen(true);
                                }}
                              >
                                Photos
                              </Button>
                              <PhotoModal
                                open={photoModalOpen}
                                close={() => {
                                  setPhotoModalOpen(false);
                                }}
                                profilePhoto={eachActivity.checkInImage}
                                idPhoto={eachActivity.idPhoto}
                              />
                            </Grid>
                          </>
                        )}
                        <br />
                        <br />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};

export default VisitDetails;
