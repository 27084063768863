import { ThemeProvider } from "@material-ui/core";
import LogRocket from "logrocket";
import React, { useEffect, useState } from "react";
import preval from "preval.macro";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import GlobalStyles from "src/components/GlobalStyles";
import "src/mixins/chartjs";
import routes from "src/routes";
import theme from "src/theme";
import { business, login, organization, organizationSettings } from "./actions";
import Toast from "./components/Toast";
import api from "./utils/api";
import Loader from "./utils/loader";

const App = () => {
  const parts = window.location.host.split(".");
  const domainAvailable = parts.length >= 3;
  const [detectingDomain, setDetectingDomain] = useState(domainAvailable);

  const user = useSelector(state => {
    return state.user;
  });
  const loggedIn = useSelector(state => {
    return state.loggedIn;
  });
  const tostMessage = useSelector(state => state.tostMessage);

  const routing = useRoutes(
    loggedIn
      ? user.role
        ? routes[user.role]
        : routes.visitor
      : routes.notloggedin
  );
  const token = localStorage ? localStorage.getItem("token") : null;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user && token) {
      (async () => {
        try {
          let validator = await api("POST", "auth/validateToken", null, true);
          localStorage.setItem("token", validator.token);
          if (validator.data.organizationId) {
            let organiztionFromUser = await api(
              "GET",
              "organization/" + validator.data.organizationId,
              null,
              true
            );
            dispatch(organization(organiztionFromUser));
          }
          let businessFromUser = await api(
            "GET",
            "business/" + validator.data.businessId,
            null,
            true
          );
          dispatch(business(businessFromUser));
          var orgSettings = {};
          if (validator.data.loginType !== "businessAdmin") {
            orgSettings = await api(
              "GET",
              "organization/getOrgSettings/" + validator.data.organizationId,
              null,
              true
            );
            dispatch(organizationSettings(orgSettings));
          }

          const businessSettings = await api(
            "GET",
            "business/getBusinessSettings/" + validator.data.businessId,
            null,
            true
          );
          dispatch(
            organizationSettings({ ...orgSettings, ...businessSettings })
          );
          dispatch(login(validator));

          LogRocket.identify("User", {
            name: validator.data.name,
            email: validator.data.email,
            mobile: validator.data.mobile,
            id: validator.data.id,
            org: validator.data.organizationId
          });
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      })();
    } else {
      setLoading(false);
    }
    if (domainAvailable) {
      (async () => {
        try {
          const validateDomain = await api(
            "GET",
            "business/searchByDomain/" + parts[0],
            null,
            false
          );
          if (validateDomain.id) {
            dispatch(business(validateDomain));
          }
          setDetectingDomain(false);
        } catch (error) {
          if (error.response && error.response.data) setDetectingDomain(false);
        }
      })();
    }
  }, [loggedIn]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {tostMessage && tostMessage.closeFlag && <Toast />}
      {loading || detectingDomain ? <Loader /> : routing}
      <p style={{ display: "none" }}>
        Build Date: {preval`module.exports = new Date().toLocaleString();`}
      </p>
    </ThemeProvider>
  );
};

export default App;
