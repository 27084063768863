import axios from "axios";
import baseurl from "./baseurl";

const upload = async (file, folder) => {
  const token = localStorage ? localStorage.getItem("token") : null;
  let filenameSplit = file.name.split(".");
  const formData = new FormData();
  formData.append(
    "file",
    file,
    Date.now() + "." + filenameSplit[filenameSplit.length - 1]
  );
  formData.append("fileStorage", folder);

  try {
    let response = await axios({
      method: "POST",
      url: baseurl + "file/upload",
      data: formData,
      headers: {
        Authorization: "Bearer " + token
      },
      onUploadProgress: progressEvent => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
      }
    });
    if (process.env.NODE_ENV !== "production") {
      console.group(
        `%cAPI%c ${"UPLOAD"} %c${"file/upload"}`,
        `color: blue; font-weight: 700;padding: 0 2px; border: 1px solid blue; border-radius: 2px`,
        `color: green; font-weight: 700`,
        `color: white;`
      );
      console.log(
        `%c[REQ][DATA] `,
        `color: orangered;font-weight: 700`,
        formData
      );
      console.log(
        `%c[RES][DATA] `,
        `color: green;font-weight: 700`,
        response.data
      );
      console.groupEnd();
    }
    return response.data;
  } catch (error) {
    if (process.env.NODE_ENV !== "production") {
      console.group(
        `%cAPI%c ${"UPLOAD"} %c${"file/upload"}`,
        `color: blue; font-weight: 700;padding: 0 2px; border: 1px solid blue; border-radius: 2px`,
        `color: green; font-weight: 700`,
        `color: white;`
      );
      console.log(
        `%c[REQ][DATA] `,
        `color: darkslategray;font-weight: 700`,
        formData
      );

      console.log(error.response);
      console.log(error);
      console.groupEnd();
    }
    return Promise.reject(error);
  }
};

export default upload;
