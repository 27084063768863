import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  FormLabel,
  TextField,
  Typography,
  Checkbox,
  Switch,
  FormGroup
} from "@material-ui/core";
import { toastAction } from "../../../actions";
import { Alert } from "@material-ui/lab";
import PerfectScrollbar from "react-perfect-scrollbar";
import clsx from "clsx";
import moment from "moment";
import api from "src/utils/api";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles({
  root: {},
  item: {
    display: "flex",
    flexDirection: "column"
  },
  cardHeader: {
    height: "427px",
    overflow: "scroll"
  }
});
function PremiseAccessRule() {
  const classes = useStyles();
  const organisationId = useSelector(state => state.org.id);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [localDate, setLocalDate] = useState(moment().format("YYYY-MM-DD"));
  const [ruleList, setRuleList] = useState([]);
  const [roomList, setRoomList] = useState([{}]);
  const [isRuleactive, setIsRuleactive] = useState(false);

  // var utcStart = new moment("09:00", "HH:mm").utc();
  const [endTime, setEndTime] = useState(moment.utc().format("HH:mm"));
  const [room, setRoom] = useState([]);
  const [startTime, setStartTime] = useState(
    moment()
      .utc()
      .format("HH:mm")
  );

  const [endRepeatDay, setEndRepeatDay] = useState(
    moment().format("YYYY-MM-DD")
  );

  //get all rules API
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!ruleList.id) {
      setStartTime(
        moment()
          .startOf("day")
          .format("hh:mm")
      );
      setEndTime(
        moment()
          .endOf("day")
          .format("hh:mm")
      );
      setLocalDate(
        moment()
          .startOf("day")
          .format("YYYY-MM-DD")
      );
      setEndRepeatDay(
        moment()
          .endOf("day")
          .format("YYYY-MM-DD")
      );
    } else {
      const startTimeData = moment()
        .startOf("day")
        .add(ruleList.openDoorTiming + moment().utcOffset(), "minutes")
        .format("HH:mm");
      const endTimeData = moment()
        .startOf("day")
        .add(ruleList.closeDoorTiming + moment().utcOffset(), "minutes")
        .format("HH:mm");

      setStartTime(startTimeData);
      setEndTime(endTimeData);
      setLocalDate(
        moment(
          new Date(parseInt(ruleList.startRepeatDate) * 1000).toString()
        ).format("YYYY-MM-DD")
      );
      setEndRepeatDay(
        moment(
          new Date(parseInt(ruleList.endRepeatDate) * 1000).toString()
        ).format("YYYY-MM-DD")
      );
      setIsRuleactive(ruleList.isActive);
    }
  }, [ruleList]);

  //call delete API
  const handleDelete = () => {
    setError("");
    dispatch(
      toastAction({
        status: true,
        message: "Deleting Access",
        type: "info",
        noIcon: true
      })
    );
    (async () => {
      try {
        await api("DELETE", "premiseAccessRules/" + ruleList.id, null, true);
        dispatch(
          toastAction({
            status: true,
            message: "Successfully Deleted ",
            type: "success"
          })
        );
        fetchData();
      } catch (error) {
        dispatch(
          toastAction({
            status: true,
            message: "Something went wrong",
            type: "error"
          })
        );
        error.response && error.response.data && setError(error.response.data);
      }
    })();
  };

  // Handle onclick for update button
  const handleUpdate = () => {
    setError("");
    (async () => {
      try {
        if (ruleList.id) {
          if (!isRuleactive) {
            handleDelete();
          } else {
            handleLink();
            handleUpdateRuleAndLinks();
          }
        } else {
          handleLink();
          handleCreateRuleAndLinks();
        }
      } catch (error) {
        setError(error.response);
      }
    })();
  };

  //call create API
  const handleCreateRuleAndLinks = () => {
    setError("");
    let body = {
      //var utcStart = new moment("09:00", "HH:mm").utc();
      openDoorTiming: new moment(startTime, "HH:mm").utc(),
      closeDoorTiming: new moment(endTime, "HH:mm").utc(),
      startRepeatDay: Math.floor(new Date(localDate).getTime() / 1000),
      endRepeatDay: Math.floor(new Date(endRepeatDay).getTime() / 1000),
      organizationId: organisationId,
      isActive: true
    };
    dispatch(
      toastAction({
        status: true,
        message: "Adding " + "...",
        type: "info",
        noIcon: true
      })
    );
    (async () => {
      try {
        await api("POST", "premiseAccessRules/" + organisationId, body, true);
        dispatch(
          toastAction({
            status: true,
            message: "Successfully created ",
            type: "success"
          })
        );

        fetchData();
      } catch (error) {
        dispatch(
          toastAction({
            status: true,
            message: "Something went wrong",
            type: "error"
          })
        );
        setError(error.response);
      }
    })();
  };

  const handleUpdateRuleAndLinks = () => {
    setError("");
    let startTimeNumber = parseInt(startTime.replace(":", ""));
    let endTimeNumber = parseInt(endTime.replace(":", ""));
    if (startTimeNumber > endTimeNumber) {
      return dispatch(
        toastAction({
          status: true,
          message: "End Time cannot be before Start time",
          type: "error"
        })
      );
    }
    if (endRepeatDay < localDate) {
      return dispatch(
        toastAction({
          status: true,
          message: "End Date cannot be before Start Date",
          type: "error"
        })
      );
    }

    // Set the time to UTC
    let m1 = moment();
    let openDoorTiming = m1
      .set({
        hour: startTime.split(":")[0],
        minute: startTime.split(":")[1]
      })
      .utc()
      .format("HH:mm");
    let m2 = moment();
    let closeDoorTiming = m2
      .set({
        hour: endTime.split(":")[0],
        minute: endTime.split(":")[1]
      })
      .utc()
      .format("HH:mm");
    let body = {
      openDoorTiming: openDoorTiming,
      closeDoorTiming: closeDoorTiming,
      startRepeatDay: Math.floor(new Date(localDate).getTime() / 1000),
      endRepeatDay: Math.floor(new Date(endRepeatDay).getTime() / 1000),
      organizationId: organisationId,
      isActive: true
    };
    dispatch(
      toastAction({
        status: true,
        message: "Updating " + "...",
        type: "info",
        noIcon: true
      })
    );
    (async () => {
      try {
        if (ruleList.id) {
          await api("PATCH", "premiseAccessRules/" + ruleList.id, body, true);
          dispatch(
            toastAction({
              status: true,
              message: "Successfully updated ",
              type: "success"
            })
          );
        }

        fetchData();
      } catch (error) {
        dispatch(
          toastAction({
            status: true,
            message: "Something went wrong",
            type: "error"
          })
        );
        error.response && error.response.data && setError(error.response.data);
      }
    })();
  };

  //calling Link room API
  const handleLink = () => {
    setError("");
    let body = {
      organizationId: organisationId,
      roomIds: room
    };
    dispatch(
      toastAction({
        status: true,
        message: "Adding " + "...",
        type: "info",
        noIcon: true
      })
    );
    (async () => {
      try {
        await api("POST", "premiseAccessRules/link/" + ruleList.id, body, true);
        dispatch(
          toastAction({
            status: true,
            message: "Successfully updated premises ",
            type: "success"
          })
        );
      } catch (error) {
        dispatch(
          toastAction({
            status: true,
            message: "Something went wrong",
            type: "error"
          })
        );
        error.response && error.response.data && setError(error.response.data);
      }
    })();
  };

  //calling room list API
  const fetchData = async () => {
    try {
      const payload = {
        search: [
          {
            key: "room.allowMeeting",
            value: "",
            comparison: "="
          }
        ],
        sort: {
          key: "room.createdAt",
          order: "ASC"
        },
        skip: 0
      };
      const roomListResponse = await api("POST", "room/find", payload, true);

      setRoomList(roomListResponse);

      const premiseRuleList = await api(
        "GET",
        "premiseAccessRules/" + organisationId,
        null,
        true
      );
      setRuleList(premiseRuleList);

      if (premiseRuleList.id) {
        const premiseListData = await api(
          "GET",
          "premiseAccessRules/link/" + premiseRuleList.id,
          null,
          true
        );
        setRoom(premiseListData.map(item => item.roomId));
      }
    } catch (error) {
      setError("select room please");
    }
  };

  // One single button - Update
  // Switch input
  // OnUpdate
  // if / else

  return (
    <div>
      <>
        <Box width="100%" className={clsx(classes.root)}>
          <Grid container spacing={1} wrap="wrap">
            <Grid className={classes.item} item md={4} sm={12}>
              <Card>
                <CardContent>
                  <Typography color="primary" gutterBottom variant="h4">
                    Rules
                  </Typography>
                  <TextField
                    label="Gate Open Time"
                    id="startDate"
                    type="time"
                    inputProps={{
                      min: moment().format("hh:mm")
                    }}
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                    disabled={!isRuleactive && ruleList.id}
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={startTime}
                    onChange={e => {
                      setStartTime(e.target.value);
                    }}
                  />

                  <TextField
                    label="Gate Close Time"
                    id="startDate"
                    type="time"
                    inputProps={{
                      min: moment().format("hh:mm")
                    }}
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={endTime}
                    disabled={!isRuleactive && ruleList.id}
                    onChange={e => {
                      setEndTime(e.target.value);
                    }}
                  />
                  <TextField
                    label="Rule Start Date"
                    id="startDate"
                    type="Date"
                    inputProps={{
                      min: moment().format("YYYY-MM-DD")
                    }}
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={localDate}
                    disabled={!isRuleactive && ruleList.id}
                    onChange={e => {
                      setLocalDate(
                        moment
                          .utc(new Date(e.target.value))
                          .format("YYYY-MM-DD")
                      );
                    }}
                  />
                  <TextField
                    label="Rule End Date"
                    id="endDate"
                    type="Date"
                    inputProps={{
                      min: moment().format("YYYY-MM-DD")
                    }}
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={endRepeatDay}
                    disabled={!isRuleactive && ruleList.id}
                    onChange={e => {
                      setEndRepeatDay(
                        moment
                          .utc(new Date(e.target.value))
                          .format("YYYY-MM-DD")
                      );
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isRuleactive}
                        onChange={e => setIsRuleactive(e.target.checked)}
                      />
                    }
                    label="Enable Rule"
                  />
                </CardContent>
              </Card>
              <br />
            </Grid>

            {isRuleactive && (
              <Grid className={classes.item} item md={8} sm={12}>
                <Card className={classes.cardHeader}>
                  <CardContent>
                    <div className={classes.inner}>
                      <Typography color="primary" gutterBottom variant="h4">
                        Premise Rule Enabled On
                      </Typography>
                      <Grid item xs={6}>
                        <FormControl
                          variant="outlined"
                          style={{ width: "100%" }}
                        >
                          <FormGroup>
                            {roomList.map(roomName => {
                              return (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={room.includes(roomName.id)}
                                      onChange={e => {
                                        if (e.target.checked)
                                          setRoom([...room, e.target.name]);
                                        else
                                          setRoom(
                                            room.filter(
                                              item => item !== e.target.name
                                            )
                                          );
                                      }}
                                      name={roomName.id}
                                    />
                                  }
                                  label={roomName.name}
                                />
                              );
                            })}
                          </FormGroup>
                        </FormControl>
                      </Grid>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            )}

            <Grid className={classes.item} item md={12} sm={12}>
              <Card>
                <Alert severity="info">
                  A rule modified during the day is activated only after one
                  manual operation on that "VIP" Room's door.
                </Alert>
                <CardContent>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleUpdate}
                    >
                      Update Rule
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </>
    </div>
  );
}

export default PremiseAccessRule;
