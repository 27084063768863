import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import { captureException } from "@sentry/react";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import CsvViewer from "react-csv-viewer";
import {
  Download,
  FileText,
  PlusCircle,
  PlusSquare,
  Upload
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { toastAction } from "../../../../actions";
import api from "../../../../utils/api";
import baseurl from "../../../../utils/baseurl";
import AddAccess from "../AddAccess";
import AddModal from "../AddModal";
import AddReportee from "../AddReportee";

const useStyles = makeStyles(() => ({
  root: {},
  breadcrumbIcon: {
    marginLeft: 6,
    marginRight: 6
  }
}));

const Header = props => {
  const { className, setReload, ...rest } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [accessModelOpen, setAccessModelOpen] = useState(false);
  const [reporteeModelOpen, setReporteeModelOpen] = useState(false);
  const [accessModalId, setAccessModalId] = useState(null);
  const [addType, setAddType] = useState("");
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [uploadModalError, setUploadModalError] = useState("");
  const [uploadOrgId, setUploadOrgId] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [bulkInviteOpen, setBulkInviteModalOpen] = useState(false);
  const [inviteOrgId, setInviteOrgId] = useState("");
  const [inviteEmails, setInviteEmail] = useState("");
  const [inviteError, setInviteError] = useState("");
  const user = useSelector(state => state.user);
  const orgSettings = useSelector(state => state.orgSettings);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (user.role === "businessAdmin") {
      (async () => {
        try {
          const orgList = await api(
            "GET",
            "organization/getOrganization/" + user.businessId,
            null,
            false
          );
          setCompanyList(orgList);
        } catch (error) {
          alert("Something went wrong!");
        }
      })();
    } else {
      setInviteOrgId(user.organizationId);
    }
  }, [user]);

  const openModal = type => {
    setAddType(type);
    setModalOpen(true);
  };
  const closeModal = id => {
    setReload(false);
    setReload(true);
    setModalOpen(false);
    if (typeof id === "string") {
      setAccessModalId(id);
      if (orgSettings.individualSupervisorRosteringEnabled) {
        setReporteeModelOpen(true);
      } else {
        setAccessModelOpen(true);
      }
    }
  };

  const closeAccessModal = () => {
    setAccessModelOpen(false);
  };

  const closeReporteeModal = next => {
    setReporteeModelOpen(false);
    if (next) {
      setAccessModelOpen(true);
    }
  };

  const closeUploadModal = () => {
    setUploadModalOpen(false);
    setUploadModalError("");
    setUploadOrgId("");
  };

  const downloadSample = async () => {
    try {
      window.open(
        baseurl.replace("v1/", "") +
          (await api("GET", "employee/fetch/sampleCsv", null, true)),
        "_blank"
      );
      dispatch(
        toastAction({
          status: true,
          message: "Sample file downloaded",
          type: "success"
        })
      );
    } catch (error) {
      captureException(error);
    }
  };

  const simulateClick = () => {
    setUploadModalError("");
    const mouseClickEvents = ["mousedown", "click", "mouseup"];
    var element = document.querySelector(".csv-form-control");
    mouseClickEvents.forEach(mouseEventType =>
      element.dispatchEvent(
        new MouseEvent(mouseEventType, {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1
        })
      )
    );
  };

  const uploadCsv = async () => {
    setUploadModalError("");
    var element = document.querySelector(".csv-form-control");
    let file = element.files[0];
    const formData = new FormData();
    formData.append("file", file, file.name);
    if (user.role === "businessAdmin") {
      if (!uploadOrgId) {
        setUploadModalError("Please select an organization");
        return;
      }
      formData.append("organizationId", uploadOrgId);
    }
    try {
      let data = await api("POST", "employee/uploadCsv", formData, true);
      dispatch(
        toastAction({
          status: true,
          message: data,
          type: "success"
        })
      );
      closeUploadModal();
    } catch (error) {
      setUploadModalError(error.response.data);
    }
  };

  const sendInvite = async () => {
    let emails = inviteEmails.split(",");
    emails = emails.map(email => email.trim());
    let valid = true;
    emails.forEach(email => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      valid = re.test(String(email).toLowerCase());
    });
    if (inviteOrgId == "") {
      dispatch(
        toastAction({
          status: true,
          message: "Please select an organization",
          type: "error"
        })
      );
    } else if (!valid) {
      dispatch(
        toastAction({
          status: true,
          message: "One or more email ids are not valid",
          type: "error"
        })
      );
    } else {
      try {
        await api(
          "POST",
          "employee/invite",
          {
            organizationId: inviteOrgId,
            emails: emails
          },
          true
        );
        dispatch(
          toastAction({
            status: true,
            message: "Invitation sent",
            type: "success"
          })
        );
        setInviteEmail("");
        setBulkInviteModalOpen(false);
      } catch (error) {
        console.log(error.data);
        error.response &&
          error.response.data &&
          setInviteError(error.response.data);
      }
    }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}
        direction="row"
      >
        <Grid item>
          <Typography component="h1" variant="h3" color="primary">
            All Users
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <Button
                color="primary"
                startIcon={<Upload />}
                variant="outlined"
                component="label"
                onClick={e => setUploadModalOpen(true)}
              >
                Upload CSV File
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                startIcon={<PlusSquare />}
                variant="outlined"
                onClick={() => {
                  openModal("visitor");
                }}
              >
                Add Visitor
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                startIcon={<PlusSquare />}
                variant="outlined"
                onClick={() => {
                  openModal("staff");
                }}
              >
                Add Staff
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                startIcon={<PlusCircle />}
                variant="contained"
                onClick={() => {
                  openModal("employee");
                }}
              >
                Add Employee
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                startIcon={<PlusCircle />}
                variant="contained"
                onClick={() => {
                  setBulkInviteModalOpen(true);
                }}
              >
                Invite Employees
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AddModal open={modalOpen} close={closeModal} type={addType} />
      <AddAccess
        open={accessModelOpen}
        close={closeAccessModal}
        id={accessModalId}
      />
      <AddReportee
        open={reporteeModelOpen}
        close={closeReporteeModal}
        id={accessModalId}
      />
      <Dialog open={uploadModalOpen} onClose={closeUploadModal} maxWidth="lg">
        <DialogTitle disableTypography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={5}
          >
            <Grid item>
              <Typography variant="h4" color="primary">
                Employee data from CSV file
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={1}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<Download />}
                    onClick={downloadSample}
                  >
                    Download Sample
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<FileText />}
                    color="primary"
                    onClick={simulateClick}
                  >
                    Select File
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="body2">
              Click on the 'Select file' icon and select a file from your
              device. Once a valid file is selected click on the 'Upload' button
              bellow to upload.
            </Typography>
            {user.role === "businessAdmin" && (
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                margin="normal"
              >
                <InputLabel id="company"> Select company</InputLabel>
                <Select
                  labelId="company"
                  id="company"
                  label="Select company"
                  displayEmpty
                  onChange={e => {
                    setUploadOrgId(e.target.value);
                  }}
                  autoWidth
                >
                  {companyList.map(item => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {uploadModalError !== "" && (
              <Alert severity="error">{uploadModalError}</Alert>
            )}
            <CsvViewer />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeUploadModal} color="primary">
            Cancel
          </Button>
          <Button onClick={uploadCsv} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={bulkInviteOpen}
        onClose={e => {
          setInviteEmail("");
          setBulkInviteModalOpen(false);
        }}
        maxWidth="lg"
      >
        <DialogTitle disableTypography>
          <Typography variant="h4" color="primary">
            Invite employees to sign up
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="body2">
              Enter the email addresses of the employees you want to invite.
              Seperate individual email addresses by a comma.
            </Typography>
            {inviteError !== "" && (
              <Alert severity="error">{inviteError}</Alert>
            )}
            {user.role === "businessAdmin" && (
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                margin="normal"
              >
                <InputLabel id="company"> Select company</InputLabel>
                <Select
                  labelId="company"
                  id="company"
                  label="Select company"
                  displayEmpty
                  onChange={e => {
                    setInviteOrgId(e.target.value);
                  }}
                  autoWidth
                >
                  {companyList.map(item => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <TextField
              label="Email addresses seperated by comma"
              variant="outlined"
              type="text"
              margin="normal"
              fullWidth
              value={inviteEmails}
              onChange={e => {
                setInviteEmail(e.target.value);
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={e => {
              setInviteEmail("");
              setBulkInviteModalOpen(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={sendInvite} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
      <br />
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
