import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { Doughnut } from "react-chartjs-2";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%"
  }
}));

const DoughnutChart = ({ dataList, labelsList, className, title, ...rest }) => {
  const classes = useStyles();
  const color = [
    "#F1CD3C",
    "#3C73F1",
    "#F1A73C",
    "#F13C57",
    "#F13C3C",
    "#AF3CF1",
    "#3CF144"
  ];
  const colorList = color.slice(0, dataList && dataList.length);

  const chartData = {
    options: {
      responsive: true,
      maintainAspectRatio: true,
      legend: {
        position: "bottom",
        Align: "center"
      }
    },
    dataItems: {
      datasets: [
        {
          data: dataList,
          backgroundColor: colorList
        }
      ],
      labels: labelsList
    }
  };

  const cardDetails = () => {
    if (dataList.length !== 0) {
      return (
        <Doughnut data={chartData.dataItems} options={chartData.options} />
      );
    } else {
      return (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="h4"
            component="h2"
            align="center"
            color="textSecondary"
          >
            No data available
          </Typography>
        </Box>
      );
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title={title} />
      <Divider />
      <CardContent style={{ height: "calc(100% - 60px)" }}>
        {cardDetails()}
      </CardContent>
    </Card>
  );
};

DoughnutChart.propTypes = {
  className: PropTypes.string
};

export default DoughnutChart;
