import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  TextField,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Card,
  CardMedia
} from "@material-ui/core";
import {
  Clear,
  PersonOutline,
  Camera,
  Cancel,
  CheckCircle
} from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import Page from "../../components/Page";
import api from "../../utils/api";
import upload from "../../utils/upload";
import Loader from "../../utils/loader";
import SignaturePad from "react-signature-canvas";
import Webcam from "react-webcam";

const WebcamCapture = props => {
  const webcamRef = React.useRef(null);
  const [captured, setcaptured] = useState("");

  const videoConstraints = {
    width: window.innerWidth,
    height: window.innerHeight,
    facingMode: "user"
  };
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setcaptured(imageSrc);
  }, [webcamRef]);

  return (
    <>
      {captured === "" && (
        <Webcam
          audio={false}
          height={window.innerHeight}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={window.innerWidth}
          videoConstraints={videoConstraints}
        />
      )}
      {captured !== "" && <img src={captured} width={window.innerWidth} />}
      <div
        style={{ position: "absolute", bottom: 5, width: "100vw", height: 120 }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-around"
        >
          {captured === "" && (
            <Grid item>
              <IconButton color="inherit" onClick={capture}>
                <Camera fontSize="large" color="primary" />
              </IconButton>
            </Grid>
          )}
          {captured !== "" && (
            <>
              <Grid item>
                <IconButton color="inherit" onClick={e => setcaptured("")}>
                  <Cancel fontSize="large" color="error" />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton color="inherit" onClick={e => props.done(captured)}>
                  <CheckCircle fontSize="large" color="action" />
                </IconButton>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </>
  );
};

const CheckinSteps = props => {
  var query = {};
  const location = useLocation();
  location.search
    .slice(1)
    .split("&")
    .forEach(item => {
      query[item.split("=")[0]] = item.split("=")[1];
    });
  const [loading, setLoading] = useState(true);
  const [authData, setAuthData] = useState({});
  const [step, setstep] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [videoEnded, setVideoEnded] = useState(false);
  const [formError, setFormError] = useState("");
  const [success, setSuccess] = useState("");
  const [failure, setFailure] = useState("");
  const [warningDialog, setWarningDialog] = useState(false);
  const [warning, setWarning] = useState("");
  const [webcamDialog, setwebcamDialog] = useState(false);
  const [capturedImage, setcapturedImage] = useState(null);
  const [uploadedImage, setuploadedImage] = useState(null);
  const [uploadedImageURI, setuploadedImageURI] = useState(null);
  const isStep1 = questions.map(item => item.type).includes('fullPageWysiwyg');
  const sigCanvas = useRef({});

  const clearSign = () => sigCanvas.current.clear();
  const getSign = () => {
    let url = sigCanvas.current.getCanvas().toDataURL("image/png");
    console.log(url);
  };

  const checkAlreadyAnswered = async () => {
    try {
      await api(
        "POST",
        "meeting/get/participantMetadata",
        {
          participantId: query.participantId,
          keys: ["answer"]
        },
        false
      );
      setSuccess("Your survey data is already saved");
    } catch (error) {}
  };

  const dataURItoBlob = dataURI => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    //New Code
    return new Blob([ab], { type: mimeString });
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await api(
          "POST",
          "auth/mobile/getAuthDetailsFromSurveyToken",
          { token: query.token },
          false
        );
        setAuthData(data.data);
        if (data.data.showSafetySurvey) {
          await checkAlreadyAnswered();
          const questionData = await api(
            "POST",
            "question/mobile/find",
            null,
            false,
            false,
            true,
            data.data.apiKey
          );
          setQuestions(questionData.data);
        }
      } catch (error) {}
      setLoading(false);
    })();
  }, [query.token]);

  const playVideo = e => {
    let video = document.getElementById("safetyVideo");
    video.play();
    e.target.parentNode.remove();
  };

  const handleChange = (index, value) => {
    let newQuestions = [...questions];
    newQuestions[index].ans = value;
    setQuestions(newQuestions);
  };

  const handleSubmit = async () => {
    if(isStep1 && step === 0){
      setstep(1);
      return;
    }
    setLoading(true);
    setFormError("");
    var error;
    if (
      questions.map(qitem => qitem.type).includes("signature")
       &&
      !sigCanvas.current.isEmpty()
    ) {
      let blob = dataURItoBlob(
        sigCanvas.current.getCanvas().toDataURL("image/png")
      );
      let file = new File([blob], Date.now() + ".png", {
        type: "image/png"
      });

      const url = await upload(file, "signature");
      questions.find((q, index) => {
        if (q.type === "signature") handleChange(index, url);
      });
    }
    if (questions.map(qitem => qitem.type).includes("photo") && capturedImage) {
      let blob = dataURItoBlob(capturedImage);
      let file = new File([blob], Date.now() + ".png", {
        type: "image/png"
      });

      const url = await upload(file, "visitorprofile");
      questions.find((q, index) => {
        if (q.type === "photo") handleChange(index, url);
      });
    }

    setLoading(false);
    questions.forEach(item => {
      if (item.isRequired && (!item.ans || item.ans === "")) {
        error = `Please answer all the questions`;
      } else if (
        item.isRequired &&
        item.expected &&
        item.expected !== ["Any"] &&
        !item.expected.includes(item.ans)
      ) {
        if (!error) error = "";
        error =
          error +
          `Expected answer for "${item.title}" is: ${item.expected.join(
            ", "
          )}. `;
      }
    });

    if ((query.allow || query.allow === "true") && error) {
      setWarning(error);
      setWarningDialog(true);
    } else if (!error) {
      submit();
    } else {
      setFormError(error);
    }
  };

  const submit = async () => {
    setLoading(true);
    try {
      const data = await api(
        "POST",
        "meeting/save/participantMetadata",
        {
          participantId: query.participantId,
          answer: questions
        },
        false,
        false,
        true,
        authData.apiKey
      );
      setSuccess("Your survey data is sucessfully saved");
      (query.source || query.source === "tablet") &&
        window.JSBridge.showMessageInNative(JSON.stringify(data));
    } catch (error) {
      setFailure("Something went wrong");
      let androidData = { ...error.response.data };
      (query.source || query.source === "tablet") &&
        window.JSBridge.showMessageInNative(JSON.stringify(androidData));
    }
    setLoading(false);
  };
  const cancel = () => {
    window.JSBridge.showMessageInNative(
      JSON.stringify({ status: "cancelled", message: "Checkin cancelled" })
    );
  };

  return loading ? (
    <Loader />
  ) : (
    <Page title="Checkin Steps">
      <Container maxWidth="md" style={{ padding: 0 }}>
        <Box p={3} my={3} style={{ backgroundColor: "#fff" }}>
          {(!query.source || query.source !== "tablet") && (
            <>
              <Box
                mb={3}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <img
                  src={authData.businessLogo}
                  style={{ maxWidth: 180 }}
                  alt="logo"
                />
              </Box>
              <Typography
                color="primary"
                variant="h1"
                component="h2"
                align="center"
                style={{ fontWeight: 900 }}
              >
                Welcome to {authData.businessName}
              </Typography>
            </>
          )}
          {success === "" && failure === "" && (
            <>
              {authData.showSafetyVideo && !videoEnded && (
                <Box my={3}>
                  <Typography
                    variant="h4"
                    component="h2"
                    align="center"
                    style={{ fontWeight: 900 }}
                  >
                    Saftey Video
                  </Typography>
                  <video
                    // autoPlay
                    id="safetyVideo"
                    src={authData.safetyVideoLink}
                    width="100%"
                    height="100%"
                    // controls
                    // muted
                    style={{ margin: "16px 0" }}
                    onEnded={() => {
                      setVideoEnded(true);
                    }}
                  ></video>

                  <Typography variant="body1" align="center">
                    Please play and watch the full video. Then fill up the
                    survey form at the end of the video
                  </Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={playVideo}
                    fullWidth
                  >
                    Play
                  </Button>
                  {(query.source || query.source === "tablet") && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={cancel}
                      fullWidth
                      style={{ marginTop: 16 }}
                    >
                      Cancel
                    </Button>
                  )}
                </Box>
              )}
              {((authData.showSafetyVideo && videoEnded) ||
                !authData.showSafetyVideo) &&
                authData.showSafetySurvey && (
                  <Box my={3}>
                    <Typography
                      variant="h4"
                      component="h2"
                      align="center"
                      style={{ fontWeight: 900 }}
                    >
                      Checkin Questionnaire
                    </Typography>
                    {step === 0 &&
                      questions.map((item, index) => (
                        <Box my={3} key={index}>
                          {item.type !== "fullPageWysiwyg" &&
                            item.type !== "signature" && (
                              <Grid
                                container
                                direction="row"
                                spacing={1}
                                my={3}
                              >
                                <Grid item xs={12}>
                                  <Typography
                                    variant="body1"
                                    align="left"
                                    component="span"
                                  >
                                    {item.title}
                                    {item.isRequired ? " *" : ""}
                                  </Typography>
                                  {item.type === "signature" && (
                                    <IconButton
                                      color="secondary"
                                      onClick={clearSign}
                                    >
                                      <Clear />
                                    </IconButton>
                                  )}
                                </Grid>
                                {item.type === "radiogroup" &&
                                  item.choices.map((innerItem, innerIndex) => (
                                    <Grid item key={innerIndex}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={item.ans === innerItem}
                                            onChange={e => {
                                              handleChange(index, innerItem);
                                            }}
                                            name={innerItem}
                                          />
                                        }
                                        label={innerItem}
                                      />
                                    </Grid>
                                  ))}
                                {item.type === "textinput" && (
                                  <TextField
                                    fullWidth
                                    label="Answer"
                                    multiline
                                    rowsMax={4}
                                    margin="normal"
                                    type="text"
                                    value={item.ans}
                                    onChange={e => {
                                      handleChange(index, e.target.value);
                                    }}
                                    variant="outlined"
                                  ></TextField>
                                )}
                                {item.type === "signature" && (
                                  <Box
                                    borderColor="grey.300"
                                    border={1}
                                    maxHeight={180}
                                    maxWidth={360}
                                  >
                                    <SignaturePad
                                      ref={sigCanvas}
                                      penColor="blue"
                                      canvasProps={{
                                        width: 360,
                                        height: 180
                                      }}
                                    />
                                  </Box>
                                )}
                                {item.type === "photo" && (
                                  <Card>
                                    {!query.source && (
                                      <CardMedia
                                        onClick={e => {
                                          setwebcamDialog(true);
                                        }}
                                        image={
                                          capturedImage ??
                                          "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                                        }
                                        title="profile picture"
                                        style={{ height: 300, width: 360 }}
                                      />
                                    )}
                                    {(query.source ||
                                      query.source === "tablet") && (
                                      <>
                                        {uploadedImageURI && (
                                          <CardMedia
                                            image={uploadedImageURI}
                                            title="profile picture"
                                            style={{ height: 300, width: 360 }}
                                          />
                                        )}
                                        <Button
                                          variant="contained"
                                          component="label"
                                        >
                                          {uploadedImage && "Recapture"}
                                          {!uploadedImage && "Capture"}
                                          <input
                                            type="file"
                                            hidden
                                            accept="image/*"
                                            onChange={async e => {
                                              setuploadedImage(
                                                e.target.files[0]
                                              );
                                              var reader = new FileReader();
                                              reader.readAsDataURL(
                                                e.target.files[0]
                                              );
                                              reader.onload = () => {
                                                setuploadedImageURI(
                                                  reader.result
                                                );
                                                setcapturedImage(reader.result);
                                              };
                                            }}
                                          />
                                        </Button>
                                      </>
                                    )}
                                  </Card>
                                )}
                              </Grid>
                            )}
                        </Box>
                      ))}
                    {step === 1 &&
                      questions.map((item, index) => (
                        <Box my={3} key={index}>
                          {(item.type === "fullPageWysiwyg" ||
                            item.type === "signature") && (
                            <Grid container direction="row" spacing={1} my={3}>
                              <Grid item xs={12}>
                                <Typography
                                  variant="body1"
                                  align="left"
                                  component="span"
                                >
                                  {item.title}
                                  {item.isRequired ? " *" : ""}
                                </Typography>

                                {item.type === "signature" && (
                                  <IconButton
                                    color="secondary"
                                    onClick={clearSign}
                                  >
                                    <Clear />
                                  </IconButton>
                                )}
                              </Grid>
                              {item.type === "signature" && (
                                <Box
                                  borderColor="grey.300"
                                  border={1}
                                  maxHeight={180}
                                  maxWidth={360}
                                >
                                  <SignaturePad
                                    ref={sigCanvas}
                                    penColor="blue"
                                    canvasProps={{
                                      width: 360,
                                      height: 180
                                    }}
                                  />
                                </Box>
                              )}
                              {item.type === "fullPageWysiwyg" && (
                                <div
                                style={{padding: 4}}
                                  dangerouslySetInnerHTML={{
                                    __html: item.nda
                                  }}
                                ></div>
                              )}
                            </Grid>
                          )}
                        </Box>
                      ))}

                    <Box my={3}>
                      {formError !== "" && (
                        <Alert severity="error">{formError}</Alert>
                      )}
                    </Box>

                    <br />

                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleSubmit}
                      fullWidth
                    >
                      {isStep1 && step === 0 && "Continue"}
                      {!(isStep1 && step === 0) && "Submit"}
                    </Button>
                    {(query.source || query.source === "tablet") && (
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={cancel}
                        style={{ marginTop: 16 }}
                        fullWidth
                      >
                        Cancel
                      </Button>
                    )}
                  </Box>
                )}
            </>
          )}
          {success !== "" && (
            <Box my={3}>
              <Alert severity="success">
                <AlertTitle>Success!</AlertTitle>
                {success}
              </Alert>
            </Box>
          )}
          {failure !== "" && (
            <Box my={3}>
              <Alert severity="error">
                <AlertTitle>Error!</AlertTitle>
                {failure}
              </Alert>
            </Box>
          )}
        </Box>
        <Dialog open={webcamDialog} fullScreen>
          <WebcamCapture
            done={url => {
              setcapturedImage(url);
              setwebcamDialog(false);
            }}
          />
        </Dialog>
        <Dialog
          open={warningDialog}
          onClose={e => setWarningDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {warning}
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              Do you want to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={e => setWarningDialog(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={e => {
                setWarningDialog(false);
                submit();
                setLoading(true);
              }}
              color="primary"
              autoFocus
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
};

export default CheckinSteps;
