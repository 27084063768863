import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { captureException } from "@sentry/react";
import React, { useState } from "react";
import { PlusCircle } from "react-feather";
import api from "src/utils/api";
import Loader from "../../../utils/loader";
import ConfirmationDialog from "../common/ConfirmDialog";
import BeconModal from "./BeconModal";

const useStyles = makeStyles({
  root: {},
  table: {
    minWidth: 650
  },
  button: {
    marginBottom: 10
  }
});

export default function BeconRegistration() {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setLoader] = useState(true);
  const [isLoadingDelete, setDeleteLoader] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [countData, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeRowInfo, setActiveRow] = useState({});

  React.useEffect(() => {
    beconCount();
  }, []);

  const beconCount = async () => {
    try {
      const countPayload = {
        sort: { key: "becon.macAddress", order: "ASC" },
        skip: 0,
        take: 10
      };
      const res = await api("POST", "becon/findCount", countPayload, true);
      setCount(res);
    } catch (error) {
      captureException(error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [rowsPerPage, page]);

  const fetchData = async () => {
    setLoader(true);
    try {
      const payload = {
        sort: {
          key: "becon.macAddress",
          order: "ASC"
        },
        skip: page * rowsPerPage,
        take: rowsPerPage
      };
      const resInfo = await api("POST", "becon/find", payload, true);
      const res = resInfo || [];
      setDataList(res);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      captureException(error);
    }
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleModal = () => {
    setIsOpen(true);
  };

  const handleClick = (event, info) => {
    setAnchorEl(event.currentTarget);
    setActiveRow(info);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveRow({});
  };

  const editBeconToArray = item => {
    const ind = dataList.findIndex(l => l.beconId === item.beconId);
    dataList[ind] = item;
    setDataList(dataList);
    handleClose();
  };

  const deleteItem = async () => {
    if (activeRowInfo && activeRowInfo.beconId) {
      setDeleteLoader(true);
      try {
        const res = await api(
          "DELETE",
          `becon/${activeRowInfo.beconId}`,
          null,
          true
        );
        if (res.beconId) {
          handleClose();
          setOpen(false);
          fetchData();
          beconCount();
          setDeleteLoader(false);
          setAnchorEl(null);
        }
      } catch (error) {
        captureException(error);
        setDeleteLoader(false);
      }
    }
  };

  const DialogButtons = (
    <React.Fragment>
      <Button variant="outlined" color="primary" onClick={() => setOpen(false)}>
        NO
      </Button>
      <Button variant="contained" color="primary" onClick={deleteItem}>
        {isLoadingDelete ? "Loading..." : "Yes delete"}
      </Button>
    </React.Fragment>
  );

  if (isLoading) {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Loader />
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}
        direction="row"
      >
        <Grid item />
        <Grid item>
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <Button
                color="primary"
                className={classes.button}
                startIcon={<PlusCircle />}
                variant="outlined"
                onClick={handleModal}
              >
                Create New BLE Card
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">SI No</TableCell>
              <TableCell align="center">Navigate Beacon Id</TableCell>
              <TableCell align="center">BLE Card QR</TableCell>
              <TableCell align="center">Mac ID</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map((row, ind) => (
              <TableRow key={ind}>
                <TableCell align="center">
                  {rowsPerPage * page - 1 + ind + 2 || ""}
                </TableCell>
                <TableCell align="center">{row.beconId || "-"}</TableCell>
                <TableCell align="center">{row.bleCard || "-"}</TableCell>
                <TableCell align="center">{row.macAddress || "-"}</TableCell>
                <TableCell align="center">
                  <IconButton
                    aria-label="edit"
                    onClick={event => handleClick(event, row)}
                  >
                    <MoreVertIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={countData}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      {isOpen && (
        <BeconModal
          open={isOpen}
          editBeconToArray={editBeconToArray}
          activeData={activeRowInfo}
          beconCount={beconCount}
          fetchData={fetchData}
          close={() => setIsOpen(false)}
        />
      )}
      {open && (
        <ConfirmationDialog
          open={open}
          error={null}
          handleClose={() => setOpen(false)}
          title={"Delete"}
          content={"Are you sure you want to delete becon?"}
          btnActions={DialogButtons}
        />
      )}
      {Boolean(anchorEl) && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleModal}>Edit</MenuItem>
          <MenuItem onClick={() => setOpen(true)}>Archive</MenuItem>
        </Menu>
      )}
    </React.Fragment>
  );
}
