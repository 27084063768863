import React from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import MainLayout from "src/layouts/MainLayout";
import VisitingCard from "src/views/visitingCard";
import AccountView from "src/views/account/AccountView";
import AllMeetings from "src/views/allMeetings";
import AllParticipants from "src/views/allParticipants";
import AllVisits from "src/views/allVisits";
import AllActivities from "src/views/allActivities";
import Analytics from "src/views/analytics";
import ForgotPassword from "src/views/auth/ForgotPassword";
// import DashboardView from "src/views/reports/DashboardView";
import LoginView from "src/views/auth/LoginView";
import RegisterView from "src/views/auth/RegisterView";
import CheckinSteps from "src/views/checkinSteps";
import CreateMeeting from "src/views/createMeeting";
import CreateVisit from "src/views/createVisit";
import Display from "src/views/display";
import EditMeeting from "src/views/editMeeting";
import NotFoundView from "src/views/errors/NotFoundView";
import Instant from "src/views/instantMeeting";
import ManageUsers from "src/views/manageUsers";
import MeetingDetails from "src/views/meetingDetails";
import VisitDetails from "src/views/visitDetails";
import Response from "src/views/response";
import VisitBaggage from "src/views/visitBaggage";
import ResponseInterface from "src/views/responseInterface";
import SettingsView from "src/views/settings/SettingsView";
import Tracking from "src/views/tracking";
import AllTrackingReports from "src/views/allTrackingReports";
import AllCheckInFormData from "src/views/allCheckInFormData";
import AllCovidDeclarationList from "src/views/allCovidDeclarationLogs";
import TrackingReportDetails from "src/views/trackingReportDetails";
import ViewVisit from "src/views/viewVisit";
import CheckinRules from "src/views/checkinRules/CheckinRules";
import Rosters from "src/views/rosters";
import AllTempLogs from "src/views/allTempLogs";

import RoomAvailability from "./views/roomAvailability";
import EmployeeSignUp from "./views/employeeSignUp";
import InvitationResponse from "./views/invitationResponse";
import ManagePantry from "src/views/managePantry";
import Attendance from "src/views/attendance";
import RosterResponse from "src/views/rosterResponse";

const commonRoutes = [
  {
    path: "/display",
    element: <Display />
  },
  {
    path: "/instant",
    element: <Instant />
  },
  {
    path: "/response",
    element: <Response />
  },
  {
    path: "/visitBaggage",
    element: <VisitBaggage />
  },
  {
    path: "/viewVisit",
    element: <ViewVisit />
  },
  {
    path: "/responseinterface",
    element: <ResponseInterface />
  },
  {
    path: "/checkinSteps",
    element: <CheckinSteps />
  },
  {
    path: "/employeeSignup",
    element: <EmployeeSignUp />
  },

  {
    path: "/visitingCard/:id",
    element: <VisitingCard />
  },
  {
    path: "/invitationResponse",
    element: <InvitationResponse />
  },
  {
    path: "/rosterResponse",
    element: <RosterResponse />
  }
];

const routes = {
  businessAdmin: [
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "/", element: <Analytics /> },
        { path: "account", element: <AccountView /> },
        { path: "dashboard", element: <Analytics /> },
        { path: "settings", element: <SettingsView /> },
        { path: "livetracking", element: <Tracking /> },
        { path: "trackingreports", element: <AllTrackingReports /> },
        { path: "trackingreports/:id", element: <TrackingReportDetails /> },
        { path: "meeting", element: <AllMeetings /> },
        { path: "participants", element: <AllParticipants /> },
        { path: "temperaturelog", element: <AllTempLogs /> },
        { path: "activities", element: <AllActivities /> },
        { path: "checkinformdata", element: <AllCheckInFormData /> },
        { path: "coviddeclaration", element: <AllCovidDeclarationList /> },
        { path: "rosters", element: <Rosters /> },
        { path: "visit", element: <AllVisits /> },
        { path: "visit/:id", element: <VisitDetails /> },
        { path: "meeting/:id", element: <MeetingDetails /> },
        { path: "meeting/:id/edit", element: <EditMeeting /> },
        { path: "roomavailability", element: <RoomAvailability /> },
        { path: "users", element: <ManageUsers /> },
        { path: "pantry", element: <ManagePantry /> },
        { path: "login", element: <Navigate to="/" /> },
        { path: "forgotpassword", element: <ForgotPassword /> },
        { path: "register", element: <Navigate to="/" /> },
        { path: "*", element: <NotFoundView /> }
      ]
    },
    ...commonRoutes
  ],
  superadmin: [
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "/", element: <Analytics /> },
        { path: "account", element: <AccountView /> },
        { path: "dashboard", element: <Analytics /> },
        { path: "settings", element: <SettingsView /> },
        { path: "settings/integration/:authtype", element: <SettingsView /> },
        { path: "livetracking", element: <Tracking /> },
        { path: "trackingreports", element: <AllTrackingReports /> },
        { path: "trackingreports/:id", element: <TrackingReportDetails /> },
        { path: "createmeeting", element: <CreateMeeting /> },
        { path: "createvisit", element: <CreateVisit /> },
        { path: "activities", element: <AllActivities /> },
        { path: "visit", element: <AllVisits /> },
        { path: "visit/:id", element: <VisitDetails /> },
        { path: "meeting", element: <AllMeetings /> },
        { path: "participants", element: <AllParticipants /> },
        { path: "meeting/:id", element: <MeetingDetails /> },
        { path: "meeting/:id/edit", element: <EditMeeting /> },
        { path: "temperaturelog", element: <AllTempLogs /> },
        { path: "users", element: <ManageUsers /> },
        { path: "checkinRules", element: <CheckinRules /> },
        { path: "attendance", element: <Attendance /> },
        { path: "rosters", element: <Rosters /> },
        { path: "login", element: <Navigate to="/" /> },
        { path: "roomavailability", element: <RoomAvailability /> },
        { path: "forgotpassword", element: <ForgotPassword /> },
        { path: "register", element: <Navigate to="/" /> },
        { path: "*", element: <NotFoundView /> }
      ]
    },
    ...commonRoutes
  ],
  admin: [
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "/", element: <Analytics /> },
        { path: "account", element: <AccountView /> },
        { path: "dashboard", element: <Analytics /> },
        { path: "livetracking", element: <Tracking /> },
        { path: "createmeeting", element: <CreateMeeting /> },
        { path: "createvisit", element: <CreateVisit /> },
        { path: "activities", element: <AllActivities /> },
        { path: "visit", element: <AllVisits /> },
        { path: "visit/:id", element: <VisitDetails /> },
        { path: "meeting", element: <AllMeetings /> },
        { path: "participants", element: <AllParticipants /> },
        { path: "temperaturelog", element: <AllTempLogs /> },
        { path: "meeting/:id", element: <MeetingDetails /> },
        { path: "meeting/:id/edit", element: <EditMeeting /> },
        { path: "roomavailability", element: <RoomAvailability /> },
        { path: "users", element: <ManageUsers /> },
        { path: "pantry", element: <ManagePantry /> },
        { path: "attendance", element: <Attendance /> },
        { path: "checkinRules", element: <CheckinRules /> },
        { path: "login", element: <Navigate to="/" /> },
        { path: "forgotpassword", element: <ForgotPassword /> },
        { path: "register", element: <Navigate to="/" /> },
        { path: "*", element: <NotFoundView /> }
      ]
    },
    ...commonRoutes
  ],
  employee: [
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "/", element: <Analytics /> },
        { path: "account", element: <AccountView /> },
        { path: "dashboard", element: <Analytics /> },
        { path: "createmeeting", element: <CreateMeeting /> },
        { path: "meeting", element: <AllMeetings /> },
        { path: "meeting/:id", element: <MeetingDetails /> },
        { path: "meeting/:id/edit", element: <EditMeeting /> },
        { path: "rosters", element: <Rosters /> },
        { path: "login", element: <Navigate to="/" /> },
        { path: "forgotpassword", element: <ForgotPassword /> },
        { path: "register", element: <Navigate to="/" /> },
        { path: "*", element: <NotFoundView /> }
      ]
    },
    ...commonRoutes
  ],
  visitor: [
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "/", element: <Analytics /> },
        { path: "account", element: <AccountView /> },
        { path: "dashboard", element: <Analytics /> },
        { path: "createmeeting", element: <CreateMeeting /> },
        { path: "createvisit", element: <CreateVisit /> },
        { path: "visit", element: <AllVisits /> },
        { path: "visit/:id", element: <VisitDetails /> },
        { path: "meeting", element: <AllMeetings /> },
        { path: "meeting/:id", element: <MeetingDetails /> },
        { path: "meeting/:id/edit", element: <EditMeeting /> },
        { path: "login", element: <Navigate to="/" /> },
        { path: "forgotpassword", element: <ForgotPassword /> },
        { path: "register", element: <Navigate to="/" /> },
        { path: "*", element: <NotFoundView /> }
      ]
    },
    ...commonRoutes
  ],
  notloggedin: [
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "login", element: <LoginView /> },
        { path: "forgotpassword", element: <ForgotPassword /> },
        { path: "register", element: <RegisterView /> },
        { path: "404", element: <NotFoundView /> },
        { path: "account", element: <LoginView /> },
        { path: "customers", element: <LoginView /> },
        { path: "dashboard", element: <LoginView /> },
        { path: "products", element: <LoginView /> },
        { path: "settings", element: <LoginView /> },
        { path: "createmeeting", element: <LoginView /> },
        { path: "createvisit", element: <LoginView /> },
        { path: "activities", element: <LoginView /> },
        { path: "visit", element: <LoginView /> },
        { path: "visit/:id", element: <LoginView /> },
        { path: "checkinRules", element: <LoginView /> },
        { path: "attendance", element: <LoginView /> },
        { path: "checkinformdata", element: <LoginView /> },
        { path: "rosters", element: <LoginView /> },
        { path: "coviddeclaration", element: <LoginView /> },
        { path: "meeting", element: <LoginView /> },
        { path: "temperaturelog", element: <LoginView /> },
        { path: "attendance", element: <LoginView /> },
        { path: "meeting/:id", element: <LoginView /> },
        { path: "participants", element: <LoginView /> },
        { path: "meeting/:id/edit", element: <LoginView /> },
        { path: "roomavailability", element: <LoginView /> },
        { path: "users", element: <LoginView /> },
        { path: "pantry", element: <LoginView /> },
        { path: "livetracking", element: <LoginView /> },
        { path: "trackingreports", element: <LoginView /> },
        { path: "trackingreports/:id", element: <LoginView /> },
        { path: "/", element: <Navigate to="login" /> },
        { path: "*", element: <NotFoundView /> }
      ]
    },
    ...commonRoutes
  ]
};

export default routes;
