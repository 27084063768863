import { Grid, Paper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Page from "src/components/Page";
import BeconRegistration from "../beconRegistration/BeconRegistration";
import Organizations from "../organizations/Organizations";
import Buildings from "../buildings/Buildings";
import Checkin from "../checkin/Checkin";
import Checkout from "../checkout/Checkout";
import DeviceRegistration from "../deviceRegistration/DeviceRegistration";
import Integration from "../integration/Integration";
import Notification from "../notification/Notification";
import Meeting from "../meeting/Meeting";
import Rooms from "../rooms/Rooms";
import Floors from "../floors/Floors";
import User from "../user/User";
import PremiseAccess from "../vip/VipRoomAccess";
import RoomControls from "../Controls/RoomControls";
import PremiseAccessRule from "../PremiseRule/PremiseAccessRule";
import BleAccessControl from "../bleAccessControl/BleAccessControl";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: "100%" }}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));

const SettingView = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const user = useSelector(state => state.user);
  const orgSettings = useSelector(state => state.orgSettings);
  const params = useParams();
  const { search } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();

  var tabs = [
    {
      displayName: "Notification",
      alias: "notification",
      component: Notification
    },
    {
      displayName: "Meeting",
      alias: "meeting",
      component: Meeting
    },
    {
      displayName: "Integration",
      alias: "integration",
      component: Integration
    },
    {
      displayName: "Checkin ",
      alias: "checkin",
      component: Checkin
    },
    {
      displayName: "Premise Access Rules",
      alias: "premiseAccessRules",
      component: PremiseAccessRule
    }
  ];
  if (user.role === "businessAdmin") {
    tabs = [
      {
        displayName: "Organisations",
        alias: "organizations",
        component: Organizations
      },
      {
        displayName: "User",
        alias: "user",
        component: User
      },
      ...tabs.filter(
        item => item.alias !== "meeting" && item.alias !== "premiseAccessRules"
      ),
      {
        displayName: "Checkout  ",
        alias: "checkout",
        component: Checkout
      },
      {
        displayName: "Buildings",
        alias: "buildings",
        component: Buildings
      },
      {
        displayName: "Floors",
        alias: "floors",
        component: Floors
      },
      {
        displayName: "Rooms",
        alias: "rooms",
        component: Rooms
      },
      {
        displayName: "Room Controls",
        alias: "roomControls",
        component: RoomControls
      },
      {
        displayName: "Premise Access",
        alias: "premiseAccess",
        component: PremiseAccess
      },
      {
        displayName: "BLE Access Control",
        alias: "bleAccessControl",
        component: BleAccessControl
      },
      {
        displayName: "Device Registration",
        alias: "devices",
        component: DeviceRegistration
      },
      {
        displayName: "Beacon Registration",
        alias: "beacons",
        component: BeconRegistration
      }
    ];
  }

  if (!orgSettings.rosslareIntegration) {
    tabs = tabs.filter(item => item.alias !== "bleAccessControl");
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate("#" + tabs[newValue].alias);
  };

  useEffect(() => {
    let panelAlias = window.location.href.split("#")[1];
    const panel = tabs.find(item => item.alias === panelAlias);
    if (panel) setValue(tabs.indexOf(panel));
    if (location.pathname.includes("/integration/")) {
      navigate("../settings" + search + "&type=" + params.authtype+ "#integration");
      setValue(tabs.indexOf(tabs.find(item => item.alias === "integration")));
    }
  }, []);

  return (
    <Page className={classes.root} title="Organization settings">
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}
        direction="row"
      >
        <Grid item>
          <Typography component="h1" variant="h3" color="primary">
            Organization Settings
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Paper>
        <Tabs
          orientation="horizontal"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
        >
          {tabs.map((item, index) => (
            <Tab
              label={item.displayName}
              {...a11yProps(index)}
              wrapped
              key={index}
            />
          ))}
        </Tabs>
      </Paper>
      <br />
      {tabs.map((item, index) => (
        <TabPanel value={value} index={index} key={index}>
          {item.component}
        </TabPanel>
      ))}
    </Page>
  );
};

export default SettingView;
