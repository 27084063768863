import React from "react";

const Logo = props => {
  const source = props.src || "/static/logo.png";
  return (
    <img
      alt="Logo"
      src={source}
      {...props}
      style={{
        backgroundColor: "#fff",
        marginTop: 2,
        borderRadius: 6,
        padding: 4,
        height: 48
      }}
    />
  );
};

export default Logo;
