import {
  Avatar,
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, withStyles } from "@material-ui/styles";
import { captureException } from "@sentry/react";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearToastAction, toastAction } from "../../../../actions";
import api from "../../../../utils/api";
import getAvatarStyle from "../../../../utils/getAvatarStyle";
import getInitials from "../../../../utils/getInitials";
import Loader from "../../../../utils/loader";
import Image from "material-ui-image";
import axios from "axios";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end"
  },
  capitalize: {
    textTransform: "capitalize"
  },
  chipButton: {
    paddingLeft: 12,
    paddingRight: 12
  },
  tableCell: {
    paddingLeft: 8,
    paddingRight: 8
  },
  bold: {
    fontWeight: "bold"
  },
  checkedIn: {
    backgroundColor: "green"
  },
  checkedOut: {
    backgroundColor: "red"
  },

  filterTextField: {
    "&::placeholder": {
      color: "#000"
    },
    "&::-ms-input-placeholder ": {
      color: "#000"
    }
  }
}));

const columns = [
  {
    label: "Name",
    dataType: "text",
    field: "visitorName|employeeName"
  },
  {
    label: "User type",
    dataType: "text",
    field: "userType"
  },
  {
    label: "Time",
    dataType: "datetime",
    field: "checkInTime"
  },
  {
    label: "Temperature",
    dataType: "temperature",
    field: "temperature"
  },
  {
    label: "Declaration",
    dataType: "declaration",
    field: "declarationData"
  }
];

const Results = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const orgSettings = useSelector(state => state.orgSettings);
  const [loading, setLoading] = useState(true);
  const [dataModal, setDataModal] = useState(false);
  const [dataModalIndex, setDataModalIndex] = useState(-1);
  const [allstartKey, setalltartKey] = useState([null]);
  const [meetings, setMeetings] = useState([]);
  const [meetingCount, setMeetingCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filters, setFilters] = useState({});
  const [currentSort, setCurrentSort] = useState({
    label: "Date & time",
    direction: "desc"
  });

  const [query, setQuery] = useState({
    limit: rowsPerPage,
    skip: 0
  });

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(query);
    handleQuery();
  }, [page, rowsPerPage]);

  const renderDataColumns = (data, columns) => {
    return columns.map((item, i) => {
      var dataField;
      if (item.field.includes("|")) {
        item.field.split("|").forEach(item => {
          dataField =
            dataField || item.split(".").reduce((ox, ix) => ox[ix], data);
        });
      } else if (item.field.includes("&")) {
        try {
          item.field.split("&").forEach((item, index) => {
            if (index === 0)
              dataField =
                item.split(".").reduce((ox, ix) => ox[ix], data) || "";
            else
              dataField =
                dataField +
                  ", " +
                  item.split(".").reduce((ox, ix) => ox[ix], data) || "";
          });
        } catch (error) {
          dataField = "";
        }
      } else {
        dataField = item.field.split(".").reduce((ox, ix) => ox[ix], data);
      }
      switch (item.dataType) {
        case "datetime":
          return dataField ? (
            <TableCell size="small" className={classes.tableCell} key={i}>
              <b>
                {moment
                  .utc(new Date(parseInt(dataField)).toISOString())
                  .local()
                  .format("LT")}
              </b>{" "}
              {moment
                .utc(new Date(parseInt(dataField)).toISOString())
                .local()
                .format("Do MMM YYYY")}
            </TableCell>
          ) : (
            <TableCell size="small" className={classes.tableCell} key={i}>
              Not Available
            </TableCell>
          );
        case "temperature":
          return (
            <TableCell
              align="center"
              size="small"
              key={i}
              className={clsx(classes.capitalize, classes.tableCell)}
            >
              {dataField}&#8451;
            </TableCell>
          );
        case "declaration":
          return (
            <TableCell
              align="center"
              size="small"
              key={i}
              className={clsx(classes.capitalize, classes.tableCell)}
            >
              {/* <img style={{ maxHeight: 60 }} src={dataField} /> */}
              <Button variant="outlined">View Declaration</Button>
            </TableCell>
          );
        default:
          return (
            <TableCell size="small" className={classes.tableCell} key={i}>
              {dataField}
            </TableCell>
          );
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage, allstartKey);
    setQuery({ ...query, skip: query.limit * newPage });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setQuery({ ...query, skip: 0, limit: event.target.value });
    setRowsPerPage(event.target.value);
    setPage(0);
    setalltartKey([null]);
  };

  const handleRowClick = index => {
    setDataModalIndex(index);
    setDataModal(true);
  };

  const handleQuery = async () => {
    dispatch(
      toastAction({
        status: true,
        message: "Loading...",
        type: "info",
        noIcon: true
      })
    );
    try {
      fetch(
        "https://kdgavyhkd5.execute-api.ap-south-1.amazonaws.com/viewLogCount",
        {
          method: "POST",
          body: JSON.stringify({
            businessId: user.businessId,
            take: query.limit,
            skip: query.skip
          })
        }
      )
        .then(data => {
          return data.json();
        })
        .then(data => {
          setMeetingCount(data);
        })
        .catch(error => {
          console.error("Error:", error);
        });
      fetch("https://kdgavyhkd5.execute-api.ap-south-1.amazonaws.com/viewLog", {
        method: "POST",
        body: JSON.stringify({
          businessId: "cvsdvd654651a",
          take: query.limit,
          skip: query.skip
        })
      })
        .then(data => {
          return data.json();
        })
        .then(data => {
          setMeetings(
            data.map(item => {
              return {
                ...item,
                userType: item.employeeId ? "Employee" : "Visitor"
              };
            })
          );
        })
        .catch(error => {
          console.error("Error:", error);
        });

      setLoading(false);
      dispatch(clearToastAction({}));
    } catch (error) {
      dispatch(clearToastAction({}));
      captureException(error);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="h4">
        All Entries
      </Typography>
      <Typography color="textSecondary" gutterBottom variant="caption">
        {meetingCount} Results found. Page {page + 1} of{" "}
        {Math.ceil(meetingCount / rowsPerPage)}
      </Typography>
      <Card>
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((col, i) => (
                      <TableCell
                        className={classes.capitalize}
                        key={i}
                        align={i > 0 ? "center" : "left"}
                      >
                        {col.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {meetings.map((meeting, index) => (
                    <TableRow
                      hover
                      key={index}
                      onClick={() => handleRowClick(index)}
                    >
                      {renderDataColumns(meeting, columns)}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={meetingCount}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
      <Dialog
        open={dataModal}
        onBackdropClick={e => setDataModal(false)}
        onEscapeKeyDown={e => setDataModal(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Typography variant="h4" component="h2">
            Covid Declaration Answers -{" "}
            <small>
              {" "}
              Submitted at:{" "}
              {meetings[dataModalIndex]?.declarationTime &&
                moment
                  .utc(
                    new Date(
                      parseInt(meetings[dataModalIndex]?.declarationTime)
                    ).toISOString()
                  )
                  .local()
                  .format("LT")}
            </small>
          </Typography>
        </DialogTitle>
        <DialogContent>
          {meetings[dataModalIndex]?.declarationData?.map(item => {
            return (
              <>
                <Typography variant="h5" component="h2">
                  {item.question}
                </Typography>
                <Typography variant="body1" component="h2">
                  {item.answer}
                </Typography>
                <br />
              </>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={e => setDataModal(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  meetings: PropTypes.array.isRequired
};

Results.defaultProps = {
  meetings: []
};

export default Results;
