import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timelinePlugin from "@fullcalendar/timeline";
import { makeStyles } from "@material-ui/styles";
import {
  Modal,
  TextField,
  Card,
  CardContent,
  colors,
  useTheme,
  useMediaQuery,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box
} from "@material-ui/core";
import { captureException } from "@sentry/react";
import api from "../../utils/api";
// import '@fullcalendar/core/main.css';
// import '@fullcalendar/daygrid/main.css';
// import '@fullcalendar/timegrid/main.css';
// import '@fullcalendar/list/main.css';
import Toolbar from "./Toolbar";
import Page from "src/components/Page";

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: "Roboto",
    height: "100%",
    padding: theme.spacing(3),
    "& .fc-button": {
      textTransform: "capitalize"
    },
    "& .fc-timegrid-axis-frame": {
      textTransform: "capitalize"
    },
    "& .fc-unthemed td": {
      borderColor: theme.palette.divider
    },
    "& .fc-widget-header": {
      backgroundColor: colors.grey[50]
    },
    "& .fc-axis": {
      ...theme.typography.body2
    },
    "& .fc-list-item-time": {
      ...theme.typography.body2
    },
    "& .fc-list-item-title": {
      ...theme.typography.body1
    },
    "& .fc-list-heading-main": {
      ...theme.typography.h6
    },
    "& .fc-list-heading-alt": {
      ...theme.typography.h6
    },
    "& .fc th": {
      borderColor: theme.palette.divider
    },
    "& .fc-day-header": {
      ...theme.typography.subtitle2,
      fontWeight: 500,
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      backgroundColor: colors.grey[50]
    },
    "& .fc-day-top": {
      ...theme.typography.body2
    },
    "& .fc-highlight": {
      backgroundColor: colors.blueGrey[50]
    },
    "& .fc-event": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderWidth: 2,
      opacity: 0.9,
      "& .fc-time": {
        ...theme.typography.h6,
        color: "inherit"
      },
      "& .fc-title": {
        ...theme.typography.body1,
        color: "inherit"
      }
    },
    "& .fc-list-empty": {
      ...theme.typography.subtitle1
    }
  }
}));

const RoomAvailability = () => {
  const classes = useStyles();
  const calendarRef = useRef(null);
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [roomId, setRoomId] = useState("");
  const [roomList, setRoomList] = useState([]);
  const [rangeEndTime, setRangeEndTime] = useState("");
  const [rangeStartTime, setRangeStartTime] = useState("");
  const [title, setTitle] = useState(moment().format("MMMM YYYY"));
  const [view, setView] = useState(mobileDevice ? "listWeek" : "dayGridMonth");
  const [date, setDate] = useState(moment().toDate());
  const [events, setEvents] = useState([]);
  const [eventModal, setEventModal] = useState({
    open: false,
    event: null
  });
  useEffect(() => {
    (async () => {
      try {
        const availablerooms = await api(
          "POST",
          "room/find",
          {
            search: [],
            sort: { key: "room.createdAt", order: "ASC" }
          },
          true
        );
        setRoomList(availablerooms);
      } catch (error) {
        captureException(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (rangeEndTime != "" && rangeStartTime != "") {
      (async () => {
        try {
          const filteredMeetings = await api(
            "POST",
            "meeting/findByDateLimit",
            {
              search: [
                {
                  key: "meeting.roomId",
                  value: roomId == "all" ? "" : roomId,
                  comparison: "="
                },
                {
                  key: "meeting.startTime",
                  value: rangeEndTime,
                  comparison: "<"
                },
                {
                  key: "meeting.endTime",
                  value: rangeStartTime,
                  comparison: ">="
                }
              ],
              sort: { key: "meeting.startTime", order: "DESC" }
            },
            true
          );

          setEvents(
            filteredMeetings.map(meeting => {
              return {
                id: meeting.id,
                title: meeting.purpose,
                start: moment
                  .utc(new Date(parseInt(meeting.startTime)).toISOString())
                  .local()
                  .format(),
                end: moment
                  .utc(new Date(parseInt(meeting.endTime)).toISOString())
                  .local()
                  .format()
              };
            })
          );
        } catch (error) {
          captureException(error);
        }
      })();
    }
  }, [rangeEndTime, rangeStartTime, roomId]);

  const handleEventClick = info => {
    const selected = events.find(event => event.id === info.event.id);

    setEventModal({
      open: true,
      event: selected
    });
  };

  const handleEventNew = () => {
    setEventModal({
      open: true,
      event: null
    });
  };

  const handleEventDelete = event => {
    setEvents(events => events.filter(e => e.id !== event.id));
    setEventModal({
      open: false,
      event: null
    });
  };

  const handleModalClose = () => {
    setEventModal({
      open: false,
      event: null
    });
  };

  const handleEventAdd = event => {
    setEvents(events => [...events, event]);
    setEventModal({
      open: false,
      event: null
    });
  };

  const handleEventEdit = event => {
    setEvents(events => events.map(e => (e.id === event.id ? event : e)));
    setEventModal({
      open: false,
      event: null
    });
  };

  const updateTitle = () => {
    const calendar = calendarRef.current;
    if (calendar) {
      setTitle(calendar.getApi().view.title);
    }
  };

  const handleDateToday = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.today();
    setDate(calendarApi.getDate());
  };

  const handleViewChange = (view, date) => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.changeView(view, date);
    setView(view);
    setDate(date);
  };

  const handleDatePrev = () => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.prev();
    setDate(calendarApi.getDate());
  };

  const handleDateNext = () => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.next();
    setDate(calendarApi.getDate());
  };

  return (
    <div>
      <Page className={classes.root} title="Room Availability">
        <Grid alignItems="center" container spacing={3} justify="space-between">
          <Grid item>
            <Box width={240}>
              <Typography component="span" variant="h3">
                Room Availability
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <TextField
              size="small"
              label="Filter by room"
              variant="outlined"
              style={{ width: 200 }}
              select
              SelectProps={{
                value: roomId,
                onChange: e => {
                  setRoomId(e.target.value);
                },
                multiline: true,
                displayEmpty: true
              }}
            >
              <MenuItem value="all">
                <em>All Rooms</em>
              </MenuItem>
              {roomList.map(room => {
                return (
                  <MenuItem value={room.id} key={room.id}>
                    {room.name}
                    {", "}
                    {room.building.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
        </Grid>
        <br />
        <Card className={classes.card}>
          <CardContent>
            <Toolbar
              date={date}
              onDateNext={handleDateNext}
              onDatePrev={handleDatePrev}
              onDateToday={handleDateToday}
              onEventAdd={handleEventNew}
              onViewChange={handleViewChange}
              filterByRoom={setRoomId}
              view={view}
              title={title}
            />
            <br />
            <FullCalendar
              headerToolbar={false}
              firstDay={1}
              themeSystem=""
              datesSet={dateSetArgument => {
                setRangeEndTime(dateSetArgument.end);
                setRangeStartTime(dateSetArgument.start);
                updateTitle();
              }}
              dayMaxEventRows={5}
              moreLinkClick={moreLinkInfo => {
                handleViewChange("timeGridDay", moreLinkInfo.date);
              }}
              moreLinkText={"View More"}
              moreLinkContent={moreLinkContentInfo => {
                return (
                  <Typography
                    component="span"
                    variant="overline"
                    color="primary"
                  >
                    <b>View All</b>
                  </Typography>
                );
              }}
              schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
              dateClick={info => {
                handleViewChange("timeGridDay", info.date);
              }}
              // droppable
              // editable
              eventClick={handleEventClick}
              // eventResizableFromStart
              events={events}
              header={false}
              height={540}
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
                timelinePlugin
              ]}
              ref={calendarRef}
              rerenderDelay={10}
              // selectable
              weekends
            />
          </CardContent>
        </Card>
      </Page>
    </div>
  );
};

export default RoomAvailability;
