import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/material.css";
import api from "../../utils/api";
import Loader from "../../utils/loader";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

const ViewModal = props => {
  const classes = useStyles();
  const { open, close, participantId } = props;
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (participantId !== "") {
      (async () => {
        try {
          let data = await api(
            "POST",
            "meeting/get/participantMetadata",
            {
              participantId: participantId,
              keys: ["answer"]
            },
            true
          );
          setAnswers(data);
          setLoading(false);
        } catch (error) {
          setAnswers([]);
          setLoading(false);
        }
      })();
    }
  }, [open]);

  const handleClose = () => {
    setAnswers([]);
    close();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle disableTypography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography component="h1" variant="h3" color="primary">
              Visitor Safety Survey
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {loading ? (
            <Loader />
          ) : (
            <Grid
              container
              direction="row-reverse"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={12} style={{ padding: "0 16px" }}>
                {answers.length > 0 && (
                  <List disablePadding style={{ width: "100%" }}>
                    {answers.map((item, index) => (
                      <>
                        <ListItem disableGutters key={index}>
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography variant="h4" color="textSecondary">
                                {item.title}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="body1">
                                {["radiogroup", "textinput"].includes(item.type)
                                  ? item.ans
                                  : ""}
                              </Typography>
                            }
                          />
                        </ListItem>
                        {["signature", "photo"].includes(item.type) && (
                          <img style={{ width: "100%" }} src={item.ans} />
                        )}
                      </>
                    ))}
                  </List>
                )}
                {!answers.length > 0 && (
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    align="center"
                  >
                    No data found
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
          <Grid
            container
            direction="row-reverse"
            alignItems="center"
            spacing={3}
            className={classes.marginNormal}
          >
            <Grid item>
              <Button onClick={handleClose} variant="contained">
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewModal;
