import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  DialogActions,
  Button
} from "@material-ui/core";

import { captureException } from "@sentry/react";
import api from "src/utils/api";
import { useSelector, useDispatch } from "react-redux";
import { clearToastAction, toastAction } from "../../../actions";

function EditAccessModal(props) {
  const { open, onClose, payload } = props;
  const dispatch = useDispatch();
  const [accessGroups, setAccessGroups] = useState([]);
  const [accessGroupId, setaccessGroupId] = useState("");

  useEffect(() => {
    console.log(payload);
    if (payload?.UserAccGrp?.ID) {
      setaccessGroupId(payload.UserAccGrp.ID);
    }
    (async () => {
      try {
        var data = await api(
          "GET",
          "ble/groups/getAllAccessGroups",
          null,
          true
        );
        setAccessGroups(data.data.Data);
      } catch (error) {
        captureException(error);
      }
    })();
  }, [payload]);
  const handleClose = () => {
    onClose();
    setaccessGroupId("");
  };

  const handleSubmit = async () => {
    if (accessGroupId === "") {
      dispatch(
        toastAction({
          status: true,
          message: "Please select an access group",
          type: "error",
          noIcon: true
        })
      );
      return;
    }

    (async () => {
      try {
        // TODO: Put accessGroup to payload

        let data = await api(
          "PATCH",
          "ble/user/editWithAccessGroup",
          {
            ...payload,
            UserAccGrp: accessGroups.find(x => x.ID === accessGroupId)
          },
          true
        );
        handleClose();
        dispatch(
          toastAction({
            status: true,
            message: "Group updated successfully",
            type: "success",
            noIcon: true
          })
        );
      } catch (error) {
        captureException(error);
        dispatch(
          toastAction({
            status: true,
            message: "Something went wrong",
            type: "error",
            noIcon: true
          })
        );
      }
    })();
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">
        Edit Access Group for - {payload.tFirstName}
      </DialogTitle>
      <DialogContent>
        <br />
        <FormControl variant="outlined" style={{ width: "100%" }}>
          <InputLabel id="accessGroup">Access Group</InputLabel>
          <Select
            labelId="accessGroup"
            id="accessGroupDropdown"
            label="Access Group"
            value={accessGroupId}
            onChange={e => {
              setaccessGroupId(e.target.value);
            }}
            autoWidth
          >
            {accessGroups.map(group => {
              return (
                <MenuItem key={group.ID} value={group.ID}>
                  {group.tDesc}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditAccessModal;
