import {
  Avatar,
  Badge,
  Card,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  DialogContentText,
  FormGroup,
  FormControlLabel,
  Checkbox,
  DialogActions
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, withStyles } from "@material-ui/styles";
import { captureException } from "@sentry/react";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearToastAction, toastAction } from "../../../../actions";
import api from "../../../../utils/api";
import getAvatarStyle from "../../../../utils/getAvatarStyle";
import getInitials from "../../../../utils/getInitials";
import Loader from "../../../../utils/loader";
import rolename from "../../../../utils/rolename";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end"
  },
  capitalize: {
    textTransform: "capitalize"
  },
  chipButton: {
    paddingLeft: 12,
    paddingRight: 12
  },
  tableCell: {
    paddingLeft: 8,
    paddingRight: 8
  },
  bold: {
    fontWeight: "bold"
  },
  requested: {
    backgroundColor: "red"
  },
  approved: {
    backgroundColor: "green"
  },
  completed: {
    backgroundColor: "grey"
  },
  ongoing: {
    backgroundColor: "blue"
  },
  cancelled: {
    backgroundColor: "brown"
  },
  filterTextField: {
    "&::placeholder": {
      color: "#000"
    },
    "&::-ms-input-placeholder ": {
      color: "#000"
    }
  }
}));

const columns = [
  {
    label: "requested by",
    dataType: "profile",
    field: "requestVisitor|requestEmployee"
  },
  {
    label: "Date & time",
    dataType: "datetimerange",
    field: "startTime&endTime"
  },
  {
    label: "purpose",
    dataType: "text",
    field: "purpose"
  },
  {
    label: "venue",
    dataType: "venue",
    field: "building.name&room.name"
  },
  {
    label: "requested at",
    dataType: "datetime",
    field: "createdAt"
  },
  {
    label: "Visitor Arrived at",
    dataType: "datetime",
    field: "tatStartTime"
  },
  {
    label: "Turn Around Time",
    dataType: "text",
    field: "tat"
  },
  {
    label: "status",
    dataType: "status",
    field: "status"
  }
];

const StyledBadge = withStyles(theme => ({
  badge: {
    right: -48,
    top: 10,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px"
  }
}))(Badge);

const Results = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const orgSettings = useSelector(state => state.orgSettings);
  const [loading, setLoading] = useState(true);
  const [suggestionLoading, setSuggestionLoading] = useState(false);
  const [openSuggestion, setOpenSuggestion] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [filterEmail, setFilterEmail] = useState("");
  const [meetings, setMeetings] = useState([]);
  const [meetingCount, setMeetingCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filters, setFilters] = useState({});
  const [currentSort, setCurrentSort] = useState({
    label: "Date & time",
    direction: "desc"
  });

  const [loadingReport, setloadingReport] = useState(false);
  const [reportModalopen, setreportModalopen] = useState(false);
  const [reportFields, setreportFields] = useState([
    { id: "name", title: "Visitor's name", status: false },
    { id: "phone", title: "Visitor's Phone number", status: false },
    { id: "visitorCompany", title: "Visitor's company", status: false },
    { id: "purpose", title: "Purpose of visit", status: false },
    { id: "startTime", title: "Meeting Start Time", status: false },
    { id: "endTime", title: "Meeting End Time", status: false },
    { id: "room", title: "Room", status: false },
    { id: "building", title: "Building", status: false },
    { id: "createdAt", title: "Scheduled Time", status: false },
    { id: "employeeName", title: "Employee Name", status: false },
    { id: "employeeEmail", title: "Employee Email", status: false },
    { id: "employeeDepartment", title: "Employee Department", status: false },
    { id: "checkInTime", title: "Entry Time", status: false },
    { id: "checkOutTime", title: "Exit Time", status: false },
    { id: "tatStartTime", title: "Visitor arrived at", status: false },
    { id: "tatEndTime", title: "Visitor received at", status: false },
    { id: "tatDuration", title: "Visitor waiting time", status: false },
    { id: "totalVisitor", title: "Total Visitor", status: false }
  ]);
  const [query, setQuery] = useState({
    search: ["businessAdmin", "superadmin", "admin"].includes(user.role)
      ? []
      : user.role === "visitor"
      ? [
          {
            key: "participant.visitorId",
            value: user.userId,
            comparison: "="
          }
        ]
      : [
          {
            key: "participant.employeeId",
            value: user.userId,
            comparison: "="
          }
        ],
    sort: { key: "meeting.startTime", order: "DESC" },
    skip: 0,
    take: 10
  });

  let navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    handleQuery();
  }, [page, rowsPerPage]);

  const handleReport = () => {
    setreportModalopen(true);
  };

  const changeReportFields = (e, index) => {
    let oldFields = reportFields;
    oldFields[index].status = e.target.checked;
    setreportFields(oldFields);
  };

  useEffect(() => {
    (async () => {
      try {
        const availablerooms = await api(
          "POST",
          "room/find",
          {
            search: [],
            sort: { key: "room.createdAt", order: "ASC" }
          },
          true
        );
        setRoomList(availablerooms);
      } catch (error) {
        captureException(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (!openSuggestion) {
      setSuggestionList([]);
    } else {
      setSuggestionLoading(true);
      (async () => {
        try {
          var data = await api(
            "POST",
            "meeting/searchUsersWithKeyword",
            { keyword: filterEmail, organizationId: user.organizationId },
            true
          );
          var suggestions = [...data.employee, ...data.visitor];

          setSuggestionList(suggestions);
          setSuggestionLoading(false);
        } catch (error) {
          captureException(error);
        }
      })();
    }
  }, [openSuggestion, filterEmail]);

  const renderFilterColumns = (column, i) => {
    switch (column.dataType) {
      case "profile":
        return (
          <TableCell key={i} style={{ paddingLeft: 8, paddingRight: 8 }}>
            <Autocomplete
              fullWidth
              open={openSuggestion}
              blurOnSelect
              clearOnBlur
              noOptionsText={"No user found"}
              options={suggestionList}
              loading={suggestionLoading}
              onOpen={() => {
                setOpenSuggestion(true);
              }}
              onClose={() => {
                setOpenSuggestion(false);
              }}
              onChange={(event, newValue) => {
                if (newValue) {
                  setQuery({
                    ...query,
                    search: [
                      ...query.search.filter(
                        item => item.key !== "meeting.createdBy"
                      ),
                      {
                        key: "meeting.createdBy",
                        value: newValue.id,
                        comparison: "="
                      }
                    ]
                  });
                  setFilters({ ...filters, "Created by": newValue.name });
                }
              }}
              filterOptions={(options, state) => options}
              getOptionLabel={option => {
                return option.name;
              }}
              renderOption={item => (
                <ListItem dense disableGutters style={{ overflowX: "hidden" }}>
                  <ListItemAvatar>
                    <Avatar style={getAvatarStyle(item.email)}>
                      {getInitials(item.name)}
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      <StyledBadge
                        badgeContent={
                          item.role ? rolename(item.role) : "Visitor"
                        }
                        color={item.role ? "primary" : "secondary"}
                      >
                        <Typography variant="h5">{item.name}</Typography>
                      </StyledBadge>
                    }
                    secondary={
                      <Typography variant="body2" color="textSecondary">
                        {item.role &&
                          item[orgSettings.employeePrimaryIdentifier]}
                        {!item.role &&
                          item[orgSettings.visitorPrimaryIdentifier]}
                      </Typography>
                    }
                  />
                </ListItem>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  className={classes.filterTextField}
                  placeholder="Search user by email or phone number"
                  variant="outlined"
                  onChange={e => {
                    setFilterEmail(e.target.value);
                  }}
                  value={filterEmail}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {suggestionLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />
          </TableCell>
        );
      case "venue":
        return (
          <TableCell key={i} style={{ paddingLeft: 8, paddingRight: 8 }}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <Select
                id="building"
                defaultValue=""
                displayEmpty
                onChange={e => {
                  setQuery({
                    ...query,
                    search: [
                      ...query.search.filter(
                        item => item.key !== "meeting.roomId"
                      ),
                      {
                        key: "meeting.roomId",
                        value: e.target.value,
                        comparison: "="
                      }
                    ]
                  });
                  const theRoom = roomList.find(
                    room => room.id === e.target.value
                  );
                  setFilters({
                    ...filters,
                    Room: theRoom.name + ", " + theRoom.building.name
                  });
                }}
                autoWidth
              >
                <MenuItem value="" disabled>
                  Filter by venue
                </MenuItem>
                {roomList.map(room => {
                  return (
                    <MenuItem value={room.id} key={room.id}>
                      {room.name}
                      {", "}
                      {room.building.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </TableCell>
        );
      case "datetimerange":
        return (
          <TableCell key={i} style={{ paddingLeft: 8, paddingRight: 8 }}>
            <TextField
              type="date"
              size="small"
              margin="none"
              variant="outlined"
              placeholder="Filter by date"
              onChange={e => {
                setQuery({
                  ...query,
                  search: [
                    ...query.search.filter(
                      item => item.key !== "meeting.startTime"
                    ),
                    {
                      key: "meeting.startTime",
                      value: moment(e.target.value)
                        .endOf("day")
                        .utc()
                        .format("MM-DD-YYYY HH:mm"),
                      comparison: "<="
                    },
                    {
                      key: "meeting.startTime",
                      value: moment(e.target.value)
                        .startOf("day")
                        .utc()
                        .format("MM-DD-YYYY HH:mm"),
                      comparison: ">"
                    }
                  ]
                });
                setFilters({
                  ...filters,
                  Date: e.target.value
                });
              }}
              required
              fullWidth
            />
          </TableCell>
        );
      case "datetime":
        return (
          <TableCell key={i} style={{ paddingLeft: 8, paddingRight: 8 }}>
            <TextField
              type="date"
              size="small"
              margin="none"
              variant="outlined"
              placeholder="Filter by date"
              onChange={e => {
                setQuery({
                  ...query,
                  search: [
                    ...query.search.filter(
                      item => item.key !== "meeting.createdAt"
                    ),
                    {
                      key: "meeting.createdAt",
                      value: moment(e.target.value)
                        .endOf("day")
                        .utc()
                        .format("MM-DD-YYYY HH:mm"),
                      comparison: "<="
                    },
                    {
                      key: "meeting.createdAt",
                      value: moment(e.target.value)
                        .startOf("day")
                        .utc()
                        .format("MM-DD-YYYY HH:mm"),
                      comparison: ">"
                    }
                  ]
                });
                setFilters({
                  ...filters,
                  "Requested on": e.target.value
                });
              }}
              required
              fullWidth
            />
          </TableCell>
        );
      case "status":
        return (
          <TableCell key={i} style={{ paddingLeft: 8, paddingRight: 8 }}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <Select
                id="building"
                defaultValue=""
                displayEmpty
                // value={buildingId}
                onChange={e => {
                  setQuery({
                    ...query,
                    search: [
                      ...query.search.filter(
                        item => item.key !== "meeting.status"
                      ),
                      {
                        key: "meeting.status",
                        value: e.target.value,
                        comparison: "="
                      }
                    ]
                  });
                  setFilters({
                    ...filters,
                    Status:
                      e.target.value.charAt(0).toUpperCase() +
                      e.target.value.slice(1)
                  });
                }}
                autoWidth
              >
                <MenuItem value="" disabled>
                  Filter by status
                </MenuItem>
                <MenuItem value="requested">Requested</MenuItem>
                <MenuItem value="approved">Approved</MenuItem>
                <MenuItem value="completed">Completed</MenuItem>
                <MenuItem value="ongoing">Ongoing</MenuItem>
                <MenuItem value="cancelled">Cancelled</MenuItem>
              </Select>
            </FormControl>
          </TableCell>
        );
      default:
        return (
          <TableCell key={i} style={{ paddingLeft: 8, paddingRight: 8 }}>
            <TextField
              type="text"
              className={classes.filterTextField}
              size="small"
              margin="none"
              variant="outlined"
              placeholder={"Filter by " + column.label}
              required
              disabled={column.field === "tat"}
              fullWidth
              onChange={e => {
                setQuery({
                  ...query,
                  search: [
                    ...query.search.filter(
                      item => item.key !== "meeting." + column.field
                    ),
                    {
                      key: "meeting." + column.field,
                      value: e.target.value,
                      comparison: "LIKE"
                    }
                  ]
                });
                setFilters({
                  ...filters,
                  [column.label]: e.target.value
                });
              }}
            />
          </TableCell>
        );
    }
  };
  const renderDataColumns = (data, columns) => {
    const type = data.requestEmployee ? "employee" : "visitor";
    return columns.map((item, i) => {
      var dataField;
      if (item.field.includes("|")) {
        item.field.split("|").forEach(item => {
          dataField =
            dataField || item.split(".").reduce((ox, ix) => ox[ix], data);
        });
      } else if (item.field.includes("&")) {
        try {
          item.field.split("&").forEach((item, index) => {
            if (index === 0)
              dataField =
                item.split(".").reduce((ox, ix) => ox[ix], data) || "";
            else
              dataField =
                dataField +
                  ", " +
                  item.split(".").reduce((ox, ix) => ox[ix], data) || "";
          });
        } catch (error) {
          dataField = "";
        }
      } else {
        dataField = item.field.split(".").reduce((ox, ix) => ox[ix], data);
      }
      switch (item.dataType) {
        case "profile":
          return (
            <TableCell size="small" className={classes.tableCell} key={i}>
              <ListItem disableGutters dense>
                <ListItemAvatar>
                  <Avatar style={getAvatarStyle(dataField.email)}>
                    {getInitials(dataField.name)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="h5" className={classes.capitalize}>
                      {dataField.name}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="textSecondary">
                      {dataField.role &&
                        dataField[orgSettings.employeePrimaryIdentifier]}
                      {!dataField.role &&
                        dataField[orgSettings.visitorPrimaryIdentifier]}
                    </Typography>
                  }
                />
              </ListItem>
            </TableCell>
          );
        case "venue":
          return (
            <TableCell size="small" className={classes.tableCell} key={i}>
              {dataField.split(", ")[0]} <br />{" "}
              <b>{dataField.split(", ")[1]}</b>
            </TableCell>
          );
        case "datetimerange":
          const times = dataField.split(", ");
          const date = moment
            .utc(new Date(parseInt(times[0])).toISOString())
            .local()
            .format("Do MMM YYYY");
          const start = moment
            .utc(new Date(parseInt(times[0])).toISOString())
            .local()
            .format("LT");
          const end = moment
            .utc(new Date(parseInt(times[1])).toISOString())
            .local()
            .format("LT");
          return (
            <TableCell size="small" className={classes.tableCell} key={i}>
              {date} <br /> <b>{start + " - " + end}</b>
            </TableCell>
          );
        case "datetime":
          return dataField ? (
            <TableCell size="small" className={classes.tableCell} key={i}>
              <b>
                {moment
                  .utc(new Date(parseInt(dataField)).toISOString())
                  .local()
                  .format("LT")}
              </b>{" "}
              {moment
                .utc(new Date(parseInt(dataField)).toISOString())
                .local()
                .format("Do MMM YYYY")}
            </TableCell>
          ) : (
            <TableCell size="small" className={classes.tableCell} key={i}>
              Not Available
            </TableCell>
          );
        case "status":
          return (
            <TableCell
              size="small"
              key={i}
              className={clsx(classes.capitalize, classes.tableCell)}
            >
              <Chip
                label={dataField}
                color="primary"
                size="small"
                className={classes[dataField]}
              />
            </TableCell>
          );
        default:
          return (
            <TableCell size="small" className={classes.tableCell} key={i}>
              {dataField}
            </TableCell>
          );
      }
    });
  };
  const handleChangePage = (event, page) => {
    setQuery({ ...query, skip: page * rowsPerPage });
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setQuery({ ...query, skip: 0, take: event.target.value });
    setRowsPerPage(event.target.value);
  };

  const handleSort = label => {
    var direction = "asc";
    var key;
    if (currentSort.label === label && currentSort.direction === "asc")
      direction = "desc";
    const order = direction.toUpperCase();

    switch (label.toLowerCase()) {
      case "date & time":
        key = "meeting.startTime";
        break;
      case "requested at":
        key = "meeting.createdAt";
        break;
      case "purpose":
        key = "meeting.purpose";
        break;
      case "status":
        key = "meeting.status";
        break;
      case "venue":
        key = "room.name";
        break;
      default:
        break;
    }
    handleQuery({ ...query, sort: { key, order }, skip: 0 });
    setQuery({ ...query, sort: { key, order }, skip: 0 });
    setCurrentSort({ label, direction });
  };

  const handleDeleteChip = async key => {
    switch (key) {
      case "Created by":
        setQuery({
          ...query,
          search: [
            ...query.search.filter(item => item.key !== "meeting.createdBy")
          ]
        });
        setFilterEmail("");
        break;
      case "Room":
        setQuery({
          ...query,
          search: [
            ...query.search.filter(item => item.key !== "meeting.roomId")
          ]
        });
        break;
      case "Date":
        setQuery({
          ...query,
          search: [
            ...query.search.filter(item => item.key !== "meeting.startTime")
          ]
        });
        break;
      case "Status":
        setQuery({
          ...query,
          search: [
            ...query.search.filter(item => item.key !== "meeting.status")
          ]
        });
        break;
      default:
        break;
    }

    let allFilters = filters;
    delete allFilters[key];
    Object.keys(allFilters).length === 0 &&
      handleQuery({
        ...query,
        search: ["superadmin", "admin"].includes(user.role)
          ? []
          : user.role === "visitor"
          ? [
              {
                key: "participant.visitorId",
                value: user.userId,
                comparison: "="
              }
            ]
          : [
              {
                key: "participant.employeeId",
                value: user.userId,
                comparison: "="
              }
            ]
      });
    setFilters({ ...allFilters });
  };

  const handleRowClick = id => {
    navigate(id);
  };

  const handleQuery = async customQuery => {
    dispatch(
      toastAction({
        status: true,
        message: "Loading...",
        type: "info",
        noIcon: true
      })
    );
    try {
      const meetingCountData = customQuery
        ? await api("POST", "meeting/findCount", customQuery, true)
        : await api("POST", "meeting/findCount", query, true);
      let meetingData = customQuery
        ? await api("POST", "meeting/find", customQuery, true)
        : await api("POST", "meeting/find", query, true);
      setMeetingCount(meetingCountData);
      meetingData = meetingData.map(item => {
        let tat = "Not available";
        if (item.tatEndTime && item.tatStartTime) {
          tat = moment
            .utc(item.tatEndTime - item.tatStartTime)
            .format(
              item.tatEndTime - item.tatStartTime > 60 * 60 * 1000
                ? "H[h]m[m]s[s] "
                : " m[m]s[s]"
            );
        }
        return { ...item, tat: tat };
      });
      setMeetings(meetingData);
      setLoading(false);
      dispatch(clearToastAction({}));
    } catch (error) {
      dispatch(clearToastAction({}));
      captureException(error);
    }
  };

  const downloadReport = async () => {
    setloadingReport(true);
    const meetingReportUrl = await api(
      "POST",
      "meeting/findCustomReport",
      {
        query: query,
        fields: reportFields.filter(fitem => fitem.status).map(item => item.id)
      },
      true
    );
    setloadingReport(false);
    setreportModalopen(false);
    window.open(meetingReportUrl, "_blank");
  };

  return loading ? (
    <Loader />
  ) : (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <Grid item>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h4"
            component="span"
          >
            All Meetings
          </Typography>
          <Typography
            color="textSecondary"
            variant="h5"
            component="span"
            style={{ lineHeight: 2.4 }}
          >
            &nbsp;&nbsp;From &nbsp;&nbsp;
          </Typography>
          <TextField
            type="date"
            size="small"
            margin="none"
            variant="outlined"
            placeholder="Start Date"
            onChange={e => {
              const customQuery = {
                ...query,
                search: [
                  ...query.search.filter(
                    item => item.key !== "meeting.startTime"
                  ),
                  {
                    key: "meeting.startTime",
                    value: moment(e.target.value)
                      .startOf("day")
                      .utc()
                      .format("MM-DD-YYYY HH:mm"),
                    comparison: ">="
                  }
                ]
              };
              setQuery(customQuery);
              handleQuery(customQuery);
            }}
          />
          <Typography
            color="textSecondary"
            variant="h5"
            component="span"
            style={{ lineHeight: 2.4 }}
          >
            &nbsp;&nbsp;to&nbsp;&nbsp;
          </Typography>
          <TextField
            type="date"
            size="small"
            margin="none"
            variant="outlined"
            placeholder="End Date"
            onChange={e => {
              const customQuery = {
                ...query,
                search: [
                  ...query.search.filter(
                    item => item.key !== "meeting.endTime"
                  ),
                  {
                    key: "meeting.endTime",
                    value: moment(e.target.value)
                      .endOf("day")
                      .utc()
                      .format("MM-DD-YYYY HH:mm"),
                    comparison: "<="
                  }
                ]
              };
              setQuery(customQuery);
              handleQuery(customQuery);
            }}
          />
        </Grid>
        {user.role !== "businessAdmin" && (
          <Grid
            Container
            direction="column"
            alignItems="end"
            justify="space-around"
          >
            <Grid item>
              <Button onClick={handleReport} variant="outlined" color="primary">
                Download Report
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Typography color="textSecondary" gutterBottom variant="caption">
        {meetingCount} Results found. Page {page + 1} of{" "}
        {Math.ceil(meetingCount / rowsPerPage)}
      </Typography>
      <Card>
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((col, i) => (
                      <TableCell className={classes.capitalize} key={i}>
                        {col.label.toLowerCase() !== "requested by" &&
                          col.label.toLowerCase() !== "turn around time" && (
                            <TableSortLabel
                              active={currentSort.label === col.label}
                              direction={currentSort.direction}
                              onClick={() => handleSort(col.label)}
                            >
                              {col.label}
                            </TableSortLabel>
                          )}
                        {(col.label.toLowerCase() === "requested by" ||
                          col.label.toLowerCase() === "turn around time") &&
                          col.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>{columns.map(renderFilterColumns)}</TableRow>
                  {Object.keys(filters).length > 0 && (
                    <TableRow selected>
                      <TableCell colSpan={columns.length} align="center">
                        <Typography variant="h6" component="p">
                          Selected filters: &nbsp;
                        </Typography>
                        {Object.keys(filters).map((filter, i) => {
                          return (
                            <React.Fragment key={i}>
                              <Chip
                                variant="outlined"
                                color="primary"
                                onDelete={() => {
                                  handleDeleteChip(filter);
                                }}
                                label={
                                  <>
                                    <b className={classes.capitalize}>
                                      {filter}
                                    </b>
                                    : {filters[filter]}
                                  </>
                                }
                              />
                              &nbsp; &nbsp;
                            </React.Fragment>
                          );
                        })}
                        <Chip
                          color="primary"
                          icon={<SearchIcon />}
                          className={classes.chipButton}
                          clickable
                          onClick={() => handleQuery()}
                          label={<b>Search</b>}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  {meetings.map(meeting => (
                    <TableRow
                      hover
                      key={meeting.id}
                      onClick={() => handleRowClick(meeting.id)}
                    >
                      {renderDataColumns(meeting, columns)}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={meetingCount}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
      <Dialog
        open={reportModalopen}
        onClose={e => setreportModalopen(true)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Generate Report</DialogTitle>
        {loadingReport ? (
          <Box px={5}>
            <Loader />
          </Box>
        ) : (
          <>
            <DialogContent>
              <DialogContentText>
                To generate report, please select the data columns you want on
                your report.
              </DialogContentText>
              <FormGroup>
                {reportFields.map((fitem, index) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        onChange={e => changeReportFields(e, index)}
                        label={fitem.title}
                      />
                    }
                    label={fitem.title}
                  />
                ))}
              </FormGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={e => setreportModalopen(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={downloadReport} color="primary">
                Export
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  meetings: PropTypes.array.isRequired
};

Results.defaultProps = {
  meetings: []
};

export default Results;
