import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Tooltip,
  ButtonGroup,
  IconButton,
  Typography,
  Button
} from "@material-ui/core";
import { NavigateNext, NavigateBefore } from "@material-ui/icons";
import ViewConfigIcon from "@material-ui/icons/ViewComfyOutlined";
import ViewWeekIcon from "@material-ui/icons/ViewWeekOutlined";
import ViewDayIcon from "@material-ui/icons/ViewDayOutlined";
import ViewAgendaIcon from "@material-ui/icons/ViewAgendaOutlined";

const useStyles = makeStyles(() => ({
  root: {}
}));

const Toolbar = props => {
  const {
    date,
    view,
    onDatePrev,
    onDateNext,
    onEventAdd,
    onViewChange,
    onDateToday,
    className,
    title,
    ...rest
  } = props;

  const classes = useStyles();

  const viewOptions = [
    {
      label: "Month",
      value: "dayGridMonth",
      icon: ViewConfigIcon
    },
    {
      label: "Week",
      value: "timeGridWeek",
      icon: ViewWeekIcon
    },
    {
      label: "Day",
      value: "timeGridDay",
      icon: ViewDayIcon
    },
    {
      label: "Agenda",
      value: "listWeek",
      icon: ViewAgendaIcon
    }
  ];

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid alignItems="center" container justify="space-between" spacing={3}>
        <Grid item>
          <ButtonGroup>
            <Button onClick={onDatePrev}>
              <NavigateBefore />
            </Button>
            <Button onClick={onDateToday}>Today</Button>
            <Button onClick={onDateNext}>
              <NavigateNext />
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <Typography component="p" variant="h3">
            {title}
          </Typography>
        </Grid>
        <Grid item>
          {viewOptions.map(viewOption => {
            const Icon = viewOption.icon;
            return (
              <Tooltip key={viewOption.value} title={viewOption.label}>
                <IconButton
                  color={viewOption.value === view ? "primary" : "default"}
                  onClick={() => onViewChange(viewOption.value)}
                  style={{backgroundColor: viewOption.value === view ? "aliceblue" : "transparent",}}
                >
                  <Icon />
                </IconButton>
              </Tooltip>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};

Toolbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onDateNext: PropTypes.func,
  onDatePrev: PropTypes.func,
  onDateToday: PropTypes.func,
  onEventAdd: PropTypes.func,
  onViewChange: PropTypes.func,
  view: PropTypes.string.isRequired
};

export default Toolbar;
