import { Box, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import "react-phone-input-2/lib/material.css";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

const PhotoModal = props => {
  const classes = useStyles();
  const { open, close, idPhoto, checkInPhoto } = props;

  const handleClose = () => {
    close();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle disableTypography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography component="h1" variant="h3" color="primary">
              Visitor's Photos
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid
            container
            direction="row-reverse"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} style={{ padding: 16 }}>
              {checkInPhoto && (
                <Box maxWidth={"100%"}>
                  <Typography component="h1" variant="h5" color="primary">
                    Visitor's Checkin Photo
                  </Typography>
                  <img
                    src={checkInPhoto}
                    alt="img"
                    style={{ maxWidth: "100%" }}
                  />
                  <br />
                  <br />
                </Box>
              )}
              {idPhoto && (
                <Box maxWidth={"100%"}>
                  <Typography component="h1" variant="h5" color="primary">
                    Visitor's ID Photo
                  </Typography>
                  <img src={idPhoto} alt="img" style={{ maxWidth: "100%" }} />
                </Box>
              )}
              <Divider />
            </Grid>
          </Grid>

          <Grid
            container
            direction="row-reverse"
            alignItems="center"
            spacing={3}
            className={classes.marginNormal}
          >
            <Grid item>
              <Button onClick={handleClose} variant="contained">
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PhotoModal;
