import {
  Avatar,
  Badge,
  Card,
  CardActions,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Dialog
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, withStyles } from "@material-ui/styles";
import { captureException } from "@sentry/react";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearToastAction, toastAction } from "../../../../actions";
import api from "../../../../utils/api";
import getAvatarStyle from "../../../../utils/getAvatarStyle";
import getInitials from "../../../../utils/getInitials";
import Loader from "../../../../utils/loader";
import Image from "material-ui-image";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end"
  },
  capitalize: {
    textTransform: "capitalize"
  },
  chipButton: {
    paddingLeft: 12,
    paddingRight: 12
  },
  tableCell: {
    paddingLeft: 8,
    paddingRight: 8
  },
  bold: {
    fontWeight: "bold"
  },
  checkedIn: {
    backgroundColor: "green"
  },
  checkedOut: {
    backgroundColor: "red"
  },

  filterTextField: {
    "&::placeholder": {
      color: "#000"
    },
    "&::-ms-input-placeholder ": {
      color: "#000"
    }
  }
}));

const columns = [
  {
    label: "Time",
    dataType: "datetime",
    field: "dateTime"
  },
  {
    label: "User type",
    dataType: "text",
    field: "type"
  },
  {
    label: "Name",
    dataType: "text",
    field: "name"
  },
  {
    label: "Employee ID",
    dataType: "text",
    field: "employeeId"
  },
  {
    label: "Is Sanitized?",
    dataType: "text",
    field: "sanitized"
  },
  {
    label: "Is Vaccinated?",
    dataType: "text",
    field: "vaccinated"
  },
  {
    label: "Is Quarantined?",
    dataType: "text",
    field: "isQuarantined"
  },
  {
    label: "Travel History",
    dataType: "text",
    field: "travelHistory"
  }
];

const Results = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const user = useSelector(state => state.user);
  const orgSettings = useSelector(state => state.orgSettings);
  const [loading, setLoading] = useState(true);
  const [imageModal, setImageModal] = useState(false);
  const [imageModalurl, setImageModalurl] = useState("");
  const [allstartKey, setalltartKey] = useState([null]);
  const [meetings, setMeetings] = useState([]);
  const [meetingCount, setMeetingCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [query, setQuery] = useState({
    limit: rowsPerPage,
    startKey: null
  });

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(query);
    handleQuery();
  }, [page, rowsPerPage]);

  const renderDataColumns = (data, columns) => {
    return columns.map((item, i) => {
      var dataField = item.field.split(".").reduce((ox, ix) => ox[ix], data);
      switch (item.dataType) {
        case "datetime":
          return dataField ? (
            <TableCell size="small" className={classes.tableCell} key={i}>
              <b>
                {moment
                  .utc(new Date(parseInt(dataField)).toISOString())
                  .local()
                  .format("LT")}
              </b>{" "}
              {moment
                .utc(new Date(parseInt(dataField)).toISOString())
                .local()
                .format("Do MMM YYYY")}
            </TableCell>
          ) : (
            <TableCell size="small" className={classes.tableCell} key={i}>
              Not Available
            </TableCell>
          );

        default:
          return (
            <TableCell size="small" className={classes.tableCell} key={i}>
              {item.field == "type"
                ? dataField.toUpperCase()
                : dataField || "-"}
            </TableCell>
          );
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    console.log(newPage, allstartKey);
    setQuery({ ...query, startKey: allstartKey[newPage] });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setQuery({ ...query, startKey: null, limit: event.target.value });
    setRowsPerPage(event.target.value);
    setPage(0);
    setalltartKey([null]);
  };

  const handleRowClick = index => {
    // setImageModalurl(meetings[index].image);
    // setImageModal(true);
  };

  const handleQuery = async () => {
    dispatch(
      toastAction({
        status: true,
        message: "Loading...",
        type: "info",
        noIcon: true
      })
    );

    fetch("https://nxlt9a4lfd.execute-api.ap-south-1.amazonaws.com/fetch")
      .then(response => response.json())
      .then(data => {
        console.log("Success:", data);
        setMeetings(data.Items.sort(function(a, b) {
          var keyA = new Date(a.dateTime),
            keyB = new Date(b.dateTime);
          // Compare the 2 dates
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        }));
        setMeetingCount(data.Count);
        setLoading(false);
        dispatch(clearToastAction({}));
      })
      .catch(error => {
        dispatch(clearToastAction({}));
        captureException(error);
      });
  };

  return loading ? (
    <Loader />
  ) : (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="h4">
        All Entries
      </Typography>
      <Typography color="textSecondary" gutterBottom variant="caption">
        {meetingCount} Results found.
      </Typography>
      <Card>
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((col, i) => (
                      <TableCell
                        className={classes.capitalize}
                        key={i}
                        align="left"
                      >
                        {col.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {meetings.map((meeting, index) => (
                    <TableRow
                      hover
                      key={index}
                      onClick={() => handleRowClick(index)}
                    >
                      {renderDataColumns(meeting, columns)}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
      </Card>
      <Dialog
        open={imageModal}
        onBackdropClick={e => setImageModal(false)}
        onEscapeKeyDown={e => setImageModal(false)}
      >
        <img style={{ width: "100%" }} src={imageModalurl} />
      </Dialog>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  meetings: PropTypes.array.isRequired
};

Results.defaultProps = {
  meetings: []
};

export default Results;
