import { makeStyles } from "@material-ui/styles";
import React from "react";
import Page from "src/components/Page";
import { Header, Results } from "./components";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  }
}));

const MeetingList = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Meeting List">
      <Header />

      <Results className={classes.results} />
    </Page>
  );
};

export default MeetingList;
