import { makeStyles } from "@material-ui/styles";
import { Button, Grid, Typography, Select, MenuItem } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Page from "src/components/Page";
import Tracking from "./Tracking";
import { useSelector } from "react-redux";
import api from "../../utils/api";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  }
}));

const LiveTracking = () => {
  const classes = useStyles();
  // const user = useSelector(state => state.user);
  const [buildingId, setbuildingId] = useState(15016);
  const [floorId, setfloorId] = useState(15016);
  const [buildings, setbuildings] = useState([]);
  const [floors, setfloors] = useState([]);

  useEffect(() => {
    (async () => {
      const allbuildings = await api(
        "POST",
        "building/find",
        {
          search: [{ key: "building.isActive", value: "", comparison: "=" }],
          sort: { key: "building.createdAt", order: "ASC" },
          skip: 0,
          take: 100000
        },
        true
      );
      setbuildings(allbuildings);
      setbuildingId(allbuildings[0].id);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const allfloors = await api(
        "POST",
        "floor/find",
        {
          search: [{ key: "building.id", value: buildingId, comparison: "=" }],
          sort: { key: "floor.createdAt", order: "ASC" },
          skip: 0,
          take: 100000
        },
        true
      );
      // console.log(allfloors[0]);
      setfloors(allfloors);
      setfloorId(allfloors[0]?.subLocationId);
    })();
  }, [buildingId]);

  return (
    <Page className={classes.root} title="Live Tracking">
      <Grid
        alignItems="center"
        container
        justify="space-between"
        spacing={3}
        direction="row"
      >
        <Grid item>
          <Typography component="h1" variant="h3" color="primary">
            Live Tracking
          </Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={3} direction="row">
            <Grid item>
              <Select
                labelId="building"
                id="building"
                value={buildingId}
                onChange={e => setbuildingId(e.target.value)}
                autoWidth
              >
                {buildings.map((item, index) => (
                  <MenuItem value={item.id} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item>
              <Select
                labelId="floor"
                id="floor"
                value={floorId}
                onChange={e => setfloorId(e.target.value)}
                autoWidth
              >
                {floors.map((item, index) => (
                  <MenuItem value={item.subLocationId} key={index}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <br />
      {floorId === 15017 && <Tracking floorId={15017} />}
      {floorId === 15016 && <Tracking floorId={15016} />}
      {floorId === 15018 && <Tracking floorId={15018} />}
    </Page>
  );
};

export default LiveTracking;
