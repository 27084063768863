import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import LogRocket from "logrocket";
import React, { useEffect, useState } from "react";
import { LogIn } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Page from "src/components/Page";
import {
  login,
  business,
  organizationSettings,
  organization
} from "../../actions";
import api from "../../utils/api";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%"
  },
  container: {
    padding: theme.spacing(3),
    backgroundColor: "#fff"
  },
  img: {
    padding: theme.spacing(3)
  },
  forgotLink: {
    cursor: "pointer"
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const org = useSelector(state => state.org);
  const businessOnState = useSelector(state => state.business);
  const orgSettings = useSelector(state => state.orgSettings);
  const [accountType, setAccountType] = useState("");
  const [accountAdmin, setAccountAdmin] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [domain, setDomain] = useState(
    businessOnState && businessOnState.id ? businessOnState.domain : ""
  );
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    (async () => {
      try {
        if (businessOnState) {
          setDomain(businessOnState.domain);
          const orgList = await api(
            "GET",
            "organization/getOrganization/" + businessOnState.id,
            null,
            false
          );
          setCompanyList(orgList);
          if(orgList.length === 1) {
            dispatch(organization(orgList[0]));
          }
        }
      } catch (error) {
        setError("Could not fetch organization list in this for this account");
      }
    })();
  }, [businessOnState]);

  const handleSelectOrganization = async orgId => {
    dispatch(organization(companyList.find(item => (item.id = orgId))));
  };

  const findDomainFromInput = async () => {
    try {
      const validateDomain = await api(
        "GET",
        "business/searchByDomain/" + domain,
        null,
        false
      );
      if (validateDomain.id) {
        dispatch(business(validateDomain));
        setError("");
      } else {
        setError("Could not find account for this domain");
      }
    } catch (error) {
      setError("Could not find account for this domain");
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setError("");
    if (
      email.trim() === "" ||
      password === "" ||
      domain.trim() === "" ||
      accountType === ""
    ) {
      setError("Please fill up all the fields");
      return;
    }
    if (!accountAdmin && !org) {
      setError("Please select an organization");
      return;
    }
    try {
      let validator = await api(
        "POST",
        "auth/login",
        accountAdmin
          ? { contact: email, password, domain, loginType: "businessAdmin" }
          : {
              contact: email,
              password,
              domain,
              organizationId: org.id
            },
        // credentials,
        false
      );

      localStorage.setItem("token", validator.token);
      var orgSettings = {};
      if (validator.data.loginType !== "businessAdmin") {
        orgSettings = await api(
          "GET",
          "organization/getOrgSettings/" + validator.data.organizationId,
          null,
          true
        );
        dispatch(organizationSettings(orgSettings));
      }

      const businessSettings = await api(
        "GET",
        "business/getBusinessSettings/" + validator.data.businessId,
        null,
        true
      );
      dispatch(organizationSettings({ ...orgSettings, ...businessSettings }));

      dispatch(login(validator));

      LogRocket.identify("User", {
        name: validator.data.name,
        email: validator.data.email,
        mobile: validator.data.mobile,
        id: validator.data.id,
        org: validator.data.organizationId
      });
      navigate("/");
    } catch (error) {
      if (error.response && error.response.data) setError(error.response.data);
    }
  };

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="flexStart"
        className={classes.container}
      >
        <Container maxWidth="lg">
          <Grid container direction="row" spacing={3} alignItems="center">
            <Grid item md={8} xs={12}>
              <Container maxWidth="md">
                <img
                  src="/static/banner.png"
                  style={{ maxWidth: "100%", padding: "60px 0" }}
                  className={classes.img}
                  alt="img"
                />
              </Container>
            </Grid>
            <Grid item md={4} xs={12}>
              {businessOnState && businessOnState.id && (
                <Box mb={3}>
                  <Box>
                    <img
                      src={businessOnState.logo}
                      style={{ maxWidth: "100%" }}
                      className={classes.img}
                      alt="logo"
                    />
                  </Box>
                  <Typography
                    color="primary"
                    variant="h1"
                    component="span"
                    style={{ fontWeight: 900 }}
                  >
                    Welcome to {businessOnState.name}
                  </Typography>
                </Box>
              )}

              <form onSubmit={handleSubmit}>
                <Box mb={1}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in using your email/phone number and password
                  </Typography>
                </Box>

                {error !== "" && (
                  <Alert
                    onClose={() => {
                      setError("");
                    }}
                    severity="error"
                  >
                    {error}
                  </Alert>
                )}

                {(!businessOnState || !businessOnState.id) && (
                  <TextField
                    fullWidth
                    label="Domain"
                    margin="normal"
                    name="domain"
                    id="domain"
                    onBlur={findDomainFromInput}
                    onChange={e => setDomain(e.target.value)}
                    type="text"
                    value={domain}
                    variant="outlined"
                  />
                )}
                <FormControl component="fieldset" margin="normal">
                  <FormLabel component="legend">
                    <b>Sign in as</b>
                  </FormLabel>
                  <RadioGroup
                    row
                    onChange={e => {
                      setAccountType(e.target.value);
                      if (e.target.value === "accountAdmin")
                        setAccountAdmin(true);
                      else setAccountAdmin(false);
                    }}
                  >
                    <FormControlLabel
                      value="visitor"
                      control={<Radio />}
                      label="Visitor"
                    />
                    <FormControlLabel
                      value="employee"
                      control={<Radio />}
                      label="Employee"
                    />
                    <FormControlLabel
                      value="accountAdmin"
                      control={<Radio />}
                      label="Global Admin"
                    />
                  </RadioGroup>
                </FormControl>

                {!accountAdmin && companyList.length > 1 && (
                  <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                    margin="normal"
                  >
                    <InputLabel id="company"> Select company</InputLabel>
                    <Select
                      labelId="company"
                      id="company"
                      label="Select company"
                      displayEmpty
                      onChange={e => {
                        handleSelectOrganization(e.target.value);
                      }}
                      autoWidth
                    >
                      {companyList.map(item => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <TextField
                  fullWidth
                  label="Email or Phone number"
                  margin="normal"
                  name="email"
                  id="email"
                  onChange={e => setEmail(e.target.value)}
                  type="text"
                  value={email}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Password"
                  margin="normal"
                  name="password"
                  id="password"
                  onChange={e => setPassword(e.target.value)}
                  type="password"
                  value={password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    id="submit"
                    type="submit"
                    variant="contained"
                    endIcon={<LogIn />}
                  >
                    Sign in
                  </Button>
                </Box>
              </form>
              <Box>
                <Grid container direction="row" justify="space-between">
                  <Grid item>
                    <Typography
                      color="primary"
                      variant="h6"
                      component="span"
                      style={{ fontWeight: 900 }}
                      className={classes.forgotLink}
                      onClick={() => navigate("/forgotpassword")}
                    >
                      <Link>Forgot password?</Link>
                    </Typography>
                  </Grid>
                  <Grid item>
                    {/* <Typography
                      color="primary"
                      variant="h6"
                      component="span"
                      style={{ fontWeight: 900 }}
                      className={classes.forgotLink}
                      onClick={() => navigate("/register")}
                    >
                      <Link>Book a meeting</Link>
                    </Typography> */}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
