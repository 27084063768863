import {
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Dialog,
  FormLabel
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { KeyboardBackspace } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/material.css";
import { useDispatch } from "react-redux";
import { toastAction } from "../../../actions";
import api from "../../../utils/api";
import DeviceSettings from "./DeviceSettings";
import { DropzoneDialog } from "material-ui-dropzone";
import axios from "axios";
import baseurl from "../../../utils/baseurl";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    minWidth: 300,
    justifyContent: "space-between"
  },
  formControl: {
    marginTop: "16px"
  },
  formSelect: {
    marginBottom: "5px"
  },
  deviceModal: {
    "& .MuiDialog-paper": {
      maxWidth: 800,
      width: "100%"
    }
  },
  deviceTable: {
    padding: "0 50px"
  },
  addDeviceSetting: {
    marginBottom: 20
  },
  openAddSettingBtn: {
    padding: "0 25px",
    "& button": {
      float: "right"
    }
  },
  backButton: {
    cursor: "pointer"
  }
}));

const DeviceModal = props => {
  const classes = useStyles();
  const { close, dataList } = props;
  const [roomList, setRoomList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [deviceSettingsData, setDeviceSettingsData] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [pantryList, setPantryList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [buildingsSelected, setBuildingsSelected] = useState([]);
  const [roomSelected, setRoomSelected] = useState({
    id: null,
    name: "None"
  });
  const [pantrySelected, setPantrySelected] = useState({
    id: null,
    name: "None"
  });
  const [employeeEdited, setEmployeeEdited] = useState(false);
  const [employeeSelected, setEmployeeSelected] = useState({
    id: null,
    name: "None"
  });

  const [device, setDevice] = useState({
    floorIds: [],
    category: "",
    subCategory: [""],
    roomId: "",
    employeeId: "",
    pantryId: "",
    deviceType: ""
  });
  const [dropzoneOpen, setDropzoneOpen] = useState(false);
  const [dropzone, setDropzone] = useState({});
  const [viewOpen, setViewOpen] = useState(false);
  const [viewUrl, setViewUrl] = useState("");
  const [viewType, setViewType] = useState("");

  const dispatch = useDispatch();

  const handleViewDialog = (url, type) => {
    setViewUrl(url);
    setViewType(type);
    setViewOpen(true);
  };

  const handleUploadDalog = (title, key, type, flag) => {
    setDropzone({
      title,
      key,
      type,
      flag
    });
    setDropzoneOpen(true);
  };

  const handleUpload = async files => {
    dispatch(
      toastAction({
        status: true,
        message: "Uploading video ... ",
        type: "info",
        noIcon: true
      })
    );
    const url = await upload(files[0], "meeting_room_device_video");
    // console.log(url);
    setDevice({ ...device, videoUrl: url });
    setDropzoneOpen(false);
    dispatch(
      toastAction({
        status: true,
        message: "Video uploaded. Click update to confirm",
        type: "success",
        noIcon: true
      })
    );
  };
  const upload = async (file, folder) => {
    const token = localStorage ? localStorage.getItem("token") : null;
    let filenameSplit = file.name.split(".");
    const formData = new FormData();
    formData.append(
      "file",
      file,
      Date.now() + "." + filenameSplit[filenameSplit.length - 1]
    );
    formData.append("fileStorage", folder);

    try {
      let response = await axios({
        method: "POST",
        url: baseurl + "file/upload",
        data: formData,
        headers: {
          Authorization: "Bearer " + token
        },
        onUploadProgress: progressEvent => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          dispatch(
            toastAction({
              status: true,
              message: "Uploading file ... " + percentCompleted + "%",
              type: "info",
              noIcon: true
            })
          );
        }
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    (async () => {
      const room = await api(
        "POST",
        "room/find",
        {
          search: [
            {
              key: "room.allowMeeting",
              value: "",
              comparison: "="
            }
          ]
        },
        true
      );
      setRoomList(room);
      if (device.roomId !== "") {
        const thisRoom = room.find(item => item.id === device.roomId);
        setRoomSelected(thisRoom);
      }
    })();
  }, [device.id]);

  useEffect(() => {
    (async () => {
      const pantry = await api("POST", "pantry/find", null, true);
      setPantryList(pantry);
      if (device.pantryId !== "") {
        const thisPantry = pantry.find(item => item.id === device.pantryId);
        setPantrySelected(thisPantry);
      }
    })();
  }, [device.id]);

  useEffect(() => {
    if (employeeEdited) {
      (async () => {
        const employee = await api(
          "POST",
          "employee/find",
          {
            search: [
              {
                key: "employee.name",
                value: searchInput,
                comparison: "LIKE"
              }
            ],
            take: 10
          },
          true
        );
        setEmployeeList(employee);
        if (device.employeeId !== "") {
          const thisEmployee = employee.find(
            item => item.id === device.employeeId
          );
          setEmployeeSelected(thisEmployee);
        }
      })();
    } else if (device.employeeId) {
      (async () => {
        const data = await api(
          "POST",
          "employee/find",
          {
            search: [
              {
                key: "employee.id",
                value: device.employeeId,
                comparison: "="
              }
            ],
            take: 10
          },
          true
        );
        setEmployeeSelected(data[0]);
      })();
    }
  }, [searchInput, device.id]);

  useEffect(() => {
    console.log("this is device");
    console.log(dataList);
    setDevice({
      ...dataList,
      floorIds: dataList.building.map(item => item.floorId)
    });
    (async () => {
      const data = await api(
        "GET",
        "deviceSetting/device/" + dataList.id,
        null,
        true
      );
      setDeviceSettingsData(data);
      const floorL = await api("POST", "floor/find", null, true);
      setFloorList(floorL);
      let allBuildings = floorL.map(floor => floor.building);
      let selectedBuildingIds = dataList.building.map(item => item.buildingId);
      let selectedBuil = allBuildings
        .filter(item => selectedBuildingIds.includes(item.id))
        .filter(
          (value, index, self) =>
            self.indexOf(self.find(item => item.id === value.id)) === index
        );
      setBuildingsSelected(selectedBuil);
    })();
  }, [dataList.deviceId]);

  const onBuildingChange = (event, values) => {
    setBuildingsSelected(values);
  };

  const onFloorChange = (event, values) => {
    setDevice(formData => ({
      ...formData,
      floorIds: values.map(item => item.id)
    }));
  };

  const handleClose = () => {
    close();
  };

  const onRoomChange = (event, value) => {
    setRoomSelected(value);
  };
  const onEmployeeChange = (event, value) => {
    setEmployeeSelected(value);
  };
  const onPantryChange = (event, value) => {
    setPantrySelected(value);
  };

  const updateDevice = async () => {
    try {
      let body = {
        name: device.name,
        isActive: device.isActive,
        floorIds: device.floorIds,
        category: device.category,
        subCategory: device.subCategory,
        deviceType: device.deviceType,
        roomId: roomSelected?.id ?? null,
        videoUrl: device.videoUrl,
        employeeId: employeeSelected?.id ?? null,
        pantryId: pantrySelected?.id ?? null
      };

      await api("PATCH", "device/" + dataList.passcode, body, true);
      dispatch(
        toastAction({
          status: true,
          message: "Device updated successfully",
          type: "success"
        })
      );

      // setDeviceSettingsData(data);
    } catch (error) {
      dispatch(
        toastAction({
          status: true,
          message: error.response.data,
          type: "error"
        })
      );
    }
  };

  return (
    <div>
      <Grid alignItems="center" container spacing={3} direction="row">
        <Grid item>
          <IconButton aria-label="close" onClick={handleClose} size="small">
            <KeyboardBackspace />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography
            variant="h6"
            onClick={handleClose}
            color="textPrimary"
            className={classes.backButton}
          >
            Back to All Devices
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Card>
        <CardContent>
          <Typography variant="h4" color="primary">
            Device Detail
          </Typography>

          <br />
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                onChange={e => setDevice({ ...device, name: e.target.value })}
                label="Device Name"
                value={device.name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel> Device Type</InputLabel>
                <Select
                  labelId="deviceType"
                  id="deviceType"
                  label="Device Type"
                  value={device.deviceType}
                  onChange={e => {
                    setDevice({ ...device, deviceType: e.target.value });
                  }}
                  autoWidth
                >
                  <MenuItem value="Android">Android</MenuItem>
                  <MenuItem value="TelpoAndroid">Telpo - Android</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="category"> Category</InputLabel>
                <Select
                  labelId="category"
                  id="category"
                  label="Category"
                  value={device.category}
                  displayEmpty
                  onChange={e => {
                    let update = { category: e.target.value };
                    if (
                      !["insideVIPRoom", "outsideVIPRoom"].includes(
                        e.target.value
                      )
                    ) {
                      update.employeeId = null;
                      setEmployeeSelected(null);
                    }
                    if (
                      ![
                        "authenticator",
                        "entrance",
                        "parkingAuthenticator"
                      ].includes(e.target.value)
                    ) {
                      update.subCategory = [];
                    }
                    if (!["pantry"].includes(e.target.value)) {
                      update.pantryId = null;
                      setPantrySelected(null);
                    }
                    if (!["outdoorMeetingRoom"].includes(e.target.value)) {
                      update.videoUrl = null;
                    }
                    if (
                      ![
                        "insideVIPRoom",
                        "outsideVIPRoom",
                        "outdoorMeetingRoom",
                        "insideMeetingRoom"
                      ].includes(e.target.value)
                    ) {
                      update.roomId = null;
                      setRoomSelected(null);
                    }

                    setDevice({ ...device, ...update });
                  }}
                  autoWidth
                >
                  <MenuItem value="entrance">Entrance</MenuItem>
                  <MenuItem value="authenticator">Authenticator</MenuItem>
                  <MenuItem value="parkingAuthenticator">
                    Parking Authenticator
                  </MenuItem>
                  <MenuItem value="tempScanner">Temperature Scanner</MenuItem>
                  <MenuItem value="uvcKiosk">UVC Kiosk</MenuItem>
                  <MenuItem value="outdoorMeetingRoom">
                    Meeting Room Entrance
                  </MenuItem>
                  <MenuItem value="insideMeetingRoom">
                    Inside Meeting Room
                  </MenuItem>
                  <MenuItem value="insideVIPRoom">Inside VIP Room</MenuItem>
                  <MenuItem value="outsideVIPRoom">Outside VIP Room</MenuItem>
                  <MenuItem value="pantry">Pantry Tab</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="subCategory">Sub category</InputLabel>
                <Select
                  labelId="subCategory"
                  disabled={
                    ![
                      "authenticator",
                      "entrance",
                      "parkingAuthenticator"
                    ].includes(device.category)
                  }
                  id="subCategory"
                  label="Sub category"
                  value={device.subCategory}
                  onChange={e =>
                    setDevice({ ...device, subCategory: e.target.value })
                  }
                  multiple
                  autoWidth
                >
                  <MenuItem value="checkIn">Check In</MenuItem>
                  <MenuItem value="checkOut">Check Out</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="building"
                options={floorList
                  .map(item => item.building)
                  .filter((value, index, self) => {
                    return (
                      self.indexOf(self.find(item => item.id === value.id)) ===
                      index
                    );
                  })}
                getOptionLabel={option => option.name}
                onChange={onBuildingChange}
                value={buildingsSelected}
                multiple
                disableClearable
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Buildings"
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            {buildingsSelected.length > 0 && (
              <Grid item xs={6}>
                <Autocomplete
                  id="floor"
                  options={floorList.filter(floor =>
                    buildingsSelected
                      .map(item => item.id)
                      .includes(floor.building.id)
                  )}
                  getOptionLabel={option => {
                    return option?.name ?? "";
                  }}
                  onChange={onFloorChange}
                  value={device.floorIds.map(id =>
                    floorList.find(item => item.id === id)
                  )}
                  multiple
                  disableClearable
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Floors"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )}
            {["pantry"].includes(device.category) && (
              <>
                <Grid item xs={6}>
                  <Autocomplete
                    id="pantry"
                    options={pantryList}
                    getOptionLabel={option => option.name}
                    onChange={onPantryChange}
                    value={pantrySelected}
                    disableClearable
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Pantry"
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                  <br />
                </Grid>
              </>
            )}
            {["insideVIPRoom", "outsideVIPRoom"].includes(device.category) && (
              <>
                <Grid item xs={6}>
                  <Autocomplete
                    id="room"
                    options={roomList}
                    getOptionLabel={option => option.name}
                    onChange={onRoomChange}
                    value={roomSelected}
                    disableClearable
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Rooms"
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    id="employee"
                    options={employeeList}
                    getOptionLabel={option => option.name}
                    onChange={onEmployeeChange}
                    value={!employeeEdited && employeeSelected}
                    disableClearable
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Employee"
                        value={searchInput}
                        onChange={e => {
                          setEmployeeEdited(true);
                          setSearchInput(e.target.value);
                        }}
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </>
            )}

            {["outdoorMeetingRoom", "insideMeetingRoom"].includes(
              device.category
            ) && (
              <>
                <Grid item xs={6}>
                  <Autocomplete
                    id="room"
                    options={roomList}
                    getOptionLabel={option => option.name}
                    onChange={onRoomChange}
                    value={roomSelected}
                    disableClearable
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Room"
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                  <br />
                </Grid>
                {device.category === "outdoorMeetingRoom" && (
                  <Grid item xs={6}>
                    <Grid container direction="row" spacing={3}>
                      <Grid item>
                        <FormLabel component="legend">
                          Video to display:{" "}
                        </FormLabel>
                      </Grid>
                      <Grid item>
                        <Grid container direction="row" spacing={3}>
                          {device.videoUrl && (
                            <>
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={e =>
                                    handleViewDialog(device.videoUrl, "video")
                                  }
                                >
                                  View
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  onClick={e => {
                                    toastAction({
                                      status: true,
                                      message:
                                        "Video removed. Click update to confirm",
                                      type: "success",
                                      noIcon: true
                                    });
                                    setDevice({ ...device, videoUrl: "" });
                                  }}
                                >
                                  Remove
                                </Button>
                              </Grid>
                            </>
                          )}

                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={e =>
                                handleUploadDalog(
                                  "Meeting Room Video",
                                  "meetingroomvideo",
                                  "video",
                                  "meetingroomvideo"
                                )
                              }
                            >
                              Upload
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            )}

            <Grid item xs={12}>
              <Button
                onClick={updateDevice}
                color="primary"
                variant="contained"
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <br />
      <Divider />
      <br />
      <Card>
        <CardContent>
          <DeviceSettings
            dataList={deviceSettingsData}
            deviceId={dataList.id}
          />
        </CardContent>
      </Card>
      <DropzoneDialog
        open={dropzoneOpen}
        onSave={files => {
          handleUpload(files);
        }}
        acceptedFiles={[dropzone.type + "/*"]}
        showPreviews={true}
        filesLimit={1}
        maxFileSize={5000000000}
        dialogTitle={dropzone.title}
        onClose={() => setDropzoneOpen(false)}
        submitButtonText="Upload"
        cancelButtonText="Cancel"
      />
      <Dialog
        open={viewOpen}
        onClose={() => setViewOpen(false)}
        disableAutoFocus={true}
      >
        {viewType === "video" && (
          <video src={viewUrl} width="100%" height="100%" controls></video>
        )}
      </Dialog>
    </div>
  );
};

export default DeviceModal;
