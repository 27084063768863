import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import "react-phone-input-2/lib/material.css";
import { useSelector } from "react-redux";
import api from "../../../utils/api";
import Loader from "../../../utils/loader";

const ChangePassword = () => {
  const userId = useSelector(state => state.user.userId);
  const loginType = useSelector(state => state.user.loginType);
  const [success, setSuccess] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [error, setError] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEnterNewPassword, setReEnterNewPassword] = useState("");

  const handleSubmit = async e => {
    e.preventDefault();
    setIsloading(true);
    setSuccess(false);
    if (
      currentPassword.trim() === "" ||
      newPassword.trim() === "" ||
      reEnterNewPassword.trim() === ""
    ) {
      setError("Please fill up all the fields");
      setIsloading(false);
      return;
    }
    if (newPassword.trim() !== reEnterNewPassword.trim()) {
      setError("New password and Re-Entered New Password must be the same");
      setIsloading(false);
      return;
    }

    try {
      let changePasswordApi = await api(
        "POST",
        `auth/changePassword/${userId}`,
        {
          currentPassword,
          newPassword,
          userType: loginType
        },
        true
      );
      setIsloading(false);
      setError(changePasswordApi);
      setSuccess(true);
      setCurrentPassword("");
      setNewPassword("");
      setReEnterNewPassword("");
    } catch (error) {
      if (error.response && error.response.data) setError(error.response.data);
      setIsloading(false);
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Card>
        {isloading ? (
          <Loader />
        ) : (
          <React.Fragment>
            <CardHeader title="Change Password" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {error !== "" && (
                    <Alert
                      onClose={() => {
                        setError("");
                      }}
                      severity={success ? "success" : "error"}
                    >
                      {error}
                    </Alert>
                  )}
                  <TextField
                    fullWidth
                    label="Current password"
                    margin="normal"
                    name="CurrentPassword"
                    id="current_password"
                    onChange={e => setCurrentPassword(e.target.value)}
                    type="password"
                    value={currentPassword}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="New password"
                    margin="normal"
                    name="NewPassword"
                    id="new_password"
                    onChange={e => setNewPassword(e.target.value)}
                    type="password"
                    value={newPassword}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Re-enter new password"
                    margin="normal"
                    name="ReEnterNewPassword"
                    id="re_enter_new_password"
                    onChange={e => setReEnterNewPassword(e.target.value)}
                    type="password"
                    value={reEnterNewPassword}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button color="primary" variant="contained" type="submit">
                Change Password
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Card>
    </form>
  );
};

export default ChangePassword;
