import { Box, Button, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";

const ResponseComponent = props => {
  const { accept, decline, roomList, selectRoom, customMessage } = props;

  const [room, setRoom] = useState("");
  const [msg, setMsg] = useState("");
  return (
    <Box my={3}>
      {selectRoom && (
        <Autocomplete
          id="combo-box-demo"
          options={roomList}
          getOptionLabel={option => option.name}
          onChange={(e, value) => {
            setRoom(value.id);
          }}
          disableClearable
          renderInput={params => (
            <TextField
              {...params}
              label="Meeting Room"
              fullWidth
              variant="outlined"
            />
          )}
        />
      )}
      <br />
      {customMessage && (
        <TextField
          fullWidth
          label="Custom Message"
          margin="normal"
          onChange={e => setMsg(e.target.value)}
          type="text"
          value={msg}
          variant="outlined"
        />
      )}
      <br />
      <Grid direction="row" container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Button
            color="primary"
            fullWidth
            size="large"
            variant="contained"
            onClick={e => {
              if (selectRoom) {
                accept(room, msg);
              } else {
                accept(null, msg);
              }
            }}
          >
            Accept
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            color="secondary"
            fullWidth
            size="large"
            variant="contained"
            onClick={decline}
          >
            Decline
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResponseComponent;
