import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Typography,
  ListItem,
  ListItemText,
  RadioGroup,
  Radio
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { captureException } from "@sentry/browser";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Calendar, Clipboard, Info } from "react-feather";
import { useLocation } from "react-router-dom";
import Page from "../../components/Page";
import api from "../../utils/api";
import Loader from "../../utils/loader";

const ResponseInterface = props => {
  var query = {};
  const location = useLocation();
  location.search
    .slice(1)
    .split("&")
    .forEach(item => {
      query[item.split("=")[0]] = item.split("=")[1];
    });

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [rosterUserMap, setRosterUserMap] = useState({});
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [rosterQuestions, setRosterQuestions] = useState([]);
  const [displayRosterQuestions, setDisplayRosterQuestions] = useState(false);
  const [ifResponsesIncomplete, setIfResponsesIncomplete] = useState(true);

  useEffect(() => {
    (async () => {
      setLoading(true);
      let employeeToken = query.employeeToken;
      localStorage.setItem("employeeToken", employeeToken);
      try {
        const data = await api(
          "GET",
          "rosters/getRosterUserMap/" + query.rosterUserMapId,
          null,
          false,
          null,
          null,
          null,
          employeeToken
        );
        setRosterUserMap(data);
      } catch (error) {
        captureException(error);
      }
      setLoading(false);
      // Also load roster questions now itself. If user accepts, we can display it rightaway without any delay
      // We don't have to "await" here, as the accept button will stay disabled till questions are fully loaded
      loadRosterQuestions();
    })();
  }, [status, error]);

  const loadRosterQuestions = async () => {
    // loadingQuestions = true will disable the ACCEPT button
    setLoadingQuestions(true);
    let employeeToken = localStorage.getItem("employeeToken");
    try {
      const response = await api(
        "GET",
        "rosters/questions",
        null,
        false,
        null,
        null,
        null,
        employeeToken
      );
      if (response.status == "success") {
        processRosterQuestions(response.data.questions);
      } else {
        console.log("Roster questions not set for this organization");
      }
    } catch (error) {
      captureException(error);
    }
    // We have questions now (or an empty list), so enable the ACCEPT button
    setLoadingQuestions(false);
  };

  // Convert multi-level questions to single-level for display
  const processRosterQuestions = async multiLevelQuestions => {
    let restructuredRosterQuestions = [];
    // Loop through the top level questions and add them to global questions list in a simpler format
    // We only need question.title (string) and question.choices (string[]), nothing more
    // We can mark "dependent question" and "expected response" in inner level questions
    for (let i = 0; i < multiLevelQuestions.length; i++) {
      // This will be a single question object from parent level
      // The inner question will be extracted later
      let parentQuestionObj = {
        title: multiLevelQuestions[i].title,
        choices: [],
        parentQuestionIndex: null,
        answer: ""
      };
      let parentQuestionIndex = restructuredRosterQuestions.length;
      let additionalQuestions = [];
      for (let j = 0; j < multiLevelQuestions[i].choices.length; j++) {
        // Add the choice to parent question object
        parentQuestionObj.choices.push(
          multiLevelQuestions[i].choices[j].answer
        );
        // Check if we have an additional question at this level
        if (
          multiLevelQuestions[i].choices[j].additionalQuestion &&
          multiLevelQuestions[i].choices[j].choices
        ) {
          let additionalQuestionObj = {
            title: multiLevelQuestions[i].choices[j].additionalQuestion,
            choices: multiLevelQuestions[i].choices[j].choices,
            parentQuestionIndex: parentQuestionIndex,
            expectedParentResponse: multiLevelQuestions[i].choices[j].answer,
            answer: ""
          };
          // Keep this object in the additional questions list, to be added to reformatted roster questions later
          additionalQuestions.push(additionalQuestionObj);
        }
      }
      // Add parent question first
      restructuredRosterQuestions.push(parentQuestionObj);
      // Add additional questions (if any)
      restructuredRosterQuestions = [
        ...restructuredRosterQuestions,
        ...additionalQuestions
      ];
    }
    // Final result: we should have a single level of questions, with some having dependent question and conditional response details
    // It is much more easy to process this than the original hierarchical list of questions
    setRosterQuestions(restructuredRosterQuestions);
    console.log("Final list of questions: ", restructuredRosterQuestions);
  };

  const displayRosterQuestionsOnAccept = async () => {
    console.log("Roster questions: ", rosterQuestions);
    if (rosterQuestions.length > 0) {
      console.log(
        "display questions and submit responses after accepting roster"
      );
      setDisplayRosterQuestions(true);
    } else {
      // No questions to display, we can directly accept
      console.log("No questions from server. TODO: Accept it rightaway");
      handleResponse("accepted");
    }
  };

  // Get final question responses object
  const getFinalQuestionResponses = () => {
    // Check if all the required questions are answered. If not, return true
    let finalResponses = [];
    for (let i = 0; i < rosterQuestions.length; i++) {
      if (rosterQuestions[i].parentQuestionIndex !== null) {
        // This is a secondary question. Response is required only if parent question answer matches expectation
        let parentQuestion =
          rosterQuestions[rosterQuestions[i].parentQuestionIndex];
        if (
          parentQuestion.answer !== rosterQuestions[i].expectedParentResponse
        ) {
          // Parent response is different. This answer is not required
          continue;
        }
      }
      // If we didn't "continue" from above, then response is required
      if (rosterQuestions[i].answer && rosterQuestions[i].answer.length > 0) {
        finalResponses.push({
          question: rosterQuestions[i].title,
          answer: rosterQuestions[i].answer
        });
      } else {
        // We are not ready to finalize question responses yet
        return null;
      }
    }
    return finalResponses;
  };

  // Used to disable submit button when the responses are incomplete
  const checkIfResponsesComplete = () => {
    let finalResponses = getFinalQuestionResponses();
    if (finalResponses === null) {
      // Responses are still incomplete
      setIfResponsesIncomplete(true);
    } else {
      // We have the final responses and are ready to submit
      setIfResponsesIncomplete(false);
      console.log("We have the final responses: ", finalResponses);
    }
  };

  const submitAcceptAndQuestionResponses = async () => {
    // fetch question responses
    let finalResponses = getFinalQuestionResponses();
    // submit accept
    await handleResponse("accepted");
    // submit responses
    await submitQuestionResponses(finalResponses);
  };

  const cancelAccept = async () => {
    setDisplayRosterQuestions(false);
  };

  const checkParentQuestionResponse = question => {
    if (
      question.parentQuestionIndex === null ||
      question.parentQuestionIndex === undefined
    ) {
      return true;
    }
    let parentQuestion = rosterQuestions[question.parentQuestionIndex];
    if (parentQuestion.answer === question.expectedParentResponse) {
      return true;
    }
    return false;
  };

  const recordQuestionResponse = async (questionIndex, answer) => {
    console.log("Got response: ", questionIndex, answer);
    let rosterQuestionsCopy = [...rosterQuestions];
    rosterQuestionsCopy[questionIndex].answer = answer;
    setRosterQuestions(rosterQuestionsCopy);
    // Check if responses are completed and update state: `setIfResponsesIncomplete`
    checkIfResponsesComplete();
  };

  const handleResponse = async response => {
    setLoading(true);
    try {
      let employeeToken = query.employeeToken;
      let data = await api(
        "PATCH",
        "rosters/mobile/userMap/" + rosterUserMap.id,
        {
          status: response
        },
        false,
        null,
        null,
        null,
        employeeToken
      );
      setStatus(data.status);
      console.log(data.status);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      // captureException(error);
    }
    setLoading(false);
  };

  const submitQuestionResponses = async questionResponses => {
    setLoading(true);
    try {
      let employeeToken = query.employeeToken;
      let data = await api(
        "POST",
        "rosters/questionResponse/" + rosterUserMap.id,
        {
          value: questionResponses
        },
        false,
        null,
        null,
        null,
        employeeToken
      );
      setStatus(data.status);
      console.log(data.status);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      // captureException(error);
    }
    setLoading(false);
  };

  return loading ? (
    <Loader />
  ) : (
    <Page title="Roster Request Response">
      <Container maxWidth="sm">
        <Box p={3} m={3} style={{ backgroundColor: "#fff" }}>
          <Box mt={3} mb={2}>
            <Typography
              variant="h4"
              component="h2"
              align="center"
              color="primary"
            >
              Roster Request Response
            </Typography>
          </Box>
          <Grid container direction="column" spacing={1}>
            {/* Some extra space between page header and contents */}
            <div style={{ paddingBottom: 32 + "px" }}></div>
            <Grid item>
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item>
                  <Box width={30}>
                    <Clipboard />
                  </Box>
                </Grid>
                <Grid item>
                  <Box width={120}>
                    <ListItem disableGutters>
                      <ListItemText
                        primary={
                          <Typography variant="h5">Roster Name</Typography>
                        }
                      />
                    </ListItem>
                  </Box>
                </Grid>
                <Grid item>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <Typography variant="body1">
                          {rosterUserMap.roster.name}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item>
                  <Box width={30}>
                    <Clipboard />
                  </Box>
                </Grid>
                <Grid item>
                  <Box width={120}>
                    <ListItem disableGutters>
                      <ListItemText
                        primary={
                          <Typography variant="h5">Description</Typography>
                        }
                      />
                    </ListItem>
                  </Box>
                </Grid>
                <Grid item>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <Typography variant="body1">
                          {rosterUserMap.roster.description}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item>
                  <Box width={30}>
                    <Calendar />
                  </Box>
                </Grid>
                <Grid item>
                  <Box width={120}>
                    <ListItem disableGutters>
                      <ListItemText
                        primary={<Typography variant="h5">Date</Typography>}
                      />
                    </ListItem>
                  </Box>
                </Grid>
                <Grid item>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <Typography variant="body1">
                          {moment(parseInt(rosterUserMap.roster.startDate))
                            .local()
                            .format("Do MMM YYYY")}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item>
                  <Box width={30}>
                    <Info />
                  </Box>
                </Grid>
                <Grid item>
                  <Box width={120}>
                    <ListItem disableGutters>
                      <ListItemText
                        primary={<Typography variant="h5">Status</Typography>}
                      />
                    </ListItem>
                  </Box>
                </Grid>
                <Grid item>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <Typography variant="button">
                          {rosterUserMap.status}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* Some extra space between page contents and accept / reject buttons or the questions */}
          <div style={{ paddingBottom: 32 + "px" }}></div>
          {error !== "" && <Alert severity="error">{error}</Alert>}
          {status !== "" && (
            <Alert severity="success"> Your response has been recorded.</Alert>
          )}
          {rosterUserMap.status === "requested" && !displayRosterQuestions && (
            <Box my={3}>
              <Grid direction="row" container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    variant="contained"
                    disabled={loadingQuestions}
                    onClick={displayRosterQuestionsOnAccept}
                  >
                    Accept
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    color="secondary"
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={() => handleResponse("declined")}
                  >
                    Decline
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          {rosterUserMap.status === "requested" &&
            displayRosterQuestions &&
            rosterQuestions.map((question, questionIndex) => (
              <>
                {checkParentQuestionResponse(question) && (
                  <FormControl
                    component="fieldset"
                    key={"question-key-" + questionIndex}
                  >
                    <FormLabel component="legend">{question.title}</FormLabel>
                    <RadioGroup
                      row
                      name={"question-group-" + questionIndex}
                      key={"question-group-key-" + questionIndex}
                      value={question.answer}
                      onChange={e => {
                        recordQuestionResponse(questionIndex, e.target.value);
                      }}
                    >
                      {question.choices.map(
                        (questionChoice, questionChoiceIndex) => (
                          <FormControlLabel
                            value={questionChoice}
                            label={questionChoice}
                            key={
                              "question-" +
                              questionIndex +
                              "-choice-" +
                              questionChoiceIndex
                            }
                            control={<Radio color="secondary" />}
                          />
                        )
                      )}
                    </RadioGroup>
                  </FormControl>
                )}
                {/* Let's have some space between questions */}
                <div style={{ paddingBottom: 20 + "px" }}></div>
              </>
            ))}

          {rosterUserMap.status === "requested" && displayRosterQuestions && (
            <Box my={3}>
              <Grid direction="row" container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    variant="contained"
                    disabled={loading || ifResponsesIncomplete}
                    onClick={submitAcceptAndQuestionResponses}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    color="secondary"
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={cancelAccept}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          {/* Some extra space between page contents and footer */}
          <div style={{ paddingBottom: 32 + "px" }}></div>
          <Grid
            container
            direction="row"
            spacing={1}
            alignItems="center"
            justify="center"
          >
            <Grid item>
              <Typography variant="h5" color="primary">
                Powered by
              </Typography>
            </Grid>
            <Grid item>
              <img
                src="https://hipla.io/vectors/hipla-logo.svg"
                style={{ width: 100 }}
                alt="logo"
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default ResponseInterface;
