import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography
} from "@material-ui/core";
import { captureException } from "@sentry/browser";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { organizationSettings, toastAction } from "../../../actions";
import api from "../../../utils/api";
import Loader from "../../../utils/loader";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const useStyles = makeStyles({
  root: {},
  item: {
    display: "flex",
    flexDirection: "column"
  }
});

const Notification = ({ className, ...rest }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [updatingTemplate, setUpdatingTemplate] = useState(false);
  const [newOrgSettings, setNewOrgSettings] = useState({});
  const [rteModalOpen, setrteModalOpen] = useState(false);
  const [draftText, setDraftText] = useState(EditorState.createEmpty());
  const [editingField, setEditingField] = useState("");
  const user = useSelector(state => state.user);
  const orgSettings = useSelector(state => state.orgSettings);
  const business = useSelector(state => state.business);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(organizationSettings({ ...orgSettings, ...newOrgSettings }));
  }, [loading]);

  const mailCOntent = {
    templateWhenVisitorCreatedOrg: (
      <>
        <p>
          Your profile has been created on The Sentinel by Admin of your
          Organization.
        </p>
        <p>Go to your Account and check your profile</p>
        <div
          style={{
            width: "100%",
            padding: "16px",
            backgroundColor: "#00337010",
            borderRadius: "6px"
          }}
        >
          <h4 style={{ color: "#003370" }}>Your Login Details:</h4>
          <p>
            Email:
            <br />
            Password:
          </p>
          <br />
        </div>
      </>
    ),
    templateWhenEmployeeCreatedOrg: (
      <>
        <p>
          Your profile has been created on The Sentinel by Admin of your
          Organization.
        </p>
        <p>Go to your Account and check your profile</p>
        <div
          style={{
            width: "100%",
            padding: "16px",
            backgroundColor: "#00337010",
            borderRadius: "6px"
          }}
        >
          <h4 style={{ color: "#003370" }}>Your Login Details:</h4>
          <p>
            Email:
            <br />
            Password:
          </p>
          <br />
        </div>
      </>
    ),
    templateToMeetingOrganizerOrg: (
      <>
        <p>You have created a new meeting Request.</p>
        <div
          style={{
            width: "100%",
            padding: "16px",
            backgroundColor: "#00337010",
            borderRadius: "6px"
          }}
        >
          <h4 style={{ color: "#003370" }}>Meeting Details:</h4>
          <p>
            Meeting Title: <em> Meeting Purpose</em>
          </p>
          <p>
            Meeting Time: <em> Start Time</em> - <em> End Time </em>
          </p>
          <p>
            Venue: <em> Selected Room</em>, <em> Selected Building </em>
          </p>
          <br />
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <a
              href="#"
              style={{
                padding: "12px 40px",
                backgroundColor: "rgb(51, 156, 51)",
                borderRadius: 6,
                color: "white",
                textDecoration: "none",
                marginRight: 24
              }}
            >
              <b>Take Action</b>
            </a>
          </div>
        </div>
      </>
    ),
    templateToVisitorParticipantOrg: (
      <>
        <p>
          You have received a new meeting Request from{" "}
          <em> Meeting Organizer </em>
        </p>
        <div
          style={{
            width: "100%",
            padding: "16px",
            backgroundColor: "#00337010",
            borderRadius: "6px"
          }}
        >
          <h4 style={{ color: "#003370" }}>Meeting Details:</h4>
          <p>
            Meeting Title: <em> Meeting Purpose</em>
          </p>
          <p>
            Meeting Time: <em> Start Time</em> - <em> End Time </em>
          </p>
          <p>
            Venue: <em> Selected Room</em>, <em> Selected Building </em>
          </p>
          <br />
          <h4 style={{ color: "#003370" }}>Organizer Details:</h4>
          <p>
            Name: <em> Organizer's Name</em>
          </p>
          <p>
            Phone number: <em>Organizer's phone number </em>
          </p>
          <p>
            Email: <em> Organizer's email address</em>
          </p>
          <br />
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <a
              href="#"
              style={{
                padding: "12px 40px",
                backgroundColor: "rgb(51, 156, 51)",
                borderRadius: 6,
                color: "white",
                textDecoration: "none",
                marginRight: 24
              }}
            >
              <b>Take Action</b>
            </a>
          </div>
        </div>
      </>
    ),
    templateToEmployeeParticipantOrg: (
      <>
        <p>
          You have received a new meeting Request from{" "}
          <em> Meeting Organizer </em>
        </p>
        <div
          style={{
            width: "100%",
            padding: "16px",
            backgroundColor: "#00337010",
            borderRadius: "6px"
          }}
        >
          <h4 style={{ color: "#003370" }}>Meeting Details:</h4>
          <p>
            Meeting Title: <em> Meeting Purpose</em>
          </p>
          <p>
            Meeting Time: <em> Start Time</em> - <em> End Time </em>
          </p>
          <p>
            Venue: <em> Selected Room</em>, <em> Selected Building </em>
          </p>
          <br />
          <h4 style={{ color: "#003370" }}>Organizer Details:</h4>
          <p>
            Name: <em> Organizer's Name</em>
          </p>
          <p>
            Phone number: <em>Organizer's phone number </em>
          </p>
          <p>
            Email: <em> Organizer's email address</em>
          </p>
          <br />
        </div>
      </>
    ),
    templateToMeetingOrganizerOnEditOrg: (
      <>
        <p>You have updated a meeting.</p>
        <div
          style={{
            width: "100%",
            padding: "16px",
            backgroundColor: "#00337010",
            borderRadius: "6px"
          }}
        >
          <h4 style={{ color: "#003370" }}>Meeting Details:</h4>
          <p>
            Meeting Title: <em> Meeting Purpose</em>
          </p>
          <p>
            Meeting Time: <em> Start Time</em> - <em> End Time </em>
          </p>
          <p>
            Venue: <em> Selected Room</em>, <em> Selected Building </em>
          </p>
          <br />
          <h4 style={{ color: "#003370" }}>Organizer Details:</h4>
          <p>
            Name: <em> Organizer's Name</em>
          </p>
          <p>
            Phone number: <em>Organizer's phone number </em>
          </p>
          <p>
            Email: <em> Organizer's email address</em>
          </p>
          <br />
        </div>
      </>
    ),
    templateToVisitorParticipantOnEditOrg: (
      <>
        <p>
          <em> Meeting Participant </em> has updated the meeting
        </p>
        <div
          style={{
            width: "100%",
            padding: "16px",
            backgroundColor: "#00337010",
            borderRadius: "6px"
          }}
        >
          <h4 style={{ color: "#003370" }}>Meeting Details:</h4>
          <p>
            Meeting Title: <em> Meeting Purpose</em>
          </p>
          <p>
            Meeting Time: <em> Start Time</em> - <em> End Time </em>
          </p>
          <p>
            Venue: <em> Selected Room</em>, <em> Selected Building </em>
          </p>
          <br />
          <h4 style={{ color: "#003370" }}>Organizer Details:</h4>
          <p>
            Name: <em> Organizer's Name</em>
          </p>
          <p>
            Phone number: <em>Organizer's phone number </em>
          </p>
          <p>
            Email: <em> Organizer's email address</em>
          </p>
          <br />
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <a
              href="#"
              style={{
                padding: "12px 40px",
                backgroundColor: "rgb(51, 156, 51)",
                borderRadius: 6,
                color: "white",
                textDecoration: "none",
                marginRight: 24
              }}
            >
              <b>Take Action</b>
            </a>
          </div>
        </div>
      </>
    ),
    templateToEmployeeParticipantOnEditOrg: (
      <>
        <p>
          <em> Meeting Participant </em> has updated the meeting
        </p>
        <div
          style={{
            width: "100%",
            padding: "16px",
            backgroundColor: "#00337010",
            borderRadius: "6px"
          }}
        >
          <h4 style={{ color: "#003370" }}>Meeting Details:</h4>
          <p>
            Meeting Title: <em> Meeting Purpose</em>
          </p>
          <p>
            Meeting Time: <em> Start Time</em> - <em> End Time </em>
          </p>
          <p>
            Venue: <em> Selected Room</em>, <em> Selected Building </em>
          </p>
          <br />
          <h4 style={{ color: "#003370" }}>Organizer Details:</h4>
          <p>
            Name: <em> Organizer's Name</em>
          </p>
          <p>
            Phone number: <em>Organizer's phone number </em>
          </p>
          <p>
            Email: <em> Organizer's email address</em>
          </p>
          <br />
        </div>
      </>
    ),
    templateToMeetingOrganizerOnAcceptOrg: (
      <>
        <em> Meeting Participant </em> has accepted your meeting request
        <div
          style={{
            width: "100%",
            padding: "16px",
            backgroundColor: "#00337010",
            borderRadius: "6px"
          }}
        >
          <h4 style={{ color: "#003370" }}>Meeting Details:</h4>
          <p>
            Meeting Title: <em> Meeting Purpose</em>
          </p>
          <p>
            Meeting Time: <em> Start Time</em> - <em> End Time </em>
          </p>
          <p>
            Venue: <em> Selected Room</em>, <em> Selected Building </em>
          </p>
          <br />
          <h4 style={{ color: "#003370" }}>Organizer Details:</h4>
          <p>
            Name: <em> Organizer's Name</em>
          </p>
          <p>
            Phone number: <em>Organizer's phone number </em>
          </p>
          <p>
            Email: <em> Organizer's email address</em>
          </p>
          <br />
        </div>
      </>
    ),
    templateToVisitorParticipantOnAcceptOrg: (
      <>
        <p>
          <em> Meeting Participant </em> has accepted the meeting request
        </p>
        <div
          style={{
            width: "100%",
            padding: "16px",
            backgroundColor: "#00337010",
            borderRadius: "6px"
          }}
        >
          <h4 style={{ color: "#003370" }}>Meeting Details:</h4>
          <p>
            Meeting Title: <em> Meeting Purpose</em>
          </p>
          <p>
            Meeting Time: <em> Start Time</em> - <em> End Time </em>
          </p>
          <p>
            Venue: <em> Selected Room</em>, <em> Selected Building </em>
          </p>
          <br />
          <h4 style={{ color: "#003370" }}>Organizer Details:</h4>
          <p>
            Name: <em> Organizer's Name</em>
          </p>
          <p>
            Phone number: <em>Organizer's phone number </em>
          </p>
          <p>
            Email: <em> Organizer's email address</em>
          </p>
          <br />
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <a
              href="#"
              style={{
                padding: "12px 40px",
                backgroundColor: "rgb(51, 156, 51)",
                borderRadius: 6,
                color: "white",
                textDecoration: "none",
                marginRight: 24
              }}
            >
              <b>Take Action</b>
            </a>
          </div>
        </div>
      </>
    ),
    templateToEmployeeParticipantOnAcceptOrg: (
      <>
        <p>
          <em> Meeting Participant </em> has accepted the meeting request
        </p>
        <div
          style={{
            width: "100%",
            padding: "16px",
            backgroundColor: "#00337010",
            borderRadius: "6px"
          }}
        >
          <h4 style={{ color: "#003370" }}>Meeting Details:</h4>
          <p>
            Meeting Title: <em> Meeting Purpose</em>
          </p>
          <p>
            Meeting Time: <em> Start Time</em> - <em> End Time </em>
          </p>
          <p>
            Venue: <em> Selected Room</em>, <em> Selected Building </em>
          </p>
          <br />
          <h4 style={{ color: "#003370" }}>Organizer Details:</h4>
          <p>
            Name: <em> Organizer's Name</em>
          </p>
          <p>
            Phone number: <em>Organizer's phone number </em>
          </p>
          <p>
            Email: <em> Organizer's email address</em>
          </p>
          <br />
        </div>
      </>
    ),
    templateWhenVisitorCheckInOrg: (
      <>
        <p>
          <em> Visitor Participant </em> has just checked in at the entrance
        </p>
        <div
          style={{
            width: "100%",
            padding: "16px",
            backgroundColor: "#00337010",
            borderRadius: "6px"
          }}
        >
          <h4 style={{ color: "#003370" }}>Meeting Details:</h4>
          <p>
            Meeting Title: <em> Meeting Purpose</em>
          </p>
          <p>
            Meeting Time: <em> Start Time</em> - <em> End Time </em>
          </p>
          <p>
            Venue: <em> Selected Room</em>, <em> Selected Building </em>
          </p>
          <br />
          <h4 style={{ color: "#003370" }}>Visitor Details:</h4>
          <p>
            Name: <em> Visitor's Name</em>
          </p>
          <p>
            Phone number: <em>Visitor's phone number </em>
          </p>
          <p>
            Email: <em> Visitor's email address</em>
          </p>
          <br />
        </div>
      </>
    ),
    templateWhenVisitorCheckOutOrg: (
      <>
        <p>
          <em> Visitor Participant </em> has just checked out from the premises
        </p>
        <div
          style={{
            width: "100%",
            padding: "16px",
            backgroundColor: "#00337010",
            borderRadius: "6px"
          }}
        >
          <h4 style={{ color: "#003370" }}>Meeting Details:</h4>
          <p>
            Meeting Title: <em> Meeting Purpose</em>
          </p>
          <p>
            Meeting Time: <em> Start Time</em> - <em> End Time </em>
          </p>
          <p>
            Venue: <em> Selected Room</em>, <em> Selected Building </em>
          </p>
          <br />
          <h4 style={{ color: "#003370" }}>Visitor Details:</h4>
          <p>
            Name: <em> Visitor's Name</em>
          </p>
          <p>
            Phone number: <em>Visitor's phone number </em>
          </p>
          <p>
            Email: <em> Visitor's email address</em>
          </p>
          <br />
        </div>
      </>
    )
  };

  useEffect(() => {
    (async () => {
      try {
        const newdata =
          user.role !== "businessAdmin"
            ? await api(
                "POST",
                "organization/getOrgSettingsFilteredByKeys/" +
                  user.organizationId,
                {
                  keys: [
                    "sendEmailWhenVisitorCreatedOrg",
                    "sendEmailWhenEmployeeCreatedOrg",
                    "sendEmailToMeetingOrganizerOrg",
                    "sendEmailToVisitorParticipantOrg",
                    "sendEmailToEmployeeParticipantOrg",
                    "sendEmailToMeetingOrganizerOnEditOrg",
                    "sendEmailToVisitorParticipantOnEditOrg",
                    "sendEmailToEmployeeParticipantOnEditOrg",
                    "sendEmailToMeetingOrganizerOnAcceptOrg",
                    "sendEmailToVisitorParticipantOnAcceptOrg",
                    "sendEmailToEmployeeParticipantOnAcceptOrg",
                    "sendEmailWhenVisitorCheckInOrg",
                    "sendEmailWhenVisitorCheckOutOrg",
                    "templateWhenVisitorCreatedOrg",
                    "templateWhenEmployeeCreatedOrg",
                    "templateToMeetingOrganizerOrg",
                    "templateToVisitorParticipantOrg",
                    "templateToEmployeeParticipantOrg",
                    "templateToMeetingOrganizerOnEditOrg",
                    "templateToVisitorParticipantOnEditOrg",
                    "templateToEmployeeParticipantOnEditOrg",
                    "templateToMeetingOrganizerOnAcceptOrg",
                    "templateToVisitorParticipantOnAcceptOrg",
                    "templateToEmployeeParticipantOnAcceptOrg",
                    "templateWhenVisitorCheckInOrg",
                    "templateWhenVisitorCheckOutOrg"
                  ]
                },
                true
              )
            : {};
        const newdatabusiness = await api(
          "POST",
          "business/getBusinessSettingsFilteredByKeys/" + user.businessId,
          {
            keys: [
              "sendEmailWhenVisitorCreated",
              "sendEmailWhenEmployeeCreated",
              "sendEmailToMeetingOrganizer",
              "sendEmailToVisitorParticipant",
              "sendEmailToEmployeeParticipant",
              "sendEmailToMeetingOrganizerOnEdit",
              "sendEmailToVisitorParticipantOnEdit",
              "sendEmailToEmployeeParticipantOnEdit",
              "sendEmailToMeetingOrganizerOnAccept",
              "sendEmailToVisitorParticipantOnAccept",
              "sendEmailToEmployeeParticipantOnAccept",
              "sendEmailWhenVisitorCheckIn",
              "sendEmailWhenVisitorCheckOut"
            ]
          },
          true
        );
        setNewOrgSettings({ ...newdata, ...newdatabusiness });
        setLoading(false);
      } catch (error) {
        captureException(error);
      }
    })();
  }, []);

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    try {
      user.role !== "businessAdmin" &&
        (await api(
          "PATCH",
          "organization/settings/" + user.organizationId,
          {
            sendEmailWhenVisitorCreatedOrg:
              newOrgSettings.sendEmailWhenVisitorCreatedOrg,
            sendEmailWhenEmployeeCreatedOrg:
              newOrgSettings.sendEmailWhenEmployeeCreatedOrg,
            sendEmailToMeetingOrganizerOrg:
              newOrgSettings.sendEmailToMeetingOrganizerOrg,
            sendEmailToVisitorParticipantOrg:
              newOrgSettings.sendEmailToVisitorParticipantOrg,
            sendEmailToEmployeeParticipantOrg:
              newOrgSettings.sendEmailToEmployeeParticipantOrg,
            sendEmailToMeetingOrganizerOnEditOrg:
              newOrgSettings.sendEmailToMeetingOrganizerOnEditOrg,
            sendEmailToVisitorParticipantOnEditOrg:
              newOrgSettings.sendEmailToVisitorParticipantOnEditOrg,
            sendEmailToEmployeeParticipantOnEditOrg:
              newOrgSettings.sendEmailToEmployeeParticipantOnEditOrg,
            sendEmailToMeetingOrganizerOnAcceptOrg:
              newOrgSettings.sendEmailToMeetingOrganizerOnAcceptOrg,
            sendEmailToVisitorParticipantOnAcceptOrg:
              newOrgSettings.sendEmailToVisitorParticipantOnAcceptOrg,
            sendEmailToEmployeeParticipantOnAcceptOrg:
              newOrgSettings.sendEmailToEmployeeParticipantOnAcceptOrg,
            sendEmailWhenVisitorCheckInOrg:
              newOrgSettings.sendEmailWhenVisitorCheckInOrg,
            sendEmailWhenVisitorCheckOutOrg:
              newOrgSettings.sendEmailWhenVisitorCheckOutOrg,
            templateWhenVisitorCreatedOrg:
              newOrgSettings.templateWhenVisitorCreatedOrg,
            templateWhenEmployeeCreatedOrg:
              newOrgSettings.templateWhenEmployeeCreatedOrg,
            templateToMeetingOrganizerOrg:
              newOrgSettings.templateToMeetingOrganizerOrg,
            templateToVisitorParticipantOrg:
              newOrgSettings.templateToVisitorParticipantOrg,
            templateToEmployeeParticipantOrg:
              newOrgSettings.templateToEmployeeParticipantOrg,
            templateToMeetingOrganizerOnEditOrg:
              newOrgSettings.templateToMeetingOrganizerOnEditOrg,
            templateToVisitorParticipantOnEditOrg:
              newOrgSettings.templateToVisitorParticipantOnEditOrg,
            templateToEmployeeParticipantOnEditOrg:
              newOrgSettings.templateToEmployeeParticipantOnEditOrg,
            templateToMeetingOrganizerOnAcceptOrg:
              newOrgSettings.templateToMeetingOrganizerOnAcceptOrg,
            templateToVisitorParticipantOnAcceptOrg:
              newOrgSettings.templateToVisitorParticipantOnAcceptOrg,
            templateToEmployeeParticipantOnAcceptOrg:
              newOrgSettings.templateToEmployeeParticipantOnAcceptOrg,
            templateWhenVisitorCheckInOrg:
              newOrgSettings.templateWhenVisitorCheckInOrg,
            templateWhenVisitorCheckOutOrg:
              newOrgSettings.templateWhenVisitorCheckOuOrgt
          },
          true
        ));
      user.role === "businessAdmin" &&
        (await api(
          "PATCH",
          "business/settings/" + user.businessId,
          {
            sendEmailWhenVisitorCreated:
              newOrgSettings.sendEmailWhenVisitorCreated,
            sendEmailWhenEmployeeCreated:
              newOrgSettings.sendEmailWhenEmployeeCreated,
            sendEmailToMeetingOrganizer:
              newOrgSettings.sendEmailToMeetingOrganizer,
            sendEmailToVisitorParticipant:
              newOrgSettings.sendEmailToVisitorParticipant,
            sendEmailToEmployeeParticipant:
              newOrgSettings.sendEmailToEmployeeParticipant,
            sendEmailToMeetingOrganizerOnEdit:
              newOrgSettings.sendEmailToMeetingOrganizerOnEdit,
            sendEmailToVisitorParticipantOnEdit:
              newOrgSettings.sendEmailToVisitorParticipantOnEdit,
            sendEmailToEmployeeParticipantOnEdit:
              newOrgSettings.sendEmailToEmployeeParticipantOnEdit,
            sendEmailToMeetingOrganizerOnAccept:
              newOrgSettings.sendEmailToMeetingOrganizerOnAccept,
            sendEmailToVisitorParticipantOnAccept:
              newOrgSettings.sendEmailToVisitorParticipantOnAccept,
            sendEmailToEmployeeParticipantOnAccept:
              newOrgSettings.sendEmailToEmployeeParticipantOnAccept,
            sendEmailWhenVisitorCheckIn:
              newOrgSettings.sendEmailWhenVisitorCheckIn,
            sendEmailWhenVisitorCheckOut:
              newOrgSettings.sendEmailWhenVisitorCheckOut
          },
          true
        ));
      const newdata =
        user.role !== "businessAdmin"
          ? await api(
              "POST",
              "organization/getOrgSettingsFilteredByKeys/" +
                user.organizationId,
              {
                keys: [
                  "sendEmailWhenVisitorCreatedOrg",
                  "sendEmailWhenEmployeeCreatedOrg",
                  "sendEmailToMeetingOrganizerOrg",
                  "sendEmailToVisitorParticipantOrg",
                  "sendEmailToEmployeeParticipantOrg",
                  "sendEmailToMeetingOrganizerOnEditOrg",
                  "sendEmailToVisitorParticipantOnEditOrg",
                  "sendEmailToEmployeeParticipantOnEditOrg",
                  "sendEmailToMeetingOrganizerOnAcceptOrg",
                  "sendEmailToVisitorParticipantOnAcceptOrg",
                  "sendEmailToEmployeeParticipantOnAcceptOrg",
                  "sendEmailWhenVisitorCheckInOrg",
                  "sendEmailWhenVisitorCheckOutOrg",
                  "templateWhenVisitorCreatedOrg",
                  "templateWhenEmployeeCreatedOrg",
                  "templateToMeetingOrganizerOrg",
                  "templateToVisitorParticipantOrg",
                  "templateToEmployeeParticipantOrg",
                  "templateToMeetingOrganizerOnEditOrg",
                  "templateToVisitorParticipantOnEditOrg",
                  "templateToEmployeeParticipantOnEditOrg",
                  "templateToMeetingOrganizerOnAcceptOrg",
                  "templateToVisitorParticipantOnAcceptOrg",
                  "templateToEmployeeParticipantOnAcceptOrg",
                  "templateWhenVisitorCheckInOrg",
                  "templateWhenVisitorCheckOutOrg"
                ]
              },
              true
            )
          : {};
      const newdatabusiness = await api(
        "POST",
        "business/getBusinessSettingsFilteredByKeys/" + user.businessId,
        {
          keys: [
            "sendEmailWhenVisitorCreated",
            "sendEmailWhenEmployeeCreated",
            "sendEmailToMeetingOrganizer",
            "sendEmailToVisitorParticipant",
            "sendEmailToEmployeeParticipant",
            "sendEmailToMeetingOrganizerOnEdit",
            "sendEmailToVisitorParticipantOnEdit",
            "sendEmailToEmployeeParticipantOnEdit",
            "sendEmailToMeetingOrganizerOnAccept",
            "sendEmailToVisitorParticipantOnAccept",
            "sendEmailToEmployeeParticipantOnAccept",
            "sendEmailWhenVisitorCheckIn",
            "sendEmailWhenVisitorCheckOut"
          ]
        },
        true
      );
      setNewOrgSettings({ ...newdata, ...newdatabusiness });
      setLoading(false);
    } catch (error) {
      captureException(error);
    }
  };

  const handleChange = e => {
    setNewOrgSettings({ ...newOrgSettings, [e.target.name]: e.target.checked });
  };

  const handleUpdateTemplate = async () => {
    try {
      dispatch(
        toastAction({
          status: true,
          message: "Updating template...",
          type: "info",
          noIcon: true
        })
      );
      await api(
        "PATCH",
        "organization/settings/" + user.organizationId,
        {
          [editingField]: JSON.stringify(
            convertToRaw(draftText.getCurrentContent())
          )
        },
        true
      );
      dispatch(
        toastAction({
          status: true,
          message: "Updated!",
          type: "success",
        })
      );
      setUpdatingTemplate(false);
    } catch (error) {
      console.log(error);
      dispatch(
        toastAction({
          status: true,
          message: "Something went wrong...",
          type: "error",
        })
      );
      setUpdatingTemplate(false);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <Box width="100%" className={clsx(classes.root, className)} {...rest}>
      <form onSubmit={handleSubmit}>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                {user.role === "businessAdmin" && (
                  <>
                    <Typography color="textPrimary" gutterBottom variant="h6">
                      Notify employees when added to the sentinel.
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newOrgSettings.sendEmailWhenEmployeeCreated}
                          onChange={handleChange}
                          name="sendEmailWhenEmployeeCreated"
                        />
                      }
                      label="Enable"
                    />

                    <br />
                    <br />
                    <Typography color="textPrimary" gutterBottom variant="h6">
                      Notify visitors when added to the sentinel.
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newOrgSettings.sendEmailWhenVisitorCreated}
                          onChange={handleChange}
                          name="sendEmailWhenVisitorCreated"
                        />
                      }
                      label="Enable"
                    />

                    <br />
                    <br />
                    <Typography color="textPrimary" gutterBottom variant="h6">
                      Notify user when they create a meeting
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newOrgSettings.sendEmailToMeetingOrganizer}
                          onChange={handleChange}
                          name="sendEmailToMeetingOrganizer"
                        />
                      }
                      label="Enable"
                    />

                    <br />
                    <br />
                    <Typography color="textPrimary" gutterBottom variant="h6">
                      Notify user when their meeting request is accepted
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            newOrgSettings.sendEmailToMeetingOrganizerOnAccept
                          }
                          onChange={handleChange}
                          name="sendEmailToMeetingOrganizerOnAccept"
                        />
                      }
                      label="Enable"
                    />

                    <br />
                    <br />
                    <Typography color="textPrimary" gutterBottom variant="h6">
                      Notify employees when they are added to a meeting.
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            newOrgSettings.sendEmailToEmployeeParticipant
                          }
                          onChange={handleChange}
                          name="sendEmailToEmployeeParticipant"
                        />
                      }
                      label="Enable"
                    />

                    <br />
                    <br />
                    <Typography color="textPrimary" gutterBottom variant="h6">
                      Notify employees when a meeting they are added to is
                      updated.
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            newOrgSettings.sendEmailToEmployeeParticipantOnEdit
                          }
                          onChange={handleChange}
                          name="sendEmailToEmployeeParticipantOnEdit"
                        />
                      }
                      label="Enable"
                    />

                    <br />
                    <br />

                    <Typography color="textPrimary" gutterBottom variant="h6">
                      Notify visitors when they are added to a meeting.
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newOrgSettings.sendEmailToVisitorParticipant}
                          onChange={handleChange}
                          name="sendEmailToVisitorParticipant"
                        />
                      }
                      label="Enable"
                    />

                    <br />
                    <br />
                    <Typography color="textPrimary" gutterBottom variant="h6">
                      Notify visitors when a meeting they are added to is
                      updated.
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            newOrgSettings.sendEmailToVisitorParticipantOnEdit
                          }
                          onChange={handleChange}
                          name="sendEmailToVisitorParticipantOnEdit"
                        />
                      }
                      label="Enable"
                    />

                    <br />
                    <br />
                    <Typography color="textPrimary" gutterBottom variant="h6">
                      Notify employees when a visitor added to their meeting
                      checks in.
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newOrgSettings.sendEmailWhenVisitorCheckIn}
                          onChange={handleChange}
                          name="sendEmailWhenVisitorCheckIn"
                        />
                      }
                      label="Enable"
                    />

                    <br />
                    <br />
                    <Typography color="textPrimary" gutterBottom variant="h6">
                      Notify employees when a visitor added to their meeting
                      checks out.
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={newOrgSettings.sendEmailWhenVisitorCheckOut}
                          onChange={handleChange}
                          name="sendEmailWhenVisitorCheckOut"
                        />
                      }
                      label="Enable"
                    />

                    <br />
                    <br />
                  </>
                )}
                {user.role !== "businessAdmin" && (
                  <>
                    {newOrgSettings.sendEmailWhenEmployeeCreated && (
                      <>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h6"
                        >
                          Notify employees when added to the sentinel.
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                newOrgSettings.sendEmailWhenEmployeeCreatedOrg
                              }
                              onChange={handleChange}
                              name="sendEmailWhenEmployeeCreatedOrg"
                            />
                          }
                          label="Enable"
                        />
                        {newOrgSettings.sendEmailWhenEmployeeCreatedOrg && (
                          <>
                            <br />
                            <Button
                              onClick={e => {
                                setEditingField(
                                  "templateWhenEmployeeCreatedOrg"
                                );
                                let contentPreview = EditorState.createWithContent(
                                  convertFromRaw(
                                    JSON.parse(
                                      newOrgSettings.templateWhenEmployeeCreatedOrg
                                    )
                                  )
                                );
                                setDraftText(contentPreview);

                                setrteModalOpen(true);
                              }}
                              variant="contained"
                              color="primary"
                            >
                              Template
                            </Button>
                          </>
                        )}
                        <br />
                        <br />
                      </>
                    )}
                    {newOrgSettings.sendEmailWhenVisitorCreated && (
                      <>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h6"
                        >
                          Notify visitors when added to the sentinel.
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                newOrgSettings.sendEmailWhenVisitorCreatedOrg
                              }
                              onChange={handleChange}
                              name="sendEmailWhenVisitorCreatedOrg"
                            />
                          }
                          label="Enable"
                        />
                        {newOrgSettings.sendEmailWhenVisitorCreatedOrg && (
                          <>
                            <br />
                            <Button
                              onClick={e => {
                                setEditingField(
                                  "templateWhenVisitorCreatedOrg"
                                );
                                let contentPreview = EditorState.createWithContent(
                                  convertFromRaw(
                                    JSON.parse(
                                      newOrgSettings.templateWhenVisitorCreatedOrg
                                    )
                                  )
                                );
                                setDraftText(contentPreview);

                                setrteModalOpen(true);
                              }}
                              variant="contained"
                              color="primary"
                            >
                              Template
                            </Button>
                          </>
                        )}
                        <br />
                        <br />
                      </>
                    )}
                    {newOrgSettings.sendEmailToMeetingOrganizer && (
                      <>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h6"
                        >
                          Notify user when they create a meeting
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                newOrgSettings.sendEmailToMeetingOrganizerOrg
                              }
                              onChange={handleChange}
                              name="sendEmailToMeetingOrganizerOrg"
                            />
                          }
                          label="Enable"
                        />
                        {newOrgSettings.sendEmailToMeetingOrganizerOrg && (
                          <>
                            <br />
                            <Button
                              onClick={e => {
                                setEditingField(
                                  "templateToMeetingOrganizerOrg"
                                );
                                let contentPreview = EditorState.createWithContent(
                                  convertFromRaw(
                                    JSON.parse(
                                      newOrgSettings.templateToMeetingOrganizerOrg
                                    )
                                  )
                                );
                                setDraftText(contentPreview);

                                setrteModalOpen(true);
                              }}
                              variant="contained"
                              color="primary"
                            >
                              Template
                            </Button>
                          </>
                        )}
                        <br />
                        <br />
                      </>
                    )}
                    {newOrgSettings.sendEmailToMeetingOrganizerOnAccept && (
                      <>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h6"
                        >
                          Notify user when their meeting request is accepted
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                newOrgSettings.sendEmailToMeetingOrganizerOnAcceptOrg
                              }
                              onChange={handleChange}
                              name="sendEmailToMeetingOrganizerOnAcceptOrg"
                            />
                          }
                          label="Enable"
                        />
                        {newOrgSettings.sendEmailToMeetingOrganizerOnAcceptOrg && (
                          <>
                            <br />
                            <Button
                              onClick={e => {
                                setEditingField(
                                  "templateToMeetingOrganizerOnAcceptOrg"
                                );
                                let contentPreview = EditorState.createWithContent(
                                  convertFromRaw(
                                    JSON.parse(
                                      newOrgSettings.templateToMeetingOrganizerOnAcceptOrg
                                    )
                                  )
                                );
                                setDraftText(contentPreview);

                                setrteModalOpen(true);
                              }}
                              variant="contained"
                              color="primary"
                            >
                              Template
                            </Button>
                          </>
                        )}
                        <br />
                        <br />
                      </>
                    )}
                    {newOrgSettings.sendEmailToEmployeeParticipant && (
                      <>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h6"
                        >
                          Notify employees when they are added to a meeting.
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                newOrgSettings.sendEmailToEmployeeParticipantOrg
                              }
                              onChange={handleChange}
                              name="sendEmailToEmployeeParticipantOrg"
                            />
                          }
                          label="Enable"
                        />
                        {newOrgSettings.sendEmailToEmployeeParticipantOrg && (
                          <>
                            <br />
                            <Button
                              onClick={e => {
                                setEditingField(
                                  "templateToEmployeeParticipantOrg"
                                );

                                let contentPreview = EditorState.createWithContent(
                                  convertFromRaw(
                                    JSON.parse(
                                      newOrgSettings.templateToEmployeeParticipantOrg
                                    )
                                  )
                                );
                                setDraftText(contentPreview);

                                setrteModalOpen(true);
                              }}
                              variant="contained"
                              color="primary"
                            >
                              Template
                            </Button>
                          </>
                        )}
                        <br />
                        <br />
                      </>
                    )}
                    {newOrgSettings.sendEmailToEmployeeParticipantOnEdit && (
                      <>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h6"
                        >
                          Notify employees when a meeting they are added to is
                          updated.
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                newOrgSettings.sendEmailToEmployeeParticipantOnEditOrg
                              }
                              onChange={handleChange}
                              name="sendEmailToEmployeeParticipantOnEditOrg"
                            />
                          }
                          label="Enable"
                        />
                        {newOrgSettings.sendEmailToEmployeeParticipantOnEditOrg && (
                          <>
                            <br />
                            <Button
                              onClick={e => {
                                setEditingField(
                                  "templateToEmployeeParticipantOnEditOrg"
                                );

                                let contentPreview = EditorState.createWithContent(
                                  convertFromRaw(
                                    JSON.parse(
                                      newOrgSettings.templateToEmployeeParticipantOnEditOrg
                                    )
                                  )
                                );
                                setDraftText(contentPreview);

                                setrteModalOpen(true);
                              }}
                              variant="contained"
                              color="primary"
                            >
                              Template
                            </Button>
                          </>
                        )}
                        <br />
                        <br />
                      </>
                    )}
                    {newOrgSettings.sendEmailToVisitorParticipant && (
                      <>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h6"
                        >
                          Notify visitors when they are added to a meeting.
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                newOrgSettings.sendEmailToVisitorParticipantOrg
                              }
                              onChange={handleChange}
                              name="sendEmailToVisitorParticipantOrg"
                            />
                          }
                          label="Enable"
                        />
                        {newOrgSettings.sendEmailToVisitorParticipantOrg && (
                          <>
                            <br />
                            <Button
                              onClick={e => {
                                setEditingField(
                                  "templateToVisitorParticipantOrg"
                                );

                                let contentPreview = EditorState.createWithContent(
                                  convertFromRaw(
                                    JSON.parse(
                                      newOrgSettings.templateToVisitorParticipantOrg
                                    )
                                  )
                                );
                                setDraftText(contentPreview);

                                setrteModalOpen(true);
                              }}
                              variant="contained"
                              color="primary"
                            >
                              Template
                            </Button>
                          </>
                        )}
                        <br />
                        <br />
                      </>
                    )}
                    {newOrgSettings.sendEmailToVisitorParticipantOnEdit && (
                      <>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h6"
                        >
                          Notify visitors when a meeting they are added to is
                          updated.
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                newOrgSettings.sendEmailToVisitorParticipantOnEditOrg
                              }
                              onChange={handleChange}
                              name="sendEmailToVisitorParticipantOnEditOrg"
                            />
                          }
                          label="Enable"
                        />
                        {newOrgSettings.sendEmailToVisitorParticipantOnEditOrg && (
                          <>
                            <br />
                            <Button
                              onClick={e => {
                                setEditingField(
                                  "templateToVisitorParticipantOnEditOrg"
                                );

                                let contentPreview = EditorState.createWithContent(
                                  convertFromRaw(
                                    JSON.parse(
                                      newOrgSettings.templateToVisitorParticipantOnEditOrg
                                    )
                                  )
                                );
                                setDraftText(contentPreview);

                                setrteModalOpen(true);
                              }}
                              variant="contained"
                              color="primary"
                            >
                              Template
                            </Button>
                          </>
                        )}
                        <br />
                        <br />
                      </>
                    )}
                    {newOrgSettings.sendEmailWhenVisitorCheckIn && (
                      <>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h6"
                        >
                          Notify employees when a visitor added to their meeting
                          checks in.
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                newOrgSettings.sendEmailWhenVisitorCheckInOrg
                              }
                              onChange={handleChange}
                              name="sendEmailWhenVisitorCheckInOrg"
                            />
                          }
                          label="Enable"
                        />
                        {newOrgSettings.sendEmailWhenVisitorCheckInOrg && (
                          <>
                            <br />
                            <Button
                              onClick={e => {
                                setEditingField(
                                  "templateWhenVisitorCheckInOrg"
                                );

                                let contentPreview = EditorState.createWithContent(
                                  convertFromRaw(
                                    JSON.parse(
                                      newOrgSettings.templateWhenVisitorCheckInOrg
                                    )
                                  )
                                );
                                setDraftText(contentPreview);
                                setrteModalOpen(true);
                              }}
                              variant="contained"
                              color="primary"
                            >
                              Template
                            </Button>
                          </>
                        )}
                        <br />
                        <br />
                      </>
                    )}
                    {newOrgSettings.sendEmailWhenVisitorCheckOut && (
                      <>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h6"
                        >
                          Notify employees when a visitor added to their meeting
                          checks out.
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                newOrgSettings.sendEmailWhenVisitorCheckOutOrg
                              }
                              onChange={handleChange}
                              name="sendEmailWhenVisitorCheckOutOrg"
                            />
                          }
                          label="Enable"
                        />
                        {newOrgSettings.sendEmailWhenVisitorCheckOutOrg && (
                          <>
                            <br />
                            <Button
                              onClick={e => {
                                setEditingField(
                                  "templateWhenVisitorCheckOutOrg"
                                );

                                let contentPreview = EditorState.createWithContent(
                                  convertFromRaw(
                                    JSON.parse(
                                      newOrgSettings.templateWhenVisitorCheckOutOrg
                                    )
                                  )
                                );
                                setDraftText(contentPreview);

                                setrteModalOpen(true);
                              }}
                              variant="contained"
                              color="primary"
                            >
                              Template
                            </Button>
                          </>
                        )}
                        <br />
                        <br />
                      </>
                    )}
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <br />
        <Card>
          <CardContent>
            <Box display="flex" justifyContent="flex-end">
              <Button color="primary" variant="contained" type="submit">
                Update
              </Button>
            </Box>
          </CardContent>
        </Card>
      </form>
      <Dialog
        open={rteModalOpen}
        onClose={() => setrteModalOpen(false)}
        maxWidth="lg"
      >
        <Box p={1}>
          <Editor
            editorState={draftText}
            toolbarClassName="rteToolbar"
            editorClassName="rtEditor"
            onEditorStateChange={editorState => {
              setDraftText(editorState);
              setNewOrgSettings({
                ...newOrgSettings,
                [editingField]: JSON.stringify(
                  convertToRaw(draftText.getCurrentContent())
                )
              });
            }}
          />

          <div
            style={{
              width: "100%",
              padding: 10,
              margin: 0,
              backgroundColor: "#efefef",
              fontFamily: "Arial, Helvetica, sans-serif"
            }}
          >
            <div
              style={{ margin: " 0 auto", padding: "12px 24px" }}
              align="center"
            >
              <img
                style={{ margin: "6px auto", width: "160px" }}
                src={business.logo}
                alt={business.name}
              />
            </div>
            <div
              style={{
                margin: "0 auto",
                maxWidth: "570px",
                width: "calc(100% - 20px)",
                backgroundColor: "#fff",
                padding: "32px",
                borderTop: "7px solid #003370"
              }}
            >
              <p
                style={{
                  color: "#003370",
                  fontSize: "1.6rem",
                  fontWeight: "700"
                }}
              >
                Hello <em>User,</em>
              </p>
              {mailCOntent[editingField]}
              <div
                dangerouslySetInnerHTML={{
                  __html: stateToHTML(draftText.getCurrentContent())
                }}
              ></div>
              <p>Sign in to your account and for more details.</p>
              <div style={{ width: "100%", padding: "16px 0" }} align="center">
                <a
                  href={business.domain + ".thesentinel.io"}
                  style={{
                    display: "block",
                    width: "240px",
                    maxWidth: "100%",
                    margin: "12px auto",
                    borderRadius: "6px",
                    padding: "12px 24px",
                    background: "#003370",
                    color: "#fff",
                    textDecoration: "none"
                  }}
                >
                  <b> Login to The Sentinel </b>
                </a>
                <p> Or </p>
                <p> Login by copying the following link to your browser: </p>
                <p>
                  <b> Link to the dashboard</b>
                </p>
              </div>
              <br />
              <p>Regards,</p>
              <p>Team Sentinel</p>
            </div>

            <p align="center" style={{ opacity: 0.8 }}>
              Powered by{" "}
              <a
                href="https://hipla.io"
                style={{
                  color: "#003370",
                  textDecoration: "none",
                  fontWeight: 700
                }}
              >
                Hipla
              </a>
            </p>
          </div>
          <br />
          <Grid container direction="row-reverse">
            <Grid item>
              <Button
                disabled={updatingTemplate}
                color="primary"
                variant="contained"
                onClick={async e => {
                  setUpdatingTemplate(true);
                  await handleUpdateTemplate(editingField);
                  setrteModalOpen(false);
                }}
              >
                {updatingTemplate && "Updating Template"}
                {!updatingTemplate && "Update Template"}
                
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </Box>
  );
};

Notification.propTypes = {
  className: PropTypes.string
};

export default Notification;
