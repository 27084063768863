import React, { useState } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Edit as EditIcon,
  Visibility as VisibilityIcon
} from "@material-ui/icons";
import ViewCredentialsModal from "./ViewCredentialsModal";
import EditAccessModal from "./EditAccessModal";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  button: {
    marginBottom: 10
  },
  content: {
    padding: 0,
    "&:last-child": {
      paddingBottom: 0
    }
  }
});

function UserAccessTable(props) {
  let { dataList, fetch } = props;
  const classes = useStyles();
  const [credentialsToView, setCredentialsToView] = useState([]);
  const [credentialsOpen, setCredentialsOpen] = useState(false);
  const [editAccessGroupOpen, setEditAccessGroupOpen] = useState(false);
  const [editAccessGroupData, setEditAccessGroupData] = useState({});

  return (
    <>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">User</TableCell>
            <TableCell align="center">Access Group</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataList.map(userAccess => (
            <TableRow key={userAccess.ID}>
              <TableCell align="center">{userAccess.tFirstName}</TableCell>
              <TableCell align="center">
                {userAccess.UserAccGrp.tDesc}
              </TableCell>
              <TableCell align="center">
                <IconButton
                  aria-label="edit"
                  onClick={() => {
                    setCredentialsToView(userAccess.UserCards);
                    setCredentialsOpen(true);
                  }}
                >
                  <VisibilityIcon fontSize="small" />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    setEditAccessGroupData(userAccess);
                    setEditAccessGroupOpen(true);
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ViewCredentialsModal
        open={credentialsOpen}
        onClose={e => {
          setCredentialsOpen(false);
        }}
        data={credentialsToView}
      />
      <EditAccessModal
        open={editAccessGroupOpen}
        onClose={e => {
          fetch();
          setEditAccessGroupOpen(false);
        }}
        payload={editAccessGroupData}
      />
    </>
  );
}

export default UserAccessTable;
