import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  makeStyles
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { Line } from "react-chartjs-2";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%"
  }
}));

const LineChart = ({
  month,
  totalCount,
  instantCount,
  scheduleCount,
  className,
  selectedDate,
  dateChangeData,
  ...rest
}) => {
  const classes = useStyles();
  const data = {
    labels: month,
    datasets: [
      {
        label: "Total Visit",
        fill: false,
        borderColor: "#FF9933",
        data: totalCount
      },
      {
        label: "Instant Visit",
        fill: false,
        borderColor: "#2196f3",
        data: instantCount
      },
      {
        label: "Scheduled Visit",
        fill: false,
        borderColor: "#0e3518dd",
        data: scheduleCount
      }
    ]
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: "bottom"
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            display: false
          }
        }
      ]
    }
  };

  let currMonthName = moment(selectedDate).format("MMMM YYYY");
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader
        action={
          <React.Fragment>
            <DatePicker
              views={["year", "month"]}
              minDate={new Date("1975-01-01")}
              maxDate={new Date()}
              value={selectedDate}
              onChange={dateChangeData}
            />
          </React.Fragment>
        }
        title={`Total meetings of ${currMonthName}`}
      />
      <Divider />
      <CardContent>
        <Line height={275} options={options} data={data} />
      </CardContent>
    </Card>
  );
};

LineChart.propTypes = {
  className: PropTypes.string
};

export default LineChart;
