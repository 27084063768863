import OpenLayersMap from "openlayers-navigine";
import React from "react";

class Tracking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true
    };
  }
  componentDidMount() {
    var get = async (api, endPoint, params = {}) => {
      try {
        let queryString = "";
        if (params) {
          queryString = "?";
          Object.keys(params).map((key, index, array) => {
            const andSymbmol = index + 1 === array.length ? "" : "&";
            queryString += `${key}=${params[key]}${andSymbmol}`;
            return key;
          });
        }
        const response = await fetch(`${api}/${endPoint}${queryString}`, {
          method: "get",
          headers: {
            "content-type": "application/json"
          }
        });

        if (response.status >= 400) {
          const bodyError = await response.json();
          throw new Error(bodyError);
        }

        return await response.json();
      } catch (err) {
        console.warn("httpGet error ", err);

        // throw new Error(err.message);
      }
    };

    (async () => {
      const config = {
        apiServerUrl: "https://nvapi.thesentinel.io/client",
        floorPath: "sublocations/get",
        beaconPath: "beacon/getAll",
        trackedObjectsPath: "tracked-objects",
        trackingServerUrl: "https://nvapi.thesentinel.io/tracking/v1",
        userHash: "A689-2DCD-D9C9-67C6",
        floorId: this.props.floorId,
        appKey: "78528be0-0149-fd3f-8f72-8fb05bc122a8",
        objectsActiveTime: 60,
        timerDelay: 1 // in seconds
      };
      const paramsForFloor = {
        userHash: config.userHash,
        sublocationId: config.floorId
      };

      await get(config.apiServerUrl, config.floorPath, paramsForFloor).then(
        async responseFloor => {
          const { sublocation } = responseFloor;
          this.sublocation = sublocation;
          this.map = new OpenLayersMap({
            target: "map",
            imageURL: this.sublocation.files[0].full_url,
            imageWidth: this.sublocation.dimensions_in_pixels.width,
            imageHeight: this.sublocation.dimensions_in_pixels.height,
          });
        }
      );

      const paramsForBeacons = {
        userHash: config.userHash,
        sublocationId: config.floorId
      };

      // await get(config.apiServerUrl, config.beaconPath, paramsForBeacons).then(
      //   async responseBeacons => {
      //     // const { beacons } = responseBeacons;
      //     const beacons = responseBeacons ? responseBeacons.beacons : [];
      //     this.map.addBeacons(beacons);
      //   }
      // );

      const paramsForTrackedObjects = {
        api_key: config.appKey,
        "filter%5Bsublocation.id%5D": config.floorId,
        actv: config.objectsActiveTime
      };

      await get(
        config.trackingServerUrl,
        config.trackedObjectsPath,
        paramsForTrackedObjects
      ).then(async responseFloor => {
        const { data } = responseFloor;
        this.map.addTrackedObjects(data);
      });

      setInterval(async () => {
        this.state.active &&
          (await get(
            config.trackingServerUrl,
            config.trackedObjectsPath,
            paramsForTrackedObjects
          ).then(async responseFloor => {
            const { data } = responseFloor;
            this.map.addTrackedObjects(data);
          }));
      }, config.timerDelay * 1000);
    })();
  }
  componentWillUnmount() {
    this.state.active = false;
  }
  render() {
    return (
      <div style={{ backgroundColor: "white" }}>
        <div
          id="map"
          className="map"
          style={{ width: "100%", height: window.innerHeight - 140 }}
        ></div>
      </div>
    );
  }
}

export default Tracking;
