import { LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles(theme => ({
  loading: {
    width: 320,
    margin: "180px auto"
  }
}));

const Loader = () => {
  const classes = useStyles();
  return <LinearProgress variant="indeterminate" className={classes.loading} />;
};

export default Loader;
