import { makeStyles } from "@material-ui/styles";
import React from "react";
import Page from "src/components/Page";
import VisitDetails from "src/views/visitDetails"

import { useLocation } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}));

const ViewVisit = () => {
	const classes = useStyles();
	var query = {};
  const location = useLocation();
  location.search
    .slice(1)
    .split("&")
    .forEach(item => {
      query[item.split("=")[0]] = item.split("=")[1];
    });

  return (
    <Page className={classes.root} title="Visit Details">
      <VisitDetails customToken={query.token} visitId={query.visitId} />
    </Page>
  );
};

export default ViewVisit;
