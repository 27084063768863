import {
  DialogActions,
  DialogContentText,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { AddCircleOutline, Close } from "@material-ui/icons";
import { captureException } from "@sentry/react";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/material.css";
import { useDispatch } from "react-redux";
import api from "src/utils/api";
import { toastAction } from "../../../actions";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    minWidth: 300,
    justifyContent: "space-between"
  },
  formControl: {
    marginTop: "16px"
  },
  formSelect: {
    marginBottom: "5px"
  },
  deviceModal: {
    "& .MuiDialog-paper": {
      maxWidth: 800,
      width: "100%"
    }
  },
  closeIcon: {
    marginLeft: "auto"
  },
  deviceTable: {
    padding: "0 50px"
  },
  addDeviceSetting: {
    marginBottom: 20
  },
  openAddSettingBtn: {
    padding: "0 25px",
    "& button": {
      float: "right"
    }
  }
}));

const DeleteDialog = props => {
  const { deleteOpen, handleDeleteClose, handleDelete } = props;
  return (
    <Dialog
      open={deleteOpen}
      onClose={handleDeleteClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure that you want to delete this device setting. <br />
          <b>This cannot be undone.</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeleteClose} color="primary" autoFocus>
          No
        </Button>
        <Button onClick={() => handleDelete()} color="secondary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeviceSettings = props => {
  const classes = useStyles();
  const { dataList, deviceId } = props;
  const initialData = {
    category: "",
    subCategory: "",
    url: "",
    deviceId: "",
    host: "",
    method: "",
    port: "",
    data: "",
    topic: "",
    delay: "",
    payload: "",
    id: ""
  };

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(initialData);
  const [isLoading, setLoader] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deviceSetting, setDeviceSetting] = useState(dataList);
  const [isIndex, setIsIndex] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [deleteIndex, setDeleteIndex] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setDeviceSetting(dataList);
  }, [dataList]);

  const handleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = e => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  const handleClose = () => {
    setIsOpen(!isOpen);
    setData({
      id: "",
      key: "",
      category: "",
      subCategory: "",
      deviceId: "",
      url: "",
      host: "",
      message: "",
      method: "",
      data: "",
      topic: "",
      delay: "",
      payload: ""
    });
    setIsEdit(false);
  };

  const deleteConfirm = (data, index) => {
    setDeleteData(data);
    setDeleteIndex(index);
    setDeleteOpen(true);
  };

  const handleDelete = async () => {
    try {
      await api("DELETE", `deviceSetting/${deleteData.id}`, null, true);
      let tempdeviceSetting = [...deviceSetting];
      tempdeviceSetting.splice(deleteIndex, 1);
      setDeviceSetting(tempdeviceSetting);
      setDeleteOpen(false);
      dispatch(
        toastAction({
          status: true,
          message: "Setting deleted successfully",
          type: "success"
        })
      );
    } catch (error) {
      captureException(error);
    }
  };

  const handleEdit = (data, index) => {
    setIsIndex(index);
    setIsEdit(true);
    setData({
      id: data.id || "",
      key: data.key || "",
      category: data.type || "",
      subCategory: data.sub_type || "",
      deviceId: data.deviceId || "",
      url: data.value.url || "",
      host: data.value.host || "",
      message: data.value.message || "",
      method: data.value.method || "",
      ipAddress: data.value.ipAddress || "",
      macAddress: data.value.macAddress || "",
      printerModel: data.value.printerModel || "",
      data: data.value.data || "",
      topic: data.value.topic || "",
      delay: data.value.delay || "",
      payload: data.value.payload || ""
    });
    setIsOpen(!isOpen);
  };

  const cancelHandle = () => {
    setIsOpen(!isOpen);
    setData({
      id: "",
      key: "",
      category: "",
      subCategory: "",
      deviceId: "",
      url: "",
      host: "",
      message: "",
      method: "",
      data: "",
      topic: "",
      delay: "",
      payload: ""
    });
    setIsEdit(false);
  };

  const addDevice = async () => {
    setLoader(true);
    try {
      var value;
      if (data.subCategory === "mqtt") {
        value = { topic: data.topic, message: data.message, delay: data.delay };
      } else if (data.subCategory === "http") {
        value = {
          url: data.url,
          method: data.method,
          data: data.data,
          delay: data.delay
        };
      }
      if (data.subCategory === "badgePrint") {
        value = {
          ipAddress: data.ipAddress,
          macAddress: data.macAddress,
          printerModel: data.printerModel,
          delay: data.delay
        };
      }
      const payload = {
        deviceId: deviceId,
        key: data.key,
        type: data.category,
        sub_type: data.subCategory,
        value: value
      };
      const device = await api("POST", "deviceSetting", payload, true);
      setDeviceSetting([...deviceSetting, device]);
      setData(initialData);
      setLoader(false);
      setIsOpen(!isOpen);
      dispatch(
        toastAction({
          status: true,
          message: "Setting successfully added",
          type: "success"
        })
      );
    } catch (error) {
      setLoader(false);
      dispatch(
        toastAction({
          status: true,
          message: error.response.data,
          type: "error"
        })
      );
    }
  };

  const updateDevice = async () => {
    setLoader(true);
    try {
      var value;
      if (data.subCategory === "mqtt") {
        value = { topic: data.topic, message: data.message, delay: data.delay };
      } else if (data.subCategory === "http") {
        value = {
          url: data.url,
          method: data.method,
          data: data.data,
          delay: data.delay
        };
      }
      if (data.subCategory === "badgePrint") {
        value = {
          ipAddress: data.ipAddress,
          macAddress: data.macAddress,
          printerModel: data.printerModel,
          delay: data.delay
        };
      }
      const payload = {
        deviceId: deviceId,
        key: data.key,
        type: data.category,
        sub_type: data.subCategory,
        value: value
      };
      const device = await api(
        "PATCH",
        `deviceSetting/${data.id}`,
        payload,
        true
      );
      let newDeviceSettings = [...deviceSetting];
      newDeviceSettings[isIndex] = device;
      setDeviceSetting(newDeviceSettings);
      setData(initialData);
      setIsEdit(false);
      setLoader(false);
      setIsOpen(!isOpen);
      dispatch(
        toastAction({
          status: true,
          message: "Setting successfully updated",
          type: "success"
        })
      );
    } catch (error) {
      setLoader(false);
      dispatch(
        toastAction({
          status: true,
          message: error.response.data,
          type: "error"
        })
      );
    }
  };

  return (
    <div>
      <Grid
        alignItems="flex-start"
        container
        justify="space-between"
        spacing={3}
        direction="row"
      >
        <Grid item>
          <Typography variant="h4" color="primary">
            Device Settings
          </Typography>
        </Grid>
        <Grid item>
          <Button
            startIcon={<AddCircleOutline />}
            variant="outlined"
            color="primary"
            onClick={handleModal}
          >
            Add device setting
          </Button>
        </Grid>
      </Grid>

      <Dialog
        open={isOpen}
        className={classes.deviceModal}
        onClose={handleClose}
      >
        <DialogTitle>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            className={classes.addDeviceSetting}
          >
            <Typography component="h1" variant="h3" color="primary">
              Add Device Settings
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="SelectKey">Select Key</InputLabel>
                <Select
                  labelId="SelectKey"
                  name="key"
                  value={data.key}
                  onChange={handleChange}
                  label="Select Key"
                >
                  <MenuItem value="postCheckin">Post Checkin</MenuItem>
                  <MenuItem value="navigineListner">Navigine Listener</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="SelectCateSgory">Select category</InputLabel>
                <Select
                  labelId="SelectCatagory"
                  name="category"
                  value={data.category}
                  onChange={handleChange}
                  label="Select catagory"
                >
                  <MenuItem value="webHook">Web Hook</MenuItem>
                  <MenuItem value="deviceHook">Device Hook</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="SelectSubCategory">
                  Select Sub-Category
                </InputLabel>
                <Select
                  labelId="SelectSubCategory"
                  name="subCategory"
                  value={data.subCategory}
                  onChange={handleChange}
                  label="Select Sub-Category"
                >
                  <MenuItem value="http">HTTP</MenuItem>
                  <MenuItem value="mqtt">MQTT</MenuItem>
                  <MenuItem value="badgePrint">Badge Print</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                name="delay"
                type="number"
                label="Delay (in seconds)"
                value={data.delay}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Divider />

            {data.subCategory === "mqtt" && (
              <>
                <Grid item xs={12}>
                  <Typography component="h4">MQTT Configuration </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="topic"
                    label="Enter topic"
                    value={data.topic}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="message"
                    label="Enter message"
                    value={data.message}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
              </>
            )}
            {data.subCategory === "http" && (
              <>
                <Grid item xs={12}>
                  <Typography component="h4">HTTP Configuration </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="url"
                    label="Enter url"
                    value={data.url}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="data"
                    label="Enter Data"
                    value={data.data}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="selectMethod">Select method</InputLabel>
                    <Select
                      labelId="selectMethod"
                      name="method"
                      value={data.method}
                      onChange={handleChange}
                      label="Select Sub-Category"
                    >
                      <MenuItem value="GET">GET</MenuItem>
                      <MenuItem value="POST">POST</MenuItem>
                      <MenuItem value="DELETE">DELETE</MenuItem>
                      <MenuItem value="PATCH">PATCH</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}
            {data.subCategory === "badgePrint" && (
              <>
                <Grid item xs={12}>
                  <Typography component="h4">
                    Wireless Printer Configuration{" "}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="macAddress"
                    label="Enter MacId"
                    value={data.macAddress}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="ipAddress"
                    label="Enter IP"
                    value={data.ipAddress}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    name="printerModel"
                    label="Enter Model name"
                    value={data.printerModel}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {isEdit ? (
                <Button
                  className={classes.closeIcon}
                  disabled={isLoading}
                  variant="contained"
                  color="primary"
                  onClick={updateDevice}
                >
                  Update
                </Button>
              ) : (
                <Button
                  className={classes.closeIcon}
                  disabled={isLoading}
                  variant="contained"
                  color="primary"
                  onClick={addDevice}
                >
                  {isLoading ? "Loading" : "save"}
                </Button>
              )}
              <Button
                style={{ marginLeft: "10px" }}
                variant="outlined"
                onClick={cancelHandle}
                color="secondary"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
      </Dialog>

      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">SL No</TableCell>
            <TableCell align="left">Type</TableCell>
            <TableCell align="left">Sub Type</TableCell>
            <TableCell align="left">Value</TableCell>
            <TableCell align="left">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {deviceSetting.length > 0 ? (
            deviceSetting.map((setting, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align="left">{index + 1}</TableCell>
                  <TableCell align="left">{setting.type}</TableCell>
                  <TableCell align="left">{setting.sub_type}</TableCell>
                  <TableCell align="left">
                    {JSON.stringify(setting.value)}
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleEdit(setting, index)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      style={{ marginLeft: "10px" }}
                      color="secondary"
                      onClick={() => {
                        deleteConfirm(setting, index);
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <></>
          )}
        </TableBody>
      </Table>

      <DeleteDialog
        deleteOpen={deleteOpen}
        handleDeleteClose={() => setDeleteOpen(false)}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default DeviceSettings;
